
import { act, render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import * as service from '../helpers/services';
import UpfPaymentPage from '../index';
import { mockChargeResponse, noCustomFieldsNoSurcharge, loanPaymentPage } from './UpfPaymentPageTestProps';

jest.mock('../../../lib/utils', () => ({
  ...jest.requireActual('../../../lib/utils'),
  rollbarLog: jest.fn(),
  safeSendGA: jest.fn()
}));

describe('Payment Page Submit Happy Path Tests', () => {
  beforeEach(() => {
    jest.spyOn(window.navigator, 'languages', 'get').mockReturnValue(['en-US']);
  });

  it('Shows receipt on charge success', async () => {
    jest.spyOn(service, 'getChargeReceipt').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            data: { attributes: { receipt_html: '<div>foobar receipt</div>' }}
          });
        })
      })
    );
    render(<UpfPaymentPage {...noCustomFieldsNoSurcharge} />);
    expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
    expect(screen.queryByText('foobar receipt')).not.toBeInTheDocument();

    act(() => {
      const event = new CustomEvent('UPFChargeSuccess', { detail: { id: 'transaction_id', amount: 1000, method: {type: 'credit_card' } } });
      window.document.dispatchEvent(event);
    });
    await waitFor(() => expect(service.getChargeReceipt).toHaveBeenCalledTimes(1));
    expect(service.getChargeReceipt).toHaveBeenLastCalledWith(`${noCustomFieldsNoSurcharge.api_gw_url}/v2/receipts/transaction_id`, noCustomFieldsNoSurcharge.token);

    expect(screen.getByTestId('thank-you-page')).toBeInTheDocument();
    expect(screen.getByText('foobar receipt')).toBeInTheDocument();
    expect(screen.queryByText('Your payment is being processed. You should receive an email with your payment receipt shortly.')).not.toBeInTheDocument();
  });

  it('Shows generic thank you page on charge success but receipt request failure', async () => {
    jest.spyOn(service, 'getChargeReceipt').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        ok: false
      })
    );
    render(<UpfPaymentPage {...noCustomFieldsNoSurcharge} />);
    expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
    expect(screen.queryByText('foobar receipt')).not.toBeInTheDocument();

    act(() => {
      const event = new CustomEvent('UPFChargeSuccess', { detail: { id: 'transaction_id', amount: 1000, method: {type: 'credit_card' } } });
      window.document.dispatchEvent(event);
    });
    await waitFor(() => expect(service.getChargeReceipt).toHaveBeenCalledTimes(1));
    expect(service.getChargeReceipt).toHaveBeenLastCalledWith(`${noCustomFieldsNoSurcharge.api_gw_url}/v2/receipts/transaction_id`, noCustomFieldsNoSurcharge.token);

    expect(screen.getByTestId('thank-you-page')).toBeInTheDocument();
    expect(screen.queryByText('foobar receipt')).not.toBeInTheDocument();
    expect(screen.getByText('Your payment is being processed. You should receive an email with your payment receipt shortly.')).toBeInTheDocument();
  });

  it('Shows Loan Thank You page on loan charge success', async () => {
    render(<UpfPaymentPage {...loanPaymentPage} />);
    expect(screen.queryByTestId('loan-thank-you-page')).not.toBeInTheDocument();

    act(() => {
      const event = new CustomEvent('UPFChargeSuccess', { detail: { id: 'loan_id', amount: 1000, method: {type: 'loan' } } });
      window.document.dispatchEvent(event);
    });

    await waitFor(() => {
      expect(screen.getByTestId('loan-thank-you-page')).toBeInTheDocument();
    });
  });

  it('Disables submit button in UPF while charge is submitting to VT backend', async () => {
    jest.spyOn(service, 'getChargeReceipt').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            data: { attributes: { receipt_html: '<div>foobar receipt</div>' } }
          });
        })
      })
    );
    const { container } = render(<UpfPaymentPage {...noCustomFieldsNoSurcharge} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf.getAttribute('submit-disabled')).toEqual('false');
    act(() => {
      const event = new CustomEvent('UPFChargeSuccess', { detail: { id: 'transaction_id', amount: 1000, method: {type: 'credit_card' } } });
      window.document.dispatchEvent(event);
    });
    expect(upf.getAttribute('submit-disabled')).toEqual('true');
    await waitFor(() => expect(service.getChargeReceipt).toHaveBeenCalledTimes(1));
    expect(service.getChargeReceipt).toHaveBeenLastCalledWith(`${noCustomFieldsNoSurcharge.api_gw_url}/v2/receipts/transaction_id`, noCustomFieldsNoSurcharge.token);
  });

  it('Page with Signature submit', async () => {
    jest.spyOn(service, 'getChargeReceipt').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            data: {
              attributes: { 
                receipt_html: '<div>foobar receipt</div>',
                ...mockChargeResponse
              }}
          });
        })
      })
    );
    const props = { ...noCustomFieldsNoSurcharge, signature: 2 };
    render(<UpfPaymentPage {...props} />);
    expect(screen.queryByTestId('signature')).not.toBeInTheDocument();
    act(() => {
      const event = new CustomEvent('UPFChargeSuccess', { detail: { id: 'transaction_id', amount: 1000, method: {type: 'credit_card' } } });
      window.document.dispatchEvent(event);
    });
    await waitFor(() => expect(service.getChargeReceipt).toHaveBeenCalledTimes(1));
    expect(service.getChargeReceipt).toHaveBeenLastCalledWith(`${noCustomFieldsNoSurcharge.api_gw_url}/v2/receipts/transaction_id`, noCustomFieldsNoSurcharge.token);

    expect(screen.getByTestId('signature')).toBeInTheDocument();
  });
});