import { fireEvent, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import PaymentPageLegacy from '../index';
import { cardAndEcheckNoCustomFieldsWithSurcharge as noSwiperProps, swiperWithCustomFields as swiperPropsCF } from './PaymentPageTestProps';
import { getAPSelect } from './testHelpers';

describe('Payment Page Swiper Tests', () => {
  const mockSetCardIframeInputText = jest.fn(),
    mockFocusIframeInput = jest.fn(),
    mockEnableIframeInput = jest.fn();
  const expectSuccessfulSwipe = () => {
    expect(screen.getByLabelText('payment-form')).toHaveFormValues({
      cardName: 'A GIFT FOR YOU',
      cardExpiration: '06/2028'
    });
    expect(mockSetCardIframeInputText).toHaveBeenCalledWith('4030150315278670');
  };
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
    const HFInit = jest.fn((init, callback) => {
      global.HFCallbackExposed = callback;
      return {
        getPaymentToken: jest.fn(() => Promise.resolve({})),
        getState: jest.fn(() => {
          return {
            isReady: true,
            fields: [
              {
                selector: '#cc_number',
                type: 'credit_card_number',
                error: '',
                length: 16,
                card: 'visa',
                luhn: true,
                surchargeable: true,
                focus: false
              }
            ]
          };
        }),
        setCardIframeInputText: mockSetCardIframeInputText,
        focusIframeInput: mockFocusIframeInput,
        clearIframeInput: jest.fn(),
        disableIframeInput: jest.fn(),
        enableIframeInput: mockEnableIframeInput
      };
    });
    global.AffiniPay = {
      HostedFields: {
        initializeFields: HFInit
      }
    };
  });

  it('No button is present when swipe and swipe is ignored is not enabled in settings', () => {
    render(<PaymentPageLegacy {...noSwiperProps} />);
    expect(screen.queryByLabelText('Swipe Card')).not.toBeInTheDocument();
    expect(screen.queryByTestId('swipeData')).not.toBeInTheDocument();
  });

  it('Button is shown, hidden element is in DOM when enabled in settings', () => {
    render(<PaymentPageLegacy {...swiperPropsCF} />);
    expect(screen.queryByText('Swipe Card')).toBeInTheDocument();
    expect(screen.queryByTestId('swipeData')).toBeInTheDocument();
  });

  it('Clicking Swipe Card button disables all fields but CVV and enables swipe policies', () => {
    render(<PaymentPageLegacy {...swiperPropsCF} />);
    userEvent.click(screen.getByText('Swipe Card'));
    expect(screen.getByLabelText('Reference')).toBeDisabled();
    expect(screen.getAllByLabelText('Receipt Email')[0]).toBeDisabled();
    expect(screen.getByLabelText('Name on Card')).toBeDisabled();
    expect(screen.getAllByLabelText('Address')[0]).toBeDisabled();
    expect(screen.getAllByLabelText('City')[0]).toBeDisabled();
    expect(screen.getAllByLabelText('State')[0]).toBeDisabled();
    expect(screen.getAllByLabelText('Country')[0]).toBeDisabled();
    expect(screen.getAllByLabelText('Zip / Postal Code')[0]).toBeDisabled();
    //Custom Amount Fields
    expect(screen.getByLabelText('Blank')).toBeDisabled();
    expect(screen.getByLabelText('Amount and qty (2 and 3)')).toBeDisabled();
    //Custom Fields
    expect(screen.getByLabelText('tf not require')).toBeDisabled();
    expect(screen.getByLabelText('tf require')).toBeDisabled();
    expect(screen.getByLabelText('ta not require')).toBeDisabled();
    expect(screen.getByLabelText('ta req')).toBeDisabled();
    expect(screen.getByLabelText('ch not require')).toBeDisabled();
    expect(screen.getByLabelText('ch req')).toBeDisabled();
    expect(getAPSelect('s not req')).toBeDisabled();
    expect(getAPSelect('s req')).toBeDisabled();
  });

  it('Clicking cancel swipe enables all fields and returns to default and url preset', () => {
    const params = { email: 'foobar@email.com', readOnlyFields: 'email' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<PaymentPageLegacy {...swiperPropsCF} />);

    userEvent.click(screen.getByText('Swipe Card'));
    userEvent.click(screen.getByText('Cancel'));

    expectToGetBackToManualState();
  });

  it('Swiping card correctly lands values in fields', () => {
    render(<PaymentPageLegacy {...swiperPropsCF} />);
    swipeCard();

    expectSuccessfulSwipe();
  });

  it('Swiping card respects swipe policies', async () => {
    const params = { email: 'foobar@email.com', readOnlyFields: 'email' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<PaymentPageLegacy {...swiperPropsCF} />);
    swipeCard();

    userEvent.click(screen.getByText('Pay $108.00'));

    expect(await screen.findByText('Billing State is required.')).toBeInTheDocument();
    expect(await screen.findByText('Billing Zip/Postal Code is required.')).toBeInTheDocument();
  });

  it('Swiping and clicking cancel returns to manual policies and enables card fields', async () => {
    const params = { email: 'foobar@email.com', readOnlyFields: 'email' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<PaymentPageLegacy {...swiperPropsCF} />);

    swipeCard();
    userEvent.click(screen.getByText('Swipe Card'));
    userEvent.click(screen.getByText('Cancel'));

    expectSuccessfulSwipe();
    expect(screen.getByLabelText('Name on Card')).not.toBeDisabled();
    expect(mockEnableIframeInput).toHaveBeenCalled();

    userEvent.click(screen.getByText('Pay $108.00'));
    expect(await screen.findByText('A valid CVV is required')).toBeInTheDocument();
  });

  it('Failed swipe shows error', () => {
    const params = { email: 'foobar@email.com', readOnlyFields: 'email' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<PaymentPageLegacy {...swiperPropsCF} />);
    userEvent.click(screen.getByText('Swipe Card'));
    fireEvent.keyDown(screen.getByTestId('swipeData'), { key: 'Enter', code: 'Enter', keyCode: 13, charCode: 13, target: { value: '%B4030150315278670^YOU/A GIFT FOR            ^2806521000000000000000058000000?;foobar=failedswipe?' } });

    expect(screen.getByText('Swipe Error!')).toBeInTheDocument();
    expectToGetBackToManualState();
  });

  it('Clicking anywhere on the document in swipe mode disables it', () => {
    render(<PaymentPageLegacy {...swiperPropsCF} />);
    userEvent.click(screen.getByText('Swipe Card'));
    userEvent.click(screen.getByText('eCheck'));

    expect(screen.getByLabelText('Reference')).not.toBeDisabled();
    expectToGetBackToManualState();
  });

});

const swipeCard = () => {
  userEvent.click(screen.getByText('Swipe Card'));
  fireEvent.keyDown(screen.getByTestId('swipeData'), { key: 'Enter', code: 'Enter', keyCode: 13, charCode: 13, target: { value: '%B4030150315278670^YOU/A GIFT FOR            ^2806521000000000000000058000000?;4030150315278670=28065210000000000580?' } });
};

const expectToGetBackToManualState = () => {
  expect(screen.getByLabelText('Reference')).not.toBeDisabled();
  expect(screen.getAllByLabelText('Receipt Email')[0]).toBeDisabled();
  expect(screen.getByLabelText('Name on Card')).not.toBeDisabled();
  expect(screen.getAllByLabelText('Address')[0]).not.toBeDisabled();
  expect(screen.getAllByLabelText('City')[0]).not.toBeDisabled();
  expect(screen.getAllByLabelText('State')[0]).not.toBeDisabled();
  expect(screen.getAllByLabelText('Country')[0]).not.toBeDisabled();
  expect(screen.getAllByLabelText('Zip / Postal Code')[0]).not.toBeDisabled();
  //Custom Amount Fields
  expect(screen.getByLabelText('Blank')).not.toBeDisabled();
  expect(screen.getByLabelText('Amount and qty (2 and 3)')).not.toBeDisabled();
  //Custom Fields
  expect(screen.getByLabelText('tf not require')).not.toBeDisabled();
  expect(screen.getByLabelText('tf require')).not.toBeDisabled();
  expect(screen.getByLabelText('ta not require')).not.toBeDisabled();
  expect(screen.getByLabelText('ta req')).not.toBeDisabled();
  expect(screen.getByLabelText('ch not require')).not.toBeDisabled();
  expect(screen.getByLabelText('ch req')).not.toBeDisabled();
  expect(getAPSelect('s not req')).not.toBeDisabled();
  expect(getAPSelect('s req')).not.toBeDisabled();
};
/*
Helper method to use in console to emulate old wiper swipe
window.emulateSwipe = () => {
  let event = {};
  event.keyCode=13;
  event.target={};
  event.target.value='%B4030150315278670^YOU/A GIFT FOR            ^2806521000000000000000058000000?;4030150315278670=28065210000000000580?';
  return handleSwipeEvent(event);
};
*/