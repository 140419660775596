import React from 'react';
import {shallow, mount} from 'enzyme';
import AdHoc from '../adhoc';

describe('AdHoc Component', () => {
  it('shallow snapshot render', () => {
    const mockProps = getMockProps('new'),
      component = shallow(<AdHoc {...mockProps}/>);
    expect(component).toMatchSnapshot();
  });
  it('shallow snapshot render with no errors and destroy', () => {
    const mockProps = getMockProps('no errors destroy'),
      component = shallow(<AdHoc {...mockProps}/>);
    expect(component).toMatchSnapshot();
  });
  it('mount with no fees', () => {
    const mockProps = getMockProps('no fees'),
      component = mount(<AdHoc {...mockProps}/>);
    component.find('a').simulate('click');
    expect(component.state('fees')).toEqual([ { id: null, name: 'pci_non_compliance', frequency: 'MONTHLY', errors: null,'effective_month': '', 'effective_year': '', rate: ''} ]);
    component.unmount();
  });
  it('mount for events', () => {
    const mockProps = getMockProps('new'),
      component = mount(<AdHoc {...mockProps}/>);
    component.find('#plan_ad_hoc_fees_attributes_0_name').at(0).simulate('change', {target: {dataset: ''}});
    expect(component.state('fees')[0].name).toEqual('foo');
    component.unmount();
  });
});

const getMockProps = (type) => {
  switch(type) {
  case 'new':
    return {
      fees: [{
        id: 1,
        name: 'foo',
        _destroy: false,
        errors: {
          name: ['foo', 'bar'],
          amount: ['foo', 'bar'],
          frequency: ['foo', 'bar']
        },
        amount: '22',
        frequency: 'very foo',
        effective_month: '2',
        effective_year: '2',
        resourceName: 'merchant_application'
      }],
      withEffectiveDate: true
    };
  case 'no errors destroy':
    return {
      fees: [{
        name: 'foo',
        _destroy: true,
        errors: {},
        amount: '22',
        frequency: 'very foo',
        effective_month: '2',
        effective_year: '2',
        resourceName: 'merchant_application'
      }],
      withEffectiveDate: false
    };
  case 'no fees':
    return {
      withEffectiveDate: true
    };
  default:
    return;
  }
};
