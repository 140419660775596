import React from 'react';
import {render, screen} from '@testing-library/react';
import QRCodeCard from '../QRCodeCard';

describe('QRCodeCard Component', () => {
  it('renders without props passed', () => {
    render(<QRCodeCard />);
    expect(screen.getByText('Scan To Pay')).toBeVisible();
  });
  
  it('renders with appropriate props passed', () => {
    render(<QRCodeCard  
      currentMerchantName='Bird Law LLC'
      id='asdf1234'
      paymentPath='philadelphiabirdlaw.com'
      paymentTitle='Troll Toll for Boy Soul'
      site = 'affinipay'
    />);

    expect(screen.getByText('Scan To Pay')).toBeVisible();
  });

  it('applies the CPA Charge class when the site prop is cpacharge', () => {
    const {container} =     render(<QRCodeCard  
      currentMerchantName='Bird Law LLC'
      id='asdf1234'
      paymentPath='philadelphiabirdlaw.com'
      paymentTitle='Troll Toll for Boy Soul'
      site = 'cpacharge'
    />);
    
    expect(container.firstChild.firstChild).toHaveClass('qr-card-header-cpacharge');
  });
});
