import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ClientCreditDropdown from '../';

describe('ClientCreditDropdown', () => {

  it('shows Please Select a value label when cc_intent_status is unknown', async () => {
    render(<ClientCreditDropdown  clientCreditStatus={'unknown'} />);
    expect(screen.getByText('Please Select a value')).toBeInTheDocument();
    expect(screen.queryByText('Opt-out')).not.toBeInTheDocument();
    expect(screen.queryByText('Opt-out')).not.toBeInTheDocument();
  });

  it('selecting the opt_out option raises a confirmation modal', async () => {
    render(<ClientCreditDropdown  clientCreditStatus={'opt_in'} />);

    expect(screen.getByText('Opt-in')).toBeInTheDocument();
    expect(screen.queryByText('Please Select a value')).not.toBeInTheDocument();
    expect(screen.queryByText('Opt-out')).not.toBeInTheDocument();

    userEvent.click(screen.getByText('Opt-in'));
    userEvent.click(screen.getByText('Opt-out'));

    expect(screen.getByText('This action will deactivate the loan processing account from this merchant. Are you sure you want to continue?')).toBeInTheDocument();
    expect(screen.getByText('No, cancel')).toBeInTheDocument();
    expect(screen.getByText('Yes, deactivate')).toBeInTheDocument();
  });

  it('selecting the opt_in option does not raise a confirmation modal', async () => {
    render(<ClientCreditDropdown  clientCreditStatus={'opt_out'} />);

    expect(screen.getByText('Opt-out')).toBeInTheDocument();
    expect(screen.queryByText('Please Select a value')).not.toBeInTheDocument();
    expect(screen.queryByText('Opt-in')).not.toBeInTheDocument();

    userEvent.click(screen.getByText('Opt-out'));
    userEvent.click(screen.getByText('Opt-in'));

    expect(screen.queryByText('This action will deactivate the loan processing account from this merchant. Are you sure you want to continue?')).not.toBeInTheDocument();
    expect(screen.queryByText('No, cancel')).not.toBeInTheDocument();
    expect(screen.queryByText('Yes, deactivate')).not.toBeInTheDocument();
  });

  it('selecting the opt_in option shows reminder message', async () => {
    render(<ClientCreditDropdown  clientCreditStatus={'unknown'} />);

    expect(screen.getByText('Please Select a value')).toBeInTheDocument();
    expect(screen.queryByText('Opt-out')).not.toBeInTheDocument();
    expect(screen.queryByText('Opt-in')).not.toBeInTheDocument();

    userEvent.click(screen.getByText('Please Select a value'));
    userEvent.click(screen.getByText('Opt-in'));

    expect(screen.queryByText('Please remember to do the following after saving this change:')).toBeInTheDocument();
    expect(screen.queryByText('1. Create a loan processing account for this merchant (if it does not already exist)')).toBeInTheDocument();
    expect(screen.queryByText('2. Set up the loan account in the merchant’s payment page(s)')).toBeInTheDocument();
  });
});
