import React from 'react';
import {render, screen} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import DepositAccountsListing from '../DepositAccountsListing';
import {mockAccounts} from './mocks';

describe('DepositAccountsListing', () => {
  it('renders with "No Accounts" when no accounts passed', () => {
    render(<table><DepositAccountsListing /></table>);
    expect(screen.getByText('No Accounts')).toBeVisible();
  });

  it('renders with accounts listed when they are passed', () => {
    render(<table><DepositAccountsListing accounts={mockAccounts} handleModalOpen={jest.fn()} /></table>);

    mockAccounts.forEach(({bankName, accountIsTrust, maskedAccount, name}) => {
      if (accountIsTrust) bankName = `Trust - ${bankName}`;
      expect(screen.getByText(bankName)).toBeVisible();
      expect(screen.getByText(name)).toBeVisible();
      expect(screen.getByText(maskedAccount)).toBeVisible();
    });
  });

  it('renders Trust Icon for Trust Accounts', () => {
    render(<table><DepositAccountsListing accounts={mockAccounts} handleModalOpen={jest.fn()} /></table>);

    expect(screen.getAllByTitle('Trust Account Icon').length).toEqual(3);
  });

  it('renders Edit button for active accounts', () => {
    render(<table><DepositAccountsListing accounts={mockAccounts} handleModalOpen={jest.fn()} /></table>);

    expect(screen.getAllByText('Edit').length).toEqual(8);
  });

  it('handleModalOpen is invoked when Edit button is clicked', () => {
    const handleModalOpen = jest.fn();
    render(<table><DepositAccountsListing accounts={mockAccounts} handleModalOpen={handleModalOpen} /></table>);
    const firstEditButton = screen.getAllByText('Edit')[0];
    userEvent.click(firstEditButton);
    expect(handleModalOpen).toHaveBeenCalled();
    expect(handleModalOpen).toHaveBeenCalledWith(expect.objectContaining({
      accountIsActive: true,
      accountIsTrust: false,
      bankName: 'Bank of America',
      displayType: 'Card',
      id: 24,
      maskedAccount: '*******0011',
      name: 'Operating'
    }));
  });

  it('renders INACTIVE text for inactive accounts', () => {
    render(<table><DepositAccountsListing accounts={mockAccounts} handleModalOpen={jest.fn()} /></table>);

    expect(screen.getAllByText('INACTIVE').length).toEqual(1);
  });

  it('if no bank account present N/A is shown in bank details cell', () => {
    const accounts = [
      {
        accountIsActive: false,
        accountIsTrust: true,
        bankName: undefined,
        bankAccountPresent: false,
        displayType: 'eCheck',
        id: 32,
        maskedAccount: '******0019',
        name: 'Wells Trust ACH'
      }
    ];
    render(<table><DepositAccountsListing accounts={accounts} handleModalOpen={jest.fn()} /></table>);

    expect(screen.getByText('N/A')).toBeVisible();
  });

  it('accounts without masked account numbers render without that div', () => {
    const accounts = [
      {
        accountIsActive: true,
        accountIsTrust: true,
        bankName: 'Wells Fargo',
        bankAccountPresent: true,
        displayType: 'eCheck',
        id: 32,
        maskedAccount: undefined,
        name: 'Wells Trust ACH'
      }
    ];
    const {container} = render(<table><DepositAccountsListing accounts={accounts} handleModalOpen={jest.fn()} /></table>);
    expect(container.querySelector('.account-title-masked-number')).not.toBeInTheDocument();
  });
});