import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import AttachmentsList from '../QuickBillAttachments/AttachmentsList';

const attachments = [
  {
    fileName: 'law-document.pdf',
    id: '123abc'
  },
  {
    fileName: 'law-contract.doc',
    id: '987zxy'
  },
  {
    fileName: 'invoice.xlsx',
    id: 'abcxyz-2'
  }
];

describe('AttachementsButton', () => {
  const  handleRemove = () => {};

  it('renders an empty list if attachments is empty', () => {
    render(<AttachmentsList attachments={[]} removeAttachment={handleRemove} />);
    expect(screen.getByTestId('quick-bill-attachments-list')).toBeVisible();
  });

  it('properly displays attachments', () => {
    render(<AttachmentsList removeAttachment={handleRemove} attachments={attachments} />);
    expect(screen.getByText('law-document.pdf')).toBeVisible();
    expect(screen.getByText('law-contract.doc')).toBeVisible();
    expect(screen.getByText('invoice.xlsx')).toBeVisible();
  });

  it('handleRemove function prop is invoked with correct id when clicked', () => {
    const removeAttachment = jest.fn();
    render(
      <AttachmentsList
        attachments={attachments}
        removeAttachment={removeAttachment}
      />
    );
    userEvent.click(screen.getAllByText('Remove')[1]);
    userEvent.click(screen.getByLabelText('Remove Attachment'));
    expect(removeAttachment).toHaveBeenCalled();
    expect(removeAttachment).toHaveBeenCalledWith('987zxy');
  });
});
