
import { fireEvent, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import * as service from '../../../lib/paymentPage/services';
import PaymentPageComponent from '../index';
import { cardAndEcheckNoCustomFieldsWithSurcharge } from './PaymentPageTestProps';
import { simulateSelectEventInAPSelect } from './testHelpers';

describe('Payment Page Surcharge', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
    const HFInit = jest.fn((init, callback) => {
      global.HFCallbackExposed = callback;
      return {
        getPaymentToken: jest.fn(() => Promise.resolve({})),
        getState: jest.fn(() => {
          return {
            isReady: true,
            fields: [
              {
                selector: '#cc_number',
                type: 'credit_card_number',
                error: '',
                length: 16,
                card: 'visa',
                luhn: true,
                surchargeable: true,
                focus: false
              }
            ]
          };
        }),
        setCardIframeInputText: jest.fn(),
        clearIframeInput: jest.fn()
      };
    });
    global.AffiniPay = {
      HostedFields: {
        initializeFields: HFInit
      }
    };
    global.grecaptcha = {
      ready: callback => { callback(); },
      execute: () => Promise.resolve({})
    };
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>Custom Fields receipt</div>'
          });
        })
      })
    );
  });

  it('Card percent surcharge flow', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsWithSurcharge} />);
    userEvent.click(screen.getByTestId('Card-test-id'));
    fillOutTheForm();
    setTimeout(() => {
      expectNoSurcharge();
      HostedFieldsDecidesCardIsSurchargeable();
      expectSurcharge();
      HostedFieldsDecidesCardIsNOTSurchargeable();
      expectNoSurcharge();
      HostedFieldsDecidesCardIsSurchargeable();
      const receipt = screen.queryByText('Receipt Email');
      expect(receipt).toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('Echeck flat surcharge flow', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsWithSurcharge} />);
    userEvent.click(screen.getByTestId('Card-test-id'));
    userEvent.type(screen.getByLabelText('Payment Amount'), '1.93');
    userEvent.type(screen.getByLabelText('First Name'), 'John');
    userEvent.type(screen.getByLabelText('Last Name'), 'Doe');
    userEvent.click(screen.getByTestId('submit-btn-card'));

    setTimeout(() => {
      expect(screen.getByTestId('surcharge-label')).toHaveTextContent('Flat dollar fee');
      expect(screen.getByTestId('surcharge-value')).toHaveTextContent('$2.99');
      expect(screen.getByTestId('total')).toHaveTextContent('$4.92');
      const thankYouPage = screen.queryByTestId('thank-you-page'),
        receipt = screen.queryByText('Custom Fields receipt');
      expect(thankYouPage).toBeInTheDocument();
      expect(receipt).toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);

  });
});

const HostedFieldsDecidesCardIsSurchargeable = () => {
  global.HFCallbackExposed({
    fields: [
      { type: 'cvv', length: 3 },
      {
        selector: '#cc_number',
        type: 'credit_card_number',
        error: '',
        length: 16,
        card: 'visa',
        luhn: true,
        surchargeable: true,
        focus: false
      }
    ]
  });
};

const HostedFieldsDecidesCardIsNOTSurchargeable = () => {
  global.HFCallbackExposed({
    fields: [
      { type: 'cvv', length: 3 },
      {
        selector: '#cc_number',
        type: 'credit_card_number',
        error: '',
        length: 16,
        card: 'visa',
        luhn: true,
        surchargeable: false,
        focus: false
      }
    ]
  });
};

const expectSurcharge = () => {
  expect(screen.getByTestId('surcharge-label')).toHaveTextContent('Percent Fee (1.5%)');
  expect(screen.getByTestId('surcharge-value')).toHaveTextContent('$0.50');
  expect(screen.getByTestId('submit-btn-card')).toHaveTextContent('Pay $33.50');
};


const expectNoSurcharge = () => {
  expect(screen.queryByText('Percent Fee')).not.toBeInTheDocument();
  expect(screen.getByTestId('submit-btn-card')).toHaveTextContent('Pay $33.00');

};

const fillOutTheForm = () => {
  userEvent.type(screen.getByLabelText('Payment Amount'), '33');
  userEvent.type(screen.getByLabelText('Reference'), 'Invoice 123');
  userEvent.type(screen.getAllByLabelText('Receipt Email')[0], 'foobar@affinipay.com');
  userEvent.type(screen.getByLabelText('Name on Card'), 'John Doe');
  fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '1225' } });
  userEvent.type(screen.getAllByLabelText('Address')[0], '123 Main St');
  userEvent.type(screen.getAllByLabelText('Address 2')[0], 'apt 5a');
  userEvent.type(screen.getAllByLabelText('City')[0], 'Austin');
  simulateSelectEventInAPSelect('Country', 'Canada');
  simulateSelectEventInAPSelect('State', 'Manitoba');
  userEvent.type(screen.getAllByLabelText('Zip / Postal Code')[0], '11001');
};
