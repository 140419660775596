
import { fireEvent, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import { paymentPageErrors } from '../../../lib/paymentPage/constants';
import * as service from '../../../lib/paymentPage/services';
import PaymentPageComponent from '../index';
import { cardAndEcheckNoCustomFieldsNoSurcharge } from './PaymentPageTestProps';
import { simulateSelectEventInAPSelect } from './testHelpers';


describe('Payment Page Submit Non Happy Path Tests', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
    const HFInit = jest.fn((init, callback) => {
      global.HFCallbackExposed = callback;
      return {
        getPaymentToken: jest.fn(() => Promise.resolve({})),
        getState: jest.fn(() => {
          return {
            isReady: true
          };
        }),
        setCardIframeInputText: jest.fn(),
        clearIframeInput: jest.fn()
      };
    });
    global.AffiniPay = {
      HostedFields: {
        initializeFields: HFInit
      }
    };
    global.grecaptcha = {
      ready: callback => { callback(); },
      execute: () => Promise.resolve({})
    };
  });

  it('Vanilla Payment Page submit denied by V2 Captcha', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>'
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Please verify that you are not a robot.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('Vanilla Payment Page submit denied by backend', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>'
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });


  it('$0 amount shows validation error', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent(paymentPageErrors.zeroChargeAttempt);
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('partially filled card expiration shows validation error', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '12' } });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent(paymentPageErrors.missingExpYear);
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('invalid filled card expiration month shows validation error', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '22' } });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent(paymentPageErrors.missingExpMonth);
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display correct error message when rejected by gateway', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'card_declined'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('This card transaction has been declined by your bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display gateway error with correct merchant name', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'unsupported_currency'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[1])
        .toBeVisible()
        .toHaveTextContent('This transaction can not be processed because of a currency mismatch. Please contact AffiniPay.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display all gateway errors', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'card_declined'
              },
              {
                code: 'unsupported_currency'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('This card transaction has been declined by your bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.');

      expect(errorElements[1])
        .toBeVisible()
        .toHaveTextContent('This transaction can not be processed because of a currency mismatch. Please contact AffiniPay.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display generic error when no error object returned', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [undefined]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display generic error when no error code or gateway error message returned', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [{}]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display gateway error message when no error code returned', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [{
              message: 'test gateway message'
            }]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('test gateway message');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display gateway error message if available when unknown error code returned', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'test_error',
                message: 'test gateway message'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('test gateway message');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('should display generic error when unknown error code returned', async () => {
    jest.useFakeTimers();
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'test_error'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByTestId('submit-btn-card'));
    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.');
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });
});



const fillOutTheForm = () => {
  userEvent.click(screen.getByTestId('Card-test-id'));
  userEvent.type(screen.getByLabelText('Payment Amount'), '15.93');
  userEvent.type(screen.getByLabelText('Reference'), 'Invoice 123');
  userEvent.type(screen.getAllByLabelText('Receipt Email')[0], 'foobar@affinipay.com');
  userEvent.type(screen.getByLabelText('Name on Card'), 'John Doe');
  fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '1225' } });
  userEvent.type(screen.getAllByLabelText('Address')[0], '123 Main St');
  userEvent.type(screen.getAllByLabelText('Address 2')[0], 'apt 5a');
  userEvent.type(screen.getAllByLabelText('City')[0], 'Austin');
  simulateSelectEventInAPSelect('Country', 'Canada');
  simulateSelectEventInAPSelect('State', 'Manitoba');
  userEvent.type(screen.getAllByLabelText('Zip / Postal Code')[0], '11001');
};
