import { render, screen } from '@testing-library/react';
import React from 'react';
import { noCustomFieldsNoSurcharge, noCustomFieldsWithSurcharge, withDisabledEcheckMethod } from './UpfPaymentPageTestProps';
import UpfPaymentPage from '../index';
import userEvent from '@testing-library/user-event';

jest.mock('../../../lib/utils', () => ({
  ...jest.requireActual('../../../lib/utils'),
  rollbarLog: jest.fn(),
  safeSendGA: jest.fn()
}));

describe('UPFPaymentPage Vanilla', () => {
  beforeEach(() => {
    jest.spyOn(window.navigator, 'languages', 'get').mockReturnValue(['en-US']);
  });

  it('Form Renders with upf without surcharge', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsNoSurcharge} />);
    //Merchant Info section
    expect(screen.getByTestId('merchant-name')).toBeVisible();
    expect(screen.getByText('123 Main Street')).toBeVisible();
    expect(screen.getByText('Suite 300')).toBeVisible();
    expect(screen.getByText('Austin, TX 02360')).toBeVisible();
    expect(screen.getByText('(978) 987-9878')).toBeVisible();
    expect(screen.getByText('owner@affinipay.com')).toBeVisible();
    expect(screen.getByText('http://www.foobar.com')).toBeVisible();
    //General Section
    expect(screen.getByLabelText('Payment Amount')).toBeVisible();
    expect(screen.getByLabelText('Reference')).toBeVisible();
    // UPF
    const upf = container.querySelector('upf-checkout');
    expect(upf).toBeInTheDocument();
    expect(upf.getAttribute('token')).toEqual(noCustomFieldsNoSurcharge.token);
    expect(upf.getAttribute('bank-account-id')).toEqual(noCustomFieldsNoSurcharge.bank_account_id);
    expect(upf.getAttribute('public-key')).toEqual(noCustomFieldsNoSurcharge.public_key);
    expect(upf.getAttribute('token')).toEqual(noCustomFieldsNoSurcharge.token);
    expect(upf.getAttribute('amount')).toEqual('0');
    expect(upf.getAttribute('surcharge-disabled')).toEqual('true');
    expect(upf.getAttribute('test-mode')).toEqual(null);
  });

  it('Form Renders with upf with surcharge', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsWithSurcharge} />);
    //Merchant Info section
    expect(screen.getByTestId('merchant-name')).toBeVisible();
    expect(screen.getByText('123 Main Street')).toBeVisible();
    expect(screen.getByText('Suite 300')).toBeVisible();
    expect(screen.getByText('Austin, TX 02360')).toBeVisible();
    expect(screen.getByText('(978) 987-9878')).toBeVisible();
    expect(screen.getByText('owner@affinipay.com')).toBeVisible();
    expect(screen.getByText('http://www.foobar.com')).toBeVisible();
    //General Section
    expect(screen.getByLabelText('Payment Amount')).toBeVisible();
    expect(screen.getByLabelText('Reference')).toBeVisible();
    // UPF
    const upf = container.querySelector('upf-checkout');
    expect(upf).toBeInTheDocument();
    expect(upf.getAttribute('token')).toEqual(noCustomFieldsNoSurcharge.token);
    expect(upf.getAttribute('bank-account-id')).toEqual(noCustomFieldsNoSurcharge.bank_account_id);
    expect(upf.getAttribute('public-key')).toEqual(noCustomFieldsNoSurcharge.public_key);
    expect(upf.getAttribute('token')).toEqual(noCustomFieldsNoSurcharge.token);
    expect(upf.getAttribute('amount')).toEqual('0');
    expect(upf.getAttribute('surcharge-disabled')).toEqual('false');
    expect(upf.getAttribute('test-mode')).toEqual(null);
  });

  it('Form Renders without disabled payment methods', () => {
    const { container } = render(<UpfPaymentPage {...withDisabledEcheckMethod} />);
    const upf = container.querySelector('upf-checkout');

    expect(upf).toBeInTheDocument();
    expect(screen.queryByText('eCheck')).not.toBeInTheDocument();
  });

  it('Passes test mode to UPF when true', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsWithSurcharge} test_mode={true} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf.getAttribute('test-mode')).toEqual('true');
  });

  it('Passes test mode to UPF when false', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsWithSurcharge} test_mode={false} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf.getAttribute('test-mode')).toEqual('false');
  });

  it('Doesnt pass reference required error to UPF if reference is not required', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsWithSurcharge} reference_required={1} />);
    const upf = container.querySelector('upf-checkout');
    const expectedErrors = [''];
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));
  });

  it('Does pass reference required error to UPF if reference is required', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsWithSurcharge} reference_required={2} />);
    const upf = container.querySelector('upf-checkout');
    const expectedErrors = ['Reference is required'];
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));
    userEvent.type(screen.getByLabelText('Reference'), 'this is a reference');
    expect(upf.getAttribute('errors')).toEqual('');
  });

  it('Passes custom reference label required error to UPF if reference is required and payment page has custom label', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsWithSurcharge} reference_required={2} reference_label='Test reference label' />);
    const upf = container.querySelector('upf-checkout');
    const referenceInput = screen.getByLabelText('Test reference label');
    expect(referenceInput).toBeInTheDocument();
    const expectedErrors = ['Test reference label is required'];
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));
    userEvent.type(referenceInput, 'this is a reference');
    expect(upf.getAttribute('errors')).toEqual('');
  });

  it('Passes amount and reference to UPF correctly', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsWithSurcharge} />);
    const upf = container.querySelector('upf-checkout');
    userEvent.type(screen.getByLabelText('Payment Amount'), '15.93');
    userEvent.type(screen.getByLabelText('Reference'), 'Invoice 123');
    expect(upf.getAttribute('reference')).toEqual('Invoice 123');
    expect(upf.getAttribute('amount')).toEqual('1593');
  });

});