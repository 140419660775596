import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QuickBillModal from '../index';

const defaultProps = {
  bankAccounts: [
    {
      name: 'BBVA Compass *****0000',
      bankName: 'BBVA Compass',
      trust: false,
      xid: 'bank_e3g1J5RBRpaYF8oHvNo94',
      accountNumber: '*****0000',
      achLimit: 0
    },
    {
      name: 'TRUST Bancorp *****0001',
      bankName: 'Bancorp',
      trust: true,
      xid: 'bank_9Zq4ddK4m6eZGaAXqrQXX',
      accountNumber: '*****0001',
      achLimit: null
    },
    {
      name: 'Wells Fargo *****0000',
      bankName: 'Wells Fargo',
      trust: false,
      xid: 'bank_ECja7bEfSLTZ11QtsLOZd',
      accountNumber: '*****0000',
      achLimit: 5000
    }
  ],
  isOnQbIndex: false,
  merchantId: '123abc',
  subject: 'Quick Bill Invoice',
  body: 'Please pay us.',
  referenceLabel: 'Reference',
  attachmentDisabled: false,
  siteSupportPhone: '(555) 555-5555'
};

describe('QuickBillModal', () => {
  it('renders', () => {
    render(<QuickBillModal {...defaultProps} />);
    expect(screen.getByText('Send Quick Bill')).toBeVisible();
  });

  it('opens the modal when the "Send Quick Bill" button is clicked', async () => {
    const labels = [
      'Client Email',
      'First Name (Optional)',
      'Last Name (Optional)',
      'Payment Amount',
      'Deposit Acount',
      'Subject Line',
      'Custom Email Text',
      'Attachment Upload'
    ];

    render(<QuickBillModal {...defaultProps} />);
    userEvent.click(screen.getByText('Send Quick Bill'));
    await labels.forEach(async label => await waitFor(() => expect(screen.getAllByLabelText(label)[0]).toBeVisible()));
  });
});
