import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import BankAccountDropdown from '../BankAccountDropdown';


const bankAccounts = [
  {
    name: 'Wells Fargo',
    bankName: 'Wells Fargo',
    trust: false,
    xid: 'abc123',
    accountNumber: '4342560012345678',
    achLimit: null
  },
  {
    name: 'Chase Trust',
    bankName: 'Chase',
    trust: true,
    xid: '123abc',
    accountNumber: '9876543212345678',
    achLimit: 1000000
  },
  {
    name: 'B of A',
    bankName: 'Bank of America',
    trust: false,
    xid: '321cba',
    accountNumber: '1234567898765432',
    achLimit: null
  }
];

const defaultProps = {
  bankAccounts: bankAccounts,
  selectedBankAccount: 'abc123',
  isDisabled: false,
  onAccountChange: jest.fn()
};

describe('BankAccountDropdown', () => {
  it('renders', () => {
    render(<BankAccountDropdown {...defaultProps} />);
    expect(screen.getByRole('button')).toBeVisible();
    expect(screen.getByText('Wells Fargo')).toBeVisible();
  });

  it('maps accounts to the list of options', () => {
    const result = render(<BankAccountDropdown {...defaultProps} />);
    userEvent.click(screen.getByText('Wells Fargo'));
    expect(result.container.querySelector('#downshift-1-menu').children.length).toBe(3);
    expect(screen.getAllByText('Wells Fargo').length).toBe(2);
    expect(screen.getByText('Chase Trust')).toBeVisible();
    expect(screen.getByText('B of A')).toBeVisible();
  });

  it('adds Trust Icon if account is a trust', () => {
    render(<BankAccountDropdown {...defaultProps} />);
    userEvent.click(screen.getByText('Wells Fargo'));
    expect(screen.getByTitle('Trust Account Icon')).toBeInTheDocument();
  });

  it('onAccountChange prop is invoked on account change', async () => {
    const onAccountChange = jest.fn();
    render(<BankAccountDropdown {...defaultProps} onAccountChange={onAccountChange} />);
    await waitFor(() => userEvent.click(screen.getByText('Wells Fargo')));
    userEvent.click(screen.getByText('Chase Trust'));
    expect(onAccountChange).toHaveBeenCalledTimes(1);
    const {selectedItem} = onAccountChange.mock.calls[0,0].pop();
    expect(selectedItem.value).toBe('123abc');
    expect(selectedItem.text).toBe('Chase Trust');
  });
});
