import { fireEvent, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import { paymentPageErrors } from '../../../lib/paymentPage/constants';
import PaymentPageComponent from '../index';
import { cardAndEcheckNoCustomFieldsNoSurcharge } from './PaymentPageTestProps';
import { expectToBeSelectedInAPSelect, simulateSelectEventInAPSelect } from './testHelpers';

/*
  Test any functionality that affects and/or available for the majority of customers (for example not Custom Fields or Surcharge) here
*/
describe('PaymentPage Vanilla: Form with echeck and card accounts. Features enabled/available to customers by default', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
  });
  it('Form Renders with correct set of fields and correct default form state', () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    //default form state
    expect(screen.getByLabelText('payment-form')).toHaveFormValues({
      amountField: '',
      reference: '',
      cardEmail: '',
      cardName: '',
      cardExpiration: '',
      cardBillingAddress: '',
      cardBillingAddress2: '',
      cardBillingCity: ''
    });
    userEvent.click(screen.getByTestId('Card-test-id'));
    setTimeout(() => {
      //Merchant Info section
      expect(screen.getByTestId('merchant-name')).toBeVisible();
      expect(screen.getByText('123 Main Street')).toBeVisible();
      expect(screen.getByText('Suite 300')).toBeVisible();
      expect(screen.getByText('Austin, TX 02360')).toBeVisible();
      expect(screen.getByText('(978) 987-9878')).toBeVisible();
      expect(screen.getByText('owner@affinipay.com')).toBeVisible();
      expect(screen.getByText('http://www.foobar.com')).toBeVisible();
      //General Section
      expect(screen.getByLabelText('Payment Amount')).toBeVisible();
      expect(screen.getByLabelText('Reference')).toBeVisible();
      expect(screen.getAllByLabelText('Receipt Email')[0]).toBeVisible();
      expect(screen.getByLabelText('Name on Card')).toBeVisible();
      expect(screen.getByLabelText('Exp. Date')).toBeVisible();
      //Tabs
      expect(screen.getByTestId('Card-test-id')).toBeVisible();
      expect(screen.getByTestId('eCheck-test-id')).toBeVisible();
      //Hosted fields mounting divs
      expect(screen.getByText('Card Number')).toBeVisible();
      expect(screen.getByTestId('cc_number')).toBeVisible();
      expect(screen.getByText('CVV')).toBeVisible();
      expect(screen.getByTestId('cvv')).toBeVisible();
      expect(screen.getByText('Account Number')).toBeInTheDocument();
      expect(screen.getByTestId('accountNumber')).toBeInTheDocument();
      expect(screen.getByText('Routing Number')).toBeInTheDocument();
      expect(screen.getByTestId('routingNumber')).toBeInTheDocument();
      //Card Tab
      expect(screen.getAllByLabelText('Address')[0]).toBeVisible();
      expect(screen.getAllByLabelText('Address 2')[0]).toBeVisible();
      expect(screen.getAllByLabelText('City')[0]).toBeVisible();
      expect(screen.getAllByLabelText('State')[0]).toBeVisible();
      expect(screen.getAllByLabelText('Zip / Postal Code')[0]).toBeVisible();
      expect(screen.getAllByLabelText('Country')[0]).toBeVisible();
      //eCheck Tab
      expect(screen.getAllByLabelText('Address')[1]).toBeInTheDocument();
      expect(screen.getAllByLabelText('Address 2')[1]).toBeInTheDocument();
      expect(screen.getAllByLabelText('City')[1]).toBeInTheDocument();
      expect(screen.getAllByLabelText('State')[1]).toBeInTheDocument();
      expect(screen.getAllByLabelText('Zip / Postal Code')[1]).toBeInTheDocument();
      expect(screen.getAllByLabelText('Country')[1]).toBeInTheDocument();
      //Summary Section
      expect(screen.getByText('Amount due')).toBeVisible();
      expect(screen.getByText('Total amount due')).toBeVisible();
      expect(screen.getByText('Submit Payment')).toBeVisible();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('Card only correctly shows available accounts', () => {
    const props = { ...cardAndEcheckNoCustomFieldsNoSurcharge };
    props['accepts_banks'] = false;
    render(<PaymentPageComponent {...props} />);

    expect(screen.getByTestId('Card-test-id')).toBeInTheDocument();
    expect(screen.queryByTestId('eCheck-test-id')).not.toBeInTheDocument();
  });

  it('Echeck only correctly shows available accounts', () => {
    const props = { ...cardAndEcheckNoCustomFieldsNoSurcharge };
    props['accepts_cards'] = false;
    props['default_payment_method'] = 'echeck';
    render(<PaymentPageComponent {...props} />);

    expect(screen.queryByTestId('Card-test-id')).not.toBeInTheDocument();
    expect(screen.getByTestId('eCheck-test-id')).toBeInTheDocument();
  });

  it('Optional and Custom Reference field displayed and validated correctly', () => {
    jest.useFakeTimers();
    const props = { ...cardAndEcheckNoCustomFieldsNoSurcharge };
    props['reference_required'] = 2;
    props['reference_label'] = 'Foo Invoice';
    render(<PaymentPageComponent {...props} />);
    userEvent.click(screen.getByTestId('Card-test-id'));
    userEvent.click(screen.getByTestId('submit-btn-card'));
    setTimeout(() => {
      expect(screen.getByText('Foo Invoice')).toBeInTheDocument();
      expect(screen.queryByText('Foo Invoice is required')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);

  });

  it('Filling and submitting the form with card account selected', () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    userEvent.click(screen.getByTestId('Card-test-id'));
    userEvent.type(screen.getByLabelText('Payment Amount'), '15.93');
    userEvent.type(screen.getByLabelText('Reference'), 'Invoice 123');
    userEvent.type(screen.getAllByLabelText('Receipt Email')[0], 'foobar@affinipay.com');
    userEvent.type(screen.getByLabelText('Name on Card'), 'John Doe');
    fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '1225' } });
    userEvent.type(screen.getAllByLabelText('Address')[0], '123 Main St');
    userEvent.type(screen.getAllByLabelText('Address 2')[0], 'apt 5a');
    userEvent.type(screen.getAllByLabelText('City')[0], 'Austin');
    simulateSelectEventInAPSelect('Country', 'Canada');
    simulateSelectEventInAPSelect('State', 'Manitoba');
    userEvent.type(screen.getAllByLabelText('Zip / Postal Code')[0], '11001');
    userEvent.click(screen.getByTestId('submit-btn-card'));

    setTimeout(() => {
      expect(screen.getByLabelText('payment-form')).toHaveFormValues({
        amountField: '15.93',
        reference: 'Invoice 123',
        cardEmail: 'foobar@affinipay.com',
        cardName: 'John Doe',
        cardExpiration: '12/2025',
        cardBillingAddress: '123 Main St',
        cardBillingAddress2: 'apt 5a',
        cardBillingCity: 'Austin',
        cardBillingZip: '11001'
      });
      expectToBeSelectedInAPSelect('State', 'Manitoba');
      expectToBeSelectedInAPSelect('Country', 'Canada');
      expect(screen.getByText(paymentPageErrors.hostedFields.invalidCVV)).toBeInTheDocument();
      expect(screen.queryByText('Processing Payment')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('Validate Transaction limit card', () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    userEvent.type(screen.getByLabelText('Payment Amount'), '250.93');
    userEvent.click(screen.getByTestId('Card-test-id'));
    userEvent.click(screen.getByTestId('submit-btn-card'));

    setTimeout(() => {
      expect(screen.getByLabelText('payment-form')).toHaveFormValues({
        amountField: '250.93'
      });
      expect(screen.getByText('We could not process your transaction at the moment. Please try with a lower amount.')).toBeInTheDocument();
      expect(screen.queryByText('Processing Payment')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('Validate Transaction limit echeck', () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);


    userEvent.type(screen.getByLabelText('Payment Amount'), '1050.93');
    userEvent.click(screen.getByTestId('eCheck-test-id'));
    userEvent.click(screen.getByTestId('submit-btn-card'));

    setTimeout(() => {
      expect(screen.getByLabelText('payment-form')).toHaveFormValues({
        amountField: '1,050.93'
      });
      expect(screen.getByText('We could not process your transaction at the moment. Please try with a lower amount.')).toBeInTheDocument();
      expect(screen.queryByText('Processing Payment')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);

  });

  it('Button gets disabled when processing payment process is initiated', () => {
    jest.useFakeTimers();
    const propsWithDefaultEcheck = { ...cardAndEcheckNoCustomFieldsNoSurcharge };
    propsWithDefaultEcheck['ach_required_fields'] = '';
    propsWithDefaultEcheck['default_payment_method'] = 'echeck';
    render(<PaymentPageComponent {...propsWithDefaultEcheck} />);
    userEvent.type(screen.getByLabelText('Payment Amount'), '33');
    userEvent.click(screen.getByTestId('Card-test-id'));

    const submitBtn = screen.getByTestId('submit-btn-card');

    userEvent.click(submitBtn);
    setTimeout(() => {
      expect(submitBtn).toBeDisabled();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);

  });

  it('Switching tabs correctly changes required fields', () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    userEvent.click(screen.getByTestId('Card-test-id'));
    const submitBtn = screen.getByTestId('submit-btn-card');
    userEvent.click(submitBtn);
    setTimeout(() => {
      expect(screen.getByText('Email is required.')).toBeInTheDocument();
      expect(screen.getByText('Name on Card is required.')).toBeInTheDocument();
      expect(screen.getByText('Expiration Date is required.')).toBeInTheDocument();
      expect(screen.getByText('Billing Address is required.')).toBeInTheDocument();
      expect(screen.getByText('Billing City is required.')).toBeInTheDocument();
      expect(screen.getByText('Billing State is required.')).toBeInTheDocument();
      expect(screen.getByText('Billing Zip/Postal Code is required.')).toBeInTheDocument();
      expect(screen.queryByText('Processing Payment')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
    }, 50);


    userEvent.click(screen.getByTestId('eCheck-test-id'));

    userEvent.click(submitBtn);
    setTimeout(() => {
      expect(screen.getByText('Account Holder First Name is required.')).toBeInTheDocument();
      expect(screen.getByText('Account Holder Last Name is required.')).toBeInTheDocument();
      expect(screen.queryByText('Email is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Name on Card is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Expiration Date is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Billing Address is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Billing City is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Billing State is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Billing Zip/Postal Code is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Processing Payment')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);

  });

  it('Switching echeck account type correctly handles name field', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    userEvent.type(screen.getByLabelText('Payment Amount'), '33');
    const submitBtn = screen.getByTestId('submit-btn-card');
    userEvent.click(screen.getByTestId('eCheck-test-id'));
    userEvent.click(submitBtn);

    const errorElements = await screen.findAllByTestId('error-message');
    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Account Holder First Name is required.');
      expect(errorElements[1])
        .toBeVisible()
        .toHaveTextContent('Account Holder Last Name is required.');
      jest.runOnlyPendingTimers();
    }, 50);

    userEvent.type(screen.getByLabelText('First Name'), 'John');
    userEvent.type(screen.getByLabelText('Last Name'), 'Doe');

    expect(screen.getByLabelText('payment-form')).toHaveFormValues({
      echeckFirstName: 'John',
      echeckLastName: 'Doe'
    });

    userEvent.click(screen.getByLabelText('Business Checking Account'));
    userEvent.click(submitBtn);

    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Account Holder Name is required.');
      expect(screen.queryByText('Account Holder First Name is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Account Holder Last Name is required.')).not.toBeInTheDocument();

      jest.runOnlyPendingTimers();
    }, 50);

    userEvent.type(screen.getByLabelText('Name of Business'), 'Foo Bar');
    userEvent.click(submitBtn);

    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Account Holder Name is required.');
      expect(screen.queryByText('Account Holder First Name is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Account Holder Last Name is required.')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();

      jest.runOnlyPendingTimers();
    }, 50);

    userEvent.click(screen.getByLabelText('Personal Checking Account'));
    userEvent.click(screen.getByLabelText('Personal Savings Account'));
    userEvent.click(submitBtn);

    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Account Holder Name is required.');
      expect(screen.queryByText('Account Holder First Name is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Account Holder Last Name is required.')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();

      jest.runOnlyPendingTimers();
    }, 50);


    userEvent.click(screen.getByLabelText('Business Savings Account'));
    userEvent.click(submitBtn);

    setTimeout(() => {
      expect(errorElements[0])
        .toBeVisible()
        .toHaveTextContent('Account Holder Name is required.');
      expect(screen.queryByText('Account Holder First Name is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('Account Holder Last Name is required.')).not.toBeInTheDocument();
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();

      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });
});
