import PCICompliance from '../PCICompliance';

describe('PCICompliance', () => {
  const pciComplianceAttributes = {
    id: '23q40e9radjfiovn',
    brand: 'affinipay',
    previous_test_date:'07/01/2023',
    previous_test_dates: ['07/01/2023'],
    next_due_date: '12/12/2023',
    name: 'Bob Loblaw',
    status: 'compliant',
    contact_email: 'bob_loblaw@loblawlawblog.law',
    merchant_id: '23wsagrf093qnerio',
    merchant_uuid_base62: 'nvqo30223q0ew',
    integration_partner: undefined,
    referral_partner: undefined,
    error: undefined
  };

  const expectedOutcome = {
    id: '23q40e9radjfiovn',
    brand: 'affinipay',
    previousTestDate:'07/01/2023',
    previousTestDates: ['07/01/2023'],
    nextDueDate: '12/12/2023',
    name: 'Bob Loblaw',
    status: 'compliant',
    contactEmail: 'bob_loblaw@loblawlawblog.law',
    merchantVTID: '23wsagrf093qnerio',
    merchantUUID: 'nvqo30223q0ew',
    referralPartner: undefined,
    integrationPartner: undefined,
    error: undefined
  };
  it('returns an object with correct camelized attributes', () => {
    expect(PCICompliance({...pciComplianceAttributes})).toEqual(expectedOutcome);
  });

  it('sets the error correctly', () => {
    expect(PCICompliance({...pciComplianceAttributes, error: ''})).toEqual({
      ...expectedOutcome,
      error: ''
    });

    expect(PCICompliance({...pciComplianceAttributes, error: 'Something blew up!'})).toEqual({
      ...expectedOutcome,
      error: 'Something blew up!'
    });
  });

  it('sets an email to empty string if undefined', () => {
    expect(PCICompliance({...pciComplianceAttributes})).toEqual({
      ...expectedOutcome,
      contact_email: undefined
    });
  });
});
