import { renderHook } from '@testing-library/react-hooks';
import { useFetchContacts } from '../customHooks';
import client from '../../../../lib/ajax';
import * as Utils from '../../../../lib/utils';

describe('useFetchContact hook', () => {
  const contacts = [
    {
      email: 'foo@bar.com',
      email_id: 'eml_ClEwijmLfCph57Wh5oTij',
      id: 'p_Q7U09Db0PIVMRz3Xa2jwE',
      key: 0,
      name: 'Foo Bar'
    },
    {
      email: 'foo@baz.com',
      email_id: 'eml_7UFItowWzv7uGI3Ci1QZl',
      id: 'p_G8TF2OXWEtz2u7Ty8BVdf',
      key: 1,
      name: 'Foo Baz'
    },
    {
      email: 'foo@buz.com',
      email_id: 'eml_FbZjRLmzeSsUYMRtuZs8T',
      id: 'p_4SSXc8iFvpRa4bRy5nppF',
      key: 2,
      name: 'buz'
    }
  ];
  const clientGetResponse = {
    json: () => contacts,
    status: 200
  };
  beforeAll(() => {
    jest
      .spyOn(Utils, 'rollbarLog')
      .mockReturnValue(() => {});
    jest
      .spyOn(client, 'get')
      .mockReturnValue(Promise.resolve(clientGetResponse));
  });

  it('should show initial values', async () => {
    const { result } = renderHook(({searchTerm}) => useFetchContacts(searchTerm), {
      initialProps: {
        searchTerm: 'foo'
      }
    });
    expect(result.current.loading).toEqual(false);
    expect(result.current.error).toEqual(undefined);
    expect(result.current.data).toEqual([]);
  });

  it('should return the values from a successful fetch with many options', async () => {
    const { rerender, result, waitForNextUpdate } = renderHook(({searchTerm}) => useFetchContacts(searchTerm), {
      initialProps: {
        searchTerm: 'fo',
        shouldSearch: true
      }
    });
    rerender({searchTerm: 'foo'});
    await waitForNextUpdate();
    expect(result.current.loading).toEqual(false);
    expect(result.current.error).toEqual(undefined);
    expect(result.current.data).toEqual(contacts);
  });

  it('does not fetch contacts if shouldSearch is false', async () => {
    const { rerender, result } = renderHook(({searchTerm}) => useFetchContacts(searchTerm), {
      initialProps: {
        searchTerm: 'fo',
        shouldSearch: false
      }
    });
    rerender({searchTerm: 'foo'});
    expect(result.current.loading).toEqual(false);
    expect(result.current.error).toEqual(undefined);
    expect(result.current.data).toEqual([]);
    rerender({shouldSearch: true });
    expect(result.current.loading).toEqual(false);
    expect(result.current.error).toEqual(undefined);
    expect(result.current.data).toEqual([]);
  });

  it('sets data to empty array if outcome of response is empty', async () => {
    jest
      .spyOn(client, 'get')
      .mockReturnValue(Promise.resolve({
        json: () => [],
        status: 200
      }));
    const { rerender, result, waitForNextUpdate } = renderHook(({searchTerm}) => useFetchContacts(searchTerm), {
      initialProps: {
        searchTerm: 'fo',
        shouldSearch: false
      }
    });
    rerender({searchTerm: 'foo', shouldSearch: true});
    await waitForNextUpdate();
    expect(result.current.loading).toEqual(false);
    expect(result.current.error).toEqual(undefined);
    expect(result.current.data).toEqual([]);
  });

  it('status other than 200 will return an error and empty array in data', async () => {
    jest
      .spyOn(client, 'get')
      .mockReturnValue(Promise.resolve({
        json: () => [],
        status: 500
      }));
    const { rerender, result, waitForNextUpdate } = renderHook(({searchTerm}) => useFetchContacts(searchTerm), {
      initialProps: {
        searchTerm: 'fo',
        shouldSearch: true
      }
    });
    rerender({searchTerm: 'foo'});
    await waitForNextUpdate();
    expect(result.current.loading).toEqual(false);
    expect(result.current.error).toEqual('Something went wrong, please try again.');
    expect(result.current.data).toEqual([]);
  });
});