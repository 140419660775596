import React from 'react';
import {screen, render} from '@testing-library/react';
import AttachmentsButton from '../QuickBillAttachments/AttachmentsButton';

describe('AttachementsButton', () => {
  const  handleChange = () => {};

  it('renders', () => {
    render(<AttachmentsButton onChange={handleChange} />);
    const atchButton = screen.getByTestId('quick-bill-attachment-btn');
    expect(screen.getByLabelText('Attachment Upload')).toBeVisible();
    expect(screen.getByText('Choose File')).toBeVisible();
    expect(atchButton).toBeVisible();
  });

  it('is disabled if prop "disabled" is true', () => {
    render(<AttachmentsButton onChange={handleChange} disabled={true} />);
    const atchButton = screen.getByRole('button');
    expect(atchButton).toBeDisabled();
  });

  it('is not disabled if prop "disabled" is false', () => {
    render(<AttachmentsButton onChange={handleChange} disabled={false} />);
    const atchButton = screen.getByRole('button');
    expect(atchButton).not.toBeDisabled();
  });
});
