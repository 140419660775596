import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import PaymentPageComponent from '../index';
import { cardAndEcheckCustomFieldsNoSurcharge } from './PaymentPageTestProps';
import { getAPSelect, simulateSelectEventInAPSelect } from './testHelpers';

describe('PaymentPage with Custom Fields', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
  });

  it('Form Renders with correct set of fields and correct default form state for Card account', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckCustomFieldsNoSurcharge} />);

    //Custom Amount Fields
    expect(screen.getByText('Checkbox Static text $5 - $5.00')).toBeVisible();
    expect(screen.getAllByText('help')[0]).toBeVisible();
    expect(screen.getByText('Radio Static text $5 - $5.00')).toBeVisible();
    expect(screen.getByText('Amount Section')).toBeVisible();
    expect(screen.getByText('Subtotal Zero - $0.00')).toBeVisible();
    expect(screen.getByText('Only Amount - $100.00')).toBeVisible();
    expect(screen.getByLabelText('Blank')).toBeVisible();
    expect(screen.getByLabelText('Amount and qty (2 and 3)')).toBeVisible();
    expect(screen.getByText('Amount% 20 - $20.00')).toBeVisible();
    expect(screen.getByText('Subtotal of section B - $120.00')).toBeVisible();
    expect(screen.getByText('Amount% 10 off - -$12.00')).toBeVisible();
    expect(screen.getByText('Custom Total Amount - $108.00')).toBeVisible();

    //Custom Fields
    expect(screen.getByText('Additional Information')).toBeVisible();
    expect(screen.getByLabelText('tf not require')).toBeVisible();
    expect(screen.getByLabelText('tf require')).toBeVisible();
    expect(screen.getByLabelText('ta not require')).toBeVisible();
    expect(screen.getByLabelText('ta req')).toBeVisible();
    expect(screen.getByText('ch not require')).toBeVisible();
    expect(screen.getByText('ch req')).toBeVisible();
    expect(getAPSelect('s not req')).toBeVisible();
    expect(getAPSelect('s req')).toBeVisible();

    expect(screen.getByLabelText('payment-form')).toHaveFormValues({
      'Blank': '',
      'Amount and qty (2 and 3)': null,
      'reference': '',
      'tf not require': 'val',
      'tf require': '',
      'ta not require': 'default value',
      'ta req': '',
      'ch not require': false,
      'ch req': false
    });
    expect(screen.getByText('defval')).toBeVisible();
    expect(screen.queryByText('tf require is required.')).not.toBeInTheDocument();
  });

  it('Form Renders with correct set of fields and correct default form state for Echeck account', () => {
    render(<PaymentPageComponent {...cardAndEcheckCustomFieldsNoSurcharge} />);
    userEvent.click(screen.getByText('eCheck'));

    //Custom Amount Fields
    expect(screen.getByText('Radio $4 Max Quantity 20 - $4.00')).toBeVisible();
    expect(screen.getByText('Radio Input')).toBeVisible();
    expect(screen.getByText('Subtotal of section A - $0.00')).toBeVisible();
    expect(screen.getByText('Subtotal Zero - $0.00')).toBeVisible();
    expect(screen.getByText('Only Amount - $100.00')).toBeVisible();
    expect(screen.getByLabelText('Blank')).toBeVisible();
    expect(screen.getByLabelText('Amount and qty (2 and 3)')).toBeVisible();
    expect(screen.getByText('Amount% 20 - $20.00')).toBeVisible();
    expect(screen.getByText('Custom Total Amount - $120.00')).toBeVisible();

    //Custom fields are same as card and are tested in a previous test
  });

  it('Form Custom fields validation', async () => {
    jest.useFakeTimers();
    render(<PaymentPageComponent {...cardAndEcheckCustomFieldsNoSurcharge} />);
    userEvent.click(screen.getByTestId('submit-btn-card'));


    setTimeout(() => {
      //Custom Fields set as required prevent form submit
      expect(screen.getAllByText('tf require is required.')[0]).toBeVisible();
      expect(screen.getByText('ta req is required.')).toBeVisible();
      expect(screen.getByText('ch req is required.')).toBeVisible();
      expect(screen.getByText('s req is required.')).toBeVisible();

      //Custom Fields Not set as required don't prevent form submit
      expect(screen.queryByText('tf not require is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('ta not require is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('ch not require is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('s not req is required.')).not.toBeInTheDocument();

      //Form is not submitted
      expect(screen.queryByTestId('thank-you-page')).not.toBeInTheDocument();

      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);


    //Filling out the required custom fields
    userEvent.type(screen.getByLabelText('tf require'), 'A');
    userEvent.type(screen.getByLabelText('ta req'), 'T');
    userEvent.click(screen.getByLabelText('ch req'));
    simulateSelectEventInAPSelect('s req', 'eo');
    userEvent.click(screen.getByTestId('submit-btn-card'));
    setTimeout(() => {
      expect(screen.getByLabelText('payment-form')).toHaveFormValues({
        'tf require': 'A',
        'ta req': 'T',
        'ch req': true,
        's req': 'eo'
      });
      expect(screen.queryByText('tf require is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('ta req is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('ch req is required.')).not.toBeInTheDocument();
      expect(screen.queryByText('s req is required.')).not.toBeInTheDocument();
      jest.runOnlyPendingTimers();
      jest.useRealTimers();
    }, 50);
  });

  it('Custom Amount Field interactions and calculations', () => {
    render(<PaymentPageComponent {...cardAndEcheckCustomFieldsNoSurcharge} />);
    const subtotal = screen.getAllByTestId('subtotal')[0];
    expect(subtotal).toHaveTextContent('$108.00');


    userEvent.type(screen.getByLabelText('Blank'), '100');
    expect(subtotal).toHaveTextContent('$216.00');


    userEvent.click(screen.getByTestId('Checkbox Static text $5'));
    expect(subtotal).toHaveTextContent('$221.40');
    userEvent.click(screen.getByTestId('Checkbox Static text $5'));
    expect(subtotal).toHaveTextContent('$216.00');
    userEvent.click(screen.getByTestId('Radio Static text $5'));
    expect(subtotal).toHaveTextContent('$221.40');

    //over max allowed, should be ignored
    const amountAndQty2and3 = screen.getByLabelText('Amount and qty (2 and 3)');
    userEvent.type(amountAndQty2and3, '9');
    expect(subtotal).toHaveTextContent('$221.40');
    userEvent.type(amountAndQty2and3, '3');
    expect(subtotal).toHaveTextContent('$227.88');

    userEvent.click(screen.getByText('eCheck'));
    expect(subtotal).toHaveTextContent('$226.88');
  });

  it('Custom Amount Field fractional percentage case', () => {
    const props = { ...cardAndEcheckCustomFieldsNoSurcharge };
    props['amount_fields'] = [
      {
        amount: '',
        custom_field: 'customAmount',
        custom_value: 'custom valuez',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'customAmount',
        options: '',
        placeholder: '',
        position: 25,
        required: 1,
        type: 'amount'
      },
      {
        amount: '.015',
        custom_field: 'Amount% 1.5',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'percent',
        options: '',
        placeholder: '',
        position: 27,
        required: 1,
        type: 'amount_percent'
      }];
    render(<PaymentPageComponent {...props} />);

    expect(screen.getAllByTestId('subtotal')[0]).toHaveTextContent('$0.00');
    userEvent.type(screen.getByLabelText('customAmount'), '33');
    expect(screen.getAllByTestId('subtotal')[0]).toHaveTextContent('$33.50');
    userEvent.clear(screen.getByLabelText('customAmount'));
    userEvent.type(screen.getByLabelText('customAmount'), '15');
    expect(screen.getAllByTestId('subtotal')[0]).toHaveTextContent('$15.23');
  });

  it('Custom Amount Field subtotal and total fields', () => {
    //subtotal needs to show sum of everything above itself up to the nearest section
    //total needs to show sum of everything above itself
    const props = { ...cardAndEcheckCustomFieldsNoSurcharge };
    props['amount_fields'] = [
      {
        amount: '',
        custom_field: 'customAmount',
        custom_value: 'custom valuez',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'customAmount',
        options: '',
        placeholder: '',
        position: 1,
        required: 1,
        type: 'amount'
      },
      {
        amount: '100',
        custom_field: 'Only Amount',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Only Amount',
        options: '',
        placeholder: '',
        position: 2,
        required: 1,
        type: 'amount'
      },
      {
        amount: '',
        custom_field: 'Amount Section',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Amount Section',
        options: '',
        placeholder: '',
        position: 3,
        required: 1,
        type: 'amount_section'
      },
      {
        amount: '6',
        custom_field: 'Subtotal Zero',
        custom_value: '',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: 60,
        name: 'Subtotal Zero',
        options: '',
        placeholder: '',
        position: 4,
        required: 1,
        type: 'amount_subtotal'
      },
      {
        amount: '',
        custom_field: 'Custom Total Amount',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Custom Total Amount',
        options: '',
        placeholder: '',
        position: 5,
        required: 1,
        type: 'amount_total'
      },
      {
        amount: '5',
        custom_field: 'Another Amount',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Another Amount',
        options: '',
        placeholder: '',
        position: 6,
        required: 1,
        type: 'amount'
      }
    ];

    render(<PaymentPageComponent {...props} />);

    expect(screen.getByText('Subtotal Zero - $0.00')).toBeInTheDocument();
    expect(screen.getByText('Custom Total Amount - $100.00')).toBeInTheDocument();
    expect(screen.getAllByTestId('subtotal')[0]).toHaveTextContent('$105.00');
  });

  it('Quantity custom field amount testing for floating point math error #1', () => {
    render(<PaymentPageComponent {...getQtyFieldProps()} />);

    userEvent.type(screen.getByLabelText('test'), '5');
    expect(screen.getAllByTestId('subtotal')[0]).toHaveTextContent('$64.80');
  });

  it('Quantity custom field amount testing for floating point math error #2', () => {
    render(<PaymentPageComponent {...getQtyFieldProps()} />);

    userEvent.type(screen.getByLabelText('test'), '10');
    expect(screen.getAllByTestId('subtotal')[0]).toHaveTextContent('$129.60');
  });
});

const getQtyFieldProps = () => {
  const props = { ...cardAndEcheckCustomFieldsNoSurcharge };
  props['amount_fields'] = [
    {
      amount: '',
      custom_field: 'name',
      custom_value: '',
      default_value: '',
      help_text: 'help',
      incompatible_payment_types: [],
      max_quantity: null,
      name: 'name',
      options: '',
      placeholder: '',
      position: 1,
      required: 1,
      type: 'amount'
    },
    {
      amount: '12.96',
      custom_field: 'test',
      custom_value: '',
      default_value: '',
      help_text: '',
      incompatible_payment_types: [],
      max_quantity: 12,
      name: 'test',
      options: '',
      placeholder: '',
      position: 2,
      required: 1,
      type: 'amount'
    }];
  return props;
};

