import React from 'react';
import {render, screen, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import PCIModal from '../PCIModal';
import PCICompliancesService from '../src/services/PCICompliancesService';

const modalProps = {
  isModalOpen: true,
  merchantStatus: 'Expired',
  merchantDueDate: new Date('01/01/2022'),
  merchantUUID: '6QfxqNoSOw6WNYG4TwLJFm',
  merchantVTID: 1,
  onClose: jest.fn(),
  onCloseAndRefresh: jest.fn()
};

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

describe('PCIModal index', () => {
  beforeAll(() => {
    jest.spyOn(PCICompliancesService, 'patch').mockImplementation(() =>
      Promise.resolve({data: 'success'})
    );
  });

  it('renders', () => {
    render(<PCIModal {...modalProps} />);
    const textOnRender = [
      'Change Due Date',
      'Change Status',
      'Please select a new compliance status from the dropdown options below.',
      'Save changes'
    ];
    textOnRender.forEach(text => {
      expect(screen.getByText(text)).toBeInTheDocument();
    });
  });

  it('populates the date in datepicker input correctly', () => {
    const {container} = render(<PCIModal {...modalProps} />);
    expect(container.querySelector('#ap-pci-modal-datepicker').value).toEqual('01/01/2022');
    expect(screen.getByTestId('combobox').textContent).toEqual('Expired');
  });

  it('opens to the correct month in datepicker when date is in the past', async () => {
    const {container} = render(<PCIModal {...modalProps} />);
    userEvent.click(container.querySelector('#ap-pci-modal-datepicker'));
    const currentDate = new Date();
    const monthToday = months[currentDate.getMonth()];
    const yearToday = currentDate.getFullYear();
    const currentMonth = container.querySelector('.react-datepicker__current-month');
    await waitFor(() => expect(currentMonth).toBeInTheDocument());

    expect(currentMonth.textContent).toEqual(`${monthToday} ${yearToday}`);
  });

  it('invokes passed onCloseAndRefresh function when Save changes button is clicked', async () => {
    render(<PCIModal {...modalProps} />);
    userEvent.click(screen.getByText('Save changes'));
    await waitFor(() => expect(modalProps.onCloseAndRefresh).toHaveBeenCalled());
  });

  it('invokes passed onClose function when modal is closed', async () => {
    render(<PCIModal {...modalProps} />);
    userEvent.click(screen.getByText('Close Modal Icon'));
    await waitFor(() => expect(modalProps.onClose).toHaveBeenCalled());
  });
});
