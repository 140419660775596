import React from 'react';
import {screen, render} from '@testing-library/react';
import QuickBillStatusBarPaid from '../QuickBillStatusBarPaid';
import * as libraryUtils from '../../../../lib/utils';

const invoice = {
  id: 'abc123',
  bankAccount: '****1234',
  bankName: 'Wells Fargo',
  bankAccountType: 'operating',
  invoicePayments: [
    {payment: {
      paymentType: 'cash'
    }}
  ],
  modified: {
    timestamp: '10/10/2022'
  },
  presentmentStatus: 'sent'
};

describe('QuickBillStatusBarPaid', () => {
  beforeEach(() => {
    jest.spyOn(libraryUtils, 'dateFormat').mockImplementation(() => '10/10/2022');
  });
  it('renders', () => {
    render(<QuickBillStatusBarPaid invoice={invoice} />);
    expect(screen.getByText('Status'));
    expect(screen.getByText('Sent'));
    expect(screen.getByText('Last Updated'));
    expect(screen.getByText('10/10/2022'));
    expect(screen.getByText('Deposit Account'));
    expect(screen.getByText('Wells Fargo'));
    expect(screen.getByText('****1234'));
  });
});
