import React from 'react';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import PCIBanner from '../index.js';

describe('PCIBanner', () => {
  const defaultProps = {
    isAlertMode: false,
    pciDueBy: new Date(new Date().getFullYear() + 1, 1, 1).toLocaleString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}),
    pciExpiresWithin: 1,
    pciComplianceUrl: 'http://localhost:3000/pci_compliance'
  };

  const __location = window.location;

  afterEach(() => {
    window.sessionStorage.clear();
    window.location = __location;
  });

  test('renders get compliant button', () => {
    render(<PCIBanner { ...defaultProps } />);
    expect(screen.getByText('Get Compliant')).toBeInTheDocument();
  });

  test('clicking get compliant button redirects', () => {
    delete window.location;
    window.location = {assign: jest.fn(), pathname: '/'};
    render(<PCIBanner { ...defaultProps } />);
    const btn = screen.queryAllByRole('button')[0];
    userEvent.click(btn);
    expect(window.location.assign).toHaveBeenCalledWith(defaultProps.pciComplianceUrl);
  });

  test('renders banner with expiring text', () => {
    render(<PCIBanner { ...defaultProps } />);
    expect(screen.getByTestId('pci-banner-expiring')).toBeInTheDocument();
  });

  test('renders banner with grace text', () => {
    render(<PCIBanner { ...{ ...defaultProps, pciExpiresWithin: 0 } } />);
    expect(screen.getByTestId('pci-banner-expires-soon')).toBeInTheDocument();
  });

  test('renders banner with overdue text', () => {
    render(<PCIBanner { ...{ ...defaultProps, pciExpiresWithin: -1 } } />);
    expect(screen.getByTestId('pci-banner-expired')).toBeInTheDocument();
  });

  test('does not render when session storage value is set', () => {
    window.sessionStorage.setItem('Merchant::ComplianceDecorator::HIDE_COMPLIANCE_BANNER_KEY', true);
    const { container } = render(<PCIBanner { ...defaultProps } />);
    expect(container).toBeEmptyDOMElement();
  });

  test('does not render when url pathname matches /pci_compliance', () => {
    delete window.location;
    window.location = {assign: jest.fn(), pathname: '/pci_compliance'};
    const { container } = render(<PCIBanner { ...defaultProps } />);
    expect(container).toBeEmptyDOMElement();
  });

  test('does not render when expiresWithin is null', () => {
    const { container } = render(<PCIBanner { ...{ ...defaultProps, pciExpiresWithin: null } } />);
    expect(container).toBeEmptyDOMElement();
  });

  test('does not render grace text if merchant is not in grace', () => {
    render(<PCIBanner { ...defaultProps } />);
    expect(screen.getByTestId('pci-banner-expiring').textContent).not.toContain('grace');
  });

  test('does not render grace text if merchant is expired', () => {
    render(<PCIBanner {...{ ...defaultProps, pciExpiresWithin: -1 } } />);
    expect(screen.getByTestId('pci-banner-expired').textContent).not.toContain('grace');
  });
});
