import AccessTokenService from '../AccessTokensService';
import server from '../../../../../../lib/server';
import * as Utils from '../../../../../../lib/utils';

describe('AccessTokenService', () => {
  const accessTokenJSON = {
    access_token: 'j3oqijasojf',
    created_at: '07/07/2023',
    error: undefined,
    expires_in: '12/31/2023',
    scope: 'affinipay',
    token_type: 'admin'
  };

  const expectedOutcome = {
    accessToken: 'j3oqijasojf',
    createdAt: '07/07/2023',
    error: undefined,
    expiresIn: '12/31/2023',
    scope: 'affinipay',
    tokenType: 'admin'
  };

  beforeAll(() => {
    jest
      .spyOn(Utils, 'rollbarLog')
      .mockReturnValue(() => {});

    jest.spyOn(server, 'get').mockImplementation(() => Promise.resolve({
      status: 200,
      response: true,
      ok: true,
      json: jest.fn(() => {
        return Promise.resolve(accessTokenJSON);
      })
    }));
  });

  afterAll(() => {
    jest.restoreAllMocks();
  });

  it('on success access token is supplied with correct format', async () => {
    const accessTokenResponse = await AccessTokenService();
    expect(accessTokenResponse).toEqual(expect.objectContaining({...expectedOutcome}));
  });

  it('on failure access token is supplied with an error format', async () => {
    jest.spyOn(server, 'get').mockImplementation(() => Promise.resolve({
      status: 500,
      response: true,
      ok: false,
      json: jest.fn(() => {
        return Promise.reject({message: 'something blew up'});
      })
    }));

    const accessTokenResponse = await AccessTokenService();
    expect(accessTokenResponse).toEqual(expect.objectContaining({
      accessToken: undefined,
      createdAt: undefined,
      error: 'something blew up - admin uuid_base62:',
      expiresIn: undefined,
      scope: undefined,
      tokenType: undefined
    }));

    expect(Utils.rollbarLog).toHaveBeenCalled();
    expect(Utils.rollbarLog).toHaveBeenCalledWith('Access Tokens Service Error', 'something blew up','warning');
  });
});