import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ClientCreditCheckboxModal from '../ClientCreditCheckboxModal';

describe('ClientCreditCheckboxModal', () => {
  const vanillaProps = {
    brand: 'LawPay',
    brandLink: 'https://lawpay.com',
    supportPhone: '(512) 123-4567'
  };

  it('renders', () => {
    const mock = jest.fn();
    render(<ClientCreditCheckboxModal {...vanillaProps} closeModal={mock} />);

    expect(screen.getByText('Learn More About Pay Later')).toBeInTheDocument();
    expect(screen.getByText('(512) 123-4567')).toBeInTheDocument();
  });

  it('invokes "closeModal" function prop when Close Button is clicked', async () => {
    const mock = jest.fn();
    render(<ClientCreditCheckboxModal {...vanillaProps} closeModal={mock} />);
    await waitFor(() => userEvent.click(screen.getByText('Close')));
    expect(mock).toHaveBeenCalledTimes(1);
  });
});
