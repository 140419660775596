import React from 'react';
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import { legalEntityResponseFromAggregate } from '../helpers/fixtures';
import client from 'lib/ajax';
import userEvent from '@testing-library/user-event';
import EditLegalEntity from '../EditLegalEntity';
import notify from 'lib/notify';

describe('EditLegalEntity', () => {

  beforeEach(() => {
    jest.spyOn(notify, 'success').mockImplementation(() => ({}));
    jest.spyOn(notify, 'error').mockImplementation(() => ({}));
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(legalEntityResponseFromAggregate);
        })
      })
    );
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
  });

  it('renders loading screen then edit screen after request completes ', async () => {
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    expect(screen.getByLabelText('Loading Spinner')).toBeVisible();
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.queryByLabelText('Loading Spinner')).not.toBeInTheDocument();
    expect(screen.getByText('Edit Legal Entity')).toBeVisible();
  });

  it('renders error message box and calls notify with json error when there is an error fetching legal entity', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving legal entity information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('renders error message box and doesnt call notify json error doesnt exist when there is an error fetching legal entity', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: ''});
        })
      })
    );
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving legal entity information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).not.toHaveBeenCalledTimes(1);
  });

  it('renders error message box when an error is thrown fetching legal entity', async () => {
    jest.spyOn(client, 'get').mockImplementation(() => {
      throw new Error();
    });
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving legal entity information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).not.toHaveBeenCalledTimes(1);
  });

  it('renders error message box when legal entity response is empty', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving legal entity information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).not.toHaveBeenCalledTimes(1);
  });

  it('back button calls setEditedlegalEntityId callback function', async () => {
    const mock = jest.fn();
    render(<EditLegalEntity merchantId={1} legalEntityId={2} setEditedLegalEntityId={mock}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Back to Legal'));
    expect(mock).toHaveBeenCalledTimes(1);
  });

  it('form elements are visible after load with correct values', async () => {
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByLabelText('Legal Business Name')).toHaveDisplayValue('Adyen Inc');
    expect(screen.getByLabelText('Tax ID')).toHaveDisplayValue('123123123');
    expect(screen.getByLabelText('Doing Business As')).toHaveDisplayValue('Adyen Inc');
    expect(screen.getAllByLabelText('Business Structure')[0]).toHaveTextContent('Sole Proprietor');
    expect(screen.getByLabelText('Years In Business')).toHaveDisplayValue('5');
    expect(screen.getByLabelText('Merchant Category Code')).toHaveDisplayValue('8699');
    expect(screen.getByLabelText('Business Address')).toHaveDisplayValue('123 Street');
    expect(screen.getByLabelText('Additional Address')).toHaveDisplayValue('Unit 5');
    expect(screen.getByLabelText('City')).toHaveDisplayValue('Austin');
    expect(screen.getAllByLabelText('State')[0]).toHaveTextContent('Texas');
    expect(screen.getByLabelText('Postal Code')).toHaveDisplayValue('78746-1234');
    expect(screen.getAllByLabelText('Country')[0]).toHaveTextContent('United States');
    expect(screen.getByLabelText('Email')).toHaveDisplayValue('joeadyen@example.com');
    expect(screen.getByLabelText('Phone')).toHaveDisplayValue('1(123) 123-1241');
    expect(screen.getByLabelText('Website')).toHaveDisplayValue('');
  });

  it('can interact with form elements', async () => {
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const nameInput = screen.getByLabelText('Legal Business Name');
    expect(nameInput).toHaveDisplayValue('Adyen Inc');
    userEvent.clear(nameInput);
    userEvent.type(nameInput, 'new name');
    expect(nameInput).toHaveDisplayValue('new name');
  });

  it('can interact with form address elements', async () => {
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const addressInput = screen.getByLabelText('Business Address');
    expect(addressInput).toHaveDisplayValue('123 Street');
    userEvent.clear(addressInput);
    userEvent.type(addressInput, 'new address');
    expect(addressInput).toHaveDisplayValue('new address');
  });

  it('notifies user that only US can be selected as country and doesnt change the country', async () => {
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const countryInput = screen.getAllByLabelText('Country')[0];
    userEvent.click(countryInput);
    userEvent.click(screen.getByText('Canada'));
    expect(notify.error).toHaveBeenCalledWith('Only US addresses are supported at this time.');
    expect(countryInput).toHaveTextContent('United States');
  });

  it('can save the form', async () => {
    const setCurrentMock = jest.fn();
    const setEditedMock = jest.fn();
    render(<EditLegalEntity merchantId={1} legalEntityId={2} setCurrentLegalEntity={setCurrentMock} setEditedLegalEntityId={setEditedMock}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const saveButton = screen.getByText('Save Changes');
    fireEvent.submit(screen.getByTestId('edit-legal-entity-form'));
    expect(saveButton).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(1);
    expect(notify.success).toHaveBeenCalledWith('Legal Entity successfully updated');
    expect(setCurrentMock).toHaveBeenCalledTimes(1);
    expect(setEditedMock).toHaveBeenCalledTimes(1);
  });

  it('shows user an error and re-enables save button if there is an error returned from backend', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    fireEvent.submit(screen.getByTestId('edit-legal-entity-form'));
    expect(screen.getByText('Save Changes')).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('saving the form shows user a generic error and re-enables save button if request fails with blank error', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: ''});
        })
      })
    );
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    fireEvent.submit(screen.getByTestId('edit-legal-entity-form'));
    expect(screen.getByText('Save Changes')).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
    expect(notify.error).toHaveBeenCalledWith('Unexpected error occurred when updating legal entity', [], false);
  });

  it('saving the form shows user a generic error and re-enables save button if request throws an error', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() => {
      throw new Error();
    });
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    fireEvent.submit(screen.getByTestId('edit-legal-entity-form'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
    expect(notify.error).toHaveBeenCalledWith('Unexpected error occurred when updating legal entity', [], false);
  });

  it('has correct constraints for every form element', async () => {
    render(<EditLegalEntity merchantId={1} legalEntityId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));

    const businessNameInput = screen.getByLabelText('Legal Business Name');
    expect(businessNameInput).toBeRequired();
    expect(businessNameInput).toHaveAttribute('minLength', '3');
    expect(businessNameInput).toHaveAttribute('maxLength', '40');

    const taxIdInput = screen.getByLabelText('Tax ID');
    expect(taxIdInput).toBeRequired();
    expect(taxIdInput).toHaveAttribute('minLength', '9');
    expect(taxIdInput).toHaveAttribute('maxLength', '9');

    const doingBusinessAsInput = screen.getByLabelText('Doing Business As');
    expect(doingBusinessAsInput).not.toBeRequired();
    expect(doingBusinessAsInput).toHaveAttribute('maxLength', '24');

    const businessStructureInput = screen.getAllByLabelText('Business Structure')[0];
    expect(businessStructureInput.nextSibling.nextSibling).toBeRequired();

    const yearsInBusinessInput = screen.getByLabelText('Years In Business');
    expect(yearsInBusinessInput).toBeRequired();
    expect(yearsInBusinessInput).toHaveAttribute('type', 'number');
    expect(yearsInBusinessInput).toHaveAttribute('min', '0');

    const codeInput = screen.getByLabelText('Merchant Category Code');
    expect(codeInput).toBeRequired();
    expect(codeInput).toHaveAttribute('minLength', '4');
    expect(codeInput).toHaveAttribute('maxLength', '4');

    const address1 = screen.getByLabelText('Business Address');
    expect(address1).toBeRequired();
    expect(address1).toHaveAttribute('maxLength', '255');

    const address2 = screen.getByLabelText('Additional Address');
    expect(address2).not.toBeRequired();
    expect(address2).toHaveAttribute('maxLength', '255');

    const city = screen.getByLabelText('City');
    expect(city).toBeRequired();
    expect(city).toHaveAttribute('maxLength', '255');

    const state = screen.getAllByLabelText('State')[0];
    expect(state.nextSibling.nextSibling).toBeRequired();

    const zipCode = screen.getByLabelText('Postal Code');
    expect(zipCode).toBeRequired();

    const country = screen.getAllByLabelText('Country')[0];
    expect(country.nextSibling.nextSibling).toBeRequired();

    const emailInput = screen.getByLabelText('Email');
    expect(emailInput).not.toBeRequired();

    const phoneInput = screen.getByLabelText('Phone');
    expect(phoneInput).toBeRequired();

    const urlInput = screen.getByLabelText('Website');
    expect(urlInput).not.toBeRequired();
    expect(urlInput).toHaveAttribute('maxLength', '255');
  });
});
