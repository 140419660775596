import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import FeatureTogglePage from '../FeatureTogglePage';
import { features, featureGates, fullyEnabledFeatureGate } from '../helpers/fixtures';
import * as service from '../helpers/services';

describe('Feature gates table', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
    jest.spyOn(service, 'getFeatureGates').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(featureGates);
        })
      })
    );
  });

  it('Renders feature gates table, pagination, and filter inputs correctly', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    expect(screen.getAllByRole('row')).toHaveLength(featureGates.data.length + 1);
    expect(screen.getByText('ID')).toBeInTheDocument();
    expect(screen.getByText('Name')).toBeInTheDocument();
    expect(screen.getByText('Created At')).toBeInTheDocument();
    expect(screen.getByText(featureGates.data[0].attributes.id)).toBeInTheDocument();
    expect(screen.getByText(featureGates.data[0].attributes.value)).toBeInTheDocument();
    expect(screen.getByText(featureGates.data[0].attributes.created_at)).toBeInTheDocument();
    // buttons
    expect(screen.getByText('Filter')).toBeInTheDocument();
    expect(screen.getByText('Add Actor')).toBeInTheDocument();
    expect(screen.getByText('Enable feature for all actors')).toBeInTheDocument();
    expect(screen.getByText('Disable feature for all actors')).toBeInTheDocument();
    expect(screen.getByText('Delete Feature')).toBeInTheDocument();
    // filter input
    expect(screen.getByLabelText('Filter by name')).toBeInTheDocument();
    //pagination
    expect(screen.getByTitle('Pagination Arrow Left Disabled')).toBeInTheDocument();
    expect(screen.getByTitle('Pagination Right Arrow Disabled')).toBeInTheDocument();
  });

  it('Refreshes data when user searches for specific gate', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.type(screen.getByLabelText('Filter by name'), 'test');
    userEvent.click(screen.getByText('Filter'));
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(2));
  });

  it('Displays loading text when table is loading', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    expect(screen.getByLabelText('Loading Spinner')).toBeInTheDocument();
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    expect(screen.queryByLabelText('Loading Spinner')).not.toBeInTheDocument();
  });

  it('Displays number of enabled actors when feature is not fully enabled', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    expect(screen.getByText(`Currently enabled for ${featureGates.data.length} actor(s)`)).toBeInTheDocument();
    expect(screen.queryByText('Feature is enabled for all actors')).not.toBeInTheDocument();
  });

  it('clicking enable feature button refreshes data on success', async () => {
    jest.spyOn(service, 'enableFeature').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({data: fullyEnabledFeatureGate.data[0]});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Enable feature for all actors'));
    expect(screen.queryByText('Are you sure you want to enable this feature for ALL actors?')).toBeInTheDocument();
    userEvent.click(screen.getByText('Yes, enable'));
    await waitFor(() => expect(service.enableFeature).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(2));
  });

  it('clicking disable feature button refreshes data on success', async () => {
    jest.spyOn(service, 'disableFeature').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({data: fullyEnabledFeatureGate.data[0]});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Disable feature for all actors'));
    expect(screen.queryByText('Are you sure you want to disable this feature for ALL actors?')).toBeInTheDocument();
    userEvent.click(screen.getByText('Yes, disable'));
    await waitFor(() => expect(service.disableFeature).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(2));
  });

  it('shows an error form box when there is an error response getting feature gates', async () => {
    jest.spyOn(service, 'getFeatureGates').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    const errorText = await screen.findByText('Error retrieving feature actors');
    expect(errorText).toBeInTheDocument();
  });

  it('shows an error form box when there is a 500 error when getting feature gates ', async () => {
    jest.spyOn(service, 'getFeatureGates').mockImplementation(() => {
      throw new Error();
    });
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    const errorText = await screen.findByText('Error retrieving feature actors');
    expect(errorText).toBeInTheDocument();
  });

  it('shows an error form box when there is a 500 error when enabling feature', async () => {
    jest.spyOn(service, 'enableFeature').mockImplementation(() => {
      throw new Error();
    });
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Enable feature for all actors'));
    expect(screen.queryByText('Are you sure you want to enable this feature for ALL actors?')).toBeInTheDocument();
    userEvent.click(screen.getByText('Yes, enable'));
    const errorText = await screen.findByText('Error enabling feature for all actors');
    expect(errorText).toBeInTheDocument();
  });

  it('shows an error form box when there is an error enabling feature', async () => {
    jest.spyOn(service, 'enableFeature').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Enable feature for all actors'));
    expect(screen.queryByText('Are you sure you want to enable this feature for ALL actors?')).toBeInTheDocument();
    userEvent.click(screen.getByText('Yes, enable'));
    const errorText = await screen.findByText('test error');
    expect(errorText).toBeInTheDocument();
  });

  it('shows an error form box when there is a 500 error when disabling feature', async () => {
    jest.spyOn(service, 'disableFeature').mockImplementation(() => {
      throw new Error();
    });
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Disable feature for all actors'));
    expect(screen.queryByText('Are you sure you want to disable this feature for ALL actors?')).toBeInTheDocument();
    userEvent.click(screen.getByText('Yes, disable'));
    const errorText = await screen.findByText('Error disabling feature for all actors');
    expect(errorText).toBeInTheDocument();
  });

  it('shows an error form box when there is an error disabling feature', async () => {
    jest.spyOn(service, 'disableFeature').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Disable feature for all actors'));
    expect(screen.queryByText('Are you sure you want to disable this feature for ALL actors?')).toBeInTheDocument();
    userEvent.click(screen.getByText('Yes, disable'));
    const errorText = await screen.findByText('test error');
    expect(errorText).toBeInTheDocument();
  });

  describe('Fully enabled feature', () => {
    beforeEach(() => {
      jest.spyOn(console, 'error').mockImplementation(() => {});
      jest.spyOn(service, 'getFeatureGates').mockImplementation(() =>
        Promise.resolve({
          status: 200,
          response: true,
          ok: true,
          json: jest.fn(() => {
            return Promise.resolve(fullyEnabledFeatureGate);
          })
        })
      );
    });

    it('displays fully enabled text instead of number of actors when feature is fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.queryByText(`Currently enabled for ${fullyEnabledFeatureGate.data.length} actor(s)`)).not.toBeInTheDocument();
      expect(screen.getByText('Feature is enabled for all actors')).toBeInTheDocument();
    });

    it('Doesnt display add actor button when fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.queryByText('Add Actor')).not.toBeInTheDocument();
    });

    it('Doesnt display fully enable feature button when fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.queryByText('Enable feature for all actors')).not.toBeInTheDocument();
    });

    it('Doesnt display feature gates table when feature is fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.queryByRole('table')).not.toBeInTheDocument();
    });

    it('Doesnt display search filter input or button when fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.queryByText('Filter')).not.toBeInTheDocument();
      expect(screen.queryByLabelText('Filter by name')).not.toBeInTheDocument();
    });

    it('Doesnt display pagination when fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.queryByText('←')).not.toBeInTheDocument();
      expect(screen.queryByText('→')).not.toBeInTheDocument();
    });

    it('Does display disable feature for all button when feature is fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.getByText('Disable feature for all actors')).toBeInTheDocument();
    });

    it('Does display delete feature button when feature is fully enabled', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.getByText('Delete Feature')).toBeInTheDocument();
    });
  });

  describe('Fully disabled feature', () => {
    beforeEach(() => {
      jest.spyOn(console, 'error').mockImplementation(() => {});
      jest.spyOn(service, 'getFeatureGates').mockImplementation(() =>
        Promise.resolve({
          status: 200,
          response: true,
          ok: true,
          json: jest.fn(() => {
            return Promise.resolve({
              gates: { data: [] },
              meta: { total_record_count: 0, filtered_record_count: 0 }
            });
          })
        })
      );
    });

    it('Displays that the feature is enabled for 0 actors', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.getByText(`Currently enabled for 0 actor(s)`)).toBeInTheDocument();
    });

    it('Displays no records found text', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.getByText('No Records Found')).toBeInTheDocument();
    });

    it('Doesnt show disable feature for all actors button', async () => {
      render(<FeatureTogglePage feature={features.data[0].attributes} />);
      await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
      expect(screen.queryByText('Disable feature for all actors')).not.toBeInTheDocument();
    });

  });

});
