import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import { beneficialOwnerResponseFromAggregate } from '../helpers/fixtures';
import client from 'lib/ajax';
import userEvent from '@testing-library/user-event';
import EditBeneficialOwner from '../EditBeneficialOwner';
import notify from 'lib/notify';

describe('EditBeneficialOwner', () => {

  const today = new Date().toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'});

  beforeEach(() => {
    jest.spyOn(notify, 'success').mockImplementation(() => ({}));
    jest.spyOn(notify, 'error').mockImplementation(() => ({}));
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(beneficialOwnerResponseFromAggregate);
        })
      })
    );
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
  });

  it('renders loading screen then edit screen after request completes ', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    expect(screen.getByLabelText('Loading Spinner')).toBeVisible();
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.queryByLabelText('Loading Spinner')).not.toBeInTheDocument();
    expect(screen.getByText('Edit Beneficial Owner')).toBeVisible();
  });

  it('renders error message box and calls notify with json error when there is an error fetching beneficial owner', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving beneficial owner information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('renders error message box and doesnt call notify json error doesnt exist when there is an error fetching beneficial owner', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: ''});
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving beneficial owner information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).not.toHaveBeenCalledTimes(1);
  });

  it('renders error message box when an error is thrown fetching beneficial owner', async () => {
    jest.spyOn(client, 'get').mockImplementation(() => {
      throw new Error();
    });
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving beneficial owner information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).not.toHaveBeenCalledTimes(1);
  });

  it('renders error message box when beneficial owner response is empty', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('There was an error retrieving beneficial owner information. Please refresh and try again.')).toBeVisible();
    expect(notify.error).not.toHaveBeenCalledTimes(1);
  });

  it('back button calls setEditedBeneficialOwnerId callback function', async () => {
    const mock = jest.fn();
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2} setEditedBeneficialOwnerId={mock}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Back to Legal'));
    expect(mock).toHaveBeenCalledTimes(1);
  });

  it('form elements are visible after load with correct values', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByLabelText('Given Name')).toHaveDisplayValue('Joe');
    expect(screen.getByLabelText('Surname')).toHaveDisplayValue('Adyen');
    expect(screen.getAllByLabelText('Title')[0]).toHaveTextContent('President');
    expect(screen.getByLabelText('Address')).toHaveDisplayValue('123 street biz');
    expect(screen.getByLabelText('Additional Address')).toHaveDisplayValue('unit 5');
    expect(screen.getByLabelText('City')).toHaveDisplayValue('austin');
    expect(screen.getAllByLabelText('State')[0]).toHaveTextContent('Texas');
    expect(screen.getByLabelText('Postal Code')).toHaveDisplayValue('78746-1234');
    expect(screen.getAllByLabelText('Country')[0]).toHaveTextContent('United States');
    expect(screen.getByLabelText('Email')).toHaveDisplayValue('joeadyen@example.com');
    expect(screen.getByLabelText('Phone')).toHaveDisplayValue('1(512) 555-5555');
    expect(screen.getByLabelText('Date Of Birth')).toHaveValue('12/12/1990');
    expect(screen.getAllByLabelText('Citizenship')[0]).toHaveTextContent('United States');
    expect(screen.getByLabelText('Years At Residence')).toHaveDisplayValue('5');
    expect(screen.getByLabelText('Has Significant Ownership')).toBeChecked();
    expect(screen.getByLabelText('Has Significant Management')).not.toBeChecked();
  });

  it('can interact with form elements', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const nameInput = screen.getByLabelText('Given Name');
    expect(nameInput).toHaveDisplayValue('Joe');
    userEvent.clear(nameInput);
    userEvent.type(nameInput, 'new name');
    expect(nameInput).toHaveDisplayValue('new name');
  });

  it('can interact with form checkbox elements', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const ownershipCheckbox = screen.getByLabelText('Has Significant Ownership');
    expect(ownershipCheckbox).toBeChecked();
    userEvent.click(ownershipCheckbox);
    expect(ownershipCheckbox).not.toBeChecked();
  });

  it('can interact with form datepicker elements', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const dobInput = screen.getByLabelText('Date Of Birth');
    expect(dobInput).toHaveValue('12/12/1990');
    userEvent.click(dobInput);
    userEvent.click(screen.getByText('15'));
    expect(dobInput).toHaveValue('12/15/1990');
  });

  it('can interact with form address elements', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const addressInput = screen.getByLabelText('Address');
    expect(addressInput).toHaveDisplayValue('123 street biz');
    userEvent.clear(addressInput);
    userEvent.type(addressInput, 'new address');
    expect(addressInput).toHaveDisplayValue('new address');
  });

  it('can interact with form identification elements', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const identificationType = screen.getAllByLabelText('Identification Type')[0];
    userEvent.click(identificationType);
    userEvent.click(screen.getByText('Drivers License'));
    expect(screen.queryByLabelText('Social Security Number')).not.toBeInTheDocument();
    const licenseNumberInput = screen.getByLabelText('Drivers License Number');
    expect(licenseNumberInput).toHaveDisplayValue('');
    userEvent.type(licenseNumberInput, '12345');
    expect(licenseNumberInput).toHaveDisplayValue('12345');
  });

  it('notifies user that only US can be selected as country and doesnt change the country', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const countryInput = screen.getAllByLabelText('Country')[0];
    userEvent.click(countryInput);
    userEvent.click(screen.getByText('Canada'));
    expect(notify.error).toHaveBeenCalledWith('Only US addresses are supported at this time.');
    expect(countryInput).toHaveTextContent('United States');
  });

  it('renders social security form if identification type is social security', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getAllByLabelText('Identification Type')[0]).toHaveTextContent('Social Security');
    expect(screen.getByLabelText('Social Security Number')).toHaveDisplayValue('123-12-3123');
  });

  it('renders US drivers license form if identification type is us driver license', async () => {
    const newBeneficialOwner = { data: { attributes: {
      ...beneficialOwnerResponseFromAggregate.data.attributes,
      identification: {
        type: 'us_driver_license',
        number: '123456',
        expiration_date: '2025-05-15',
        state: 'tx'
      }
    }}};
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(newBeneficialOwner);
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getAllByLabelText('Identification Type')[0]).toHaveTextContent('Drivers License');
    expect(screen.getByLabelText('Drivers License Number')).toHaveDisplayValue('123456');
    expect(screen.getAllByLabelText('Issuing State')[0]).toHaveTextContent('Texas');
    expect(screen.getByLabelText('Expiration Date')).toHaveValue('05/15/2025');
  });

  it('renders passport form if identification type is passport', async () => {
    const newBeneficialOwner = { data: { attributes: {
      ...beneficialOwnerResponseFromAggregate.data.attributes,
      identification: {
        type: 'passport',
        number: '123456',
        expiration_date: '2025-05-15',
        country: 'us'
      }
    }}};
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(newBeneficialOwner);
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getAllByLabelText('Identification Type')[0]).toHaveTextContent('Passport');
    expect(screen.getByLabelText('Passport Number')).toHaveDisplayValue('123456');
    expect(screen.getAllByLabelText('Issuing Country')[0]).toHaveTextContent('United States');
    expect(screen.getByLabelText('Expiration Date')).toHaveValue('05/15/2025');
  });

  it('renders visa form if identification type is visa', async () => {
    const newBeneficialOwner = { data: { attributes: {
      ...beneficialOwnerResponseFromAggregate.data.attributes,
      identification: {
        type: 'visa',
        number: '123456',
        expiration_date: '2025-05-15',
        country: 'us'
      }
    }}};
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(newBeneficialOwner);
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getAllByLabelText('Identification Type')[0]).toHaveTextContent('Visa');
    expect(screen.getByLabelText('Visa Number')).toHaveDisplayValue('123456');
    expect(screen.getAllByLabelText('Issuing Country')[0]).toHaveTextContent('United States');
    expect(screen.getByLabelText('Expiration Date')).toHaveValue('05/15/2025');
  });

  it('user can switch between identification types', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByLabelText('Social Security Number')).toBeVisible();
    const identificationType = screen.getAllByLabelText('Identification Type')[0];
    
    userEvent.click(identificationType);
    userEvent.click(screen.getByText('Drivers License'));

    // drivers license form
    expect(screen.queryByLabelText('Social Security Number')).not.toBeInTheDocument();
    expect(screen.getByLabelText('Drivers License Number')).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('Issuing State')[0]).toHaveTextContent('Select One');
    expect(screen.getByLabelText('Expiration Date')).toHaveValue(today);

    userEvent.click(identificationType);
    userEvent.click(screen.getByText('Passport'));

    // passport form 
    expect(screen.queryByLabelText('Drivers License Number')).not.toBeInTheDocument();
    expect(screen.getByLabelText('Passport Number')).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('Issuing Country')[0]).toHaveTextContent('Select One');
    expect(screen.getByLabelText('Expiration Date')).toHaveValue(today);

    userEvent.click(identificationType);
    userEvent.click(screen.getByText('Visa'));

    // visa form 
    expect(screen.queryByLabelText('Passport Number')).not.toBeInTheDocument();
    expect(screen.getByLabelText('Visa Number')).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('Issuing Country')[0]).toHaveTextContent('Select One');
    expect(screen.getByLabelText('Expiration Date')).toHaveValue(today);
  });

  it('can save the form', async () => {
    const setCurrentMock = jest.fn();
    const setEditedMock = jest.fn();
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2} setCurrentLegalEntity={setCurrentMock} setEditedBeneficialOwnerId={setEditedMock}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const saveButton = screen.getByText('Save Changes');
    userEvent.click(saveButton);
    expect(saveButton).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(1);
    expect(notify.success).toHaveBeenCalledWith('Beneficial Owner successfully updated');
    expect(setCurrentMock).toHaveBeenCalledTimes(1);
    expect(setEditedMock).toHaveBeenCalledTimes(1);
  });

  it('shows user an error and re-enables save button if there is an error returned from backend', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Save Changes'));
    expect(screen.getByText('Save Changes')).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });

  it('saving the form shows user a generic error and re-enables save button if request fails with blank error', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: ''});
        })
      })
    );
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Save Changes'));
    expect(screen.getByText('Save Changes')).toBeDisabled();
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
    expect(notify.error).toHaveBeenCalledWith('Unexpected error occurred when updating beneficial owner', [], false);
  });

  it('saving the form shows user a generic error and re-enables save button if request throws an error', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() => {
      throw new Error();
    });
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
    expect(notify.error).toHaveBeenCalledWith('Unexpected error occurred when updating beneficial owner', [], false);
  });

  it('has correct constraints for every form element', async () => {
    render(<EditBeneficialOwner merchantId={1} beneficialOwnerId={2}/>);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    const givenName = screen.getByLabelText('Given Name');
    expect(givenName).toBeRequired();
    expect(givenName).toHaveAttribute('maxLength', '20');
    expect(givenName).toHaveAttribute('minLength', '1');

    const surName = screen.getByLabelText('Surname');
    expect(surName).toBeRequired();
    expect(surName).toHaveAttribute('maxLength', '20');
    expect(surName).toHaveAttribute('minLength', '1');

    const title = screen.getAllByLabelText('Title')[0];
    // use nextSibling.nextSibling to find the hidden select input which has the required attribute
    expect(title.nextSibling.nextSibling).toBeRequired();

    const address1 = screen.getByLabelText('Address');
    expect(address1).toBeRequired();
    expect(address1).toHaveAttribute('maxLength', '255');

    const address2 = screen.getByLabelText('Additional Address');
    expect(address2).not.toBeRequired();
    expect(address2).toHaveAttribute('maxLength', '255');

    const city = screen.getByLabelText('City');
    expect(city).toBeRequired();
    expect(city).toHaveAttribute('maxLength', '255');

    const state = screen.getAllByLabelText('State')[0];
    // use nextSibling.nextSibling to find the hidden select input which has the required attribute
    expect(state.nextSibling.nextSibling).toBeRequired();

    const zipCode = screen.getByLabelText('Postal Code');
    expect(zipCode).toBeRequired();

    const country = screen.getAllByLabelText('Country')[0];
    expect(country.nextSibling.nextSibling).toBeRequired();

    const email = screen.getByLabelText('Email');
    expect(email).toBeRequired();

    const phone = screen.getByLabelText('Phone');
    expect(phone).toBeRequired();

    const citizenship = screen.getAllByLabelText('Citizenship')[0];
    expect(citizenship.nextSibling.nextSibling).toBeRequired();

    const yearsAtResidence = screen.getByLabelText('Years At Residence');
    expect(yearsAtResidence).not.toBeRequired();
    expect(yearsAtResidence).toHaveAttribute('type', 'number');
    expect(yearsAtResidence).toHaveAttribute('min', '0');

    const identificationType = screen.getAllByLabelText('Identification Type')[0];
    expect(identificationType.nextSibling.nextSibling).toBeRequired();

    const ssnNumber = screen.getByLabelText('Social Security Number');
    expect(ssnNumber).toBeRequired();

    userEvent.click(identificationType);
    userEvent.click(screen.getByText('Drivers License'));

    const licenseNumber = screen.getByLabelText('Drivers License Number');
    expect(licenseNumber).toBeRequired();
    expect(licenseNumber).toHaveAttribute('minLength', '4');
    expect(licenseNumber).toHaveAttribute('maxLength', '24');

    const issuingState = screen.getAllByLabelText('Issuing State')[0];
    expect(issuingState.nextSibling.nextSibling).toBeRequired();

    userEvent.click(identificationType);
    userEvent.click(screen.getByText('Passport'));

    const passportNumber = screen.getByLabelText('Passport Number');
    expect(passportNumber).toBeRequired();
    expect(passportNumber).toHaveAttribute('minLength', '4');
    expect(passportNumber).toHaveAttribute('maxLength', '36');

    const passportCountry = screen.getAllByLabelText('Issuing Country')[0];
    expect(passportCountry.nextSibling.nextSibling).toBeRequired();

    userEvent.click(identificationType);
    userEvent.click(screen.getByText('Visa'));

    const visaNumber = screen.getByLabelText('Visa Number');
    expect(visaNumber).toBeRequired();
    expect(visaNumber).toHaveAttribute('minLength', '4');
    expect(visaNumber).toHaveAttribute('maxLength', '36');

    const visaCountry = screen.getAllByLabelText('Issuing Country')[0];
    expect(visaCountry.nextSibling.nextSibling).toBeRequired();
  });
});