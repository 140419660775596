import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QuickBillActionsForPending from '../QuickBillActionsForPending';
import * as Utils from '../../utils';
import client from '../../../../lib/ajax';

const vanillaProps = {
  bankAccounts: [
    {
      name: 'BBVA Compass *****0000',
      bankName: 'BBVA Compass',
      trust: false,
      xid: 'bank_e3g1J5RBRpaYF8oHvNo94',
      accountNumber: '*****0000',
      achLimit: 0
    },
    {
      name: 'TRUST Bancorp *****0001',
      bankName: 'Bancorp',
      trust: true,
      xid: 'bank_9Zq4ddK4m6eZGaAXqrQXX',
      accountNumber: '*****0001',
      achLimit: null
    },
    {
      name: 'Wells Fargo *****0000',
      bankName: 'Wells Fargo',
      trust: false,
      xid: 'bank_ECja7bEfSLTZ11QtsLOZd',
      accountNumber: '*****0000',
      achLimit: 5000
    }
  ],
  created: {
    timestamp: '2022-10-26T04:02:32.077Z'
  },
  invoice: {
    id: 'abc123',
    contact: {
      firstName: 'Foo',
      lastName: 'Bar'
    },
    status: 'draft',
    invoiceNumber: '1',
    invoiceDate: '10/10/2022',
    dueDate: '11/30/2022',
    totalAmount: 10000,
    totalAmountDue: 10000,
    amountPaid: 0,
    testMode: false,
    currency: 'USD',
    bankAccountId: '987654321',
    ownerId: '1',
    contactId: 'abc123',
    invoiceMessages: [
      {
        id: '23rqsdaf',
        emailAddresses: ['foo@bar.com'],
        body: 'email body',
        message: 'email message',
        subject: 'email subject',
        status: 'draft',
        type: 'eqb'
      }]
  },
  selectedBankAccount: undefined,
  setSelectedBankAccount: jest.fn(),
  permissions: {
    deleteInvoice: true,
    collectPayments: true
  }
};

describe('QuickBillActionsForPending', () => {
  beforeEach(() => {
    jest.spyOn(Utils, 'navigateToEditInvoice').mockImplementation(() =>
      Promise.resolve({
        status: 302,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
  });

  it('renders', () => {
    render(<QuickBillActionsForPending {...vanillaProps} />);

    expect(screen.getByText('Select One')).toBeVisible();
    expect(screen.getByText('Resend')).toBeVisible();
    expect(screen.getByText('Edit & Resend')).toBeVisible();
    expect(screen.getByText('Mark As Paid')).toBeVisible();
    expect(screen.getByText('Delete')).toBeVisible();
  });

  it('shows a success toast when account is changed', async () => {
    render(<QuickBillActionsForPending {...vanillaProps} />);
    userEvent.click(screen.getByText('Select One'));
    expect(screen.getByText('BBVA Compass *****0000')).toBeVisible();
    expect(screen.getByText('TRUST Bancorp *****0001')).toBeVisible();
    expect(screen.getByText('Wells Fargo *****0000')).toBeVisible();
    userEvent.click(screen.getByText('Wells Fargo *****0000'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.patch).toHaveBeenCalledWith(
      '/quick_bills/abc123',
      {
        'id': 'abc123',
        'invoice': {'bank_account_id': 'bank_ECja7bEfSLTZ11QtsLOZd'}
      }));
    expect(screen.getByText('Deposit Account has been changed')).toBeVisible();
  });

  it('shows a unauthorized toast when account change is attempted', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 401,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<QuickBillActionsForPending {...vanillaProps} />);
    userEvent.click(screen.getByText('Select One'));
    userEvent.click(screen.getByText('Wells Fargo *****0000'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.patch).toHaveBeenCalledWith(
      '/quick_bills/abc123',
      {
        'id': 'abc123',
        'invoice': {'bank_account_id': 'bank_ECja7bEfSLTZ11QtsLOZd'}
      }));
    expect(screen.getByText('You do not have permission to do this operation')).toBeVisible();
  });

  it('shows a error toast when account change has failed', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<QuickBillActionsForPending {...vanillaProps} />);
    userEvent.click(screen.getByText('Select One'));
    userEvent.click(screen.getByText('Wells Fargo *****0000'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.patch).toHaveBeenCalledWith(
      '/quick_bills/abc123',
      {
        'id': 'abc123',
        'invoice': {'bank_account_id': 'bank_ECja7bEfSLTZ11QtsLOZd'}
      }));
    expect(screen.getByText('Unable to change Deposit Account')).toBeVisible();
  });

  it('shows a error toast when account change has provides unknown http status', async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 504,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<QuickBillActionsForPending {...vanillaProps} />);
    userEvent.click(screen.getByText('Select One'));
    userEvent.click(screen.getByText('Wells Fargo *****0000'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.patch).toHaveBeenCalledWith(
      '/quick_bills/abc123',
      {
        'id': 'abc123',
        'invoice': {'bank_account_id': 'bank_ECja7bEfSLTZ11QtsLOZd'}
      }));
    expect(screen.getByText('Unable to change Deposit Account')).toBeVisible();
  });

  it('shows a success toast when quick bill is sent successfully', async () => {
    render(<QuickBillActionsForPending {...vanillaProps} />);
    userEvent.click(screen.getByText('Resend'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.post).toHaveBeenCalledWith(
      '/quick_bills/abc123/resend',
      {
        'invoice_message': {
          'email': 'foo@bar.com',
          'message': 'email message',
          'subject': 'email subject'
        }
      }));
    expect(screen.getByText('The Quick Bill has been resent to foo@bar.com')).toBeVisible();
  });

  it('shows an error toast when quick bill was not sent successfully', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<QuickBillActionsForPending {...vanillaProps} />);
    userEvent.click(screen.getByText('Resend'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Quick Bill could not be resent')).toBeVisible();
  });
});
