import React, {useState} from 'react';
import {func, object, string} from 'prop-types';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QuickBillAttachments, {decorateFileSize} from '../QuickBillAttachments';
import client from '../../../../lib/ajax';
import * as Utils from '../../../../lib/utils';

const initialFormValues = {
  amount: '',
  attachments: [],
  bankAccount: undefined,
  bankAccountId: '',
  emailAddress: '',
  emailBody: 'This is a vanilla email message',
  emailSubject: 'This is a vanilla email subject',
  firstName: '',
  lastName: '',
  reference: ''
};

const authUrl = '/quick_bills/authorize_attachment';
const testFile = new File(['bobsburgers'], 'bobsburgers.png', {type: 'image/png'});

const QuickBillAttachmentsWrapper = ({ merchantId = '1a2b', values=initialFormValues, setAttachmentsToDelete=jest.fn()}) => {
  const [formValues, setFormValues] = useState(values);
  const [isDisabled, setIsDisabled] = useState(false);  // eslint-disable-line no-unused-vars
  const checkValuesMissing = jest.fn();

  return (
    <QuickBillAttachments
      merchantId={merchantId}
      formValues={formValues}
      setFormValues={setFormValues}
      setIsDisabled={setIsDisabled}
      checkValuesMissing={checkValuesMissing}
      setAttachmentsToDelete={setAttachmentsToDelete}
    />
  );
};

QuickBillAttachmentsWrapper.propTypes = {
  merchantId: string,
  values: object,
  setFormValues: func,
  setAttachmentsToDelete: func
};

const authResponse = {
  status: 200,
  json: () => ({ url: 'http://api-gateway:80/contacts/attachments/123abc'})
};

const uploadResponse = {
  status: 200,
  json: () => ({
    id: 'xyz987',
    file_name: 'bobsburgers.png',
    processing_state: 'processing',
    type: 'invoice_file_reference'
  })
};

const virusScanResponse = {
  status: 200,
  json: () => ({ status: true })
};

describe('decorateFileSize', () => {
  it('returns 0.00B if no value is passed', () => {
    expect(decorateFileSize()).toBe('0B');
  });

  it('correctly returns amount for values under 1001 Bytes', () => {
    expect(decorateFileSize(1)).toBe('1B');
    expect(decorateFileSize(560)).toBe('560B');
    expect(decorateFileSize(1000)).toBe('1000B');
  });

  it('correctly returns amount for values between 1001 B and one MB', () => {
    expect(decorateFileSize(1001)).toBe('1.00KB');
    expect(decorateFileSize(1023)).toBe('1.02KB');
    expect(decorateFileSize(1024)).toBe('1.02KB');
    expect(decorateFileSize(99999)).toBe('100.00KB');
    expect(decorateFileSize(100000)).toBe('100.00KB');
    expect(decorateFileSize(500434)).toBe('500.43KB');
    expect(decorateFileSize(999999)).toBe('1000.00KB');
  });

  it('correctly returns amount for values over one MB', () => {
    expect(decorateFileSize(1000000)).toBe('1000.00KB');
    expect(decorateFileSize(1000001)).toBe('1.00MB');
    expect(decorateFileSize(1000010)).toBe('1.00MB');
    expect(decorateFileSize(1000100)).toBe('1.00MB');
    expect(decorateFileSize(1001000)).toBe('1.00MB');
    expect(decorateFileSize(1010000)).toBe('1.01MB');
    expect(decorateFileSize(1110000)).toBe('1.11MB');
  });
});

describe('QuickBillAttachments', () => {
  beforeAll(() => {
    jest.restoreAllMocks();
    jest
      .spyOn(client, 'get')
      .mockImplementation(url => {
        if (url === authUrl) return Promise.resolve(authResponse);
        return Promise.resolve(virusScanResponse);
      });
    jest
      .spyOn(client, 'multiPartPost')
      .mockReturnValue(Promise.resolve(uploadResponse));
    jest
      .spyOn(client, 'destroy')
      .mockReturnValue(() => ({ ok: true, status: 200 }));
    jest
      .spyOn(Utils, 'rollbarLog')
      .mockReturnValue(Promise.resolve({
        ok: true,
        status: 200
      }));
  });

  it('renders ', () => {
    render(<QuickBillAttachmentsWrapper />);
    expect(screen.getByLabelText('Attachment Upload')).toBeVisible();
    expect(screen.getByText('Add Attachment(s) (Optional)')).toBeVisible();
    expect(screen.getByText('Choose File')).toBeVisible();
  });

  it('allows user to upload files', async () => {
    render(<QuickBillAttachmentsWrapper />);
    userEvent.upload(screen.getByText('Choose File'), testFile);
    expect(screen.getByLabelText('Attachment Upload')).toBeDisabled();
    await waitFor(() => expect(screen.getByText('Uploading file:')).toBeVisible());
    await waitFor(() => expect(screen.getByText('bobsburgers.png')).toBeVisible());
    expect(screen.getByLabelText('Attachment Upload')).not.toBeDisabled();
    expect(client.get).toHaveBeenCalledTimes(2);
    expect(client.multiPartPost).toHaveBeenCalledTimes(1);
    expect(screen.getByText('Remove')).toBeVisible();
    expect(screen.getByText('bobsburgers.png')).toBeVisible();
    expect(screen.getByTestId('quick-bill-attachments-list').firstChild.children.length).toBe(1);
  });

  it('displays an error message if the attachment size exceeds 10MB', () => {
    render(<QuickBillAttachmentsWrapper />);
    const largeFile = new File(['fat-file'], 'fat-file.jpg');
    Object.defineProperty(largeFile, 'size', {value: 24975901});
    userEvent.upload(screen.getByText('Choose File'), largeFile);
    expect(screen.getByText('File size is limited to 10MB. fat-file.jpg has a size of 24.98MB'));
  });

  it('invokes client.destroy if file is not part of removal of files added', async () => {
    render(<QuickBillAttachmentsWrapper />);
    userEvent.upload(screen.getByText('Choose File'), testFile);
    await waitFor(() => expect(screen.getByText('Uploading file:')).toBeVisible());
    await waitFor(() => expect(screen.getByText('bobsburgers.png')).toBeVisible());
    expect(screen.getByText('Remove')).toBeVisible();

    userEvent.click(screen.getByText('Remove'));

    expect(screen.queryByText('Remove')).toBeInTheDocument();
    expect(screen.queryByText('bobsburgers.png')).toBeInTheDocument();
    expect(screen.getByTestId('ap-alert-modal')).toBeInTheDocument();
    expect(screen.getByText('Are you sure you want to remove the attached file bobsburgers.png?')).toBeInTheDocument();

    userEvent.click(screen.getByText('Yes, Delete'));

    await waitFor(() => expect(client.destroy).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.destroy).toHaveBeenCalledWith('/quick_bills/attachments/xyz987/delete_from_merchant/1a2b'));

    expect(screen.queryByText('bobsburgers.jpeg')).not.toBeInTheDocument();
  });


  it('invokes the setFilesToDelete prop function on removal if file is already part of an invoice', async () => {
    const passedInvoiceAttachment = {
      id: 'fref_FpSXNVcNusHiPBzcwNo1Z',
      created: {
        user: 'service@affinipay.com',
        timestamp: '2023-09-28T09:10:31.882Z',
        clientId: '4fa10f287906b2d0c2ed69a6dcca44ed472dd977e9edc20f9d44fb401f769a38',
        clientInetAddress: '172.21.240.73',
        callerInetAddress: '172.21.240.73'
      },
      modified: {
        user: 'SERVER',
        timestamp: '2023-09-28T09:10:31.975Z',
        clientId: '4fa10f287906b2d0c2ed69a6dcca44ed472dd977e9edc20f9d44fb401f769a38',
        clientInetAddress: '0.0.0.0',
        callerInetAddress: '0.0.0.0'
      },
      fileName: 'wiley.gif',
      processingState: 'completed',
      type: 'invoice_file_reference'
    };

    const formValuesWithAttachment = {
      ...initialFormValues,
      attachments: [passedInvoiceAttachment]
    };

    const handleAttachmentsToDelete = jest.fn();

    render(<QuickBillAttachmentsWrapper values={formValuesWithAttachment} setAttachmentsToDelete={handleAttachmentsToDelete} />);
    userEvent.click(screen.getByText('Remove'));
    userEvent.click(screen.getByText('Yes, Delete'));

    await waitFor(() => expect(handleAttachmentsToDelete).toHaveBeenCalledTimes(1));
  });

  it('disallows files with invalid extensions', () => {
    render(<QuickBillAttachmentsWrapper />);
    const invalidFile = new File(['bobsburgers'], 'bobsburgers.jpeg', {type: 'image/jpeg'});
    userEvent.upload(screen.getByText('Choose File'), invalidFile);
    expect(screen.queryByText('Remove')).not.toBeInTheDocument();
    expect(screen.queryByText('bobsburgers.jpeg')).not.toBeInTheDocument();
    expect(screen.getByText('File extension is invalid, it must be one of: csv, doc, docx, gif, jpg, pdf, png, rtf, tif, txt, xlsx, xls')).toBeVisible();
  });

  it('valid extensions for attachments in uppercase are considered valid', async () => {
    render(<QuickBillAttachmentsWrapper />);
    const invalidFile = new File(['bobsburgers'], 'bobsburgers.PNG', {type: 'image/png'});
    userEvent.upload(screen.getByText('Choose File'), invalidFile);
    await waitFor(() => expect(screen.getByText('Remove')).toBeVisible());
    await waitFor(() => expect(screen.getByText('bobsburgers.PNG')).toBeInTheDocument());
    expect(screen.queryByText('File extension is invalid, it must be one of: csv, doc, docx, gif, jpg, pdf, png, rtf, tif, txt, xlsx, xls')).not.toBeInTheDocument();
  });
});

describe('QuickBillAttachments Error Handling', () => {
  it('displays an error if authorization API fails', async () => {
    jest
      .spyOn(client, 'get')
      .mockImplementation(url => {
        if (url === authUrl) return Promise.resolve({status: 401, json: () => ({})});
      });
    render(<QuickBillAttachmentsWrapper />);
    userEvent.upload(screen.getByText('Choose File'), testFile);
    await waitFor(() => expect(screen.getByText('Could not get permission to upload file: bobsburgers.png')).toBeVisible());
  });

  it('displays a generic error if upload API fails without error messages', async () => {
    jest.spyOn(client, 'get').mockImplementation(url => {
      if (url === authUrl) return Promise.resolve({
        status: 200,
        json: () => ({ url: 'http://api-gateway:80/contacts/attachments/123abc'})
      });
    });
    jest.spyOn(client, 'multiPartPost').mockReturnValue(Promise.resolve({status: 403, json: () => ({})}));
    render(<QuickBillAttachmentsWrapper />);
    userEvent.upload(screen.getByText('Choose File'), testFile);
    await waitFor(() => expect(screen.getByText('Could not upload file: bobsburgers.png')).toBeVisible());
  });

  it('displays a generic error if upload API fails without error messages', async () => {
    const longname = 'Thisisareallylongemailaddressusedinordertotestthedisplayofanerror@gmail.com';
    const fileWithLongName = new File([longname], 'bobsburgers.png', {type: 'image/png'});
    jest.spyOn(client, 'get').mockImplementation(url => {
      if (url === authUrl) return Promise.resolve({
        status: 200,
        json: () => ({ url: 'http://api-gateway:80/contacts/attachments/123abc'})
      });
    });
    jest.spyOn(client, 'multiPartPost').mockReturnValue(Promise.resolve({
      status: 403,
      json: () => ({
        messages: [
          {
            code: 'invalid_request',
            level: 'error',
            message: 'File name must be less than 64 characters',
            facility: 'server'
          }
        ]
      })
    }));

    render(<QuickBillAttachmentsWrapper />);
    userEvent.upload(screen.getByText('Choose File'), fileWithLongName);
    await waitFor(() => expect(screen.getByText('File name must be less than 64 characters')).toBeVisible());
  });

  it('displays a generic error if upload API fails without error messages', async () => {
    jest.spyOn(client, 'get').mockImplementation(url => {
      if (url === authUrl) return Promise.resolve({
        status: 200,
        json: () => ({ url: 'http://api-gateway:80/contacts/attachments/123abc'})
      });
    });
    jest.spyOn(client, 'multiPartPost').mockReturnValue(Promise.resolve({status: 403, json: () => ({})}));
    render(<QuickBillAttachmentsWrapper />);
    userEvent.upload(screen.getByText('Choose File'), testFile);
    await waitFor(() => expect(screen.getByText('Could not upload file: bobsburgers.png')).toBeVisible());
  });

  it('displays an error if virus scan API fails', async () => {
    jest.spyOn(client, 'get').mockImplementation(url => {
      if (url === authUrl) return Promise.resolve({
        status: 200,
        json: () => ({ url: 'http://api-gateway:80/contacts/attachments/123abc'})
      });
      return Promise.resolve({ status: 500 });
    });
    jest.spyOn(client, 'multiPartPost').mockReturnValue(Promise.resolve(uploadResponse));
    render(<QuickBillAttachmentsWrapper />);
    userEvent.upload(screen.getByText('Choose File'), testFile);
    await waitFor(() => expect(screen.getByText('Virus scan has failed for file: bobsburgers.png')).toBeVisible());
  });
});