import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import AssociatedInvoicesTable from '../';
import { invoiceWithoutPayment, invoiceWithPayment } from './fixtures';

describe('AssociatedInvoicesTable', () => {
  const location = window.location;

  afterEach(() => {
    window.sessionStorage.clear();
    window.location = location;
    jest.restoreAllMocks();
  });

  it('still renders if no invoices are passed', () => {
    const columnNames = ['Date', 'Amount', 'Deposit Account', 'Name/Reference', 'Surcharge', 'Total Amount'];
    render(<AssociatedInvoicesTable invoicePath='/invoices/' />);
    expect(screen.getByText('Associated Invoice(s)')).toBeVisible();
    expect(screen.getByText('No records found.')).toBeVisible();
    columnNames.forEach(name => expect(screen.getByText(name)).toBeVisible());
  });

  it('the title can change based on the passed prop', () => {
    const {rerender} = render(<AssociatedInvoicesTable invoicePath='/invoices/' />);

    expect(screen.getByText('Associated Invoice(s)')).toBeVisible();
    rerender(<AssociatedInvoicesTable title="Invoice" invoicePath='/invoices/' />);
    expect(screen.getByText('Associated Invoice(s)')).toBeVisible();
    rerender(<AssociatedInvoicesTable title="Quick Bill" invoicePath='/quick_bills/'/>);
    expect(screen.getByText('Associated Quick Bill(s)')).toBeVisible();
  });

  it('renders invoices passed as props', () => {
    const columnNames = ['Date', 'Amount', 'Deposit Account', 'Name/Reference', 'Surcharge', 'Total Amount'];
    render(<AssociatedInvoicesTable invoicePath='/invoices/' />);

    expect(screen.getByText('Associated Invoice(s)')).toBeVisible();
    expect(screen.getByText('No records found.')).toBeVisible();
    columnNames.forEach(name => expect(screen.getByText(name)).toBeVisible());
  });

  it('clicking on a row redirects the user to the correct url if row values have an invoice id', () => {
    delete window.location;
    window.location = {assign: jest.fn(), origin: 'localhost:8088'};
    render(
      <AssociatedInvoicesTable
        invoicePath='/invoices/'
        invoices={[invoiceWithPayment, invoiceWithoutPayment]}
      />
    );
    const queriedRows = screen.getAllByRole('row');
    const headerRow = queriedRows[0];
    const rowWithInvoice = queriedRows[1];

    userEvent.click(rowWithInvoice);
    expect(window.location.assign).toHaveBeenCalledWith('localhost:8088/invoices/i_4C8UmxngLgXLAY30ESSwA');
    expect(window.location.assign).toHaveBeenCalledTimes(1);

    userEvent.click(headerRow);
    expect(window.location.assign).toHaveBeenCalledWith('localhost:8088/invoices/i_4C8UmxngLgXLAY30ESSwA');
    expect(window.location.assign).toHaveBeenCalledTimes(1);
  });

  it('clicking on a row does not redirect if invoicePath prop is missing', () => {
    delete window.location;
    window.location = {assign: jest.fn(), origin: 'localhost:8088'};
    render(
      <AssociatedInvoicesTable
        invoicePath={undefined}
        invoices={[invoiceWithPayment, invoiceWithoutPayment]}
      />
    );
    const queriedRows = screen.getAllByRole('row');
    const headerRow = queriedRows[0];
    const rowWithInvoice = queriedRows[1];

    userEvent.click(headerRow);
    expect(window.location.assign).not.toHaveBeenCalled();

    userEvent.click(rowWithInvoice);
    expect(window.location.assign).not.toHaveBeenCalledTimes(1);

  });
});