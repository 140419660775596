import React from 'react';
import { fireEvent, render, screen, waitFor } from '@testing-library/react';
import LegalEntities from '../index';
import {
  legalEntities,
  legalEntityResponseFromAggregate,
  beneficialOwnerResponseFromAggregate
} from '../helpers/fixtures';
import client from 'lib/ajax';
import userEvent from '@testing-library/user-event';
import notify from 'lib/notify';

describe('Legal Container', () => {
  it('renders no legal info message when no props given', () => {
    render(<LegalEntities/>);
    expect(screen.getByText('No legal information has been added.')).toBeVisible();
  });

  it('renders no legal info message when empty array of legal entities passed', () => {
    render(<LegalEntities legal_entities={{...legalEntities, data: []}} /> );
    expect(screen.getByText('No legal information has been added.')).toBeVisible();
  });

  it('renders no legal info message when legal entities is a blank object', () => {
    render(<LegalEntities legal_entities={{}} /> );
    expect(screen.getByText('No legal information has been added.')).toBeVisible();
  });

  it('doesnt render no legal info message when legal entities are passed in', () => {
    render(<LegalEntities legal_entities={legalEntities} />);
    expect(screen.queryByText('No legal information has been added.')).not.toBeInTheDocument();
  });

  it('renders correct legal entity info', () => {
    render(<LegalEntities legal_entities={legalEntities} />);
    expect(screen.getByText('Adyen business name')).toBeVisible();
    expect(screen.getByText('Doing business as: Adyen Inc.')).toBeVisible();
    expect(screen.getByText('Sole Proprietor')).toBeVisible();
    expect(screen.getByText('MCC: 8699')).toBeVisible();
    expect(screen.getByText('Tax ID: 3123')).toBeVisible();
    expect(screen.getByText('Years in business: 5')).toBeVisible();
  });

  it('renders correct contact info', () => {
    render(<LegalEntities legal_entities={legalEntities} />);
    expect(screen.getByText('123 Street')).toBeVisible();
    expect(screen.getByText('Unit 5')).toBeVisible();
    expect(screen.getByText('Austin, TX 78746')).toBeVisible();
    expect(screen.getByText('1(123) 123-1241')).toBeVisible();
    expect(screen.getByText('joeadyen@example.com')).toBeVisible();
  });

  it('renders each beneficial owners name with no edit button by default', () => {
    render(<LegalEntities legal_entities={legalEntities}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    expect(screen.queryByText('Edit')).not.toBeInTheDocument();
  });

  it('renders an edit button for beneficial owners if can_edit_beneficial_owners is true', () => {
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    expect(screen.getByText('Edit')).toBeVisible();
  });

  it('clicking on the beneficial owner edit button will show beneficial owner edit screen', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(beneficialOwnerResponseFromAggregate);
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    userEvent.click(screen.getByRole('button', {name: 'Edit'}));
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Edit Beneficial Owner')).toBeVisible();
  });

  it('clicking back on edit beneficial owner screen will go back to legal entities screen', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(beneficialOwnerResponseFromAggregate);
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    userEvent.click(screen.getByRole('button', {name: 'Edit'}));
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByText('Edit Beneficial Owner')).toBeVisible();
    userEvent.click(screen.getByText('Back to Legal'));
    expect(screen.queryByText('Edit Beneficial Owner')).not.toBeInTheDocument();
  });

  it('updates beneficial owner display name from response when updating beneficial owner', async () => {
    jest.spyOn(notify, 'success').mockImplementation(() => ({}));
    jest.spyOn(notify, 'error').mockImplementation(() => ({}));
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(beneficialOwnerResponseFromAggregate);
        })
      })
    );

    const newBeneficialOwner = {
      ...beneficialOwnerResponseFromAggregate.data.attributes,
      given_name: 'new given name',
      surname: 'new surname',
      aggregate_id: '2Y6H55VdcIC1L49ToFmud6'
    };

    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(newBeneficialOwner);
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    userEvent.click(screen.getByRole('button', {name: 'Edit'}));
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.queryByText('Joe Adyen')).not.toBeInTheDocument();
    expect(screen.getByText('new given name new surname')).toBeVisible();
  });

  it('renders each legal entity with no edit button by default', () => {
    render(<LegalEntities legal_entities={legalEntities}/>);
    expect(screen.getByText('Adyen business name')).toBeVisible();
    expect(screen.queryByText('Edit Legal Entity')).not.toBeInTheDocument();
  });

  it('renders an edit button for legal entity if can_edit_legal_entities is true', () => {
    render(<LegalEntities legal_entities={legalEntities} can_edit_legal_entities={true}/>);
    expect(screen.getByText('Adyen business name')).toBeVisible();
    expect(screen.getByText('Edit Legal Entity')).toBeVisible();
  });

  it('clicking on the legal entity edit button will show legal entity edit screen', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(legalEntityResponseFromAggregate);
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_legal_entities={true}/>);
    userEvent.click(screen.getByText('Edit Legal Entity'));
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByLabelText('Legal Business Name')).toBeVisible();
  });

  it('clicking back on edit legal entity screen will go back to legal entities screen', async () => {
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(legalEntityResponseFromAggregate);
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_legal_entities={true}/>);
    userEvent.click(screen.getByText('Edit Legal Entity'));
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    expect(screen.getByLabelText('Legal Business Name')).toBeVisible();
    userEvent.click(screen.getByText('Back to Legal'));
    expect(screen.queryByLabelText('Legal Business Name')).not.toBeInTheDocument();
  });

  it('updates legal entity info from response when updating legal entity', async () => {
    jest.spyOn(notify, 'success').mockImplementation(() => ({}));
    jest.spyOn(notify, 'error').mockImplementation(() => ({}));
    jest.spyOn(client, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(legalEntityResponseFromAggregate);
        })
      })
    );

    const newLegalEntity = {
      data: {
        ...legalEntityResponseFromAggregate.data,
        attributes: {
          ...legalEntityResponseFromAggregate.data.attributes,
          legal_business_name: 'new legal business name',
          doing_business_as: 'new doing business as',
          years_in_business: 10
        }
      }
    };

    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(newLegalEntity);
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_legal_entities={true}/>);
    expect(screen.getByText('Adyen business name')).toBeVisible();
    expect(screen.getByText('Doing business as: Adyen Inc.')).toBeVisible();
    expect(screen.getByText('Years in business: 5')).toBeVisible();
    userEvent.click(screen.getByRole('button', {name: 'Edit Legal Entity'}));
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(1));
    fireEvent.submit(screen.getByTestId('edit-legal-entity-form'));
    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(screen.queryByText('Adyen business name')).not.toBeInTheDocument();
    expect(screen.queryByText('Doing business as: Adyen Inc.')).not.toBeInTheDocument();
    expect(screen.queryByText('Years in business: 5')).not.toBeInTheDocument();
    expect(screen.getByText('new legal business name')).toBeVisible();
    expect(screen.getByText('Doing business as: new doing business as')).toBeVisible();
    expect(screen.getByText('Years in business: 10')).toBeVisible();
  });

  it('renders beneficial owner name with no delete button if there is only one beneficial owner', () => {
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    expect(screen.queryByText('Delete')).not.toBeInTheDocument();
  });

  describe('DeleteBeneficialOwnerButton', () => {
    beforeAll(() => {
      legalEntities.data[0].attributes.beneficial_owners.push(
        {
          'id': 9,
          'legal_entity_id': 6,
          'aggregate_id': '2Y6H55VdcIC1L49ToFmud4',
          'given_name': 'Test',
          'surname': 'Adyen',
          'date_of_birth': '1990-12-12',
          'address': {
            'city': 'austin',
            'type': 'us',
            'state': 'tx',
            'address1': '123 street biz',
            'address2': 'unit 5',
            'zip_code': '78746'
          },
          'phone_number': '15125555555',
          'email': 'testadyen@example.com',
          'citizenship': 'us',
          'title': 'president',
          'years_at_residence': 3,
          'significant_ownership': true,
          'significant_management': false,
          'created_at': '2022-08-24T09:35:19.000-06:00',
          'updated_at': '2022-08-24T09:35:19.000-06:00'
        }
      );
    });

    it('renders each beneficial owners name with no delete button by default', () => {
      render(<LegalEntities legal_entities={legalEntities}/>);
      expect(screen.getByText('Joe Adyen')).toBeVisible();
      expect(screen.getByText('Test Adyen')).toBeVisible();
      expect(screen.queryByText('Delete')).not.toBeInTheDocument();
    });

    it('renders a delete button for beneficial owners if can_edit_beneficial_owners is true and there is more than one BO', () => {
      render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
      expect(screen.getByText('Joe Adyen')).toBeVisible();
      expect(screen.getByText('Test Adyen')).toBeVisible();
      expect(screen.getAllByText('Delete').length).toBe(2);
    });
  });
});
