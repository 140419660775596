import React from 'react';
import {render, screen, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import PartnersFilter from '../PartnersFilter';
import PartnersService from '../src/services/PartnerService';
import { mockReferralPartnersResponse, mockIntegrationPartnersResponse } from './fixtures';

const filterProps = {
  onChange: jest.fn(),
  onSelect: jest.fn(),
  filterValues: {textFilter: '', dueDateFilter: null, statusFilter: null, referralPartnersFilter: '', integrationPartnersFilter: ''}
};

describe('Referral Partners Filter', () => {
  beforeAll(() => {
    jest.spyOn(PartnersService, 'get').mockImplementation(() =>
      Promise.resolve(mockReferralPartnersResponse)
    );
  });

  it('renders', async () => {
    render(<PartnersFilter {...filterProps} partnerType = 'referral' />);
    await waitFor(() => expect(screen.getByTestId('referral-partner-filter')).toBeVisible());
  });

  it('renders options based on user input', async () => {
    render(<PartnersFilter {...filterProps} partnerType = 'referral' />);
    await waitFor(() => expect(PartnersService.get).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(screen.getByTestId('referral-partner-filter')).toBeVisible());

    const referralPartnerInput = screen.getByTestId('referral-partner-filter');
    userEvent.type(referralPartnerInput, 'Aff');

    await waitFor(() => expect(filterProps.onChange).toHaveBeenCalled());

    await waitFor(() => expect(screen.getByText('AffiniPay Website')).toBeVisible());
    await waitFor(() =>expect(screen.queryByText('LawPay Website')).not.toBeInTheDocument());
  });

  it('fetch partners if user enters more than three characters', async () => {
    render(<PartnersFilter {...filterProps} partnerType = 'referral'/>);

    await waitFor(() => expect(PartnersService.get).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(screen.getByTestId('referral-partner-filter')).toBeVisible());

    const referralPartnerInput = screen.getByTestId('referral-partner-filter');

    userEvent.type(referralPartnerInput, 'Af');
    await waitFor(() => expect(PartnersService.get).not.toHaveBeenCalledWith(['filter[name]=Af']));

    userEvent.clear(referralPartnerInput);
    userEvent.type(referralPartnerInput, 'Affi');
    await waitFor(() => expect(PartnersService.get).toHaveBeenCalledWith(['filter[name]=Affi']));
  });
});

describe('Integration Partners Filter', () => {
  beforeAll(() => {
    jest.spyOn(PartnersService, 'get').mockImplementation(() =>
      Promise.resolve(mockIntegrationPartnersResponse)
    );
  });

  it('renders correctly', async () => {
    render(<PartnersFilter {...filterProps} partnerType = 'integration' />);
    await waitFor(() => expect(screen.getByTestId('integration-partner-filter')).toBeVisible());
  });

  it('renders options based on user input', async () => {
    render(<PartnersFilter {...filterProps} partnerType = 'integration' />);
    await waitFor(() => expect(PartnersService.get).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(screen.getByTestId('integration-partner-filter')).toBeVisible());

    const integrationPartnerInput = screen.getByTestId('integration-partner-filter');
    userEvent.type(integrationPartnerInput, 'Int');

    await waitFor(() => expect(filterProps.onChange).toHaveBeenCalled());

    await waitFor(() => expect(screen.getByText('Integration Inc.')).toBeVisible());
    await waitFor(() => expect(screen.queryByText('New Partner')).not.toBeInTheDocument());
  });

  it('fetch partners if user enters three or more characters', async () => {
    render(<PartnersFilter {...filterProps} partnerType = 'integration'/>);

    await waitFor(() => expect(PartnersService.get).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(screen.getByTestId('integration-partner-filter')).toBeVisible());

    const integrationPartnerInput = screen.getByTestId('integration-partner-filter');

    userEvent.type(integrationPartnerInput, 'In');
    await waitFor(() => expect(PartnersService.get).not.toHaveBeenCalledWith(['filter[name]=In', 'filter[partner_type]=integration]']));

    userEvent.clear(integrationPartnerInput);
    userEvent.type(integrationPartnerInput, 'Inte');
    await waitFor(() => expect(PartnersService.get).toHaveBeenCalledWith(['filter[partner_type]=integration', 'filter[name]=Inte']));
  });
});
