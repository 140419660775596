
import { render, screen } from '@testing-library/react';
import React from 'react';
import * as service from '../../../lib/paymentPage/services';
import PaymentPageLegacy from '../index';
import { cardAndEcheckNoCustomFieldsNoSurcharge, cardAndEcheckNoCustomFieldsWithSurcharge, mockChargeResponse } from './PaymentPageTestProps';
import { expectToBeSelectedInAPSelect, getAPSelect } from './testHelpers';

describe('Payment Page Url parameters', () => {
  const props = { ...cardAndEcheckNoCustomFieldsWithSurcharge };
  const propsWithSignature = { ...cardAndEcheckNoCustomFieldsWithSurcharge, signature: 2 };
  props['custom_fields'] = [{
    custom_field: 'phone',
    default_value: '',
    help_text: '',
    name: 'phone',
    options: '',
    placeholder: '',
    position: 0,
    required: 1,
    type: 'text_field'
  }];

  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
  });

  it('Presetting all fields on card and echeck page', () => {
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => allParams[key]);
    render(<PaymentPageLegacy {...props} />);

    expect(screen.getByLabelText('payment-form')).toHaveFormValues({
      amountField: '25',
      reference: 'Payment for services',
      cardEmail: 'test@gmail.com',
      cardName: 'John Doe',
      cardExpiration: '',
      phone: '515-151-5151',
      cardBillingAddress: '123 Main st',
      cardBillingAddress2: 'Apt 5',
      cardBillingCity: 'Austin',
      echeckBillingAddress: '123 Main st',
      echeckBillingAddress2: 'Apt 5',
      echeckBillingCity: 'Austin'
    });
    expectToBeSelectedInAPSelect('Country', 'United States');
    expectToBeSelectedInAPSelect('State', 'Texas');
    expect(screen.getByLabelText('Payment Amount')).not.toBeDisabled();
    expect(screen.getByLabelText('Reference')).not.toBeDisabled();
    expect(screen.getAllByLabelText('Receipt Email')[0]).not.toBeDisabled();
    expect(screen.getByLabelText('Name on Card')).not.toBeDisabled();
    expect(screen.getAllByLabelText('Address')[0]).not.toBeDisabled();
    expect(screen.getAllByLabelText('City')[0]).not.toBeDisabled();
    expect(getAPSelect('State')).not.toBeDisabled();
    expect(screen.getAllByLabelText('Zip / Postal Code')[0]).not.toBeDisabled();
    expect(getAPSelect('Country')).not.toBeDisabled();
  });

  it('Fields with values get disabled via readOnlyParams, fields without values dont get disabled', () => {
    const params = { ...allParams };
    delete params.name;
    params['readOnlyFields'] = 'name, amount, address1,%20address2,email, city,state,postal_code,phone,reference,country';
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<PaymentPageLegacy {...props} />);

    expect(screen.getByLabelText('Payment Amount')).toBeDisabled();
    expect(screen.getByLabelText('Reference')).toBeDisabled();
    expect(screen.getAllByLabelText('Receipt Email')[0]).toBeDisabled();
    expect(screen.getByLabelText('Name on Card')).not.toBeDisabled();
    expect(screen.getAllByLabelText('Address')[0]).toBeDisabled();
    expect(screen.getAllByLabelText('City')[0]).toBeDisabled();
    expect(getAPSelect('State')).toBeDisabled();
    expect(screen.getAllByLabelText('Zip / Postal Code')[0]).toBeDisabled();
    expect(getAPSelect('Country')).toBeDisabled();
  });

  it('Amount field gets disabled from legacy field true', () => {
    const params = {
      amount_ro: 'true',
      amount: '15'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    expect(screen.getByLabelText('Payment Amount')).toBeDisabled();
  });

  it('Amount field gets disabled from legacy field 1', () => {
    const params = {
      amount_ro: '1',
      amount: '15'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    expect(screen.getByLabelText('Payment Amount')).toBeDisabled();
  });

  it('Request signature feature redirects to signature page when page has electronic signature and the token call returns ok', async () => {
    const params = {
      sig: 'iamavalidsignaturetoken'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>',
            charge: mockChargeResponse
          });
        })
      })
    );
    render(<PaymentPageLegacy {...propsWithSignature} />);

    const signaturePage = await screen.findByTestId('signature');
    expect(signaturePage).toBeInTheDocument();
  });

  it('Request signature feature redirects to receipt if the page has line signature and token call returns ok', async () => {
    const params = {
      sig: 'iamavalidsignaturetoken'
    };
    const newProps = { ...props, signature: 1 };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>',
            charge: mockChargeResponse
          });
        })
      })
    );
    render(<PaymentPageLegacy {...newProps} />);

    const thankYouPage = await screen.findByTestId('thank-you-page'),
      receipt = await screen.findByText('foobar receipt');
    expect(thankYouPage).toBeInTheDocument();
    expect(receipt).toBeInTheDocument();
  });

  it('Request signature feature does not redirect to receipt if the token is returned as invalid', async () => {
    const params = {
      sig: 'iamnotavalidtoken'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockImplementation(() =>
      Promise.resolve({
        status: 404,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>',
            charge: mockChargeResponse
          });
        })
      })
    );
    render(<PaymentPageLegacy {...propsWithSignature} />);

    const signaturePage = screen.queryByTestId('signature');
    expect(signaturePage).not.toBeInTheDocument();
  });

  it('Request signature feature does not redirect to receipt if the server cannot be reached for token validation', async () => {
    const params = {
      sig: 'iamnotavalidtoken'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockImplementation(() =>
      Promise.reject({
        status: 500,
        response: false,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>',
            charge: mockChargeResponse
          });
        })
      })
    );
    render(<PaymentPageLegacy {...propsWithSignature} />);

    const signaturePage = screen.queryByTestId('signature');
    expect(signaturePage).not.toBeInTheDocument();
  });

  it('Request signature feature redirects to receipt if the page has no signature and token call returns ok', async () => {
    const params = {
      sig: 'iamavalidsignaturetoken'
    };
    const newProps = { ...props, signature: 0 };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>',
            charge: mockChargeResponse
          });
        })
      })
    );
    render(<PaymentPageLegacy {...newProps} />);

    const thankYouPage = await screen.findByTestId('thank-you-page'),
      receipt = await screen.findByText('foobar receipt');
    expect(thankYouPage).toBeInTheDocument();
    expect(receipt).toBeInTheDocument();
  });
});

//https://developers.affinipay.com/merchant/hosted-payment-pages.html
const allParams = {
  name: 'John Doe',
  amount: '25',
  address1: '123 Main st',
  address2: 'Apt 5',
  email: 'test@gmail.com',
  city: 'Austin',
  state: 'TX',
  postal_code: '12345',
  phone: '515-151-5151',
  reference: 'Payment for services',
  country: 'US'
};