import React from 'react';
import { screen, render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ClientCreditTextBox from '..';
import * as utils from '../../../lib/utils';

const lawpayLearMoreLinkIsClicked = {
  location: 'ClientCredit box',
  event: 'ChargeScreen_CLC_LawPay'
};

const signUpLinkClicked = {
  location: 'ClientCredit box',
  event: 'ChargeScreen_CLC_CA_LawPay'
};

const cpaLearnMoreLinkClicked = {
  location: 'ClientCredit box',
  event: 'ChargeScreen_CLC_CPA'
};

const lawpayCopy = 'LawPay has partnered with Affirm to bring you a legal fee lending solution that makes it possible to provide additional payment flexibility to your clients.';
const cpaChargeCopy = 'CPACharge has partnered with Affirm to bring you an accounting fee lending solution that makes it possible to provide additional payment flexibility to your clients.';

describe('ClientCreditTextBox', () => {
  it('shows correct copy based on passed site prop', () => {
    const {rerender} = render(<ClientCreditTextBox
      site = 'lawpay'
      clientCreditStatus= 'opt_in'
      merchantState = 'TX'
    />);
    expect(screen.getByText(lawpayCopy)).toBeVisible();

    rerender(<ClientCreditTextBox
      site = 'cpacharge'
      clientCreditStatus= 'opt_in'
      merchantState = 'TX'
    />);

    expect(screen.getByText(cpaChargeCopy)).toBeVisible();
  });

  it('shows learn more link for a LawPay merchant with opt_in clientcredit status', async () => {
    render(
      <ClientCreditTextBox
        site = 'lawpay'
        clientCreditStatus= 'opt_in'
        merchantState = 'TX'
      />
    );

    expect(screen.getByText('Learn more')).toBeInTheDocument();
    expect(screen.getByText('Learn more').closest('a')).toHaveAttribute('href', 'https://www.lawpay.com/features/clientcredit/');
  });

  it('shows sign up button for a LawPay CA merchant with unknown clientcredit status', async () => {
    render(
      <ClientCreditTextBox
        site = 'lawpay'
        clientCreditStatus= 'unknown'
        merchantState = 'CA'
      />
    );

    expect(screen.queryByText('Learn more')).not.toBeInTheDocument();
    expect(screen.getByText('Click the button below to have Pay Later added to your LawPay account!')).toBeInTheDocument();
    expect(screen.getByText('Sign Up')).toBeInTheDocument();
  });

  it('shows learn more link for a CPACharge merchant with opt_in status', async () => {
    render(
      <ClientCreditTextBox
        site = 'cpacharge'
        clientCreditStatus= 'opt_in'
        merchantState = 'TX'
      />
    );

    expect(screen.getByText('Learn more')).toBeInTheDocument();
    expect(screen.getByText('Learn more').closest('a')).toHaveAttribute('href', 'https://www.cpacharge.com/features/clientcredit/');
  });

  it('sends Lawpay learn more link clicked GA event', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(lawpayLearMoreLinkIsClicked);
        })
      })
    );

    render(
      <ClientCreditTextBox
        site = 'lawpay'
        clientCreditStatus= 'opt_in'
        merchantState = 'TX'
      />
    );

    await userEvent.click(screen.getByText('Learn more'));
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('ClientCredit box');
    expect(safeSendGA.mock.calls[0][1]).toBe('ChargeScreen_CLC_LawPay');
  });

  it('sends Lawpay sign up clicked GA event', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(signUpLinkClicked);
        })
      })
    );

    render(
      <ClientCreditTextBox
        site = 'lawpay'
        clientCreditStatus= 'unknown'
        merchantState = 'CA'
      />
    );

    await userEvent.click(screen.getByText('Sign Up'));
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('ClientCredit box');
    expect(safeSendGA.mock.calls[0][1]).toBe('ChargeScreen_CLC_CA_LawPay');
  });

  it('sends CPACharge learn more link clicked GA event', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(cpaLearnMoreLinkClicked);
        })
      })
    );

    render(
      <ClientCreditTextBox
        site = 'cpacharge'
        clientCreditStatus= 'opt_in'
        merchantState = 'TX'
      />
    );

    await userEvent.click(screen.getByText('Learn more'));
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('ClientCredit box');
    expect(safeSendGA.mock.calls[0][1]).toBe('ChargeScreen_CLC_CPA');
  });
});