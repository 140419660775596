import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import MarkQuickBillAsPaidForm from '../MarkQuickBillAsPaidForm';
import client from '../../../../../lib/ajax';

describe('MarkQuickBillAsPaidForm', () => {
  beforeAll(() => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
  });

  const vanillaProps = {
    invoiceID: 'abc123',
    handleClose: jest.fn(),
    setToastValues: jest.fn(),
    setIsDisabled: jest.fn()
  };

  it('renders', () => {
    render(<MarkQuickBillAsPaidForm {...vanillaProps} />);
    const options = ['Cash', 'Check', 'Credit Card', 'Other'];
    options.forEach(option => expect(screen.getByText(option)).toBeVisible());
  });

  it('invokes passed function prop "handleClose"', () => {
    render(<MarkQuickBillAsPaidForm {...vanillaProps} />);
    userEvent.click(screen.getByText('Close'));
    expect(vanillaProps.handleClose).toHaveBeenCalledTimes(1);
  });

  it('Save Changes button is initially disabled', () => {
    render(<MarkQuickBillAsPaidForm {...vanillaProps} />);
    expect(screen.getByText('Save Changes')).toBeDisabled();
    userEvent.click(screen.getByText('Save Changes'));
    expect(client.post).toHaveBeenCalledTimes(0);
  });

  it('Save Changes button is enabled when an option is selected', () => {
    render(<MarkQuickBillAsPaidForm {...vanillaProps} />);
    expect(screen.getByText('Save Changes')).toBeDisabled();
    userEvent.click(screen.getByText('Cash'));
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
  });

  it('makes a fetch call when Save Changes is clicked', async () => {
    render(<MarkQuickBillAsPaidForm {...vanillaProps} />);
    userEvent.click(screen.getByText('Cash'));
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.post).toHaveBeenCalledWith('/quick_bills/abc123/mark_as_paid', {'invoice_payment': {'payment_type': 'cash'}}));
    await waitFor(() => expect(vanillaProps.setToastValues).toHaveBeenCalledWith({
      'header': 'Success',
      'message': 'Successfully marked Quick Bill as paid',
      'show': true,
      'type':'success'
    }));
  });

  it('invokes prop setToastValues if response status is not 200', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    render(<MarkQuickBillAsPaidForm {...vanillaProps} />);
    userEvent.click(screen.getByText('Cash'));
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.post).toHaveBeenCalledWith('/quick_bills/abc123/mark_as_paid', {'invoice_payment': {'payment_type': 'cash'}}));
    await waitFor(() => expect(vanillaProps.setToastValues).toHaveBeenCalledWith({
      'header': 'Error',
      'message': 'Quick Bill could not be marked as paid',
      'show': true,
      'type': 'error'
    }));
  });
});