import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import {
  displayAbilities,
  displayAccounts,
  userAbilities,
  userAbilitiesForCheckList,
  nonEntitlementUserAbilitiesForCheckList,
  userAccounts
} from '../helpers/testHelpers';
import client from '../../../lib/ajax';
import UserForm from '..';

const userFormProps = {
  show_lawpay_plus_content: false,
  show_lawpay_plus_content_for_new_record: false,
  show_lawpay_plus_permissions_warning: false,
  pending_reconfirmation: false,
  unconfirmed_email: 'testemail@test.com',
  user_abilities: userAbilities,
  display_abilites: displayAbilities,
  user_abilities_for_check_list: userAbilitiesForCheckList,
  non_entitlement_user_abilities_for_check_list: nonEntitlementUserAbilitiesForCheckList,
  accounts: userAccounts,
  visible_account_ids: [],
  unrestrict_access: true,
  restrict_transactions: false,
  settings_path: '/settings',
  invited: false,
  phone: '',
  first_name: '',
  last_name: '',
  email: '',
  form_type: 'new_user',
  user_id: 1,
  reinvite_user_path: '/users/reinvite'
};

const showLppProps = {
  show_lawpay_plus_content: true,
  show_lawpay_plus_content_for_new_record: true,
  show_lawpay_plus_permissions_warning: true
};

const editUserProps = {
  form_type: 'edit',
  first_name: 'testEdit',
  last_name: 'test',
  email: 'testEdit@test.com',
  phone: '2222222222'
};

describe('UserForm create', () => {
  beforeEach(() => {
    jest.resetAllMocks();
  });

  afterEach(() => {
    jest.clearAllMocks();
  });

  Object.defineProperty(window, 'location', {
    configurable: true,
    value: {
      origin: 'localhost:3080',
      assign: jest.fn()
    }
  });

  it('Renders UserForm', () => {
    render(<UserForm {...userFormProps} />);
    expect(screen.getByLabelText('First name')).toBeVisible();
    expect(screen.getByLabelText('Last name')).toBeVisible();
    expect(screen.getByText('User Abilities')).toBeVisible();
    expect(screen.getByText('Account Access')).toBeVisible();
    expect(screen.getByText('Transaction Access')).toBeVisible();
  });

  it('Has all the correct abilities checked', () => {
    render(<UserForm {...userFormProps} />);

    Object.values(displayAbilities).forEach(ability => {
      if(ability === 'Administrator') {
        expect(screen.getByLabelText(ability)).not.toBeChecked();
      } else {
        expect(screen.getByLabelText(ability)).toBeChecked();
      }
    });
  });

  it('disables all abilities when Administrator is checked', () => {
    render(<UserForm {...userFormProps} />);

    Object.values(displayAbilities).forEach(ability => {
      if(ability === 'Administrator') {
        userEvent.click(screen.getByLabelText(ability));
        expect(screen.getByLabelText(ability)).toBeChecked();
      } else {
        expect(screen.getByLabelText(ability)).toBeDisabled();
      }
    });
  });

  it('displays lpp warnings when lpp enabled', () => {
    render(<UserForm {...{...userFormProps, ...showLppProps}} />);

    const abilityWarning = screen.getByTestId('ability-warning');
    const nonEntitlementAbilityWarning = screen.getByTestId('non-entitlement-warning');
    const accountAccessWarning = screen.getByTestId('account-access-warning');
    const transactionAccessWarning = screen.getByTestId('transaction-access-warning');
    const lppLicenseWarning = screen.getByTestId('lpp-license-warning');

    expect(abilityWarning).toBeVisible();
    expect(nonEntitlementAbilityWarning).toBeVisible();
    expect(accountAccessWarning).toBeVisible();
    expect(transactionAccessWarning).toBeVisible();
    expect(lppLicenseWarning).toBeVisible();
  });

  it('All accounts are checked if unrestrict_access is enabled', () => {
    render(<UserForm {...userFormProps} />);

    displayAccounts.forEach(account => {
      expect(screen.getByLabelText(account)).toBeChecked();
    });
  });

  it('All accounts are checked in unrestrice_access disabled', () => {
    render(<UserForm {...userFormProps} />);
    displayAccounts.forEach(account => {
      if (account === 'User can access all accounts') {
        userEvent.click(screen.getByLabelText(account));
      } else {
        expect(screen.getByLabelText(account)).toBeChecked();
      }
    });
  });

  it('Redirects to settings page when back button is clicked', () => {
    render(<UserForm {...userFormProps} />);

    const backButton = screen.getByText('Back');
    userEvent.click(backButton);
    expect(window.location.assign).toHaveBeenCalledWith('localhost:3080/' + userFormProps.settings_path);
  });

  it('Makes a call to reinvite when button is clicked', async () => {
    jest.spyOn(client, 'put').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        head: 'ok'
      })
    );

    render(<UserForm {...{...userFormProps, invited: true, form_type: 'edit'}} />);

    const firstName = screen.getByLabelText('First name');
    const lastName = screen.getByLabelText('Last name');
    const email = screen.getAllByLabelText('Email')[0];
    const phone = screen.getByLabelText('Phone');

    expect(firstName).toBeDisabled();
    expect(lastName).toBeDisabled();
    expect(email).toBeDisabled();
    expect(phone).toBeDisabled();

    userEvent.click(screen.getByText('Resend Invitation'));
    await waitFor(() => expect(client.put).toHaveBeenCalledTimes(1));
    expect(client.put).toHaveBeenCalledWith(userFormProps.reinvite_user_path);
  });

  it('Should submit new user form with correct data' , async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
    render(<UserForm {...userFormProps} />);

    const firstName = screen.getByLabelText('First name');
    const lastName = screen.getByLabelText('Last name');
    const email = screen.getAllByLabelText('Email')[0];
    const phone = screen.getByLabelText('Phone');
    const submitButton = screen.getByText('Save Changes');

    userEvent.type(firstName,'test');
    userEvent.type(lastName, 'testLast');
    userEvent.type(email, 'test@test.com');
    userEvent.type(phone, '5555555555');
    userEvent.click(submitButton);

    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(client.post).toHaveBeenCalledWith('/users', {
      user: {
        first_name: 'test',
        last_name: 'testLast',
        email: 'test@test.com',
        phone: '(555)555-5555',
        abilities: userAbilities,
        accounts: [],
        restrict_transactions: false,
        unrestrict_access: 1
      },
      lpp_license: 0
    });
  });

  it('Should submit edit user form with correct data' , async () => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );
    render(<UserForm {...{...userFormProps, ...editUserProps }} />);

    const firstName = screen.getByLabelText('First name');
    const lastName = screen.getByLabelText('Last name');
    const email = screen.getAllByLabelText('Email')[0];
    const phone = screen.getByLabelText('Phone');
    const submitButton = screen.getByText('Save Changes');

    expect(firstName).toHaveValue('testEdit');
    expect(lastName).toHaveValue('test');
    expect(email).toHaveValue('testEdit@test.com');
    expect(phone).toHaveValue('(222)222-2222');

    userEvent.clear(firstName);
    userEvent.type(firstName,'test');
    userEvent.clear(lastName);
    userEvent.type(lastName, 'testLast');

    expect(firstName).toHaveValue('test');
    expect(lastName).toHaveValue('testLast');
    userEvent.click(submitButton);

    await waitFor(() => expect(client.patch).toHaveBeenCalledTimes(1));
    expect(client.patch).toHaveBeenCalledWith('/users/1', {
      user: {
        first_name: 'test',
        last_name: 'testLast',
        email: 'testEdit@test.com',
        phone: '2222222222',
        abilites_mask: undefined,
        abilities: userAbilities,
        accounts: [],
        restrict_transactions: false,
        unrestrict_access: 1
      },
      lpp_license: 0
    });
  });

  it('Should display errors', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 401,
        response: true,
        json: jest.fn(() => Promise.resolve({
          full_messages: ['User is not authorized']
        }))
      })
    );
    render(<UserForm {...userFormProps} />);

    const firstName = screen.getByLabelText('First name');
    const lastName = screen.getByLabelText('Last name');
    const email = screen.getAllByLabelText('Email')[0];
    const phone = screen.getByLabelText('Phone');
    const submitButton = screen.getByText('Save Changes');

    userEvent.type(firstName,'test');
    userEvent.type(lastName, 'testLast');
    userEvent.type(email, 'test@test.com');
    userEvent.type(phone, '5555555555');
    userEvent.click(submitButton);

    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(screen.getByText('User is not authorized')).toBeVisible();
  });
});
