
import { fireEvent, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import { paymentPageErrors } from '../../../lib/paymentPage/constants';
import * as service from '../../../lib/paymentPage/services';
import PaymentPageLegacy from '../index';
import { cardAndEcheckNoCustomFieldsNoSurcharge } from './PaymentPageTestProps';
import { simulateSelectEventInAPSelect } from './testHelpers';

describe('Payment Page Legacy Submit Non Happy Path Tests', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
    const HFInit = jest.fn((init, callback) => {
      global.HFCallbackExposed = callback;
      return {
        getPaymentToken: jest.fn(() => Promise.resolve({})),
        getState: jest.fn(() => {
          return {
            isReady: true
          };
        }),
        setCardIframeInputText: jest.fn(),
        clearIframeInput: jest.fn()
      };
    });
    global.AffiniPay = {
      HostedFields: {
        initializeFields: HFInit
      }
    };
    global.grecaptcha = {
      ready: callback => { callback(); },
      execute: () => Promise.resolve({})
    };
  });

  it('Vanilla Payment Page submit denied by V2 Captcha', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>'
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));

    expect(await screen.findByText('Please verify that you are not a robot.'));
  });

  it('Vanilla Payment Page submit denied by backend', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>'
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));

    expect(await screen.findByText('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.'));
  });


  it('$0 amount shows validation error', async () => {
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    userEvent.click(screen.getByText('Pay $0.00'));
    expect(await screen.findByText(paymentPageErrors.zeroChargeAttempt));
  });

  it('partially filled card expiration shows validation error', async () => {
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '12' } });
    userEvent.click(screen.getByText('Pay $0.00'));

    expect(await screen.findByText(paymentPageErrors.missingExpYear));
  });

  it('invalid filled card expiration month shows validation error', async () => {
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);
    fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '22' } });
    userEvent.click(screen.getByText('Pay $0.00'));

    expect(await screen.findByText(paymentPageErrors.missingExpMonth));
  });

  it('should display correct error message when rejected by gateway', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'card_declined'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    const errorElement = await screen.findByTestId('error-message');
    expect(errorElement).toBeVisible();
    expect(errorElement).toHaveTextContent('This card transaction has been declined by your bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.');
  });

  it('should display gateway error with correct merchant name', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'unsupported_currency'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    const errorElement = await screen.findByTestId('error-message');
    expect(errorElement).toBeVisible();
    expect(errorElement).toHaveTextContent('This transaction can not be processed because of a currency mismatch. Please contact AffiniPay.');
  });

  it('should display all gateway errors', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'card_declined'
              },
              {
                code: 'unsupported_currency'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    expect(await screen.findByText('This card transaction has been declined by your bank. Please call the phone number on the back of the card to resolve the issue and then retry your payment.')).toBeVisible();
    expect(await screen.findByText('This transaction can not be processed because of a currency mismatch. Please contact AffiniPay.')).toBeVisible();
  });

  it('should display generic error when no error object returned', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [undefined]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    const errorElement = await screen.findByTestId('form-message-box');
    expect(errorElement).toBeVisible();
    expect(errorElement).toHaveTextContent('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.');
  });

  it('should display generic error when no error code or gateway error message returned', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [{}]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    const errorElement = await screen.findByTestId('form-message-box');
    expect(errorElement).toBeVisible();
    expect(errorElement).toHaveTextContent('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.');
  });

  it('should display gateway error message when no error code returned', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [{
              message: 'test gateway message'
            }]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    const errorElement = await screen.findByTestId('form-message-box');
    expect(errorElement).toBeVisible();
    expect(errorElement).toHaveTextContent('test gateway message');
  });

  it('should display gateway error message if available when unknown error code returned', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'test_error',
                message: 'test gateway message'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    const errorElement = await screen.findByTestId('form-message-box');
    expect(errorElement).toBeVisible();
    expect(errorElement).toHaveTextContent('test gateway message');
  });

  it('should display generic error when unknown error code returned', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            messages: [
              {
                code: 'test_error'
              }
            ]
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));
    const errorElement = await screen.findByTestId('error-message');
    expect(errorElement).toBeVisible();
    expect(errorElement).toHaveTextContent('Something went wrong. Please refresh the page and try again. If the issue persists please contact your Service Provider.');
  });
});



const fillOutTheForm = () => {
  userEvent.type(screen.getByLabelText('Payment Amount'), '15.93');
  userEvent.type(screen.getByLabelText('Reference'), 'Invoice 123');
  userEvent.type(screen.getAllByLabelText('Receipt Email')[0], 'foobar@affinipay.com');
  userEvent.type(screen.getByLabelText('Name on Card'), 'John Doe');
  fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '1225' } });
  userEvent.type(screen.getAllByLabelText('Address')[0], '123 Main St');
  userEvent.type(screen.getAllByLabelText('Address 2')[0], 'apt 5a');
  userEvent.type(screen.getAllByLabelText('City')[0], 'Austin');
  simulateSelectEventInAPSelect('Country', 'Canada');
  simulateSelectEventInAPSelect('State', 'Manitoba');
  userEvent.type(screen.getAllByLabelText('Zip / Postal Code')[0], '11001');
};