import client from '../../../../lib/ajax';
import {updateProcessorAccountName} from '../services';

describe('updateProcessorAccountName', () => {
  beforeEach(() => {
    jest.spyOn(client, 'patch').mockImplementation(() =>
      Promise.resolve({
        ok: true
      })
    );
  });

  it('takes parameters path and body and passes them to fetch', () => {
    updateProcessorAccountName('/42/accounts', { name: 'foobar' });
    expect(client.patch).toHaveBeenCalled();
    expect(client.patch).toHaveBeenCalledWith('/42/accounts', expect.objectContaining({
      account: {
        name: 'foobar'
      }
    }));
  });

  it('takes multiple body attributes and formats them to fetch', () => {
    updateProcessorAccountName('/42/accounts', { name: 'foobar', amount: '40' });
    expect(client.patch).toHaveBeenCalled();
    expect(client.patch).toHaveBeenCalledWith('/42/accounts', expect.objectContaining({
      account: {
        amount: '40',
        name: 'foobar'
      }
    }));
  });

  it('returns a response', async () => {
    const response = await updateProcessorAccountName('/42/accounts', { name: 'foobar' });

    expect(response).toEqual(expect.objectContaining({ ok: true }));
  });
});