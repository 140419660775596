import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import EditAndSendQuickBillButton from '../EditAndSendQuickBillButton';

describe('EditAndSendQuickBillButton', () => {
  it('renders', () => {
    render(<EditAndSendQuickBillButton handleEditQuickBill={jest.fn()} isSendingAllowed={true} />);
    expect(screen.getByText('Edit & Resend')).toBeVisible();
  });

  it('isDisabled prop is defaulted to false', () => {
    render(<EditAndSendQuickBillButton handleEditQuickBill={jest.fn()} />);
    expect(screen.getByRole('button')).not.toBeDisabled();
  });

  it('is disabled if isDisabled prop is true', () => {
    render(<EditAndSendQuickBillButton
      handleEditQuickBill={jest.fn()}
      isDisabled={true}
    />);
    expect(screen.getByRole('button')).toBeDisabled();
  });

  it('invokes passed prop handleEditQuickBill function when button clicked', () => {
    const handleEditQuickBill= jest.fn();
    render(<EditAndSendQuickBillButton handleEditQuickBill={handleEditQuickBill} isSendingAllowed={true} />);
    userEvent.click(screen.getByText('Edit & Resend'));
    expect(handleEditQuickBill).toHaveBeenCalledTimes(1);
  });
});