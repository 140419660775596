import React from 'react';
import {screen, render} from '@testing-library/react';
import DepositAccountsTableHead from '../DepositAccountsTableHead';

describe('DepositAccountTableHead', () => {
  it('renders proper head values if policy is not quickbills', () => {
    render(<table><DepositAccountsTableHead /></table>);

    expect(screen.getByText('Name / Type')).toBeVisible();
  });
  it('renders proper head values if policy is quickbills', () => {
    render(<table><DepositAccountsTableHead isPolicyQuickBills={true} /></table>);
    expect(screen.getByText('Deposit')).toBeVisible();
    expect(screen.getByText('Processing Account')).toBeVisible();
  });
});