import React from 'react';
import { render, screen } from '@testing-library/react';
import RecurringChargeFailedTransactionError from '../index';

describe('RecurringChargeFailedTransactionError', () => {
  it('renders correctly from scheduled payments view', () => {
    render(<RecurringChargeFailedTransactionError />);
    expect(screen.getByTestId('form-message-box')).toHaveTextContent('ACTION REQUIRED: You have a Failed Transaction. Click on the alert in the table to view more details.');
  });

  it('renders correctly from scheduled payments details view', () => {
    render(<RecurringChargeFailedTransactionError fromScheduledPaymentsDetail = {true} />);
    expect(screen.getByTestId('form-message-box')).toHaveTextContent('ACTION REQUIRED: You have a Failed transaction. Please retry.');
  });

  it('renders correct error message for failed transactions due to wrong card information from scheduled payments details view', () => {
    render(<RecurringChargeFailedTransactionError fromScheduledPaymentsDetail={true} showCardInformationError={true} />);
    expect(screen.getByTestId('form-message-box')).toHaveTextContent('ACTION REQUIRED: You have a Failed transaction. Please update the schedule with new card information.');
  });
});
