import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ClientCreditLastChanceButton from '..';


const testProps = {
  appId: 1,
  checkboxId: 'checkbox',
  formId: 'deposits-form',
  data: {
    'data-action': 'click',
    'data-form': 'deposits-form',
    'data-path': 'register/deposits',
    'data-step': 'MerchantRegistration-Deposit',
    'data-options': {}
  },
  supportPhone: '555-867-5309'
};

beforeEach(() => {
  jest.spyOn(document, 'getElementById').mockImplementation(() => ({
    className: 'client-credit-checkbox',
    id: 'forms_registrations_deposit_step_form_cc_intent_status',
    value: 'true',
    addEventListener: jest.fn(),
    removeEventListener: jest.fn()
  }));
});

afterAll(() => {
  jest.resetAllMocks();
});

describe('ClientCreditLastChance', () => {
  it('renders basic Next button', () => {
    render(<form id="deposits-form"><ClientCreditLastChanceButton {...testProps} /></form>);
    expect(screen.getByTestId('client-credit-next-btn')).toBeVisible();
  });

  it('renders basic Pay Later "Last Chance" button', async () => {
    jest.spyOn(document, 'getElementById').mockImplementation(() => ({
      className: 'client-credit-checkbox',
      id: 'forms_registrations_deposit_step_form_cc_intent_status',
      type: 'checkbox',
      value: 'false',
      addEventListener: jest.fn(),
      removeEventListener: jest.fn()
    }));
    render(<form id="deposits-form"><ClientCreditLastChanceButton {...testProps} /></form>);
    await waitFor(() => expect(screen.getByTestId('client-credit-last-chance-btn')).toBeVisible());
  });

  it('opens the LastChanceModal on "Last Chance" button click', async () => {
    jest.spyOn(document, 'getElementById').mockImplementation(() => ({
      className: 'client-credit-checkbox',
      id: 'forms_registrations_deposit_step_form_cc_intent_status',
      type: 'checkbox',
      value: 'false',
      addEventListener: jest.fn(),
      removeEventListener: jest.fn()
    }));

    render(<form id="deposits-form"><ClientCreditLastChanceButton {...testProps} /></form>);
    await waitFor(() => userEvent.click(screen.getByTestId('client-credit-last-chance-btn')));
    const modalText = [
      'Why our customers love Pay Later',
      '45% of firms report that Pay Later de-risked their business',
      '53% of firms saw an increased number of clients able to pay for their services',
      '45% of firms said that Pay Later improved the image of the company',
      '"I have retained clients that otherwise would not have had the funds to pay for my services."',
      '(Firm using Pay Later)',
      'Not ready for Pay Later yet?',
      'If you change your mind anytime, please call support at'
    ];
    modalText.forEach(text => expect(screen.getByText(text)).toBeInTheDocument());
  });
});