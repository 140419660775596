
import { screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';

export const getAPSelect = selectLabel => screen.getAllByLabelText(selectLabel)[0];

export const simulateSelectEventInAPSelect = (selectLabel, optionText) => {
  const select = getAPSelect(selectLabel); 
  userEvent.click(select);
  const option = screen.getByText(optionText);
  userEvent.click(option);
};

export const expectToBeSelectedInAPSelect = (selectLabel, selected) => { expect(getAPSelect(selectLabel)).toHaveTextContent(selected); };