
import { fireEvent, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import * as service from '../../../lib/paymentPage/services';
import PaymentPageLegacy from '../index';
import { cardAndEcheckCustomFieldsNoSurcharge, cardAndEcheckNoCustomFieldsNoSurcharge, mockChargeResponse } from './PaymentPageTestProps';
import { simulateSelectEventInAPSelect } from './testHelpers';

describe('Payment Page Submit Happy Path Tests', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
    const HFInit = jest.fn((init, callback) => {
      global.HFCallbackExposed = callback;
      return {
        getPaymentToken: jest.fn(() => Promise.resolve({})),
        getState: jest.fn(() => {
          return {
            isReady: true
          };
        }),
        setCardIframeInputText: jest.fn(),
        clearIframeInput: jest.fn()
      };
    });
    global.AffiniPay = {
      HostedFields: {
        initializeFields: HFInit
      }
    };
    global.grecaptcha = {
      ready: callback => { callback(); },
      execute: () => Promise.resolve({})
    };
  });

  it('Vanilla Payment Page submit', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>'
          });
        })
      })
    );
    render(<PaymentPageLegacy {...cardAndEcheckNoCustomFieldsNoSurcharge} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));

    const thankYouPage = await screen.findByTestId('thank-you-page'),
      receipt = await screen.findByText('foobar receipt');
    expect(thankYouPage).toBeInTheDocument();
    expect(receipt).toBeInTheDocument();
  });

  it('Custom Fields Payment Page submit', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>Custom Fields receipt</div>'
          });
        })
      })
    );
    let props = { ...cardAndEcheckCustomFieldsNoSurcharge };
    props['accepts_banks'] = false;

    render(<PaymentPageLegacy {...props} />);

    fillOutCustomFieldsForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $108.00'));

    const thankYouPage = await screen.findByTestId('thank-you-page'),
      receipt = await screen.findByText('Custom Fields receipt');
    expect(thankYouPage).toBeInTheDocument();
    expect(receipt).toBeInTheDocument();
  });

  it('Page with Signature submit', async () => {
    jest.spyOn(service, 'makeCharge').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>',
            charge: mockChargeResponse
          });
        })
      })
    );
    const props = { ...cardAndEcheckNoCustomFieldsNoSurcharge };
    props['signature'] = 2;
    render(<PaymentPageLegacy {...props} />);

    fillOutTheForm();
    global.HFCallbackExposed({
      fields: [
        { type: 'cvv', length: 3 }
      ]
    });
    userEvent.click(screen.getByText('Pay $15.93'));

    const signaturePage = await screen.findByTestId('signature');
    expect(signaturePage).toBeInTheDocument();
  });
});

const fillOutTheForm = () => {
  userEvent.type(screen.getByLabelText('Payment Amount'), '15.93');
  userEvent.type(screen.getByLabelText('Reference'), 'Invoice 123');
  userEvent.type(screen.getAllByLabelText('Receipt Email')[0], 'foobar@affinipay.com');
  userEvent.type(screen.getByLabelText('Name on Card'), 'John Doe');
  fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '1225' } });
  userEvent.type(screen.getAllByLabelText('Address')[0], '123 Main St');
  userEvent.type(screen.getAllByLabelText('Address 2')[0], 'apt 5a');
  userEvent.type(screen.getAllByLabelText('City')[0], 'Austin');
  simulateSelectEventInAPSelect('Country', 'Canada');
  simulateSelectEventInAPSelect('State', 'Manitoba');
  userEvent.type(screen.getAllByLabelText('Zip / Postal Code')[0], '11001');
};

const fillOutCustomFieldsForm = () => {
  userEvent.type(screen.getByLabelText('Reference'), 'Invoice 123');
  userEvent.type(screen.getAllByLabelText('Receipt Email')[0], 'foobar@affinipay.com');
  userEvent.type(screen.getByLabelText('Name on Card'), 'John Doe');
  fireEvent.change(screen.getByLabelText('Exp. Date'), { target: { value: '1225' } });
  userEvent.type(screen.getAllByLabelText('Address')[0], '123 Main St');
  userEvent.type(screen.getAllByLabelText('Address 2')[0], 'apt 5a');
  userEvent.type(screen.getAllByLabelText('City')[0], 'Austin');
  simulateSelectEventInAPSelect('Country', 'Canada');
  simulateSelectEventInAPSelect('State', 'Manitoba');
  userEvent.type(screen.getAllByLabelText('Zip / Postal Code')[0], '11001');
  userEvent.type(screen.getByLabelText('tf require'), 'A');
  userEvent.type(screen.getByLabelText('ta req'), 'T');
  userEvent.click(screen.getByLabelText('ch req'));
  simulateSelectEventInAPSelect('s req', 'eo');
  userEvent.click(screen.getByText('Pay $108.00'));
};
