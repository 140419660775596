
import { waitFor } from '@testing-library/dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import AFFINIPAY_COMMUNICATIONS from '../../LoanPayment/helpers/affinipayCommunications';
import AFFIRM_COMMUNICATIONS from '../../LoanPayment/helpers/affirmCommunications';
import LoanForm from '../../LoanPayment/LoanForm';
import { affirmMock } from '../testHelpers';

describe('LoanJS Form Validation Tests', () => {
  beforeAll(() => {
    affirmMock();
  });

  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
    jest.spyOn(AFFINIPAY_COMMUNICATIONS, 'getPaymentToken');
    jest.spyOn(AFFIRM_COMMUNICATIONS, 'initiateAffirmCheckout').mockImplementation(() => {});
  });

  it('Lower Limit validation', async () => {
    render(
      <LoanForm
        externalFirstName='John'
        externalLastName='Doe'
        email='jd1234@gmail.com'
        pubKey='1234abcd'
        accountId='1234'
        amount='34999'
        minTransactionLimit={35000}
        maxTransactionLimit={2500000}
        chargeURL={'httpz://foobar.com'}
        tokenURL={'httpz://foobar.com'}
      ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    userEvent.click(submitButton);
    await waitFor(() => expect(screen.getByText('The amount you entered is too low. Please enter an amount of $350.00 or higher.')).toBeVisible());
  });

  it('Upper Limit validation', async () => {
    render(
      <LoanForm
        externalFirstName='John'
        externalLastName='Doe'
        externalEmail='jd1234@gmail.com'
        pubKey='1234abcd'
        accountId='1234'
        amount='2500001'
        minTransactionLimit={35000}
        maxTransactionLimit={2500000}
        chargeURL={'httpz://foobar.com'}
        tokenURL={'httpz://foobar.com'}
      ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    await waitFor(() => expect(screen.getByText('The amount you entered is too high. Please enter an amount of $25,000.00 or less.')).toBeVisible());
    expect(submitButton).toBeDisabled();
  });

  it('Treats 0 as no limit upper', () => {
    render(
      <LoanForm
        externalFirstName='John'
        externalLastName='Doe'
        externalEmail='jd1234@gmail.com'
        pubKey='1234abcd'
        accountId='1234'
        amount='2500001'
        minTransactionLimit={0}
        maxTransactionLimit={0}
        chargeURL={'httpz://foobar.com'}
        tokenURL={'httpz://foobar.com'}
      ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    expect(screen.queryByText('The amount you entered is too high. Please enter an amount of $25,000.00 or less and try again.')).toBeFalsy();
    expect(submitButton).not.toBeDisabled();
  });

  it('Treats 0 as no limit lower', () => {
    render(
      <LoanForm
        externalFirstName='John'
        externalLastName='Doe'
        externalEmail='jd1234@gmail.com'
        pubKey='1234abcd'
        account-id='1234'
        amount='1'
        minTransactionLimit={0}
        maxTransactionLimit={0}
        chargeURL={'httpz://foobar.com'}
        tokenUrl={'httpz://foobar.com'}
      ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    expect(screen.queryByText('The amount you entered is too low. Please enter an amount of $350.00 or higher and try again.')).toBeFalsy();
    expect(submitButton).not.toBeDisabled();
  });

  it('Required fields validation', async () => {
    render(
      <LoanForm
        pubKey='1234abcd'
        account-id='1234'
        amount='88800'
        minTransactionLimit={35000}
        maxTransactionLimit={2500000}
        chargeURL={'httpz://foobar.com'}
        tokenUrl={'httpz://foobar.com'}
      ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    const firstNameInput = screen.getByLabelText('First Name');
    const lastNameInput = screen.getByLabelText('Last Name');
    const firstNameError = 'First Name is required.',
      lastNameError = 'Last Name is required.',
      emailError = 'Email Address is required.';

    userEvent.click(submitButton);
    expect(await screen.findByText(firstNameError)).toBeVisible();
    expect(await screen.findByText(lastNameError)).toBeVisible();
    expect(await screen.findByText(emailError)).toBeVisible();
    expect(submitButton).not.toBeDisabled();
    await waitFor(() => expect(AFFIRM_COMMUNICATIONS.initiateAffirmCheckout).not.toHaveBeenCalled());
    await waitFor(() => expect(AFFINIPAY_COMMUNICATIONS.getPaymentToken).not.toHaveBeenCalled());

    userEvent.type(firstNameInput, 'John');
    userEvent.type(lastNameInput, 'Doe');
    userEvent.click(submitButton);
    expect(screen.queryByText(firstNameError)).not.toBeInTheDocument();
    expect(screen.queryByText(lastNameError)).not.toBeInTheDocument();
    expect(await screen.findByText(emailError)).toBeVisible();
    expect(submitButton).not.toBeDisabled();
    await waitFor(() => expect(AFFIRM_COMMUNICATIONS.initiateAffirmCheckout).not.toHaveBeenCalled());
    await waitFor(() => expect(AFFINIPAY_COMMUNICATIONS.getPaymentToken).not.toHaveBeenCalled());
  });

  it('Correct email pattern validation', async () => {
    render(
      <LoanForm
        externalFirstName='John'
        externalLastName='Doe'
        externalEmail='jd1234'
        pubKey='1234abcd'
        account-id='1234'
        amount='99900'
        minTransactionLimit={35000}
        maxTransactionLimit={2500000}
        chargeURL={'httpz://foobar.com'}
        tokenUrl={'httpz://foobar.com'}
      ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    const emailPatternError = 'Email address is not valid. Please review and try again.';

    userEvent.click(submitButton);
    expect(await screen.findByText(emailPatternError)).toBeVisible();
    expect(submitButton).not.toBeDisabled();
    await waitFor(() => expect(AFFIRM_COMMUNICATIONS.initiateAffirmCheckout).not.toHaveBeenCalled());
    await waitFor(() => expect(AFFINIPAY_COMMUNICATIONS.getPaymentToken).not.toHaveBeenCalled());
  });

  it('Correct email pattern validation when using the email input', async () => {
    render(
      <LoanForm
        externalFirstName='John'
        externalLastName='Doe'
        pubKey='1234abcd'
        account-id='1234'
        amount='99900'
        minTransactionLimit={35000}
        maxTransactionLimit={2500000}
        chargeURL={'httpz://foobar.com'}
        tokenUrl={'httpz://foobar.com'}
      ></LoanForm>
    );

    const submitButton = screen.getByText('Begin Process');
    const emailInput = screen.getByLabelText('Email Address');
    const emailPatternError = 'Email address is not valid. Please review and try again.';

    userEvent.type(emailInput, 'invalide email 123');
    userEvent.click(submitButton);

    expect(await screen.findByText(emailPatternError)).toBeVisible();
    expect(submitButton).not.toBeDisabled();

    await waitFor(() => expect(AFFIRM_COMMUNICATIONS.initiateAffirmCheckout).not.toHaveBeenCalled());
    await waitFor(() => expect(AFFINIPAY_COMMUNICATIONS.getPaymentToken).not.toHaveBeenCalled());
  });
});
