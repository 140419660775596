
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import LoanForm from '../../LoanPayment/LoanForm';
import { affirmMock } from '../testHelpers';

describe('LoanJS Affirm interactions non-happy path tests', () => {
  beforeAll(() => {
    affirmMock();
  });

  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
  });

  it('Affirm script failed to attach global to the window object', async () => {
    render(<LoanForm
      externalFirstName='John'
      externalLastName='Doe'
      externalEmail='jd1234@gmail.com'
      pubKey='1234abcd'
      accountId='1234'
      amount='35100'
      minTransactionLimit={35000}
      maxTransactionLimit={2500000}
      chargeURL={'httpz://foobar.com'}
      tokenURL={'httpz://foobar.com'}
    ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    const affirmError = 'There was an error when creating this charge. Please refresh the page and try again.';

    userEvent.click(submitButton);
    expect(await screen.findByText(affirmError)).toBeVisible();
    expect(submitButton).not.toBeDisabled();
  });
});
