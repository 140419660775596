export const mockInvoiceJSON = {
  page: 1,
  page_size: 20,
  total_entries: 2,
  results: [
    {
      id: 'i_P7Kw9xJsL9CQhCa9VAsWC',
      created: {
        user: 'owner@example.com',
        timestamp: '2023-04-01T22:06:50.265Z',
        client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
        client_inet_address: '172.21.176.35',
        caller_inet_address: '172.21.176.35'
      },
      modified: {
        user: 'owner@example.com',
        timestamp: '2023-04-01T22:06:51.059Z',
        client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
        client_inet_address: '172.21.176.35',
        caller_inet_address: '172.21.176.35'
      },
      status: 'paid',
      invoice_number: '000001',
      invoice_date: '2023-04-01T22:06:49.806Z',
      due_date: '2023-04-01T22:06:49.806Z',
      total_amount: 10000,
      total_amount_due: 10000,
      amount_paid: 10000,
      test_mode: true,
      currency: 'USD',
      bank_account_id: 'bank_A8MBU7A6DuWVK4xheiJPz',
      owner_id: 'org_bAdZ0c9Ojaf9Isy6v1qMb',
      contact_id: 'p_SmpeZoaiTQpmsrwHCb53v',
      contact: {
        id: 'p_SmpeZoaiTQpmsrwHCb53v',
        test_mode: false,
        owner_id: 'org_bAdZ0c9Ojaf9Isy6v1qMb',
        preferred_email: 'bugs@acme.com',
        contact_code: '0001',
        first_name: 'Bugs',
        last_name: 'Bunny',
        sort_name: 'Bunny Bugs',
        name: 'Bugs Bunny',
        type: 'person',
        display_name: 'Bugs Bunny'
      },
      invoice_payments: [
        {
          direction: 'inbound',
          amount: 10000,
          created: {
            user: 'owner@example.com',
            timestamp: '2023-04-04T22:06:50.265Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          modified: {
            user: 'owner@example.com',
            timestamp: '2023-04-04T22:06:51.059Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          payment: {
            transaction_id: '843eirudjkfc',
            payment_type: 'credit_card',
            card_type: 'MASTERCARD',
            last4_digits: '6789',
            currency: 'USD',
            status: 'completed',
            id: 'aslfjahoiqhriojvn9034905'
          }
        }
      ],
      items: [
        {
          id: 'li_jb0Qzbq5oXNTnKCZlwwPk',
          code: 'EQB',
          description: 'Services Rendered',
          rate_per_quantity: '10000',
          quantity: '1',
          rate_type: 'units',
          total: 10000
        }
      ],
      lineItems: [
        {
          id: 'li_jb0Qzbq5oXNTnKCZlwwPk',
          code: 'EQB',
          description: 'Services Rendered',
          rate_per_quantity: '10000',
          quantity: '1',
          rate_type: 'units',
          total: 10000
        }
      ],
      invoice_messages: [
        {
          id: 'imsg_EX0oKszQzNjVD7nu7CDvB',
          created: {
            user: 'owner@example.com',
            timestamp: '2023-04-01T22:06:50.580Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          modified: {
            user: 'owner@example.com',
            timestamp: '2023-04-01T22:06:50.580Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          email_addresses: [
            'bugs@acme.com'
          ],
          subject: 'Carrots Payment',
          status: 'paid',
          message: 'Carrots Email',
          type: 'invoice_message'
        }
      ],
      scheduled_payment_requests: [],
      reference: 'Carrots',
      presentment_status: 'paid',
      attachments: [],
      type: 'invoice',
      bank_name: 'Wells Fargo',
      bank_account_type: 'operating',
      bank_account: '*****4321',
      payment_method: 'credit_card'
    },
    {
      id: 'i_P7Kw9xJsL9CQhCa9VAsAZ',
      created: {
        user: 'owner@example.com',
        timestamp: '2023-05-01T22:06:50.265Z',
        client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
        client_inet_address: '172.21.176.35',
        caller_inet_address: '172.21.176.35'
      },
      modified: {
        user: 'owner@example.com',
        timestamp: '2023-05-01T22:06:51.059Z',
        client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
        client_inet_address: '172.21.176.35',
        caller_inet_address: '172.21.176.35'
      },
      status: 'pending',
      invoice_number: '000001',
      invoice_date: '2023-05-01T22:06:49.806Z',
      due_date: '2023-05-01T22:06:49.806Z',
      total_amount: 100000,
      total_amount_due: 100000,
      amount_paid: 0,
      test_mode: true,
      currency: 'USD',
      bank_account_id: 'bank_A8MBU7A6DuWVK4xheiJAZ',
      owner_id: 'org_bAdZ0c9Ojaf9Isy6v1qMb',
      contact_id: 'p_SmpeZoaiTQpmsrwHCb5v3',
      contact: {
        id: 'p_SmpeZoaiTQpmsrwHCb5v3',
        test_mode: false,
        owner_id: 'org_bAdZ0c9Ojaf9Isy6v1qMb',
        preferred_email: 'wiley@acme.com',
        contact_code: '0001',
        first_name: 'Wiley',
        last_name: 'Coyote',
        sort_name: 'Coyote Wiley',
        name: 'Wiley Coyote',
        type: 'person',
        display_name: 'Wiley Coyote'
      },
      invoice_payments: [],
      items: [
        {
          id: 'li_jb0Qzbq5oXNTnKCZlwwPk',
          code: 'EQB',
          description: 'Services Rendered',
          rate_per_quantity: '100000',
          quantity: '1',
          rate_type: 'units',
          total: 100000
        }
      ],
      lineItems: [
        {
          id: 'li_jb0Qzbq5oXNTnKCZlwwPk',
          code: 'EQB',
          description: 'Services Rendered',
          rate_per_quantity: '100000',
          quantity: '1',
          rate_type: 'units',
          total: 100000
        }
      ],
      invoice_messages: [
        {
          id: 'imsg_EX0oKszQzNjVD7nu7CDvB',
          created: {
            user: 'owner@example.com',
            timestamp: '2023-05-01T22:06:50.580Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          modified: {
            user: 'owner@example.com',
            timestamp: '2023-05-01T22:06:50.580Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          email_addresses: [
            'wiley@acme.com'
          ],
          subject: 'Train Paint Payment',
          status: 'sent',
          message: 'Train Paint Email',
          type: 'invoice_message'
        }
      ],
      scheduled_payment_requests: [],
      reference: 'Train Paint',
      presentment_status: 'sent',
      attachments: [],
      type: 'invoice',
      bank_name: 'Wells Fargo',
      bank_account_type: 'operating',
      bank_account: '*****1234',
      payment_method: ''
    },
    {
      id: 'i_ucuBhBVP8YXOMjTc008pv',
      created: {
        user: 'owner@example.com',
        timestamp: '2023-05-01T22:07:17.493Z',
        client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
        client_inet_address: '172.21.176.35',
        caller_inet_address: '172.21.176.35'
      },
      modified: {
        user: 'owner@example.com',
        timestamp: '2023-05-01T22:07:17.723Z',
        client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
        client_inet_address: '172.21.176.35',
        caller_inet_address: '172.21.176.35'
      },
      status: 'pending',
      invoice_number: '000002',
      invoice_date: '2023-05-01T22:07:17.322Z',
      due_date: '2023-05-01T22:07:17.322Z',
      total_amount: 100000,
      total_amount_due: 100000,
      amount_paid: 0,
      test_mode: true,
      currency: 'USD',
      bank_account_id: 'bank_A8MBU7A6DuWVK4xheiJPz',
      owner_id: 'org_bAdZ0c9Ojaf9Isy6v1qMb',
      contact_id: 'p_J9d4qEeDHvAjw52cIOTbs',
      contact: {
        id: 'p_J9d4qEeDHvAjw52cIOTbs',
        test_mode: false,
        owner_id: 'org_bAdZ0c9Ojaf9Isy6v1qMb',
        preferred_email: 'elmer@acme.com',
        contact_code: '0002',
        first_name: 'Elmer',
        last_name: 'Fudd',
        sort_name: 'Fudd Elmer',
        name: 'Elmer Fudd',
        type: 'person',
        display_name: 'Elmer Fudd'
      },
      invoice_payments: [],
      items: [
        {
          id: 'li_2y7lkVHwBNYRiM39GArf6',
          code: 'EQB',
          description: 'Services Rendered',
          rate_per_quantity: '100000',
          quantity: '1',
          rate_type: 'units',
          total: 100000
        }
      ],
      lineItems: [
        {
          id: 'li_2y7lkVHwBNYRiM39GArf6',
          code: 'EQB',
          description: 'Services Rendered',
          rate_per_quantity: '100000',
          quantity: '1',
          rate_type: 'units',
          total: 100000
        }
      ],
      invoice_messages: [
        {
          id: 'imsg_UWloFqWNQ9jFLH30rx3XQ',
          created: {
            user: 'owner@example.com',
            timestamp: '2023-05-01T22:07:17.676Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          modified: {
            user: 'owner@example.com',
            timestamp: '2023-05-01T22:07:17.676Z',
            client_id: '512a1e108b29829c7f2728c36272d222d09217f7f16cef06ba0bd82d57ffc8d9',
            client_inet_address: '172.21.176.35',
            caller_inet_address: '172.21.176.35'
          },
          email_addresses: [
            'elmer@acme.com'
          ],
          subject: 'shotgun payment',
          status: 'viewed',
          message: 'shotgun email\n',
          type: 'invoice_message'
        }
      ],
      scheduled_payment_requests: [],
      reference: 'shotgun',
      presentment_status: 'viewed',
      attachments: [],
      type: 'invoice',
      bank_name: 'BBVA Compass',
      bank_account_type: 'operating',
      bank_account: '*****0000',
      payment_method: ''
    }
  ]
};