import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import {fetch} from 'whatwg-fetch';
import LastChanceModal from '../LastChanceModal';
import MerchantAppDepositService, {patchMerchantAppDeposit} from '../services';
import * as utils from '../../../../lib/utils';

jest.mock('whatwg-fetch', () => {
  const originalModule = jest.requireActual('whatwg-fetch');

  return {
    __esModule: true,
    ...originalModule,
    fetch: jest.fn(() => ({
      status: 200,
      url: 'https://www.affinipay.com/register/review'
    }))
  };
});

describe('patchMerchantAppDeposit', () => {
  it('takes a payload and fetch sends the correct values to the server', () => {
    const expectedFetchPayload = {
      body:'_method=patch&authenticity_token=someToken&forms_registrations_deposit_step_form%5Boperating_account_bank_name%5D=BofA&forms_registrations_deposit_step_form%5Boperating_account_number%5D=987654321&forms_registrations_deposit_step_form%5Boperating_account_number_confirmation%5D=987654321&forms_registrations_deposit_step_form%5Boperating_account_routing_number%5D=123456789&forms_registrations_deposit_step_form%5Baccept_terms%5D=true&forms_registrations_deposit_step_form%5Bcc_intent_status%5D=true&forms_registrations_deposit_step_form%5Bamex_opt_in%5D=0&forms_registrations_deposit_step_form%5Bsigned_by%5D=Bob%20Loblaw&forms_registrations_deposit_step_form%5Bsignature%5D=signature',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': ''
      },
      method: 'POST'
    };

    const payload = {
      '_method': 'patch',
      'authenticity_token': 'someToken',
      'forms_registrations_deposit_step_form[operating_account_bank_name]': 'BofA',
      'forms_registrations_deposit_step_form[operating_account_number]': '987654321',
      'forms_registrations_deposit_step_form[operating_account_number_confirmation]': '987654321',
      'forms_registrations_deposit_step_form[operating_account_routing_number]': '123456789',
      'forms_registrations_deposit_step_form[accept_terms]': 'true',
      'forms_registrations_deposit_step_form[cc_intent_status]': 'true',
      'forms_registrations_deposit_step_form[amex_opt_in]': '0',
      'forms_registrations_deposit_step_form[signed_by]': 'Bob Loblaw',
      'forms_registrations_deposit_step_form[signature]': 'signature'
    };

    patchMerchantAppDeposit('https://www.affinipay.com/deposits', payload);

    expect(fetch).toHaveBeenCalledWith('https://www.affinipay.com/deposits', expectedFetchPayload);
  });
});

describe('LastChanceModal', () => {
  const testProps = {
    appId: 1,
    checkboxId: 'last-chance',
    closeModal: jest.fn(),
    formId: 'deposits-form',
    isModalOpen: true,
    data: {
      'data-action': 'click',
      'data-form': 'deposits-form',
      'data-path': 'register/deposits',
      'data-step': 'MerchantRegistration-Deposit',
      'data-options': {}
    },
    supportPhone: '555-867-5309'
  };

  it('renders with required props', () => {
    render(
      <form id='deposits-form'>
        <input type="checkbox" id="last-chance" />
        <LastChanceModal {...testProps} />
      </form>
    );

    const modalText = [
      'Why our customers love Pay Later',
      '45% of firms report that Pay Later de-risked their business',
      '53% of firms saw an increased number of clients able to pay for their services',
      '45% of firms said that Pay Later improved the image of the company',
      '"I have retained clients that otherwise would not have had the funds to pay for my services."',
      '(Firm using Pay Later)',
      'Not ready for Pay Later yet?',
      'If you change your mind anytime, please call support at'
    ];
    modalText.forEach(text => expect(screen.getByText(text)).toBeInTheDocument());
  });

  it('clicking opt-in button invokes patchMerchantAppDeposit service', async () => {
    jest.spyOn(MerchantAppDepositService, 'patchMerchantAppDeposit').mockImplementation(() => ({status: 200, url: 'register/review'}));

    render(
      <form id='deposits-form'>
        <input
          id='forms_registrations_deposit_step_form[operating_account_bank_name]'
          defaultValue="Wells Fargo"
        />
        <input type="checkbox" id="last-chance" />
        <LastChanceModal {...testProps} />
      </form>
    );

    userEvent.click(screen.getByTestId('client-credit-last-chance-opt-in'));
    await waitFor(() => {
      expect(MerchantAppDepositService.patchMerchantAppDeposit).toHaveBeenCalledTimes(1);
      expect(MerchantAppDepositService.patchMerchantAppDeposit).toHaveBeenCalledWith(
        'register/deposits',
        {'forms_registrations_deposit_step_form[cc_intent_status]': 'true'}
      );
    });
  });

  it('if a message is displayed if an error occurs when consuming patchMerchantAppDeposit service', async () => {
    jest.spyOn(MerchantAppDepositService, 'patchMerchantAppDeposit').mockImplementation(() => ({status: 500, url: 'register/review'}));

    render(
      <form id='deposits-form'>
        <input
          id='forms_registrations_deposit_step_form[operating_account_bank_name]'
          defaultValue="Wells Fargo"
        />
        <input type="checkbox" id="last-chance" />
        <LastChanceModal {...testProps} />
      </form>
    );

    userEvent.click(screen.getByTestId('client-credit-last-chance-opt-in'));
    await waitFor(() => {
      expect(screen.getByTestId('client-credit-last-chance-error')).toBeInTheDocument();
    });
  });

  it('invokes Google Analytics when the "Not Right Now" button is clicked', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() => {});

    render(
      <form id='deposits-form'>
        <input type="checkbox" id="last-chance" />
        <LastChanceModal {...testProps} />
      </form>
    );

    await userEvent.click(screen.getByText('Not Right Now'));
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('Pay Later Opt Out Button');
    expect(safeSendGA.mock.calls[0][1]).toBe('Pay Later Opt Out Button Clicked');
  });

  it('invokes Google Analytics when the "Yes I\'ll try Pay Later" button is clicked', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() => {});
    render(
      <form id='deposits-form'>
        <input type="checkbox" id="last-chance" />
        <LastChanceModal {...testProps} />
      </form>
    );

    await userEvent.click(screen.getByText("Yes, I'll Try Pay Later"));
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('Pay Later Opt In Button');
    expect(safeSendGA.mock.calls[0][1]).toBe('Pay Later Opt In Button Clicked');
  });
});
