import React from 'react';
import {render, screen} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QRCodeModal from '../QRCodeModal';
import * as utils from '../../../lib/utils';

const analyticValues = {
  currentMerchantName: 'Bird Law LLC',
  paymentTitle: 'philadelphiabirdlaw.com',
  paymentPath: 'Troll Toll for Boy Soul'
};

const modalOpenEvent = {
  location: 'Charges QR Modal',
  event: 'QR Modal opened',
  ...analyticValues
};

const printEvent = {
  location: 'Charges QR Modal',
  event: 'QR Modal print button clicked',
  ...analyticValues
};

describe('QRCodeCard Component', () => {
  afterAll(() => {
    jest.resetAllMocks();
  });

  it('renders without props passed', () => {
    const {container} = render(<QRCodeModal />);
    expect(container.firstChild).toHaveClass('qr-code');
  });

  it('opens the QR modal when the QR button is clicked', () => {
    const {container} = render(
      <QRCodeModal
        currentMerchantName='Bird Law LLC'
        id='asdf1234'
        paymentPath='philadelphiabirdlaw.com'
        paymentTitle='Troll Toll for Boy Soul'
        site='affinipay'
      />
    );

    expect(container.querySelector('.qr-code')).toBeInTheDocument();
    expect(container.querySelector('.APOverlay')).not.toBeInTheDocument();
    userEvent.click(screen.getByRole('button'));
    expect(container.querySelector('.APOverlay')).toBeInTheDocument();
  });

  it('invokes Google Analytics when the QR button is clicked', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(modalOpenEvent);
        })
      })
    );

    render(
      <QRCodeModal
        currentMerchantName='Bird Law LLC'
        id='asdf1234'
        paymentPath='Troll Toll for Boy Soul'
        paymentTitle='philadelphiabirdlaw.com'
        site='affinipay'
      />
    );
    await userEvent.click(screen.getByTitle('qr-code-svg-button'));
    expect(safeSendGA).toHaveBeenCalled();
    expect(safeSendGA.mock.calls[0][0]).toBe('Charges QR Modal');
    expect(safeSendGA.mock.calls[0][1]).toBe('QR Modal opened');
  });

  it('invokes Google Analytics when the Print button is clicked', async () => {
    const safeSendGA = jest.spyOn(utils, 'safeSendGA').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        json: jest.fn(() => {
          return Promise.resolve(printEvent);
        })
      })
    );

    window.open = jest.fn().mockImplementationOnce(() => ({
      ...window,
      print: jest.fn(),
      onafterprint: jest.fn()
    }));

    render(
      <QRCodeModal
        currentMerchantName='Bird Law LLC'
        id='asdf1234'
        paymentPath='Troll Toll for Boy Soul'
        paymentTitle='philadelphiabirdlaw.com'
        site='affinipay'
      />
    );

    await userEvent.click(screen.getByTitle('qr-code-svg-button'));
    await userEvent.click(screen.getByText('Print'));
    expect(safeSendGA).toHaveBeenCalledTimes(2);
    expect(safeSendGA.mock.calls[1][0]).toBe('Charges QR Modal');
    expect(safeSendGA.mock.calls[1][1]).toBe('QR Modal print button clicked');
    expect(window.open).toHaveBeenCalledTimes(1);
  });
});
