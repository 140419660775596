
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import PaymentPageLegacy from '../index';
import { cardAndEcheckNoCustomFieldsNoSurcharge, loanPaymentPage } from './PaymentPageTestProps';
import { simulateSelectEventInAPSelect } from './testHelpers';

describe('PaymentPageLegacy Frequency', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
  });

  /* Basic frequency is not implemented correctly. Correct implementation should satisfy this test (uncomment expects) */

  it('No Frequency does not render frequency elements', () => {
    const props = { ...cardAndEcheckNoCustomFieldsNoSurcharge };
    props['recurring_visible'] = 'NONE';
    render(<PaymentPageLegacy {...props} />);

    expect(screen.queryByLabelText('Frequency')).not.toBeInTheDocument();
  });

  it('Full Frequency correctly renders dynamic options', () => {
    const props = { ...cardAndEcheckNoCustomFieldsNoSurcharge };
    props['recurring_visible'] = 'FULL';
    render(<PaymentPageLegacy {...props} />);

    simulateSelectEventInAPSelect('Frequency', 'Twice a Month');
    simulateSelectEventInAPSelect('Day of Month', '3rd');
    userEvent.click(screen.getByLabelText('When total paid:'));
    userEvent.type(screen.getByLabelText('Total Amount Due'), '500');

    setTimeout(() => {
      expect(screen.getByLabelText('payment-form')).toHaveFormValues({
        recurFrequency: 'BI_MONTHLY',
        secondDayOfMonth: '3',
        recurringEnds: 'whenTotalPaid',
        endAmountField: '500'
      });
    }, 50);
  });

  it('does not render frequency options when loan tab is selected', () => {
    const props = { ...loanPaymentPage, recurring_visible: 'FULL', default_payment_method: 'card' };
    render(<PaymentPageLegacy {...props} />);
    expect(screen.getByText('No Schedule')).toBeInTheDocument();
    userEvent.click(screen.getByText('Pay Later'));
    expect(screen.queryByText('No Schedule')).not.toBeInTheDocument();
  });
});