import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import PaymentPageLegacy from '../index';
import { cardAndEcheckCustomFieldsNoSurcharge, loanPaymentPage } from './PaymentPageTestProps';

describe('Payment Page Legacy with Loan', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => { });
  });

  it('Payment Page renders loan tab correctly', () => {
    render(<PaymentPageLegacy {...loanPaymentPage} />);
    expect(screen.queryByTestId('Pay Later')).toBeVisible();
  });

  it('Switching to Loan Tab removes PP summary section', async () => {
    render(<PaymentPageLegacy {...loanPaymentPage} />);
    userEvent.click(screen.getByTestId('Pay Later'));

    await waitFor(() => expect(screen.queryByTestId('subtotal')).not.toBeInTheDocument());
  });

  it('Switching to Loan Tab removes PP action button', async () => {
    render(<PaymentPageLegacy {...loanPaymentPage} />);
    userEvent.click(screen.getByTestId('Pay Later'));

    await waitFor(() => expect(screen.queryByText(`Pay $120.00`)).not.toBeInTheDocument());
  });

  it('Loan tab is not available if loan processing account is not supplied', () => {
    render(<PaymentPageLegacy {...cardAndEcheckCustomFieldsNoSurcharge} />);
    expect(screen.queryByText('Pay Later') === null);
  });

  it('Loan tab shows error for logged in merchant', async () => {
    const loggedInMerchant = { ...loanPaymentPage, current_user: true };
    render(<PaymentPageLegacy {...loggedInMerchant} />);

    userEvent.click(screen.getByTestId('Pay Later'));

    await waitFor(() => {
      const errMessage1 = screen.queryByText('Pay Later cannot be used when you’re logged in to AffiniPay');
      const errMessage2 = screen.queryByText('We recommend that you share the payment page link directly with your client, or call support at (855) 656-4685.');
      expect(errMessage1).toBeInTheDocument();
      expect(errMessage2).toBeInTheDocument();
    });
  });

  it('LoanJS success handler invokes thank you page', async () => {
    render(<PaymentPageLegacy {...loanPaymentPage} />);
    userEvent.click(screen.getByTestId('Pay Later'));
    _dispatchMockLoanJSSuccessEvent();
    await waitFor(() => {
      const thankYouPage = screen.getByText('Thank you for your payment.');
      expect(thankYouPage).toBeInTheDocument();
    });
  });

  it('LoanJS declined handler shows error message', async () => {
    render(<PaymentPageLegacy {...loanPaymentPage} />);
    userEvent.click(screen.getByTestId('Pay Later'));
    _dispatchMockLoanJSDeclinedEvent();
    await waitFor(() => {
      const declinedMessage = screen.getByText('Your Pay Later loan request did not go through.');
      expect(declinedMessage).toBeInTheDocument();
    });
  });

  it('loan-js is visible when click on Pay Later Button', async () => {
    render(<PaymentPageLegacy {...loanPaymentPage} />);
    userEvent.click(screen.getByTestId('Pay Later'));

    await waitFor(() => expect(screen.queryByTestId('loan-js')).toBeVisible());
  });
});

const _dispatchMockLoanJSSuccessEvent = _ => {
  const event = new Event('loan-js-success', { bubbles: true });
  window.dispatchEvent(event);
};

const _dispatchMockLoanJSDeclinedEvent = _ => {
  const event = new Event('loan-js-affirm-error', { bubbles: true });
  window.dispatchEvent(event);
};
