import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import DeleteQuickBillButton from '../DeleteQuickBillButton';
import client from '../../../../../lib/ajax';


const invoiceID = 'abc123';

describe('DeleteQuickBillButton', () => {
  beforeEach(() => {
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
  });

  it('renders', () => {
    render(<DeleteQuickBillButton invoiceID={invoiceID} />);
    expect(screen.getByText('Delete')).toBeVisible();
  });

  it('is disabled if isDisabled prop is true', () => {
    render(<DeleteQuickBillButton invoiceID={invoiceID} isDisabled={true} />);
    expect(screen.getByText('Delete')).toBeVisible();
    expect(screen.getByRole('button')).toBeDisabled();
  });

  it('opens confirmation modal when Delete button is clicked', async () => {
    render(<DeleteQuickBillButton invoiceID={invoiceID} />);
    userEvent.click(screen.getByText('Delete'));
    await waitFor(() => expect(screen.getByText('Confirm Delete')).toBeInTheDocument());
    await waitFor(() => expect(screen.getByText('Cancel')).toBeInTheDocument());
    await waitFor(() => expect(screen.getByText('Yes, delete')).toBeInTheDocument());
    await waitFor(() => expect(screen.getByText(
      'After you delete this Quick Bill, it cannot be recovered. Do you still want to delete it?'
    )).toBeInTheDocument());
  });

  it('sends a request with the passed invoiceID prop', async () => {
    render(<DeleteQuickBillButton invoiceID={invoiceID} />);
    userEvent.click(screen.getByText('Delete'));
    const deleteButton = await screen.getByText('Yes, delete');
    await waitFor(() => expect(deleteButton).toBeInTheDocument());

    userEvent.click(deleteButton);
    await waitFor(() => expect(client.destroy).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(client.destroy).toHaveBeenCalledWith('/quick_bills/abc123'));
  });

  it('cancel button in alert modal closes the modal', async () => {
    render(<DeleteQuickBillButton invoiceID={invoiceID} />);
    userEvent.click(screen.getByText('Delete'));
    const cancelButton = await screen.getByText('Cancel');
    await waitFor(() => expect(cancelButton).toBeInTheDocument());

    userEvent.click(cancelButton);
    await waitFor(() => expect(screen.queryByText('Cancel')).not.toBeInTheDocument());
    await waitFor(() => expect(screen.queryByText('Confirm')).not.toBeInTheDocument());
    await waitFor(() => expect(screen.queryByText(
      'After you delete this Quick Bill, it cannot be recovered. Do you still want to delete it?'
    )).not.toBeInTheDocument());
  });

  it('clicking cancel button does not result in calling client.destroy', async () => {
    render(<DeleteQuickBillButton invoiceID={invoiceID} />);
    userEvent.click(screen.getByText('Delete'));
    const cancelButton = await screen.getByText('Cancel');
    await waitFor(() => expect(cancelButton).toBeInTheDocument());

    userEvent.click(cancelButton);
    expect(client.destroy).toHaveBeenCalledTimes(0);
  });

  it('requests that fail show a toast', async () => {
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 403,
        json: jest.fn(() => Promise.resolve({}))
      })
    );

    render(<DeleteQuickBillButton invoiceID={invoiceID} />);
    userEvent.click(screen.getByText('Delete'));
    const deleteButton = await screen.getByText('Yes, delete');
    await waitFor(() => expect(deleteButton).toBeInTheDocument());

    userEvent.click(deleteButton);
    await waitFor(() => expect(client.destroy).toHaveBeenCalledTimes(1));
    expect(deleteButton).not.toBeInTheDocument();
    expect(screen.getByText('Quick Bill could not be deleted.')).toBeInTheDocument();
  });

  it('disables the delete confirmation button after clicking it once', async () => {
    render(<DeleteQuickBillButton invoiceID={invoiceID} />);
    userEvent.click(screen.getByText('Delete'));
    const deleteButton = await screen.getByText('Yes, delete');
    await waitFor(() => expect(deleteButton).toBeInTheDocument());
    expect(deleteButton).not.toBeDisabled();
    userEvent.click(deleteButton);
    expect(deleteButton).toBeDisabled();
    userEvent.click(deleteButton);
    await waitFor(() => expect(client.destroy).toHaveBeenCalledTimes(1));
  });
});