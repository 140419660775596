import React from 'react';
import { render, screen } from '@testing-library/react';
import RecurringChargeFilterToggle from '../index';

describe('RecurringChargeFilterToggle', () => {
  it('renders correctly', () => {
    render(<RecurringChargeFilterToggle  toggleText = {'Filter Toggle'} />);
    expect(screen.getByText('Filter Toggle')).toBeInTheDocument();
  });
});
