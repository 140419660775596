import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QuickBillEditForm from '../index';
import client from '../../../../../lib/ajax';
import * as QBUtils from '../../../utils';
import * as DateUtils from '../../../../../lib/utils';

const invoice = {
  id: 'abc123',
  contact: {
    firstName: 'Bob',
    lastName: 'Loblaw',
    name: 'Bob Loblaw'
  },
  status: 'pending',
  presentmentStatus: 'sent',
  invoiceNumber: '1',
  invoiceDate: '10/10/2022',
  dueDate: '11/30/2022',
  totalAmount: 10000,
  totalAmountDue: 10000,
  amountPaid: 0,
  testMode: false,
  currency: 'USD',
  bankAccountId: '987654321',
  ownerId: '1',
  contactId: 'abc123',
  reference: 'lawyer stuff',
  invoiceMessages: [
    {
      id: '23rqsdaf',
      emailAddresses: ['foo@bar.com'],
      body: 'email body',
      message: 'bobs email message',
      subject: 'bobs email subject',
      status: 'sent',
      type: 'eqb'
    }],
  attachments: [],
  created: {
    timestamp: '2022-10-26T04:02:32.077Z'
  },
  modified: {
    timestamp: '2022-10-26T04:02:32.077Z'
  },
  items: [
    {
      code: 'EQB',
      description: 'Services Rendered',
      id: 't_123',
      quantity: '1',
      ratePerQuantity: '1000',
      rateType: 'units',
      total: 1000
    }
  ]
};

describe('QuickBillEditForm', () => {
  beforeAll(() => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
    jest.spyOn(client, 'destroy').mockImplementation(() => Promise.resolve({
      status: 200
    }));

    jest.spyOn(QBUtils, 'formatDaysOut').mockImplementation(() => '3');
    jest.spyOn(QBUtils, 'daysOutText').mockImplementation(() => '3 Days');
    jest.spyOn(DateUtils, 'dateFormat').mockImplementation(() => '10/10/2022');

    window.ResizeObserver = jest.fn().mockImplementation(() => ({
      observe: jest.fn(),
      unobserve: jest.fn(),
      disconnect: jest.fn()
    }));
  });

  const vanillaProps = {
    attachmentDisabled: false,
    bankAccount: {
      achLimit: 600000
    },
    defaultSubject: 'Payment Requested',
    defaultBody: 'Please make a payment',
    invoice,
    siteSupportPhone: '1-800-867-5309',
    setIsDisabled: jest.fn(),
    setShowModal: jest.fn()
  };

  const invoiceWithAttachment = {
    ...vanillaProps.invoice,
    attachments: [{
      id: 'fref_FpSXNVcNusHiPBzcwNo1Z',
      created: {
        user: 'service@affinipay.com',
        timestamp: '2023-09-28T09:10:31.882Z',
        clientId: 'e9edc20f9d44fb401f769a38',
        clientInetAddress: '172.21.240.73',
        callerInetAddress: '172.21.240.73'
      },
      modified: {
        user: 'SERVER',
        timestamp: '2023-09-28T09:10:31.975Z',
        clientId: 'e9edc20f9d44fb401f769a38',
        clientInetAddress: '0.0.0.0',
        callerInetAddress: '0.0.0.0'
      },
      fileName: 'friday-mufasa.gif',
      processingState: 'completed',
      type: 'invoice_file_reference'
    }]
  };

  it('renders', () => {
    render(<QuickBillEditForm {...vanillaProps} />);
    const textToSearch = [
      'Status',
      'Sent',
      'Age',
      '3 Days',
      'Last Updated',
      '10/10/2022',
      'Client Email',
      'First Name',
      'Last Name',
      'Payment Amount',
      'Reference Number',
      'Subject Line',
      'Message Body',
      'bobs email message',
      'Note: A secure, custom payment link will be embedded in the email message.',
      'Add Attachment(s) (Optional)',
      'Choose File',
      'Cancel',
      'Save & Resend'
    ];
    textToSearch.forEach(text => expect(screen.getByText(text)).toBeVisible());
  });

  it('places prop values in respective fields correctly', () => {
    render(<QuickBillEditForm {...vanillaProps} />);
    expect(screen.getByLabelText('Client Email').value).toBe('foo@bar.com');
    expect(screen.getByLabelText('First Name').value).toBe('Bob');
    expect(screen.getByLabelText('Last Name').value).toBe('Loblaw');
    expect(screen.getByLabelText('Payment Amount').value).toBe('100');
    expect(screen.getByLabelText('Reference Number').value).toBe('lawyer stuff');
    expect(screen.getByLabelText('Subject Line').value).toBe('bobs email subject');
    expect(screen.getByLabelText('Message Body').value).toBe('bobs email message');
  });

  it('uses defaultBody and defaultSubject props to populate body and subject of email fields', () => {
    const modifiedInvoice = {...invoice, invoiceMessages: []};
    render(<QuickBillEditForm
      {...vanillaProps}
      invoice={modifiedInvoice}
    />);
    expect(screen.getByLabelText('Subject Line').value).toBe('Payment Requested');
    expect(screen.getByLabelText('Message Body').value).toBe('Please make a payment');
  });

  it('undefined defaultBody and defaultSubject props are empty strings', () => {
    const modifiedInvoice = {...invoice, invoiceMessages: []};
    render(<QuickBillEditForm
      {...vanillaProps}
      defaultSubject={undefined}
      defaultBody={undefined}
      invoice={modifiedInvoice}
    />);
    expect(screen.getByLabelText('Subject Line').value).toBe('');
    expect(screen.getByLabelText('Message Body').value).toBe('');
  });

  it('inputting an amount that exceeds limits on eCheck accounts will show a warning', async () => {
    render(<QuickBillEditForm {...vanillaProps} />);
    expect(screen.queryByTestId('message-header')).not.toBeInTheDocument();
    userEvent.type(screen.getByText('Payment Amount'), '10000');
    expect(screen.getByText('Payment amount exceeds single eCheck transaction limit.')).toBeVisible();
    expect(screen.getByTestId('warning-message')).toBeVisible();
    userEvent.type(screen.getByText('Payment Amount'), '{backspace}{backspace}{backspace}{backspace}');
    await waitFor(() => expect(screen.queryByText('Payment amount exceeds single eCheck transaction limit.')).not.toBeInTheDocument());
  });

  it('accounts without a limit do not show the eCheck warning', async () => {
    render(<QuickBillEditForm {...vanillaProps} bankAccount ={{achLimit: null}} />);
    expect(screen.queryByTestId('message-header')).not.toBeInTheDocument();
    userEvent.type(screen.getByText('Payment Amount'), '10000');
    await waitFor(() => expect(screen.queryByText('Payment amount exceeds single eCheck transaction limit.')).not.toBeInTheDocument());
  });

  it('deletes invoice attachments from invoices and the attachments themselves on save and resend', async () => {
    render(<QuickBillEditForm {...vanillaProps} invoice={invoiceWithAttachment} />);
    const mufasaGIF = screen.getByText('friday-mufasa.gif');
    expect(mufasaGIF).toBeVisible();

    userEvent.click(screen.getByText('Remove'));
    const removalConfirmation = screen.getByText('Yes, Delete');
    expect(removalConfirmation).toBeInTheDocument();
    userEvent.click(removalConfirmation);
    expect(mufasaGIF).not.toBeVisible();

    userEvent.click(screen.getByText('Save & Resend'));
    expect(client.destroy).toHaveBeenNthCalledWith(1, 'attachments/fref_FpSXNVcNusHiPBzcwNo1Z/delete_from_invoice/abc123');
  });

  it('can edit and resent an invoice if attachment is present', async () => {
    const invoicePayload =  {
      id: 'abc123',
      invoice: {
        amount: 100,
        attachment_ids: [
          'fref_FpSXNVcNusHiPBzcwNo1Z'
        ],
        bank_account_id: '987654321',
        body: 'bobs email message',
        contact_id: 'abc123',
        email_address: 'foo@test.com',
        first_name: 'John',
        last_name: 'Loblaw',
        line_item_id: 't_123',
        merchant_id: '1',
        reference: 'lawyer stuff',
        subject: 'bobs email subject'
      }
    };

    render(<QuickBillEditForm {...vanillaProps} invoice={invoiceWithAttachment} />);
    const mufasaGIF = screen.getByText('friday-mufasa.gif');
    expect(mufasaGIF).toBeVisible();

    const emailInput = screen.getByLabelText('Client Email');
    const nameInput = screen.getByLabelText('First Name');
    userEvent.clear(emailInput);
    userEvent.type(emailInput, 'foo@test.com');
    userEvent.clear(nameInput);
    userEvent.type(nameInput, 'John');
    userEvent.click(screen.getByText('Save & Resend'));

    await waitFor(() => expect(client.destroy).toHaveBeenNthCalledWith(1, 'attachments/fref_FpSXNVcNusHiPBzcwNo1Z/delete_from_invoice/abc123'));
    expect(client.post).toHaveBeenLastCalledWith('/quick_bills/abc123/save_and_resend', invoicePayload);
  });
});
