import { getDefaultValuesFromUrl, getDisabledFieldsFromUrl } from '../urlParams';

describe('getDefaultFieldsFromUrl', () => {
  it('Returns default field from url params', () => {
    const url = new URL('https://example.com?city=test&amount=1000');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({city: 'test', amount: '1000'});
  });

  it('Doesnt return params that are not in allowed params', () => {
    const url = new URL('https://example.com?city=test&amount=1000&notreal=test');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({city: 'test', amount: '1000'});
  });

  it('Decodes spaces represented by %20 char', () => {
    const url = new URL('https://example.com?address1=123%20main%20street');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({address1: '123 main street'});
  });

  it('Decodes spaces represented by + char', () => {
    const url = new URL('https://example.com?address1=123+main+street');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({address1: '123 main street'});
  });

  it('Doesnt include amount if amount is not a number', () => {
    const url = new URL('https://example.com?amount=test');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({});
  });

  it('Decodes amount correctly when $ is present', () => {
    const url = new URL('https://example.com?amount=$1000');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({amount: '1000'});
  });

  it('Decodes amount correctly when commas are present', () => {
    const url = new URL('https://example.com?amount=$1,000');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({amount: '1000'});
  });

  it('Doesnt return blank values in query string', () => {
    const url = new URL('https://example.com?city=test&address1=&address2=');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({city: 'test'});
  });

  it('Maps name to both surname and givenName fields as well as name', () => {
    const url = new URL('https://example.com?name=test+tester+IV');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({name: 'test tester IV', given_name: 'test', surname: 'tester IV'});
  });

  it('Maps name to just givenname if name is one word', () => {
    const url = new URL('https://example.com?name=tester');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({name: 'tester', given_name: 'tester', surname: ''});
  });

  it('Doesnt transform snake case to camelcase in url', () => {
    const url = new URL('https://example.com?postal_code=12345');
    const params = new URLSearchParams(url.search);
    expect(getDefaultValuesFromUrl(params)).toEqual({postal_code: '12345'});
  });
});

describe('getDisabledFieldsFromUrl', () => {
  it('Returns array of read only fields from url that are defaulted', () => {
    const url = new URL('https://example.com?amount=1000&address1=test&state=TX&city=austin&readOnlyFields=address1,state,city,amount');
    const params = new URLSearchParams(url.search);
    expect(getDisabledFieldsFromUrl(params)).toEqual(expect.arrayContaining(['address1', 'state', 'city', 'amount']));
  });

  it('Returns empty array if readOnlyFields is not present', () => {
    const url = new URL('https://example.com');
    const params = new URLSearchParams(url.search);
    expect(getDisabledFieldsFromUrl(params)).toEqual(expect.arrayContaining([]));
  });

  it('Doesnt return field if it is not defaulted in url', () => {
    const url = new URL('https://example.com?&readOnlyFields=address1,state,city');
    const params = new URLSearchParams(url.search);
    expect(getDisabledFieldsFromUrl(params)).toEqual(expect.arrayContaining([]));
  });

  it('Returns all three name fields if name is disabled', () => {
    const url = new URL('https://example.com?&name=test&readOnlyFields=name');
    const params = new URLSearchParams(url.search);
    expect(getDisabledFieldsFromUrl(params)).toEqual(expect.arrayContaining(['name', 'given_name', 'surname']));
  });

  it('Returns amount if legacy amount read only param is true', () => {
    const url = new URL('https://example.com?amount=1000&amount_ro=true');
    const params = new URLSearchParams(url.search);
    expect(getDisabledFieldsFromUrl(params)).toEqual(expect.arrayContaining(['amount']));
  });

  it('Returns amount if legacy amount read only param is set to 1', () => {
    const url = new URL('https://example.com?amount=1000&amount_ro=1');
    const params = new URLSearchParams(url.search);
    expect(getDisabledFieldsFromUrl(params)).toEqual(expect.arrayContaining(['amount']));
  });
});