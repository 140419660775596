import React from 'react';
import {getNodeText, render, screen, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import PCICompliances from '../';
import PCICompliancesService from '../src/services/PCICompliancesService';
import {getCurrentMonthString} from '../../../../components/APTimelinePicker/helpers.js';
import PartnersService from '../src/services/PartnerService';
import {mockVanillaResponse, mockReferralPartnersResponse, mockIntegrationPartnersResponse} from './fixtures';


describe('PCICompliances index', () => {
  beforeAll(() => {
    jest.spyOn(PCICompliancesService, 'get').mockImplementation(() =>
      Promise.resolve(mockVanillaResponse)
    );

    jest.spyOn(PartnersService, 'get').mockImplementation(() =>
      Promise.resolve(mockReferralPartnersResponse)
    );

    jest.spyOn(PCICompliancesService, 'patch').mockImplementation(() =>{
      const cloneVanillaResponse = {...mockVanillaResponse};
      const removedElement= cloneVanillaResponse.data.shift();

      cloneVanillaResponse.data.unshift({
        ...removedElement,
        status: 'compliant'
      });
      const patchedResponse = {
        data: cloneVanillaResponse.data,
        totalCount: cloneVanillaResponse.totalCount
      };
      return Promise.resolve(patchedResponse);
    });

    jest.spyOn(PCICompliancesService, 'csv').mockImplementation(() =>
      Promise.resolve({ data: 'example.csv'})
    );
  });

  it('renders', async () => {
    render(<PCICompliances />);
    const textOnRender = [
      'Name or VT ID',
      'Due date range',
      'Compliance status',
      'Apply Filters',
      'Clear',
      'Export .CSV',
      'VTID',
      'UUID',
      'Name',
      'Brand',
      'Previous Test Date',
      'Due Date',
      'Compliance Status',
      'Contact Email',
      'Referral Partners',
      'Integration Partners'
    ];

    await waitFor(() => {
      textOnRender.forEach(text => {
        if (text == 'Referral Partners' || text == 'Integration Partners') {
          //Referral/Integration Partners filter
          expect(screen.getAllByText(text)[0]).toBeVisible();
          // Referral/Integrations Partners column
          expect(screen.getAllByText(text)[1]).toBeVisible();
        } else {
          expect(screen.getByText(text)).toBeVisible();
        }
      });
    });
  });

  it('shows the correct number of results found', async () => {
    render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());

    // extra row added for column headers on table
    expect(screen.getAllByRole('row').length).toBe(29);
    expect(screen.getAllByText('Update').length).toBe(28);
  });

  it('opens the update modal with correct values when "Update" button is clicked', async () => {
    const textInPCIModal = [
      'Change Status',
      'Please select a new compliance status from the dropdown options below.',
      'Save changes'
    ];
    const {container} = render(<PCICompliances />);
    expect(screen.queryByText('Change Due Date')).not.toBeInTheDocument();
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());

    userEvent.click(screen.getAllByText('Update')[0]);
    await waitFor(() => expect(screen.getByText('Change Due Date')).toBeInTheDocument());

    const datepickerText = getNodeText(screen.getByTestId('ap-pci-modal-datepicker-text'));
    const customerIdText = getNodeText(screen.getByTestId('ap-pci-modal-datepicker-customer-id'));
    const comboboxList = screen.getAllByTestId('combobox');

    expect(datepickerText).toEqual('Please use the datepicker below to change  date of PCI compliance.');
    expect(customerIdText).toEqual('Customer ID 1\'s');
    textInPCIModal.forEach(text => expect(screen.getByText(text)).toBeInTheDocument());
    expect(container.querySelector('#ap-pci-modal-datepicker').value).toEqual('06/18/2023');
    expect(comboboxList[comboboxList.length - 1].textContent).toEqual('Due Soon');
  });

  it('invokes PCICompliancesService.get with the passed params via the due date filter', async () => {
    const {rerender} = render(<PCICompliances />);
    expect(PCICompliancesService.get).toHaveBeenCalledTimes(1);
    expect(PCICompliancesService.get).toHaveBeenCalledWith(['page[number]=1', 'page[size]=25']);
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());

    userEvent.click(screen.getByTestId('aptimeline-label'));
    () => expect(screen.getByTestId('aptimeline-selector')).toBeInTheDocument();

    userEvent.click(screen.getByText('Current Month'));
    userEvent.click(screen.getByText('Apply Filters'));
    await waitFor(() => expect(PCICompliancesService.get).toHaveBeenCalledTimes(2));

    rerender(<PCICompliances />);

    userEvent.click(screen.getByTestId('aptimeline-label'));
    userEvent.click(screen.getByText('Next Month'));
    userEvent.click(screen.getByText('Apply Filters'));
    await waitFor(() => expect(PCICompliancesService.get).toHaveBeenCalledTimes(3));

    rerender(<PCICompliances />);

    userEvent.click(screen.getByTestId('aptimeline-label'));
    userEvent.click(screen.getByText('Previous Month'));
    userEvent.click(screen.getByText('Apply Filters'));
    await waitFor(() => expect(PCICompliancesService.get).toHaveBeenCalledTimes(4));
  });

  it('values changed in the modal are reflected in the table', async () => {
    const {container} = render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());
    expect(container.querySelector('#ap-table-row-1-cell-6').innerHTML).toEqual('06/18/2023');
    expect(container.querySelector('#ap-table-row-1-cell-7').innerHTML).toEqual('Due Soon');

    userEvent.click(screen.getAllByText('Update')[0]);

    const comboboxList = screen.getAllByTestId('combobox');
    const complianceStatusDropdown = comboboxList[comboboxList.length - 1];
    expect(complianceStatusDropdown.textContent).toEqual('Due Soon');

    await waitFor(() => expect(screen.getByText('Change Due Date')).toBeInTheDocument());
    userEvent.click(complianceStatusDropdown);
    const compliantStatusOptionId = `#${complianceStatusDropdown.id.split('-').slice(0, 2).join('-')}-item-0`;

    userEvent.click(container.querySelector(compliantStatusOptionId));
    expect(expect(complianceStatusDropdown.textContent).toEqual('Compliant'));

    userEvent.click(screen.getByText('Save changes'));
    await waitFor(() => expect(container.querySelector('#ap-table-row-1-cell-7').innerHTML).toEqual('Compliant'));
  });

  it('pagination invokes service to fetch with proper params', async () => {
    const {container} = render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());

    userEvent.click(screen.getByText('Pagination Right Arrow'));
    expect(container.querySelector('.loading')).toBeInTheDocument();
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());
    expect(container.querySelector('.loading')).not.toBeInTheDocument();
    expect(PCICompliancesService.get).toHaveBeenCalledWith(['page[number]=2', 'page[size]=25']);
    expect(container.querySelector('#ap-pagination-number-input').value).toEqual('2');
  });

  it('clicking "Export .CSV" button invokes the PCI', async () => {
    render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());
    userEvent.click(screen.getByText('Export .CSV'));
    expect(PCICompliancesService.csv).toHaveBeenCalledTimes(1);
    expect(PCICompliancesService.csv).toHaveBeenCalledWith(['page[number]=1', 'page[size]=25']);
  });

  it('resetting the filters invokes a fetch with cleared filter values', async () => {
    render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('28 results found')).toBeVisible());

    await waitFor(() =>userEvent.click(screen.getByTestId('aptimeline-label')));

    userEvent.click(screen.getByText('Current Month'));
    userEvent.click(screen.getByText('Apply Filters'));

    await waitFor(() => expect(screen.getByTestId('aptimeline-input-field').value).toEqual(getCurrentMonthString()));

    await waitFor(() => userEvent.click(screen.getByText('Clear')));
    expect(screen.getByTestId('aptimeline-input-field').value).toEqual('All');
  });

  it('displays an error screen when PCICompliance.get fails', async () => {
    jest.spyOn(PCICompliancesService, 'get').mockImplementation(() =>
      Promise.reject()
    );

    render(<PCICompliances />);
    await waitFor(() => expect(screen.queryByText('There was a problem loading this content.')).not.toBeInTheDocument());

    await waitFor(() => expect(screen.getByText('0 results found')));
    expect(screen.getByText('There was a problem loading this content.')).toBeVisible();
    expect(screen.getByText('Try reloading this page.')).toBeVisible();
  });

  it('changes "results" count to singular when only one PCI Compliance artifact is recovered', async () => {
    jest.spyOn(PCICompliancesService, 'get').mockImplementation(() =>
      Promise.resolve({data: [mockVanillaResponse.data[0]], totalCount: 1})
    );

    render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('1 result found')));
  });

  it ('filters compliances by referral partner', async () => {
    const {container} = render(<PCICompliances />);
    await waitFor(() => expect(screen.getByTestId('referral-partner-filter')).toBeVisible());

    const referralPartnerInput = screen.getByTestId('referral-partner-filter');
    userEvent.type(referralPartnerInput, 'a');
    const partnerOption = container.querySelector(`#${referralPartnerInput.id.split('-').slice(0, 2).join('-')}-item-0`);

    await waitFor(() => expect(partnerOption).toBeInTheDocument());
    expect(expect(partnerOption.textContent).toEqual('AffiniPay Website'));

    userEvent.click(partnerOption);
    expect(referralPartnerInput.value).toBe('AffiniPay Website');
    userEvent.click(screen.getByText('Apply Filters'));

    jest.spyOn(PCICompliancesService, 'get').mockImplementation(() =>
      Promise.resolve({data: [mockVanillaResponse.data[0]], totalCount: 1})
    );

    expect(PCICompliancesService.get).toHaveBeenCalledWith(['filter[referral_partner_id]=5SvGR9N4ZviEznDyEwT4yH', 'page[number]=1', 'page[size]=25']);

    render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('1 result found')));
    expect(container.querySelector('#ap-table-row-1-cell-9').innerHTML).toEqual('AffiniPay Website');
  });

  it ('filters compliances by integration partner', async () => {
    jest.spyOn(PartnersService, 'get').mockImplementation(() =>
      Promise.resolve(mockIntegrationPartnersResponse)
    );

    const {container} = render(<PCICompliances />);
    await waitFor(() => expect(screen.getByTestId('integration-partner-filter')).toBeVisible());

    const integrationPartnerInput = screen.getByTestId('integration-partner-filter');
    userEvent.type(integrationPartnerInput, 'In');
    const partnerOption = container.querySelector(`#${integrationPartnerInput.id.split('-').slice(0, 2).join('-')}-item-0`);

    await waitFor(() => expect(partnerOption).toBeInTheDocument());
    expect(expect(partnerOption.textContent).toEqual('Integration Inc.'));

    userEvent.click(partnerOption);
    expect(integrationPartnerInput.value).toBe('Integration Inc.');
    userEvent.click(screen.getByText('Apply Filters'));

    jest.spyOn(PCICompliancesService, 'get').mockImplementation(() =>
      Promise.resolve({data: [mockVanillaResponse.data[0]], totalCount: 1})
    );

    expect(PCICompliancesService.get).toHaveBeenCalledWith(['filter[integration_partner_id]=5SvGR9N4ZviEznDyEwT4aS', 'page[number]=1', 'page[size]=25']);

    render(<PCICompliances />);
    await waitFor(() => expect(screen.getByText('1 result found')));
    expect(container.querySelector('#ap-table-row-1-cell-10').innerHTML).toEqual('Integration Inc.');
  });

  it ('filters compliances by status', async () => {
    render(<PCICompliances />);

    await waitFor(() => expect(screen.getByTestId('status-combobox')).toBeVisible());

    userEvent.click(screen.getByTestId('status-combobox'));

    await waitFor(() => expect(screen.getByTestId('option-due_soon')).toBeInTheDocument());

    await waitFor(() => userEvent.click(screen.getByTestId('option-due_soon')));

    userEvent.click(screen.getByText('Apply Filters'));

    const response = mockVanillaResponse.data.filter((compliance) => compliance.status === 'due_soon');

    jest.spyOn(PCICompliancesService, 'get').mockImplementation(() =>
      Promise.resolve({data: response, totalCount: response.length})
    );

    expect(PCICompliancesService.get).toHaveBeenCalledWith(['filter[status]=due_soon', 'page[number]=1', 'page[size]=25']);

    render(<PCICompliances />);

    await waitFor(() => expect(screen.getByText(`${response.length} results found`)).toBeVisible());
  });
});