import {act, render, screen} from '@testing-library/react';
import React from 'react';
import TerminalCheckoutModal from '../index';
import server from '../../../lib/server';
import {waitFor} from '@testing-library/dom';
import userEvent from '@testing-library/user-event';

jest.mock('../../../lib/utils', () => ({
  ...jest.requireActual('../../../lib/utils'),
  rollbarLog: jest.fn()
}));

describe('TerminalCheckoutModal', () => {

  const __location = window.location;

  afterEach(() => {
    window.location = __location;
  });

  it('Does not initially show the modal', async () => {
    act(() => {
      render(<TerminalCheckoutModal/>);
    });

    await screen.findByTitle('IconChargeReader');
    expect(screen.getByText('Charge With Reader')).toBeInTheDocument();
    expect(window.document.body.getElementsByTagName('terminal-checkout')).toHaveLength(0);
  });

  it('Shows the modal when charge-with-reader button is clicked', async () => {
    jest.spyOn(server, 'get').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            access_token: 'JWT',
            expires_in: 100
          });
        })
      })
    );

    act(() => {
      render(<TerminalCheckoutModal />);

      const button = screen.getByRole('button');
      userEvent.click(button);
    });

    await screen.findByTitle('Close Modal Icon');
    const checkout = window.document.body.getElementsByTagName('terminal-checkout')[0];
    expect(checkout.getAttribute('token')).toEqual('JWT');
  });

  describe('With the modal open', () => {
    // Render the component, open the modal, and wait for it to be present
    beforeEach(async () => {
      act(() => {
        render(<TerminalCheckoutModal/>);

        const button = screen.getByRole('button');
        userEvent.click(button);
      });

      await screen.findByTitle('Close Modal Icon');
    });

    it('Hides the modal when dismissed', async () => {
      act(() => {
        userEvent.click(screen.getByLabelText('Close Modal'));
      });

      await waitFor(() => {
        expect(screen.queryByTitle('Close Modal Icon')).not.toBeInTheDocument();
      });
      expect(window.document.body.getElementsByTagName('terminal-checkout')).toHaveLength(0);
    });

    it('Hides the modal on TerminalCheckoutTerminationRequested', async () => {
      act(() => {
        const event = new CustomEvent('TerminalCheckoutTerminationRequested', {detail: {}});
        window.document.dispatchEvent(event);
      });

      await waitFor(() => {
        expect(screen.queryByTitle('Close Modal Icon')).not.toBeInTheDocument();
      });
      expect(window.document.body.getElementsByTagName('terminal-checkout')).toHaveLength(0);
    });

    it('Hides the modal on TerminalCheckoutManualPaymentRequested', async () => {
      act(() => {
        const event = new CustomEvent('TerminalCheckoutManualPaymentRequested', {detail: {}});
        window.document.dispatchEvent(event);
      });

      await waitFor(() => {
        expect(screen.queryByTitle('Close Modal Icon')).not.toBeInTheDocument();
      });
      expect(window.document.body.getElementsByTagName('terminal-checkout')).toHaveLength(0);
    });

    it('Navigates to the receipt view when triggered', async () => {
      delete window.location;
      window.location = {href: jest.fn()};

      act(() => {
        const event = new CustomEvent('TerminalCheckoutReceiptRequested', {detail: {transactionId: 'nDidHHGzSb-O4kvKkh7IYA'}});
        window.document.dispatchEvent(event);
      });

      expect(window.location.href).toEqual('/transactions/nDidHHGzSb-O4kvKkh7IYA');
    });
  });
});
