import React from 'react';
import {screen, render} from '@testing-library/react';
import QuickBillDetails from '../QuickBillDetails';

const invoice = {
  id: 'abc123',
  contact: { name: 'Bob Loblaw' },
  status: 'draft',
  invoiceNumber: '1',
  invoiceDate: '10/10/2022',
  dueDate: '11/30/2022',
  totalAmount: 10000,
  totalAmountDue: 10000,
  amountPaid: 0,
  testMode: false,
  currency: 'USD',
  bankAccountId: '987654321',
  ownerId: '1',
  contactId: 'abc123',
  invoiceMessages: [
    {
      id: '23rqsdaf',
      emailAddresses: ['foo@bar.com'],
      body: 'email body',
      message: 'email message',
      subject: 'email subject',
      status: 'draft',
      type: 'eqb'
    }],
  attachments: []
};

describe('QuickBillDetails', () => {
  it('renders', () => {
    render(<QuickBillDetails invoice={invoice} />);
    const labels = ['Details', 'Total Amount', 'Client', 'Subject Line', 'Body Message', 'Attachment(s)'];
    labels.forEach(label => expect(screen.getByText(label)).toBeVisible());
  });

  it('shows a reference if attribute is found in invoice', () => {
    const {rerender} = render(<QuickBillDetails invoice={invoice} />);
    expect(screen.queryByText('Reference')).not.toBeInTheDocument();

    rerender(<QuickBillDetails invoice={{...invoice, reference: 'test reference'}} />);
    expect(screen.getByText('Reference')).toBeVisible();
    expect(screen.getByText('test reference')).toBeVisible();
  });

  it('shows attachments if they are added to the invoice', () => {
    const attachments = [
      {id:'123abc', fileName: 'test1.png'},
      {id:'987zyx', fileName: 'test2.docx'},
      {id:'fooboo', fileName: 'test3.png'}
    ];
    render(<QuickBillDetails invoice={{...invoice, attachments}} />);
    attachments.forEach(({fileName}) => expect(screen.getByText(fileName)).toBeVisible());
  });

  it('shows client values if attributes found in invoice', () => {
    const {rerender} = render(<QuickBillDetails invoice={invoice} />);
    expect(screen.getByText('Bob Loblaw')).toBeVisible();
    expect(screen.getByText('foo@bar.com')).toBeVisible();

    rerender(
      <QuickBillDetails
        invoice={{
          ...invoice,
          invoiceMessages: [{
            ...invoice.invoiceMessages[0],
            emailAddresses: []
          }]
        }}
      />
    );

    expect(screen.getByText('Bob Loblaw')).toBeVisible();

    rerender(
      <QuickBillDetails
        invoice={{
          ...invoice,
          invoiceMessages: [{
            ...invoice.invoiceMessages[0],
            emailAddresses: []
          }],
          contact: {name: null}
        }}
      />
    );

    expect(screen.queryByText('Client')).not.toBeInTheDocument();
  });
});