import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ContactSearchField, {wrapContactListItems, contactToString} from '../ContactSearchField';
import * as ContactsService from '../customHooks';
const contact = {
  email: 'foo@bar.com',
  email_id: 'eml_ClEwijmLfCph57Wh5oTij',
  name: 'Foo Bar',
  id: 'p_Q7U09Db0PIVMRz3Xa2jwE'
};

describe('contactToString', () => {
  it('take a contact and returns an email if one is present', () => {
    expect(contactToString(contact)).toBe('foo@bar.com');
    expect(contactToString({...contact, email: undefined})).toBe('');
  });
});

describe('wrapContactListItems', () => {
  let wrapContactListItemsArguments, highlightedIndex, getItemProps;
  beforeEach(() => {
    highlightedIndex = 1;
    getItemProps = jest.fn();
    wrapContactListItemsArguments = [highlightedIndex, getItemProps];
  });


  it('returns a closure', () => {
    expect(typeof wrapContactListItems(...wrapContactListItemsArguments)).toBe('function');
  });

  it('the returned closure creates a list item with the correct values', () => {
    const mapContactListItem = wrapContactListItems(...wrapContactListItemsArguments);
    render(mapContactListItem(contact, 1));
    expect(screen.getByTestId('contact-search-list-item')).toBeVisible;
    expect(screen.getByText('Foo Bar foo@bar.com')).toBeVisible();
  });

  it('the returned closure adds a class if the highlighted index matches the focused list item index', () => {
    const focusedContactListItem = wrapContactListItems(...wrapContactListItemsArguments);
    render(focusedContactListItem(contact, 1));
    const focusedListItem = screen.getByTestId('contact-search-list-item');
    expect(focusedListItem).toHaveClass('ap-combobox-list-item-highlight');
    const unfocusedContactListItem = wrapContactListItems(...wrapContactListItemsArguments);
    render(unfocusedContactListItem(contact, 2));
    expect(focusedListItem).toHaveClass('ap-combobox-list-item-highlight');
  });
});

describe('ContactSearchField', () => {
  const contacts = [
    {
      email: 'foo@bar.com',
      email_id: 'eml_ClEwijmLfCph57Wh5oTij',
      name: 'Foo Bar',
      id: 'p_Q7U09Db0PIVMRz3Xa2jwE'
    },
    {
      email: 'foo@baz.com',
      email_id: 'eml_7UFItowWzv7uGI3Ci1QZl',
      name: 'Foo Baz',
      id: 'p_G8TF2OXWEtz2u7Ty8BVdf'},
    {
      email: 'foo@buz.com',
      email_id: 'eml_FbZjRLmzeSsUYMRtuZs8T',
      name: 'buz',
      id: 'p_4SSXc8iFvpRa4bRy5nppF'
    }
  ];
  beforeEach(() => {
    jest.spyOn(ContactsService, 'useFetchContacts').mockReturnValue({
      loading: false,
      data: contacts,
      error: undefined
    });
  });
  const handleSelect = jest.fn();
  const handleChange = jest.fn();

  it('renders ', () => {
    render(<ContactSearchField onSelect={handleSelect} onChange={handleChange} />);
    expect(screen.getAllByLabelText('Client Email')[0]).toBeVisible();
    expect(screen.getByTestId('apcomboboxemail')).toBeVisible();
  });

  it('invokes passed prop of onChange', () => {
    const handleChangeTest = jest.fn();
    render(<ContactSearchField onSelect={handleSelect} onChange={handleChangeTest} />);
    const input = screen.getByTestId('apcomboboxemail');
    userEvent.type(input, 'bar@foo.com');
    expect(input.value).toBe('bar@foo.com');
    expect(handleChangeTest).toHaveBeenCalledTimes(11);
  });
});