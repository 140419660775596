
import { act, render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import UpfPaymentPage from '../index';
import { customFieldsWithSurcharge } from './UpfPaymentPageTestProps';
import { getAPSelect, simulateSelectEventInAPSelect } from './testHelpers';

jest.mock('../../../lib/utils', () => ({
  ...jest.requireActual('../../../lib/utils'),
  rollbarLog: jest.fn(),
  safeSendGA: jest.fn()
}));

describe('UPFPaymentPage with Custom Fields', () => {

  beforeEach(() => {
    jest.spyOn(window.navigator, 'languages', 'get').mockReturnValue(['en-US']);
  });

  it('Form Renders with correct set of fields and correct default form state for Card account', async () => {
    const { container } = render(<UpfPaymentPage {...customFieldsWithSurcharge} />);
    const upf = container.querySelector('upf-checkout');

    //Custom Amount Fields
    expect(screen.getByText('Checkbox Static text $5 - $5.00')).toBeVisible();
    expect(screen.getAllByText('help')[0]).toBeVisible();
    expect(screen.getByText('Radio Static text $5 - $5.00')).toBeVisible();
    expect(screen.getByText('Amount Section')).toBeVisible();
    expect(screen.getByText('Subtotal Zero - $0.00')).toBeVisible();
    expect(screen.getByText('Only Amount - $100.00')).toBeVisible();
    expect(screen.getByLabelText('Blank')).toBeVisible();
    expect(screen.getByLabelText('Amount and qty (2 and 3)')).toBeVisible();
    expect(screen.getByText('Amount% 20 - $20.00')).toBeVisible();
    expect(screen.getByText('Subtotal of section B - $120.00')).toBeVisible();
    expect(screen.getByText('Amount% 10 off - -$12.00')).toBeVisible();
    expect(screen.getByText('Custom Total Amount - $108.00')).toBeVisible();

    //Custom Fields
    expect(screen.getByText('Additional Information')).toBeVisible();
    expect(screen.getByLabelText('tf not require')).toBeVisible();
    expect(screen.getByLabelText('tf require')).toBeVisible();
    expect(screen.getByLabelText('ta not require')).toBeVisible();
    expect(screen.getByLabelText('ta req')).toBeVisible();
    expect(screen.getByText('ch not require')).toBeVisible();
    expect(screen.getByText('ch req')).toBeVisible();
    expect(getAPSelect('s not req')).toBeVisible();
    expect(getAPSelect('s req')).toBeVisible();
    expect(screen.getByText('defval')).toBeVisible();
    expect(screen.queryByText('tf require is required.')).not.toBeInTheDocument();

    // upf custom fields property
    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'Only Amount': '100',
        'Amount% 20': 20,
        'Amount% 10 off': -12,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });
  });

  it('Form Renders correct currency symbol when currency is USD and user is in canada', async () => {
    jest.spyOn(window.navigator, 'languages', 'get').mockReturnValue(['en-CA']);
    render(<UpfPaymentPage {...customFieldsWithSurcharge} />);

    //Custom Amount Fields
    expect(screen.getByText('Checkbox Static text $5 - US$5.00')).toBeVisible();
    expect(screen.getByText('Radio Static text $5 - US$5.00')).toBeVisible();
    expect(screen.getByText('Subtotal Zero - US$0.00')).toBeVisible();
    expect(screen.getByText('Only Amount - US$100.00')).toBeVisible();
    expect(screen.getByText('Amount% 20 - US$20.00')).toBeVisible();
    expect(screen.getByText('Subtotal of section B - US$120.00')).toBeVisible();
    expect(screen.getByText('Amount% 10 off - -US$12.00')).toBeVisible();
    expect(screen.getByText('Custom Total Amount - US$108.00')).toBeVisible();
  });

  it('Form Renders correct currency symbol when currency is CAD and user is in US', async () => {
    render(<UpfPaymentPage {...{...customFieldsWithSurcharge, bank_account_currency: 'CAD'}} />);

    //Custom Amount Fields
    expect(screen.getByText('Checkbox Static text $5 - CA$5.00')).toBeVisible();
    expect(screen.getByText('Radio Static text $5 - CA$5.00')).toBeVisible();
    expect(screen.getByText('Subtotal Zero - CA$0.00')).toBeVisible();
    expect(screen.getByText('Only Amount - CA$100.00')).toBeVisible();
    expect(screen.getByText('Amount% 20 - CA$20.00')).toBeVisible();
    expect(screen.getByText('Subtotal of section B - CA$120.00')).toBeVisible();
    expect(screen.getByText('Amount% 10 off - -CA$12.00')).toBeVisible();
    expect(screen.getByText('Custom Total Amount - CA$108.00')).toBeVisible();
  });

  it('Form Renders correct currency symbol when currency is CAD and user is in CA', async () => {
    jest.spyOn(window.navigator, 'languages', 'get').mockReturnValue(['en-CA']);
    render(<UpfPaymentPage {...{...customFieldsWithSurcharge, bank_account_currency: 'CAD'}} />);

    //Custom Amount Fields
    expect(screen.getByText('Checkbox Static text $5 - $5.00')).toBeVisible();
    expect(screen.getByText('Radio Static text $5 - $5.00')).toBeVisible();
    expect(screen.getByText('Subtotal Zero - $0.00')).toBeVisible();
    expect(screen.getByText('Only Amount - $100.00')).toBeVisible();
    expect(screen.getByText('Amount% 20 - $20.00')).toBeVisible();
    expect(screen.getByText('Subtotal of section B - $120.00')).toBeVisible();
    expect(screen.getByText('Amount% 10 off - -$12.00')).toBeVisible();
    expect(screen.getByText('Custom Total Amount - $108.00')).toBeVisible();
  });


  it('Form Renders with correct set of fields and correct default form state for Echeck account', () => {
    const { container } = render(<UpfPaymentPage {...customFieldsWithSurcharge} />);
    const upf = container.querySelector('upf-checkout');

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'Only Amount': '100',
        'Amount% 20': 20,
        'Amount% 10 off': -12,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });

    act(() => {
      const event = new CustomEvent('UPFPaymentMethodChange', { detail: { paymentMethod: 'ach' } });
      window.document.dispatchEvent(event);
    });

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'Only Amount': '100',
        'Amount% 20': 20,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });

    //Custom Amount Fields
    expect(screen.getByText('Radio $4 Max Quantity 20 - $4.00')).toBeVisible();
    expect(screen.getByText('Radio Input')).toBeVisible();
    expect(screen.getByText('Subtotal of section A - $0.00')).toBeVisible();
    expect(screen.getByText('Subtotal Zero - $0.00')).toBeVisible();
    expect(screen.getByText('Only Amount - $100.00')).toBeVisible();
    expect(screen.getByLabelText('Blank')).toBeVisible();
    expect(screen.getByLabelText('Amount and qty (2 and 3)')).toBeVisible();
    expect(screen.getByText('Amount% 20 - $20.00')).toBeVisible();
    expect(screen.getByText('Custom Total Amount - $120.00')).toBeVisible();

    //Custom fields are same as card and are tested in a previous test
  });

  it('Required custom fields are passed to UPF in errors attribute', () => {
    const { container } = render(<UpfPaymentPage {...customFieldsWithSurcharge} />);
    const upf = container.querySelector('upf-checkout');

    const expectedErrors = ['tf require is required', 'ta req is required', 'ch req is required', 's req is required'];
  
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));

    //Filling out the required custom fields
    userEvent.type(screen.getByLabelText('tf require'), 'A');
    userEvent.type(screen.getByLabelText('ta req'), 'T');
    userEvent.click(screen.getByLabelText('ch req'));
    simulateSelectEventInAPSelect('s req', 'eo');

    expect(upf.getAttribute('errors')).toEqual('');
  });

  it('Required custom amount fields with max quantity and amount are passed to UPF in errors attribute when inactive or 0', () => {
    const props = {...customFieldsWithSurcharge, custom_fields: []};
    props['amount_fields'] = [
      {
        amount: '5',
        custom_field: 'amount max required field',
        custom_value: '',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: 10,
        name: 'amount max required field',
        options: '',
        placeholder: '',
        position: 1,
        required: 2,
        type: 'amount_checkbox'
      }
    ];
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');

    const expectedErrors = ['amount max required field is required'];
  
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));
    userEvent.click(screen.getByTestId('amount max required field'));
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));
    userEvent.click(screen.getByTestId('increase-quantity-button'));
    expect(upf.getAttribute('errors')).toEqual('');
    userEvent.click(screen.getByTestId('decrease-quantity-button'));
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));
  });

  it('Required custom amount fields are passed to UPF in errors attribute', () => {
    const props = {...customFieldsWithSurcharge};
    props['amount_fields'] = [
      {
        amount: '',
        custom_field: 'amount required field',
        custom_value: '',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'amount required field',
        options: '',
        placeholder: '',
        position: 1,
        required: 2,
        type: 'amount'
      }
    ];
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');

    const expectedErrors = ['tf require is required', 'ta req is required', 'ch req is required', 's req is required', 'amount required field is required'];
  
    expect(upf.getAttribute('errors')).toEqual(expectedErrors.join(', '));

    //Filling out the required custom fields
    userEvent.type(screen.getByLabelText('amount required field'), '10');
    userEvent.type(screen.getByLabelText('tf require'), 'A');
    userEvent.type(screen.getByLabelText('ta req'), 'T');
    userEvent.click(screen.getByLabelText('ch req'));
    simulateSelectEventInAPSelect('s req', 'eo');

    expect(upf.getAttribute('errors')).toEqual('');
  });

  it('Passes current_user_id as user_id to customData', () => {
    const { container } = render(<UpfPaymentPage {...customFieldsWithSurcharge} current_user_id='test_user_id' />);
    const upf = container.querySelector('upf-checkout');
    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      user_id: 'test_user_id',
      custom_fields: {
        'Only Amount': '100',
        'Amount% 20': 20,
        'Amount% 10 off': -12,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });
  });

  it('Custom Amount Field interactions and calculations', () => {
    const { container } = render(<UpfPaymentPage {...customFieldsWithSurcharge} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf.getAttribute('amount')).toEqual('10800');

    userEvent.type(screen.getByLabelText('Blank'), '100');
    expect(upf.getAttribute('amount')).toEqual('21600');


    userEvent.click(screen.getByTestId('Checkbox Static text $5'));
    expect(upf.getAttribute('amount')).toEqual('22140');
    userEvent.click(screen.getByTestId('Checkbox Static text $5'));
    expect(upf.getAttribute('amount')).toEqual('21600');
    userEvent.click(screen.getByTestId('Radio Static text $5'));
    expect(upf.getAttribute('amount')).toEqual('22140');

    //over max allowed, should be ignored
    const amountAndQty2and3 = screen.getByLabelText('Amount and qty (2 and 3)');
    userEvent.type(amountAndQty2and3, '9');
    expect(upf.getAttribute('amount')).toEqual('22140');
    userEvent.type(amountAndQty2and3, '3');
    expect(upf.getAttribute('amount')).toEqual('22788');

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'Radio Static text $5': '5',
        'Only Amount': '100',
        'Blank': '100.00',
        'Amount and qty (2 and 3)': [ '3', 6 ],
        'Amount% 20': 42.2,
        'Amount% 10 off': -25.32,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });

    act(() => {
      const event = new CustomEvent('UPFPaymentMethodChange', { detail: { paymentMethod: 'ach' } });
      window.document.dispatchEvent(event);
    });

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'Only Amount': '100',
        'Blank': '100.00',
        'Amount and qty (2 and 3)':  [ '3', 6 ],
        'Amount% 20': 41.2,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });

    expect(upf.getAttribute('amount')).toEqual('24720');
  });

  it('Custom Amount Field fractional percentage case', () => {
    const props = { ...customFieldsWithSurcharge };
    props['amount_fields'] = [
      {
        amount: '',
        custom_field: 'customAmount',
        custom_value: 'custom valuez',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'customAmount',
        options: '',
        placeholder: '',
        position: 25,
        required: 1,
        type: 'amount'
      },
      {
        amount: '.015',
        custom_field: 'Amount% 1.5',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'percent',
        options: '',
        placeholder: '',
        position: 27,
        required: 1,
        type: 'amount_percent'
      }];
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf.getAttribute('amount')).toEqual('0');

    userEvent.type(screen.getByLabelText('customAmount'), '33');

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'customAmount': '33',
        'percent': 0.5,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });
    expect(upf.getAttribute('amount')).toEqual('3350');

    userEvent.clear(screen.getByLabelText('customAmount'));
    userEvent.type(screen.getByLabelText('customAmount'), '15');

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'customAmount': '15',
        'percent': 0.23,
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });
    expect(upf.getAttribute('amount')).toEqual('1523');
  });

  it('Custom Amount Field subtotal and total fields', () => {
    //subtotal needs to show sum of everything above itself up to the nearest section
    //total needs to show sum of everything above itself
    const props = { ...customFieldsWithSurcharge };
    props['amount_fields'] = [
      {
        amount: '',
        custom_field: 'customAmount',
        custom_value: 'custom valuez',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'customAmount',
        options: '',
        placeholder: '',
        position: 1,
        required: 1,
        type: 'amount'
      },
      {
        amount: '100',
        custom_field: 'Only Amount',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Only Amount',
        options: '',
        placeholder: '',
        position: 2,
        required: 1,
        type: 'amount'
      },
      {
        amount: '',
        custom_field: 'Amount Section',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Amount Section',
        options: '',
        placeholder: '',
        position: 3,
        required: 1,
        type: 'amount_section'
      },
      {
        amount: '6',
        custom_field: 'Subtotal Zero',
        custom_value: '',
        default_value: '',
        help_text: 'help',
        incompatible_payment_types: [],
        max_quantity: 60,
        name: 'Subtotal Zero',
        options: '',
        placeholder: '',
        position: 4,
        required: 1,
        type: 'amount_subtotal'
      },
      {
        amount: '',
        custom_field: 'Custom Total Amount',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Custom Total Amount',
        options: '',
        placeholder: '',
        position: 5,
        required: 1,
        type: 'amount_total'
      },
      {
        amount: '5',
        custom_field: 'Another Amount',
        custom_value: '',
        default_value: '',
        help_text: '',
        incompatible_payment_types: [],
        max_quantity: null,
        name: 'Another Amount',
        options: '',
        placeholder: '',
        position: 6,
        required: 1,
        type: 'amount'
      }
    ];

    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');

    expect(screen.getByText('Subtotal Zero - $0.00')).toBeInTheDocument();
    expect(screen.getByText('Custom Total Amount - $100.00')).toBeInTheDocument();

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'Only Amount': '100',
        'Another Amount': '5',
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });
    expect(upf.getAttribute('amount')).toEqual('10500');
  });

  it('Quantity custom field amount testing for floating point math error #1', () => {
    const { container } = render(<UpfPaymentPage {...getQtyFieldProps()}/>);
    const upf = container.querySelector('upf-checkout');

    userEvent.type(screen.getByLabelText('test amount'), '5');

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'test amount': [ '5', 64.8 ],
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });
    expect(upf.getAttribute('amount')).toEqual('6480');
  });

  it('Quantity custom field amount testing for floating point math error #2', () => {
    const { container } = render(<UpfPaymentPage {...getQtyFieldProps()}/>);
    const upf = container.querySelector('upf-checkout');
    userEvent.type(screen.getByLabelText('test amount'), '10');

    expect(upf['custom-data']).toEqual({
      payment_page_id: 'test_pp_id',
      custom_fields: {
        'test amount': [ '10', 129.6 ],
        'tf not require': 'val',
        'tf hidden': 'default value',
        'ta not require': 'default value',
        's not req': 'defval'
      }
    });
    expect(upf.getAttribute('amount')).toEqual('12960');
  });
});

const getQtyFieldProps = () => {
  const props = { ...customFieldsWithSurcharge };
  props['amount_fields'] = [
    {
      amount: '',
      custom_field: 'name',
      custom_value: '',
      default_value: '',
      help_text: 'help',
      incompatible_payment_types: [],
      max_quantity: null,
      name: 'name',
      options: '',
      placeholder: '',
      position: 1,
      required: 1,
      type: 'amount'
    },
    {
      amount: '12.96',
      custom_field: 'test amount',
      custom_value: '',
      default_value: '',
      help_text: '',
      incompatible_payment_types: [],
      max_quantity: 12,
      name: 'test amount',
      options: '',
      placeholder: '',
      position: 2,
      required: 1,
      type: 'amount'
    }];
  return props;
};