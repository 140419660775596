import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import ClientCreditCheckbox from '../';

describe('ClientCreditCheckbox', () => {
  const vanillaProps = {
    brandTitle: 'LawPay',
    brandLink: 'https://lawpay.com',
    checkboxName: 'test_cc_intent',
    clientCreditIntent: null,
    isCaliforniaBusiness: false,
    supportPhone: '(512) 123-4567'
  };

  it('renders', () => {
    render(<ClientCreditCheckbox {...vanillaProps} />);

    const textInRender = [
      'I want to accept payment cards',
      'I want to activate Pay Later',
      'Get paid 100% up front, while your clients pay installments over time',
      'No need to follow up on client payments',
      'Expand your client base with additional payment options',
      '(512) 123-4567'
    ];
    textInRender.forEach(text => expect(screen.getByText(text)).toBeVisible());
  });

  it('clicking on the "Learn More" text opens the client credit modal', async () => {
    render(<ClientCreditCheckbox {...vanillaProps} />);
    const textInModal = [
      'Why is Pay Later a good option for you and your firm?',
      'How does Pay Later work?',
      'this link'
    ];
    await waitFor(() => userEvent.click(screen.getByText('Learn more about Pay Later')));
    textInModal.forEach(text => expect(screen.getByText(text)).toBeInTheDocument());
  });

  it('state of the checkbox is checked when clientCreditIntent is null', () => {
    render(<ClientCreditCheckbox {...vanillaProps} clientCreditIntent={null} />);
    const checkbox = screen.getByTestId('client-credit-checkbox');
    expect(checkbox.value).toEqual('true');
  });

  it('state of the checkbox is checked when clientCreditIntent is true', () => {
    render(<ClientCreditCheckbox {...vanillaProps} clientCreditIntent={true} />);
    const checkbox = screen.getByTestId('client-credit-checkbox');
    expect(checkbox.value).toEqual('true');
  });

  it('state of the checkbox is unchecked when clientCreditIntent is false', () => {
    render(<ClientCreditCheckbox {...vanillaProps} clientCreditIntent={false} />);
    const checkbox = screen.getByTestId('client-credit-checkbox');
    expect(checkbox.value).toEqual('false');
  });

  it('state of the checkbox is unchecked when clientCreditIntent is falsy and business is non-Californian', () => {
    const {rerender} = render(<ClientCreditCheckbox {...vanillaProps} clientCreditIntent={undefined} />);
    expect(screen.getByTestId('client-credit-checkbox').value).toEqual('false');

    rerender(<ClientCreditCheckbox {...vanillaProps} clientCreditIntent={[]} />);
    expect(screen.getByTestId('client-credit-checkbox').value).toEqual('false');

    rerender(<ClientCreditCheckbox {...vanillaProps} clientCreditIntent='' />);
    expect(screen.getByTestId('client-credit-checkbox').value).toEqual('false');
  });

  it('California businesses in LawPay have box unchecked when clientCreditIntent is null', () => {
    render(<ClientCreditCheckbox {...vanillaProps} isCaliforniaBusiness={true} />);
    const checkbox = screen.getByTestId('client-credit-checkbox');
    expect(checkbox.value).toEqual('false');
  });

  it('California businesses in CPACharge have box checked when clientCreditIntent is null', () => {
    render(<ClientCreditCheckbox {...vanillaProps} isCaliforniaBusiness={true} brandTitle="CPA Charge" />);
    const checkbox = screen.getByTestId('client-credit-checkbox');
    expect(checkbox.value).toEqual('true');
  });

  // This test is necessary for the functionality to work in Rails
  it('checkbox changes the checkbox value of the visible and hidden checkboxes', () => {
    render(<ClientCreditCheckbox {...vanillaProps} clientCreditIntent={false} />);
    const checkbox = screen.getByTestId('client-credit-checkbox');
    const hiddenCheckbox = screen.getByTestId('hidden-client-credit-checkbox');

    expect(checkbox.value).toEqual('false');
    expect(hiddenCheckbox.value).toEqual('false');

    userEvent.click(checkbox);

    expect(hiddenCheckbox.value).toEqual('true');
    expect(checkbox.value).toEqual('true');
  });
});
