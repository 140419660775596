import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import FeatureTogglePage from '../FeatureTogglePage';
import FeatureToggles from '../index';
import { features, featureGates } from '../helpers/fixtures';
import * as service from '../helpers/services';

describe('Feature toggle modal tests', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
    jest.spyOn(service, 'getFeatureGates').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(featureGates);
        })
      })
    );
  });

  it('clicking create feature button shows add feature modal', () => {
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getByText('Create Feature'));
    expect(screen.getByText('Add Feature')).toBeInTheDocument();
  });

  it('create button in add feature modal is disabled if name input has no value', async () => {
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getByText('Create Feature'));
    expect(screen.getByText('Create', {exact: true})).toBeDisabled();
  });

  it('create button in add feature modal is not disabled if name input has value', () => {
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getByText('Create Feature'));
    userEvent.type(screen.getByLabelText('Feature Name'), 'test');
    expect(screen.getByText('Create', {exact: true})).not.toBeDisabled();
  });

  it('Creating new feature in modal shows gates table for that feature', async () => {
    jest.spyOn(service, 'createFeature').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({data: features.data[1]});
        })
      })
    );
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getByText('Create Feature'));
    userEvent.type(screen.getByLabelText('Feature Name'), 'test');
    userEvent.click(screen.getByText('Create', {exact: true}));
    await waitFor(() => expect(service.createFeature).toHaveBeenCalledTimes(1));
    expect(screen.queryByText('Feature Toggles')).not.toBeInTheDocument();
    expect(screen.getByText(features.data[1].attributes.key)).toBeInTheDocument();
  });

  it('shows form error box if there is an error creating feature in modal', async () => {
    jest.spyOn(service, 'createFeature').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getByText('Create Feature'));
    userEvent.type(screen.getByLabelText('Feature Name'), 'test');
    userEvent.click(screen.getByText('Create', {exact: true}));
    const errorText = await screen.findByText('test error');
    expect(errorText).toBeInTheDocument();
  });

  it('shows form error box if there is a 500 error creating feature in modal', async () => {
    jest.spyOn(service, 'createFeature').mockImplementation(() => {
      throw new Error();
    });
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getByText('Create Feature'));
    userEvent.type(screen.getByLabelText('Feature Name'), 'test');
    userEvent.click(screen.getByText('Create', {exact: true}));
    const errorText = await screen.findByText('Error creating feature');
    expect(errorText).toBeInTheDocument();
  });

  it('Clicking add actor button shows add feature gate modal', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Add Actor'));
    expect(screen.getAllByText('Add Actor')[1]).toBeInTheDocument();
  });

  it('create button in add feature gate modal is disabled if name input has no value', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Add Actor'));
    expect(screen.getByText('Create', {exact: true})).toBeDisabled();
  });

  it('create button in add feature gate modal is not disabled if name input has value', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Add Actor'));
    userEvent.type(screen.getByLabelText('Actor Name'), 'test');
    expect(screen.getByText('Create', {exact: true})).not.toBeDisabled();
  });

  it('refreshes data when new feature gate is created', async () => {
    jest.spyOn(service, 'createFeatureGate').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({data: featureGates.data[0]});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Add Actor'));
    userEvent.type(screen.getByLabelText('Actor Name'), 'test');
    userEvent.click(screen.getByText('Create', {exact: true}));
    await waitFor(() => expect(service.createFeatureGate).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(2));
  });

  it('shows form error box if there is an error creating feature gate', async () => {
    jest.spyOn(service, 'createFeatureGate').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Add Actor'));
    userEvent.type(screen.getByLabelText('Actor Name'), 'test');
    userEvent.click(screen.getByText('Create', {exact: true}));
    const errorText = await screen.findByText('test error');
    expect(errorText).toBeInTheDocument();
  });

  it('shows form error box if there is a 500 error creating feature gate', async () => {
    jest.spyOn(service, 'createFeatureGate').mockImplementation(() => {
      throw new Error();
    });
    render(<FeatureTogglePage feature={features.data[0].attributes} />);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByText('Add Actor'));
    userEvent.type(screen.getByLabelText('Actor Name'), 'test');
    userEvent.click(screen.getByText('Create', {exact: true}));
    const errorText = await screen.findByText('Error enabling feature for actor');
    expect(errorText).toBeInTheDocument();
  });

  it('shows delete feature gate modal when clicking on a gate row delete button', async () => {
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getAllByText('Delete', {exact: true})[0]);
    expect(screen.getByText('Disable Feature for this Actor')).toBeInTheDocument();
  });

  it('closes delete feature gate modal and refreshes data on delete', async () => {
    jest.spyOn(service, 'deleteFeatureGate').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({data: features.data[0]});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getAllByText('Delete', {exact: true})[0]);
    userEvent.click(screen.getByText('Remove'));
    await waitFor(() => expect(service.deleteFeatureGate).toHaveBeenCalledTimes(1));
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(2));
    expect(screen.getByRole('table')).toBeInTheDocument();
  });

  it('Shows a form error box if there is an error deleting a feature gate', async () => {
    jest.spyOn(service, 'deleteFeatureGate').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getAllByText('Delete', {exact: true})[0]);
    userEvent.click(screen.getByText('Remove'));
    const errorText = await screen.findByText('test error');
    expect(errorText).toBeInTheDocument();
  });

  it('Shows a form error box if there is an 500 error deleting a feature gate', async () => {
    jest.spyOn(service, 'deleteFeatureGate').mockImplementation(() => {
      throw new Error();
    });
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getAllByText('Delete', {exact: true})[0]);
    userEvent.click(screen.getByText('Remove'));
    const errorText = await screen.findByText('Error removing actor from feature');
    expect(errorText).toBeInTheDocument();
  });

  it('Shows a form error box if there is an 500 error deleting a feature', async () => {
    jest.spyOn(service, 'deleteFeature').mockImplementation(() => {
      throw new Error();
    });
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));

    userEvent.click(screen.getByText('Delete Feature'));
    userEvent.click(screen.getByText('Remove'));
    await waitFor(() => expect(service.deleteFeature).toHaveBeenCalledTimes(1));

    const errorText = await screen.findByText('Error deleting feature');
    expect(errorText).toBeInTheDocument();
  });

  it('Shows form error box if there is an error deleting feature', async () => {
    jest.spyOn(service, 'deleteFeature').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<FeatureTogglePage feature={features.data[0].attributes}/>);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));

    userEvent.click(screen.getByText('Delete Feature'));
    userEvent.click(screen.getByText('Remove'));
    await waitFor(() => expect(service.deleteFeature).toHaveBeenCalledTimes(1));

    const errorText = await screen.findByText('test error');
    expect(errorText).toBeInTheDocument();
  });
});
