
import { render, screen, waitFor } from '@testing-library/react';
import React from 'react';
import * as service from '../helpers/services';
import UpfPaymentPage from '../index';
import { noCustomFieldsNoSurcharge, mockChargeResponse } from './UpfPaymentPageTestProps';

jest.mock('../../../lib/utils', () => ({
  ...jest.requireActual('../../../lib/utils'),
  rollbarLog: jest.fn(),
  safeSendGA: jest.fn()
}));

//https://developers.affinipay.com/merchant/hosted-payment-pages.html
const allParams = {
  name: 'John Doe',
  amount: '25',
  address1: '123 Main st',
  address2: 'Apt 5',
  email: 'test@gmail.com',
  city: 'Austin',
  state: 'TX',
  postal_code: '12345',
  phone: '515-151-5151',
  reference: 'Payment for services',
  country: 'US',
  Invoice: 'test invoice'
};

describe('Payment Page Url parameters', () => {
  beforeEach(() => {
    jest.spyOn(window.navigator, 'languages', 'get').mockReturnValue(['en-US']);
  });

  afterEach(() => {
    jest.restoreAllMocks();
  });

  const props = { ...noCustomFieldsNoSurcharge };
  const propsWithSignature = { ...noCustomFieldsNoSurcharge, signature: 2 };
  props['custom_fields'] = [
    {
      custom_field: 'phone',
      default_value: '',
      help_text: '',
      name: 'phone',
      options: '',
      placeholder: '',
      position: 0,
      required: 1,
      type: 'text_field'
    },
    {
      custom_field: 'Invoice',
      default_value: '',
      help_text: '',
      name: 'Invoice',
      options: '',
      placeholder: '',
      position: 1,
      required: 1,
      type: 'text_field'
    }
  ];

  it('Presetting all fields', () => {
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => allParams[key]);
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf['default-fields']).toEqual(expect.objectContaining({
      name: 'John Doe',
      given_name: 'John',
      surname: 'Doe',
      address1: '123 Main st',
      address2: 'Apt 5',
      email: 'test@gmail.com',
      city: 'Austin',
      state: 'TX',
      postal_code: '12345',
      phone: '515-151-5151',
      reference: 'Payment for services',
      country: 'US',
      Invoice: 'test invoice'
    }));
    expect(upf.getAttribute('amount')).toEqual('2500');
    expect(screen.getByLabelText('Payment Amount')).toHaveValue('25');
    expect(screen.getByLabelText('Invoice')).toHaveValue('test invoice');
    expect(screen.getByLabelText('phone')).toHaveValue('515-151-5151');
    expect(screen.getByLabelText('Reference')).toHaveValue('Payment for services');
  });

  it('Correctly parses $ and commas in amount pre filled fields', () => {
    const params = { amount: '$1,000' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');
    expect(screen.getByLabelText('Payment Amount')).toHaveValue('1,000');
    expect(upf.getAttribute('amount')).toEqual('100000');
  });

  it('Ignores amount pre filled field if it is not a number', () => {
    const params = { amount: 'not a number' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');
    expect(screen.getByLabelText('Payment Amount')).toHaveValue('');
    expect(upf.getAttribute('amount')).toEqual('0');
  });

  it('Correctly parses + and %20 characters representing spaces in pre filled fields', () => {
    const params = { address1: '123%20main%20street', address2: 'apt+123' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf['default-fields']).toEqual(expect.objectContaining({address1: '123 main street', address2: 'apt 123'}));
  });

  it('Pre fills name fields based on name pre filled field', () => {
    const params = { name: 'Test Tester' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf['default-fields']).toEqual(expect.objectContaining({name: 'Test Tester', given_name: 'Test', surname: 'Tester'}));
  });

  it('Fields with values get disabled via readOnlyParams, fields without values dont get disabled', () => {
    const {name, ...params } =  allParams;
    params['readOnlyFields'] = 'name, amount, address1,%20address2,email, city,state,postal_code,phone,reference,country';
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');

    expect(screen.getByLabelText('Payment Amount')).toBeDisabled();
    expect(screen.getByLabelText('Reference')).toBeDisabled();
    expect(upf.getAttribute('disabled-fields').split(',')).toEqual(expect.arrayContaining(['email', 'address1','city','state','postal_code','country', 'city', 'phone', 'amount']));
    expect(upf.getAttribute('disabled-fields').includes('name')).toEqual(false);
  });

  it('Disables name, first name, and last name inputs when name is a disabled field in url query params', () => {
    const params = { name: 'test name', readOnlyFields: 'name' };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);
    const upf = container.querySelector('upf-checkout');
    expect(upf.getAttribute('disabled-fields').split(',')).toEqual(expect.arrayContaining(['name', 'given_name', 'surname']));
  });

  it('Amount field gets disabled from legacy field true', () => {
    const params = {
      amount_ro: 'true',
      amount: '15'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<UpfPaymentPage {...props} />);
    expect(screen.getByLabelText('Payment Amount')).toBeDisabled();
  });

  it('Amount field gets disabled from legacy field 1', () => {
    const params = {
      amount_ro: '1',
      amount: '15'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    render(<UpfPaymentPage {...props} />);
    expect(screen.getByLabelText('Payment Amount')).toBeDisabled();
  });

  it('Request signature feature redirects to signature page when page has electronic signature and the token call returns ok', async () => {
    const params = {
      sig: 'iamavalidsignaturetoken'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockResolvedValue({
      status: 200,
      response: true,
      ok: true,
      json: jest.fn(() => {
        return Promise.resolve({
          receipt_html: '<div>foobar receipt</div>',
          charge: mockChargeResponse
        });
      })
    });
    render(<UpfPaymentPage {...propsWithSignature} />);
    await waitFor(() => expect(service.processSignatureLink).toHaveBeenCalledTimes(1));
    expect(screen.getByTestId('signature')).toBeInTheDocument();
  });

  it('Request signature feature redirects to receipt if the page has line signature and token call returns ok', async () => {
    const params = {
      sig: 'iamavalidsignaturetoken'
    };
    const newProps = { ...props, signature: 1 };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({
            receipt_html: '<div>foobar receipt</div>',
            charge: mockChargeResponse
          });
        })
      })
    );
    render(<UpfPaymentPage {...newProps} />);
    await waitFor(() => expect(service.processSignatureLink).toHaveBeenCalledTimes(1));
    const thankYouPage = screen.getByTestId('thank-you-page'), receipt = screen.getByText('foobar receipt');
    expect(thankYouPage).toBeInTheDocument();
    expect(receipt).toBeInTheDocument();
  });

  it('Request signature feature does not redirect to receipt if the token is returned as invalid', async () => {
    const params = {
      sig: 'iamnotavalidtoken'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockRejectedValue({
      status: 404,
      response: true,
      ok: false,
      json: jest.fn(() => {
        return Promise.resolve({
          receipt_html: '<div>foobar receipt</div>',
          charge: mockChargeResponse
        });
      })
    });
    render(<UpfPaymentPage {...propsWithSignature} />);
    await waitFor(() => expect(service.processSignatureLink).toHaveBeenCalledTimes(1));

    const signaturePage = screen.queryByTestId('signature');
    expect(signaturePage).not.toBeInTheDocument();
  });

  it('Request signature feature does not redirect to receipt if the server cannot be reached for token validation', async () => {
    const params = {
      sig: 'iamnotavalidtoken'
    };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockRejectedValue({
      status: 500,
      response: false,
      ok: false,
      json: jest.fn(() => {
        return Promise.resolve({
          receipt_html: '<div>foobar receipt</div>',
          charge: mockChargeResponse
        });
      })
    });
    render(<UpfPaymentPage {...propsWithSignature} />);
    await waitFor(() => expect(service.processSignatureLink).toHaveBeenCalledTimes(1));

    const signaturePage = screen.queryByTestId('signature');
    expect(signaturePage).not.toBeInTheDocument();
  });

  it('Request signature feature redirects to receipt if the page has no signature and token call returns ok', async () => {
    const params = {
      sig: 'iamavalidsignaturetoken'
    };
    const newProps = { ...props, signature: 0 };
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    jest.spyOn(service, 'processSignatureLink').mockResolvedValue({
      status: 200,
      response: true,
      ok: true,
      json: jest.fn(() => {
        return Promise.resolve({
          receipt_html: '<div>foobar receipt</div>',
          charge: mockChargeResponse
        });
      })
    });
    render(<UpfPaymentPage {...newProps} />);
    await waitFor(() => expect(service.processSignatureLink).toHaveBeenCalledTimes(1));

    const thankYouPage = screen.getByTestId('thank-you-page'), receipt = screen.getByText('foobar receipt');
    expect(thankYouPage).toBeInTheDocument();
    expect(receipt).toBeInTheDocument();
  });
});