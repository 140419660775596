import React from 'react';
import { render, screen } from '@testing-library/react';
import AdyenAdditionalFees from '../AdyenAdditionalFees';
import userEvent from '@testing-library/user-event';
import { adHocFeeNames } from 'lib/constants';

const currentYear = new Date().getFullYear();
const nextYear = currentYear + 1;

const fee = {
  id: 1,
  name: 'pci_non_compliance',
  _destroy: false,
  errors: {},
  rate: '22',
  frequency: 'MONTHLY',
  effective_month: '2',
  effective_year: nextYear.toString(),
  resourceName: 'pricing'
};

const errors = {
  name: ['first name error', 'second name error'],
  rate: ['amount error'],
  frequency: ['frequency error']
};

describe('AdyenAdditionalFees', () => {
  it('renders with no props', () => {
    render(<AdyenAdditionalFees />);
    expect(screen.getByText('+ Add fee')).toBeInTheDocument();
  });

  it('renders each fees inputs in props', () => {
    render(<AdyenAdditionalFees fees={[fee]}/>);
    const nameInput = screen.getByLabelText('Fee Name');
    expect(nameInput).toBeInTheDocument();
    expect(nameInput).toHaveDisplayValue('PCI Non-compliance Fee');
    const amountInput = screen.getByLabelText('Amount');
    expect(amountInput).toBeInTheDocument();
    expect(amountInput).toHaveValue('22');
    const frequencyInput = screen.getByLabelText('Frequency');
    expect(frequencyInput).toBeInTheDocument();
    expect(frequencyInput).toHaveDisplayValue('Monthly');
    expect(screen.queryByLabelText('Effective')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('Year')).not.toBeInTheDocument();
  });

  it('renders effective date inputs when withEffectiveDate is true', () => {
    render(<AdyenAdditionalFees fees={[fee]} withEffectiveDate={true}/>);
    const monthInput = screen.getByLabelText('Effective');
    expect(monthInput).toBeInTheDocument();
    expect(monthInput).toHaveDisplayValue('February');
    const yearInput = screen.getByLabelText('Year');
    expect(yearInput).toBeInTheDocument();
    expect(yearInput).toHaveDisplayValue(nextYear.toString());
  });

  it('clicking delete button on fee with ID (persisted) keeps fee but adds deleted class', () => {
    render(<AdyenAdditionalFees fees={[fee]} withEffectiveDate={true}/>);
    const feeRow = screen.getByTestId('adhoc-fees-row-0');
    expect(feeRow.classList.contains('adhoc-fees__fee-row--marked-as-deleted')).toBeFalsy();
    userEvent.click(screen.getByTestId('delete-icon'));
    expect(feeRow).toBeInTheDocument();
    expect(feeRow.classList.contains('adhoc-fees__fee-row--marked-as-deleted')).toBeTruthy();
  });

  it('clicking delete button on fee without ID (not persisted) removes fee from screen', () => {
    render(<AdyenAdditionalFees fees={[{...fee, id: undefined}]} withEffectiveDate={true}/>);
    const feeRow = screen.getByTestId('adhoc-fees-row-0');
    expect(feeRow.classList.contains('adhoc-fees__fee-row--marked-as-deleted')).toBeFalsy();
    userEvent.click(screen.getByTestId('delete-icon'));
    expect(feeRow).not.toBeInTheDocument();
  });

  it('clicking delete button makes delete button disappear for that row', () => {
    render(<AdyenAdditionalFees fees={[fee]} withEffectiveDate={true}/>);
    userEvent.click(screen.getByTestId('delete-icon'));
    expect(screen.queryByTestId('delete-icon')).not.toBeInTheDocument();
  });

  it('disables frequency, effective month, and effective year fields when restrictedEdit is true', () => {
    render(<AdyenAdditionalFees fees={[fee]} restrictedEdit={true} withEffectiveDate={true}/>);
    const nameInput = screen.getByLabelText('Fee Name');
    expect(nameInput).not.toHaveAttribute('readonly');
    const amountInput = screen.getByLabelText('Amount');
    expect(amountInput).not.toHaveAttribute('readonly');
    const frequencyInput = screen.getByLabelText('Frequency');
    expect(frequencyInput).toHaveAttribute('readonly');
    const monthInput = screen.getByLabelText('Effective');
    expect(monthInput).toHaveAttribute('readonly');
    const yearInput = screen.getByLabelText('Year');
    expect(yearInput).toHaveAttribute('readonly');
  });

  it('disables all inputs and doesnt show delete icon when disabled is true', () => {
    render(<AdyenAdditionalFees fees={[fee]} disabled={true} withEffectiveDate={true}/>);
    const nameInput = screen.getByLabelText('Fee Name');
    expect(nameInput).toBeDisabled();
    const amountInput = screen.getByLabelText('Amount');
    expect(amountInput).toBeDisabled();
    const frequencyInput = screen.getByLabelText('Frequency');
    expect(frequencyInput).toBeDisabled();
    const monthInput = screen.getByLabelText('Effective');
    expect(monthInput).toBeDisabled();
    const yearInput = screen.getByLabelText('Year');
    expect(yearInput).toBeDisabled();
    expect(screen.queryByTestId('delete-icon')).not.toBeInTheDocument();
  });

  it('shows errors for fields that have errors', () => {
    render(<AdyenAdditionalFees fees={[{...fee, errors: errors}]} withEffectiveDate={true}/>);
    expect(screen.getByText('first name error, second name error')).toBeInTheDocument();
    expect(screen.getByText('amount error')).toBeInTheDocument();
    expect(screen.getByText('frequency error')).toBeInTheDocument();
  });

  it('form can be interacted with by user', () => {
    render(<AdyenAdditionalFees fees={[fee]} withEffectiveDate={true}/>);
    // name input
    const nameInput = screen.getByLabelText('Fee Name');
    expect(nameInput).toHaveDisplayValue('PCI Non-compliance Fee');

    // amount
    const amountInput = screen.getByLabelText('Amount');
    expect(amountInput).toHaveValue('22');
    userEvent.clear(amountInput);
    userEvent.type(amountInput, '10');
    expect(amountInput).toHaveValue('10');

    // frequency
    const frequencyInput = screen.getByLabelText('Frequency');
    expect(frequencyInput).toHaveDisplayValue('Monthly');
    userEvent.selectOptions(frequencyInput, 'ONE_TIME');
    expect(frequencyInput).toHaveDisplayValue('One Time');
    expect(frequencyInput).toHaveValue('ONE_TIME');

    // effective month
    const monthInput = screen.getByLabelText('Effective');
    expect(monthInput).toHaveDisplayValue('February');
    userEvent.selectOptions(monthInput, '10');
    expect(monthInput).toHaveDisplayValue('October');
    expect(monthInput).toHaveValue('10');

    // effective year
    const yearInput = screen.getByLabelText('Year');
    expect(yearInput).toHaveDisplayValue(nextYear.toString());
    userEvent.selectOptions(yearInput, currentYear.toString());
    expect(yearInput).toHaveDisplayValue(currentYear.toString());
  });

  it('clicking add fee button adds a new fee row', () => {
    render(<AdyenAdditionalFees withEffectiveDate={true}/>);
    expect(screen.queryByLabelText('Fee Name')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('Amount')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('Frequency')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('Effective')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('Year')).not.toBeInTheDocument();
    userEvent.click(screen.getByText('+ Add fee'));
    expect(screen.queryByLabelText('Fee Name')).toBeInTheDocument();
    expect(screen.queryByLabelText('Amount')).toBeInTheDocument();
    expect(screen.queryByLabelText('Frequency')).toBeInTheDocument();
    expect(screen.queryByLabelText('Effective')).toBeInTheDocument();
    expect(screen.queryByLabelText('Year')).toBeInTheDocument();
  });

  // Right now there is only one ad hoc name available, the button should be displayed just once
  it('shows add fee button as many times as there are diferent ad hoc names available', () => {
    render(<AdyenAdditionalFees withEffectiveDate={true}/>);
    expect(screen.getByText('+ Add fee')).toBeInTheDocument();
    adHocFeeNames.forEach( (_, index) => {
      if (index > 0) {
        userEvent.click(screen.getByText('+ Add another fee'));
      } else {
        userEvent.click(screen.getByText('+ Add fee'));
      }
    });
    expect(screen.queryByLabelText('+ Add another fee')).not.toBeInTheDocument();
  });
});
