import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import DeleteBeneficialOwnerButton from '../DeleteBeneficialOwnerButton';
import client from 'lib/ajax';
import userEvent from '@testing-library/user-event';
import notify from 'lib/notify';

describe('DeleteBeneficialOwner', () => {

  it('clicking on the beneficial owner delete button will raise a confirmation message and delete the beneficial owner', async () => {
    const setCurrentMock = jest.fn();
    jest.spyOn(notify, 'success').mockImplementation(() => ({}));
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({});
        })
      })
    );

    render(<DeleteBeneficialOwnerButton  merchantId={1} beneficialOwnerId={1} beneficialOwnerName={'Joe'} beneficialOwnerSurname={'Adyen'} setCurrentLegalEntity={setCurrentMock} />);
    expect(screen.getByText('Delete')).toBeVisible();
    userEvent.click(screen.getByText('Delete'));
    expect(screen.getByText('Are you sure you want to delete beneficial owner Joe Adyen?')).toBeInTheDocument();
    expect(screen.getByText('Cancel')).toBeInTheDocument();
    expect(screen.getByText('Confirm')).toBeInTheDocument();
    userEvent.click(screen.getByText('Confirm'));
    await waitFor(() => expect(client.destroy).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledTimes(1);
    expect(notify.success).toHaveBeenCalledWith('Benefical Owner successfully deleted');
    expect(setCurrentMock).toHaveBeenCalledTimes(1);
  });

  it('showns an error message if there is an error when trying to delete the beneficial owner', async () => {
    jest.spyOn(notify, 'success').mockImplementation(() => ({}));
    jest.spyOn(notify, 'error').mockImplementation(() => ({}));
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );

    render(<DeleteBeneficialOwnerButton  merchantId={1} beneficialOwnerId={1} beneficialOwnerName={'Joe'} beneficialOwnerSurname={'Adyen'}/>);
    expect(screen.getByText('Delete')).toBeVisible();
    userEvent.click(screen.getByText('Delete'));
    expect(screen.getByText('Are you sure you want to delete beneficial owner Joe Adyen?')).toBeInTheDocument();
    expect(screen.getByText('Cancel')).toBeInTheDocument();
    expect(screen.getByText('Confirm')).toBeInTheDocument();
    userEvent.click(screen.getByText('Confirm'));
    await waitFor(() => expect(client.destroy).toHaveBeenCalledTimes(1));
    expect(notify.success).not.toHaveBeenCalled();
    expect(notify.error).toHaveBeenCalledTimes(1);
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });
});