import React from 'react';
import {render, screen} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import DepositAccountsEditModal from '../DepositAccountsEditModal';

describe('DepositAccountsEditModal', () => {
  const modalProps = {
    supportEmail: 'bobloblaw@loblawlawblog.law',
    isModalOpen: true,
    isSaving: false,
    selectedAccountName: 'Loblaw Law Blog Account',
    handleAccountNameChange: jest.fn().mockImplementation(value => value),
    handleModalClose: jest.fn(),
    handleSave: jest.fn()
  };

  const renderedTextValues = [
    'Edit Processing Account',
    'Close Modal Icon',
    'Account Name:',
    'If you would like to make changes to your Bank Account information, please email',
    'bobloblaw@loblawlawblog.law',
    'Cancel',
    'Save'
  ];

  it('renders when provided required props', () => {
    render(<DepositAccountsEditModal {...modalProps} />);

    renderedTextValues.forEach(text => expect(screen.getByText(text)).toBeInTheDocument());
  });

  it('passed onChange function is invoked', () => {
    render(<DepositAccountsEditModal {...modalProps} />);
    const nameChangeInput = screen.getByLabelText('Account Name:');
    userEvent.type(nameChangeInput, 'Foo Account');

    expect(modalProps.handleAccountNameChange).toHaveBeenCalledTimes(11);
  });

  it('passed save function is invoked when save button is clicked', () => {
    render(<DepositAccountsEditModal {...modalProps} />);
    const saveButton = screen.getByText('Save');
    userEvent.click(saveButton);

    expect(modalProps.handleSave).toHaveBeenCalled();
  });

  it('passed modal close function is invoked when cancel button is clicked', () => {
    render(<DepositAccountsEditModal {...modalProps} />);
    const cancelButton = screen.getByText('Cancel');
    userEvent.click(cancelButton);

    expect(modalProps.handleModalClose).toHaveBeenCalled();
  });

  it('is not showen when "isModalOpen" prop is false', () => {
    render(<DepositAccountsEditModal {...modalProps} isModalOpen={false} />);

    renderedTextValues.forEach(text => expect(screen.queryByText(text)).not.toBeInTheDocument());
  });
});