import React from 'react';
import { screen, render } from '@testing-library/react';
import {
  DateCell,
  AmountCell,
  AccountCell,
  NameReferenceCell,
  SurchargeCell,
  TotalAmountCell,
  formatTimestampToDate
} from '../utils/cellComponents';

describe('formatTimestampToDate', () => {
  it('returns an empty string if no timestamp is provided', () => {
    const formattedDate = formatTimestampToDate();
    expect(formattedDate).toEqual('');
  });

  it('returns an empty string if timestamp is invalid', () => {
    const formattedDate = formatTimestampToDate('invalid Date');
    expect(formattedDate).toEqual('');
  });

  it('returns an a formatted Date if timestamp is valid', () => {
    const date = new Date(1722363552406);
    const formattedDate = formatTimestampToDate(date);
    expect(formattedDate).toEqual('7/30/2024');
  });

  it('returns a formatted Date with time if arguments timestamp is valid and includeTime is true', () => {
    const date = new Date('Tue Jul 30 2024 11:59:11 GMT-0500 (Central Daylight Time)');
    const formattedDate = formatTimestampToDate(date, true);

    const dateOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
    const timeOptions = { hour12: true, hour: 'numeric', minute: 'numeric' };
    const expectedOutcome = `${date.toLocaleDateString('en-US', dateOptions)} ${date.toLocaleTimeString('en-US', timeOptions)}`;
    expect(formattedDate).toEqual(expectedOutcome);
  });
});

const row = { index: 0 };

describe('DateCell', () => {
  it('renders with the formatted date displayed correctly if timestamp is an instance of Date', () => {
    Date.now = jest.fn(() => 'Tue Jul 30 2024 11:59:11 GMT-0500 (Central Daylight Time)');
    const date = Date.now();
    render(<DateCell value={date} row={row} />);
    expect(screen.getByText(formatTimestampToDate(date, true))).toBeVisible();
  });

  it('renders without a passed timestamp as an empty span', () => {
    render(<DateCell value={undefined} row={row} />);
    expect(screen.queryByText('7/30/2024 11:59 AM')).not.toBeInTheDocument();
    expect(screen.getByTestId('associated-invoices-date-cell-0')).toBeVisible();
  });
});

describe('AmountCell', () => {
  it('renders with the correct amount if arguments subtotal and currency are present', () => {
    const value = {subTotal: 50000, currency: 'USD', showAmount: true};
    const {rerender} = render(<AmountCell value={value} row={row} />);
    expect(screen.getByText('$500.00')).toBeVisible();
    rerender(<AmountCell value={{ subTotal: value.subTotal, currency: 'CAD', showAmount: true }} row={row} />);
    expect(screen.getByText('CA$500.00')).toBeVisible();
  });

  it('renders with the correct amount defaulting to USD currency if currency is undefined', () => {
    render(<AmountCell value={{ subTotal: 50000, showAmount: true }} row={row} />);
    expect(screen.getByText('$500.00')).toBeVisible();
  });

  it('renders with the 0 amount defaulting to USD currency if no values are passed', () => {
    render(<AmountCell row={row} />);
    expect(screen.getByText('$0.00')).toBeVisible();
  });

  it('renders with the "--" amount if failedTransaction is true', () => {
    render(<AmountCell value={{subTotal: 50000, currency: 'USD', failedTransaction: true}} row={row} />);
    expect(screen.getByText('--')).toBeVisible();
  });
});

describe('AccountCell', () => {
  it('renders without props', () => {
    render(<AccountCell row={row} />);
    expect(screen.getByTestId('associated-invoices-account-cell-0')).toBeVisible();
  });

  it('renders with the bankName and bankAccount showing if either or both are passed as props', () => {
    const bankAccount = '*******6789';
    const bankName = 'Wells Fargo';
    const value = {bankAccount, bankName};
    const {rerender} = render(<AccountCell value={value} row={row} />);
    expect(screen.getByText('Wells Fargo *6789')).toBeVisible();

    rerender(<AccountCell value={{bankAccount}} row={row} />);
    expect(screen.getByText('*6789')).toBeVisible();

    rerender(<AccountCell value={{bankName}} row={row} />);
    expect(screen.getByText('Wells Fargo')).toBeVisible();
  });

  it('shows TrustIcon and Trust wording when bankAccount is a trust account', () => {
    const value = {bankAccount: '*******6789', bankName: 'Wells Fargo', isTrustAccount: true};
    render(<AccountCell value={value} row={row} />);
    expect(screen.getByTitle('Trust Account Icon')).toBeInTheDocument();
    expect(screen.getByText('Trust - Wells Fargo *6789')).toBeVisible();
  });
});

describe('NameReferenceCell', () => {
  it('renders with the correct name and reference if arguments are present', () => {
    const value = {displayName: 'Bob Loblaw', reference: 'loblaw1'};
    render(<NameReferenceCell value={value} row={row} />);
    expect(screen.getByText('Bob Loblaw')).toBeVisible();
    expect(screen.getByText('loblaw1')).toBeVisible();
  });

  it('renders with the correct amount defaulting to USD currency if currency is undefined', () => {
    render(<AmountCell value={{subTotal: 50000, showAmount: true}} row={row} />);
    expect(screen.getByText('$500.00')).toBeVisible();
  });

  it('still renders if no arguments are passed', () => {
    render(<NameReferenceCell row={row} />);
    expect(screen.getByTestId('associated-invoices-name-cell-0')).toBeVisible();
  });
});

describe('SurchargeCell', () => {
  it('renders with the correct surcharge if arguments surchargeAmount and currency are present', () => {
    const value = {surchargeAmount: 3495, currency: 'USD'};
    const {rerender} = render(<SurchargeCell value={value} row={row} />);
    expect(screen.getByText('$34.95')).toBeVisible();
    rerender(<SurchargeCell value={{surchargeAmount: value.surchargeAmount, currency: 'CAD'}} row={row} />);
    expect(screen.getByText('CA$34.95')).toBeVisible();
  });

  it('renders with the correct surcharge defaulting to USD currency if currency is undefined', () => {
    render(<SurchargeCell value={{surchargeAmount: 3495}} row={row} />);
    expect(screen.getByText('$34.95')).toBeVisible();
  });

  it('renders with surcharge showing "--" if no values are passed', () => {
    render(<SurchargeCell row={row} />);
    expect(screen.getByTestId('associated-invoices-surcharge-cell-0')).toBeVisible();
    expect(screen.getByText('--')).toBeVisible();
  });
});

describe('TotalAmountCell', () => {
  it('renders with the correct total amount if arguments amount and currency are present', () => {
    const value = {amount: 123456, currency: 'USD'};
    const {rerender} = render(<TotalAmountCell value={value} row={row} />);
    expect(screen.getByText('$1,234.56')).toBeVisible();
    rerender(<TotalAmountCell value={{amount: value.amount, currency: 'CAD'}} row={row} />);
    expect(screen.getByText('CA$1,234.56')).toBeVisible();
  });

  it('renders with the correct surcharge defaulting to USD currency if currency is undefined', () => {
    render(<TotalAmountCell value={{amount: 123456}} row={row} />);
    expect(screen.getByText('$1,234.56')).toBeVisible();
  });

  it('renders with surcharge showing $0 if no values are passed', () => {
    render(<TotalAmountCell row={row} />);
    expect(screen.getByTestId('associated-invoices-totalamount-cell-0')).toBeVisible();
    expect(screen.getByText('$0.00')).toBeVisible();
  });

  it('renders with the label if label is "Failed"', () => {
    render(<TotalAmountCell value={{ amount: 123456, currency: 'USD', failedTransaction: 'true' }} row={row} />);
    expect(screen.getByText('--')).toBeVisible();
  });
});
