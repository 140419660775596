import React from 'react';
import {screen, render} from '@testing-library/react';
import ShowQuickBill from '../index';
import * as QBUtils from '../../utils';

const vanillaProps = {
  bankAccounts: [
    {
      name: 'BBVA Compass *****0000',
      bankName: 'BBVA Compass',
      trust: false,
      xid: 'bank_e3g1J5RBRpaYF8oHvNo94',
      accountNumber: '*****0000',
      achLimit: 0
    },
    {
      name: 'TRUST Bancorp *****0001',
      bankName: 'Bancorp',
      trust: true,
      xid: 'bank_9Zq4ddK4m6eZGaAXqrQXX',
      accountNumber: '*****0001',
      achLimit: null
    },
    {
      name: 'Wells Fargo *****0000',
      bankName: 'Wells Fargo',
      trust: false,
      xid: 'bank_ECja7bEfSLTZ11QtsLOZd',
      accountNumber: '*****0000',
      achLimit: 5000
    }
  ],
  invoice: {
    id: 'abc123',
    invoicePayments: [
      {payment: {
        paymentType: 'cash'
      }}
    ],
    modified: {
      timestamp: '10/10/2022'
    },
    presentmentStatus: 'sent',
    created: {
      timestamp: '10/10/2022'
    },
    status: 'draft',
    invoiceNumber: '1',
    invoiceDate: '10/10/2022',
    dueDate: '11/30/2022',
    totalAmount: 10000,
    totalAmountDue: 10000,
    amountPaid: 0,
    testMode: false,
    currency: 'USD',
    bankAccountId: '987654321',
    ownerId: '1',
    contactId: 'abc123',
    invoiceMessages: [
      {
        id: '23rqsdaf',
        emailAddresses: ['foo@bar.com'],
        body: 'email body',
        message: 'email message',
        subject: 'email subject',
        status: 'draft',
        type: 'eqb'
      }]
  },
  permissions: {
    deleteInvoice: true,
    collectPayments: true
  }
};

describe('ShowQuickBill', () => {
  beforeEach(() => {
    jest.spyOn(QBUtils, 'formatDaysOut').mockImplementation(() => '3');
    jest.spyOn(QBUtils, 'daysOutText').mockImplementation(() => '3 Days');
  });

  it('renders', () => {
    render(<ShowQuickBill {...vanillaProps} />);

    const textToFind = [
      'Status',
      'Age',
      '3 Days',
      'Last Updated',
      'Select One',
      'Resend',
      'Edit & Resend',
      'Mark As Paid',
      'Delete'
    ];

    textToFind.forEach(text => {
      expect(screen.getByText(text)).toBeVisible();
    });
  });

  it('shows PaidQuickBill component values if invoice status is paid', () => {
    render(<ShowQuickBill invoice={{...vanillaProps.invoice, status: 'paid', presentmentStatus: 'paid'}} />);
    expect(screen.getByText('Status')).toBeVisible();
    expect(screen.getByText('Marked As Paid')).toBeVisible();
  });
});