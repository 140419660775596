import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import { beneficialOwnerResponseFromAggregate, legalEntities } from '../helpers/fixtures';
import client from 'lib/ajax';
import userEvent from '@testing-library/user-event';
import LegalEntities from '../index';
import notify from 'lib/notify';

describe('CreateBeneficialOwner', () => {

  beforeAll(() => {
    jest.spyOn(notify, 'success').mockImplementation(() => ({}));
    jest.spyOn(notify, 'error').mockImplementation(() => ({}));
  });

  const fillOutForm = async () => {
    const nameInput = screen.getByLabelText('Given Name');
    userEvent.type(nameInput, 'first');
    const surnameInput = screen.getByLabelText('Surname');
    userEvent.type(surnameInput, 'last');
    const titleInput = screen.getAllByLabelText('Title')[0];
    userEvent.click(titleInput);
    userEvent.click(screen.getByText('V.President'));
    const addressInput = screen.getByLabelText('Address');
    userEvent.type(addressInput, 'address');
    const cityInput = screen.getByLabelText('City');
    userEvent.type(cityInput, 'city');
    const stateInput = screen.getAllByLabelText('State')[0];
    userEvent.click(stateInput);
    userEvent.click(screen.getByText('California'));
    const zipInput = screen.getByLabelText('Postal Code');
    userEvent.type(zipInput, '40206');
    const countryInput = screen.getAllByLabelText('Country')[0];
    userEvent.click(countryInput);
    userEvent.click(screen.getByText('United States'));
    const emailInput = screen.getByLabelText('Email');
    userEvent.type(emailInput, 'test@test.com');
    const phoneInput = screen.getByLabelText('Phone');
    userEvent.type(phoneInput, '15023379876');
    const dobInput = screen.getByLabelText('Date Of Birth');
    userEvent.type(dobInput, '10/05/1996');
    // APDatepicker opening is async so have to await this so test doesnt throw errors
    await waitFor(() => expect(dobInput).toHaveDisplayValue('10/05/1996'));
    const citizenshipInput = screen.getAllByLabelText('Citizenship')[0];
    userEvent.click(citizenshipInput);
    userEvent.click(screen.getByText('Canada'));
    const yearsInput = screen.getByLabelText('Years At Residence');
    userEvent.type(yearsInput, '10');
    const identificationInput = screen.getAllByLabelText('Identification Type')[0];
    userEvent.click(identificationInput);
    userEvent.click(screen.getByText('Social Security'));
    const ssnInput = screen.getByLabelText('Social Security Number');
    userEvent.type(ssnInput, '123-456-1234');
  };

  it('Doesnt show create button if canEditBeneficialOwners is false', () => {
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={false} />);
    expect(screen.queryByText('Create')).not.toBeInTheDocument();
  });

  it('Clicking create button shows empty beneficial owner form', () => {
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    userEvent.click(screen.getByText('Create'));
    expect(screen.getByTestId('beneficial-owner-form')).toBeVisible();
  });

  it('Save button is disabled until required fields are filled out', async () => {
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    userEvent.click(screen.getByText('Create'));
    expect(screen.getByText('Save Changes')).toBeDisabled();
    await fillOutForm();
    expect(screen.getByText('Save Changes')).not.toBeDisabled();
  });

  it('Clicking back will go back to legal entities screen', () => {
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    userEvent.click(screen.getByText('Create'));
    expect(screen.getByTestId('beneficial-owner-form')).toBeVisible();
    userEvent.click(screen.getByText('Back to Legal'));
    expect(screen.queryByTestId('beneficial-owner-form')).not.toBeInTheDocument();
    expect(screen.getByText('Beneficial Owners')).toBeVisible();
  });

  it('Can create a beneficial owner and adds to the list of owners', async () => {

    const newBeneficialOwner = { 
      ...beneficialOwnerResponseFromAggregate.data.attributes,
      given_name: 'new given name',
      surname: 'new surname',
      aggregate_id: '2Y6H55VdcIC1L49ToFmud9',
      id: 1234
    };

    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(newBeneficialOwner);
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    userEvent.click(screen.getByText('Create'));
    expect(screen.getByTestId('beneficial-owner-form')).toBeVisible();
    await fillOutForm();
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.success).toHaveBeenCalledWith('Beneficial Owner successfully created');
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    expect(screen.getByText('new given name new surname')).toBeVisible();
  });

  it('Shows error when there is an error creating beneficial owner', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: 'test error'});
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    userEvent.click(screen.getByText('Create'));
    expect(screen.getByTestId('beneficial-owner-form')).toBeVisible();
    await fillOutForm();
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.success).not.toHaveBeenCalled();
    expect(notify.error).toHaveBeenCalledWith('test error', [], false);
  });
  
  it('saving the form shows user a generic error and re-enables save button if request fails with blank error', async () => {
    jest.spyOn(client, 'post').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({error: ''});
        })
      })
    );
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    userEvent.click(screen.getByText('Create'));
    expect(screen.getByTestId('beneficial-owner-form')).toBeVisible();
    await fillOutForm();
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.success).not.toHaveBeenCalled();
    expect(notify.error).toHaveBeenCalledWith('Unexpected error occurred when creating beneficial owner', [], false);
  });

  it('saving the form shows user a generic error and re-enables save button if request throws an error', async () => {
    jest.spyOn(client, 'post').mockImplementation(() => {
      throw new Error();
    });
    render(<LegalEntities legal_entities={legalEntities} can_edit_beneficial_owners={true}/>);
    expect(screen.getByText('Joe Adyen')).toBeVisible();
    userEvent.click(screen.getByText('Create'));
    expect(screen.getByTestId('beneficial-owner-form')).toBeVisible();
    await fillOutForm();
    userEvent.click(screen.getByText('Save Changes'));
    await waitFor(() => expect(client.post).toHaveBeenCalledTimes(1));
    expect(notify.success).not.toHaveBeenCalled();
    expect(notify.error).toHaveBeenCalledWith('Unexpected error occurred when creating beneficial owner', [], false);
  });

});