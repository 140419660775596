import React from 'react';
import { render, screen } from '@testing-library/react';
import AdyenCardFees from '../AdyenCardFees';
import userEvent from '@testing-library/user-event';

const fees = [
  { name: 'credit_net_volume', rate: 3.0 },
  { name: 'credit_net_volume_amex', rate: 4.0 },
  { name: 'credit_per_transaction', rate: 5.0 },
  { name: 'credit_per_transaction_amex', rate: 6.0 },
  { name: 'chargeback_per_transaction', rate: 7.0 },
  { name: 'foreign_net_volume', rate: 8.0 },
  { name: 'debit_net_volume_reg', rate: 9.0 },
  { name: 'debit_per_transaction_reg', rate: 10.0 }
];

const pricingTypes = [
  { text: 'Standard', value: 'standard' }
];

describe('AdyenCardFees', () => {
  it('renders the form with no fees passed in', () => {
    render(<AdyenCardFees pricingTypes={pricingTypes} />);
    expect(screen.getAllByLabelText('Pricing Model')[0]).toHaveTextContent('Standard');
    expect(screen.getAllByLabelText('Regulated Debit')[0]).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('Regulated Debit')[1]).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('Card')[0]).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('Card')[1]).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('American Express')[0]).toHaveDisplayValue('');
    expect(screen.getAllByLabelText('American Express')[1]).toHaveDisplayValue('');
    expect(screen.getByLabelText('Chargeback Fee')).toHaveDisplayValue('');
    expect(screen.getByLabelText('Foreign Card Fee')).toHaveDisplayValue('');
  });

  it('user can interact with form elements', () => {
    render(<AdyenCardFees pricingTypes={pricingTypes} />);
    const cardRate = screen.getAllByLabelText('Card')[0];
    expect(cardRate).toHaveDisplayValue('');
    userEvent.type(cardRate, '3');
    expect(cardRate).toHaveDisplayValue('3');

    const cardPerTransaction = screen.getAllByLabelText('Card')[1];
    expect(cardPerTransaction).toHaveNa;
    expect(cardPerTransaction).toHaveDisplayValue('');
    userEvent.type(cardPerTransaction, '4');
    expect(cardPerTransaction).toHaveDisplayValue('4');

    const amexRate = screen.getAllByLabelText('American Express')[0];
    expect(amexRate).toHaveDisplayValue('');
    userEvent.type(amexRate, '5');
    expect(amexRate).toHaveDisplayValue('5');

    const amexPerTransaction = screen.getAllByLabelText('American Express')[1];
    expect(amexPerTransaction).toHaveDisplayValue('');
    userEvent.type(amexPerTransaction, '6');
    expect(amexPerTransaction).toHaveDisplayValue('6');

    const chargeback = screen.getByLabelText('Chargeback Fee');
    expect(chargeback).toHaveDisplayValue('');
    userEvent.type(chargeback, '7');
    expect(chargeback).toHaveDisplayValue('7');

    const foreignCard = screen.getByLabelText('Foreign Card Fee');
    expect(foreignCard).toHaveDisplayValue('');
    userEvent.type(foreignCard, '8');
    expect(foreignCard).toHaveDisplayValue('8');

    const debitVolumeReg = screen.getAllByLabelText('Regulated Debit')[0];
    expect(debitVolumeReg).toHaveDisplayValue('');
    userEvent.type(debitVolumeReg, '9');
    expect(debitVolumeReg).toHaveDisplayValue('9');

    const debitPerTransactionReg = screen.getAllByLabelText('Regulated Debit')[1];
    expect(debitPerTransactionReg).toHaveDisplayValue('');
    userEvent.type(debitPerTransactionReg, '10');
    expect(debitPerTransactionReg).toHaveDisplayValue('10');

    const passThroughFeesCheckbox = screen.getByLabelText('Enable Pass Through Fees');
    expect(passThroughFeesCheckbox).toBeChecked();
    userEvent.click(passThroughFeesCheckbox);
    expect(passThroughFeesCheckbox).not.toBeChecked();
  });

  it('renders the form correctly with passed in rates from props', () => {
    render(<AdyenCardFees fees={fees} pricingTypes={pricingTypes} />);
    expect(screen.getAllByLabelText('Pricing Model')[0]).toHaveTextContent('Standard');
    expect(screen.getAllByLabelText('Card')[0]).toHaveDisplayValue('3');
    expect(screen.getAllByLabelText('Card')[1]).toHaveDisplayValue('5');
    expect(screen.getAllByLabelText('American Express')[0]).toHaveDisplayValue('4');
    expect(screen.getAllByLabelText('American Express')[1]).toHaveDisplayValue('6');
    expect(screen.getByLabelText('Chargeback Fee')).toHaveDisplayValue('7');
    expect(screen.getByLabelText('Foreign Card Fee')).toHaveDisplayValue('8');
    expect(screen.getAllByLabelText('Regulated Debit')[0]).toHaveDisplayValue('9');
    expect(screen.getAllByLabelText('Regulated Debit')[1]).toHaveDisplayValue('10');
  });

  it('Shows pass through fees checkbox with checked by default if its a new plan', () => {
    render(<AdyenCardFees newPlan={true} fees={fees} pricingTypes={pricingTypes}/>);
    expect(screen.getByLabelText('Enable Pass Through Fees')).toBeChecked();
  });

  it('Pass through fees checkbox is checked when value passed in props is true and its not a new plan', () => {
    render(<AdyenCardFees newPlan={false} fees={fees} pricingTypes={pricingTypes} includePassthroughFees={true}/>);
    expect(screen.getByLabelText('Enable Pass Through Fees')).toBeChecked();
  });

  it('Pass through fees checkbox is not checked when value passed in props is false and its not a new plan', () => {
    render(<AdyenCardFees newPlan={false} fees={fees} pricingTypes={pricingTypes} includePassthroughFees={false}/>);
    expect(screen.getByLabelText('Enable Pass Through Fees')).not.toBeChecked();
  });

  it('Renders hidden pass through fees input to send value in form when checkbox is not checked', () => {
    render(<AdyenCardFees newPlan={false} fees={fees} pricingTypes={pricingTypes} includePassthroughFees={false}/>);
    const hiddenPassThroughFeesInput = screen.getByTestId('hidden-passthrough-fees-input', {hidden: true});
    expect(hiddenPassThroughFeesInput.value).toEqual('false');
  });

  it('form elements are required', () => {
    render(<AdyenCardFees fees={fees} pricingTypes={pricingTypes} />);
    expect(screen.getAllByLabelText('Pricing Model')[0].nextSibling.nextSibling).toBeRequired();
  });

  it('shows error messages for input if rate has an error', () => {
    const newFees = fees.map(fee => ({
      ...fee,
      errors: {
        rate: [`${fee.name} rate error 1`, `${fee.name} rate error 2`]
      }
    }));

    render(<AdyenCardFees fees={newFees}/>);
    fees.forEach(fee => {
      expect(screen.getByText(`${fee.name} rate error 1, ${fee.name} rate error 2`, {exact: false})).toBeVisible();
    });
  });
});
