import { act, render, screen } from '@testing-library/react';
import React from 'react';
import { noCustomFieldsNoSurcharge } from './UpfPaymentPageTestProps';
import UpfPaymentPage from '../index';
import userEvent from '@testing-library/user-event';
import { expectToBeSelectedInAPSelect, getAPSelect, simulateSelectEventInAPSelect } from './testHelpers';

jest.mock('../../../lib/utils', () => ({
  ...jest.requireActual('../../../lib/utils'),
  rollbarLog: jest.fn(),
  safeSendGA: jest.fn()
}));

describe('UPFPaymentPage Recurring Charges tests', () => {

  beforeEach(() => {
    jest.useFakeTimers('modern');
    jest.setSystemTime(new Date(2023, 11, 15));
    jest.spyOn(window.navigator, 'languages', 'get').mockReturnValue(['en-US']);
  });

  afterEach(() => {
    jest.useRealTimers();
    jest.restoreAllMocks();
  });

  it('Does not render frequency input if recurring_visible is NONE', () => {
    const { container } = render(<UpfPaymentPage {...noCustomFieldsNoSurcharge} />);
    expect(screen.queryByLabelText('Frequency')).not.toBeInTheDocument();

    const upf = container.querySelector('upf-checkout');
    expect(upf).toBeInTheDocument();
    expect(upf['recurring-data']).toEqual(undefined);
  });

  it('Does not render frequency input if recurring_visible is undefined', () => {
    const props = {...noCustomFieldsNoSurcharge, recurring_visible: undefined};
    const { container } = render(<UpfPaymentPage {...props} />);
    expect(screen.queryByLabelText('Frequency')).not.toBeInTheDocument();

    const upf = container.querySelector('upf-checkout');
    expect(upf).toBeInTheDocument();
    expect(upf['recurring-data']).toEqual(undefined);
  });

  it('Disables frequency input if readOnlyParams has recur_frequency and default URL value is present', () => {
    const params = { recur_frequency: 'WEEKLY', readOnlyFields: 'recur_frequency' };
    const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);

    expectToBeSelectedInAPSelect('Frequency', 'Every Week');
    const frequencyInput = getAPSelect('Frequency');
    expect(frequencyInput).toBeDisabled();
    expect(screen.getByLabelText('When total paid:')).toBeDisabled();
    expect(screen.getByLabelText('Total Amount Due')).toBeDisabled();
    expect(screen.getByLabelText('Never')).toBeDisabled();

    const upf = container.querySelector('upf-checkout');
    expect(upf['recurring-data']).toEqual({
      interval_unit: 'WEEK',
      interval_delay: 1,
      start: '2023-12-15'
    });
  });

  it('Doesnt disable input if readOnlyParams has recur_frequency but default URL value is not present', () => {
    const params = { readOnlyFields: 'recur_frequency' };
    const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
    jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
    const { container } = render(<UpfPaymentPage {...props} />);

    expectToBeSelectedInAPSelect('Frequency', 'No Schedule');
    const frequencyInput = getAPSelect('Frequency');
    expect(frequencyInput).not.toBeDisabled();
    const upf = container.querySelector('upf-checkout');
    expect(upf['recurring-data']).toEqual({});
  });

  describe('BASIC frequency input tests', () => {
    it('Does render frequency input if recurring_visible is BASIC', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'BASIC'};
      const { container } = render(<UpfPaymentPage {...props} />);
      expect(getAPSelect('Frequency')).toBeInTheDocument();
      expectToBeSelectedInAPSelect('Frequency', 'One-Time Payment');

      const upf = container.querySelector('upf-checkout');
      expect(upf).toBeInTheDocument();
      expect(upf['recurring-data']).toEqual({});
    });

    it('Does not show max amount input when schedule is selected', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'BASIC'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
      simulateSelectEventInAPSelect('Frequency', 'Every Week');
      expect(screen.queryByLabelText('When total paid:')).not.toBeInTheDocument();
      expect(screen.queryByLabelText('Total Amount Due')).not.toBeInTheDocument();
      expect(screen.queryByLabelText('Never')).not.toBeInTheDocument();
    });

    it('Does not have bi-monthly as an option', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'BASIC'};
      render(<UpfPaymentPage {...props} />);
      expect(screen.queryByText('Twice a Month')).not.toBeInTheDocument();
    });

    it('Does not render frequency input if selected payment method is loan', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'BASIC'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
      simulateSelectEventInAPSelect('Frequency', 'Every Week');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'WEEK',
        interval_delay: 1,
        start: '2023-12-15'
      });

      act(() => {
        const event = new CustomEvent('UPFPaymentMethodChange', { detail: 'loan' });
        window.document.dispatchEvent(event);
      });
      expect(upf['recurring-data']).toEqual({});
      expect(screen.queryByLabelText('Frequency')).not.toBeInTheDocument();
    });

    it('Can prefill frequency input from url params', () => {
      const params = { recur_frequency: 'MONTHLY' };
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'BASIC'};
      jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
      const { container } = render(<UpfPaymentPage {...props} />);

      expectToBeSelectedInAPSelect('Frequency', 'Every Month');
      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 1,
        start: '2023-12-15',
        days: ['15']
      });
    });

    it('Doesnt prefill frequency input if value in url params is not valid value for recurring type', () => {
      const params = { recur_frequency: 'BI_MONTHLY' };
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'BASIC'};
      jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
      const { container } = render(<UpfPaymentPage {...props} />);

      expectToBeSelectedInAPSelect('Frequency', 'One-Time Payment');
      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
    });

  });

  describe('FULL frequency input tests', () => {
    it('Does render frequency input if recurring_visible is FULL', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);
      expect(getAPSelect('Frequency')).toBeInTheDocument();
      expectToBeSelectedInAPSelect('Frequency', 'No Schedule');

      const upf = container.querySelector('upf-checkout');
      expect(upf).toBeInTheDocument();
      expect(upf['recurring-data']).toEqual({});
    });

    it('Does not render frequency input if selected payment method is loan', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
      simulateSelectEventInAPSelect('Frequency', 'Every Week');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'WEEK',
        interval_delay: 1,
        start: '2023-12-15'
      });

      act(() => {
        const event = new CustomEvent('UPFPaymentMethodChange', { detail: 'loan' });
        window.document.dispatchEvent(event);
      });
  
      expect(upf['recurring-data']).toEqual({});
      expect(screen.queryByLabelText('Frequency')).not.toBeInTheDocument();
    });

    it('Does show max amount input when schedule is selected with never checked by default', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      render(<UpfPaymentPage {...props} />);

      expect(screen.queryByLabelText('When total paid:')).not.toBeInTheDocument();
      expect(screen.queryByLabelText('Total Amount Due')).not.toBeInTheDocument();
      expect(screen.queryByLabelText('Never')).not.toBeInTheDocument();

      simulateSelectEventInAPSelect('Frequency', 'Every Week');

      const neverRadio = screen.getByLabelText('Never');
      const maxAmountRadio = screen.getByLabelText('Total Amount Due');
      const maxAmountInput = screen.getByLabelText('Total Amount Due');

      expect(neverRadio).toBeInTheDocument();
      expect(neverRadio.checked).toEqual(true);
      expect(maxAmountRadio).toBeInTheDocument();
      expect(maxAmountRadio.checked).toEqual(false);
      expect(maxAmountInput).toBeInTheDocument();
      expect(maxAmountInput).toBeDisabled();
    });

    it('Passes max amount to UPF when selected', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
      simulateSelectEventInAPSelect('Frequency', 'Every Week');
      userEvent.click(screen.getByLabelText('When total paid:'));
      userEvent.type(screen.getByLabelText('Total Amount Due'), '50');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'WEEK',
        interval_delay: 1,
        start: '2023-12-15',
        max_amount: 5000
      });
    });

    it('Doesnt prefill frequency input if value in url params is not valid value for recurring type', () => {
      const params = { recur_frequency: 'TEST' };
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      jest.spyOn(URLSearchParams.prototype, 'get').mockImplementation(key => params[key]);
      const { container } = render(<UpfPaymentPage {...props} />);

      expectToBeSelectedInAPSelect('Frequency', 'No Schedule');
      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
    });

    it('Passes correct data to UPF for weekly schedule', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
      simulateSelectEventInAPSelect('Frequency', 'Every Week');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'WEEK',
        interval_delay: 1,
        start: '2023-12-15'
      });
    });

    it('Passes correct data to UPF for bi-weekly schedule', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
      simulateSelectEventInAPSelect('Frequency', 'Every Two Weeks');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'WEEK',
        interval_delay: 2,
        start: '2023-12-15'
      });
    });

    it('Passes correct data to UPF for monthly schedule', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});
      simulateSelectEventInAPSelect('Frequency', 'Every Month');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 1,
        start: '2023-12-15',
        days: ['15']
      });
    });

    it('Passes correct data to UPF for monthly schedule when current day of month is greater than 28', () => {
      jest.setSystemTime(new Date(2023, 11, 29));
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Every Month');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 1,
        start: '2023-12-29',
        days: ['LAST'],
        next_occurence_after: '2023-12-31'
      });
    });

    it('Passes correct data to UPF for bi-monthly schedule with second day selected', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Twice a Month');
      simulateSelectEventInAPSelect('Day of Month', '27th');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 1,
        start: '2023-12-15',
        days: ['15', '27']
      });
    });

    it('Passes correct data to UPF for bi-monthly schedule with last day selected', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Twice a Month');
      simulateSelectEventInAPSelect('Day of Month', 'Last Day');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 1,
        start: '2023-12-15',
        days: ['15', 'LAST']
      });
    });

    it('Passes correct data to UPF for bi-monthly schedule when current day is greater than 28', () => {
      jest.setSystemTime(new Date(2023, 11, 29));
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Twice a Month');
      simulateSelectEventInAPSelect('Day of Month', '15th');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 1,
        start: '2023-12-29',
        days: ['LAST', '15'],
        next_occurence_after: '2023-12-31'
      });
    });

    it('Passes correct data to UPF for every two months schedule', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Every Two Months');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 2,
        start: '2023-12-15'
      });
    });

    it('Passes correct data to UPF for every three months schedule', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Every Three Months');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 3,
        start: '2023-12-15'
      });
    });

    it('Passes correct data to UPF for every 6 months schedule', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Every Six Months');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 6,
        start: '2023-12-15'
      });
    });


    it('Passes correct data to UPF for every year schedule', () => {
      const props = {...noCustomFieldsNoSurcharge, recurring_visible: 'FULL'};
      const { container } = render(<UpfPaymentPage {...props} />);

      const upf = container.querySelector('upf-checkout');
      expect(upf['recurring-data']).toEqual({});

      simulateSelectEventInAPSelect('Frequency', 'Every Year');
      expect(upf['recurring-data']).toEqual({
        interval_unit: 'MONTH',
        interval_delay: 12,
        start: '2023-12-15'
      });
    });

  });
});