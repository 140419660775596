export const mockAccounts = [
  {
    accountIsActive: true,
    accountIsTrust: false,
    bankName: 'Bank of America',
    bankAccountPresent: true,
    displayType: 'Card',
    id: 24,
    maskedAccount: '*******0011',
    name: 'Operating'
  },
  {
    accountIsActive: true,
    accountIsTrust: true,
    bankName: 'Bancorp',
    bankAccountPresent: true,
    displayType: 'Pay Later',
    id: 25,
    maskedAccount: '******0012',
    name: 'Trust'
  },
  {
    accountIsActive: true,
    accountIsTrust: false,
    bankName: 'BBVA Compass',
    bankAccountPresent: true,
    displayType: 'eCheck',
    id: 26,
    maskedAccount: '*******0013',
    name: 'Operating ACH'
  },
  {
    accountIsActive: true,
    accountIsTrust: false,
    bankName: 'PNC',
    bankAccountPresent: true,
    displayType: 'Card',
    id: 27,
    maskedAccount: '*************0014',
    name: 'Test Processing Account'
  },
  {
    accountIsActive: true,
    accountIsTrust: false,
    bankName: 'Test Banking',
    bankAccountPresent: true,
    displayType: 'eCheck',
    id: 28,
    maskedAccount: '*************00015',
    name: 'Test ACH Processing Account'
  },
  {
    accountIsActive: true,
    accountIsTrust: false,
    bankName: 'Wells Fargo',
    bankAccountPresent: true,
    displayType: 'Card',
    id: 29,
    maskedAccount: '******0016',
    name: 'Wells Operating'
  },
  {
    accountIsActive: true,
    accountIsTrust: true,
    bankName: 'Wells Fargo',
    bankAccountPresent: true,
    displayType: 'Card',
    id: 30,
    maskedAccount: '******0017',
    name: 'Wells Trust'
  },
  {
    accountIsActive: true,
    accountIsTrust: false,
    bankName: 'Regions',
    bankAccountPresent: true,
    displayType: 'eCheck',
    id: 31,
    maskedAccount: '******0018',
    name: 'Wells Operating ACH'
  },
  {
    accountIsActive: false,
    accountIsTrust: true,
    bankName: 'Chase',
    bankAccountPresent: true,
    displayType: 'eCheck',
    id: 32,
    maskedAccount: '******0019',
    name: 'Wells Trust ACH'
  }
];