/*
  Do not modify these mocks. Import them in your tests, create a copy and modify/act on the copy if needed.
*/
const defaultProps = {
  accepts_banks: true,
  accepts_cards: true,
  accepts_loan: false,
  ach_limit: 100000,
  cc_limit: 20000,
  cc_required_fields: 'name,cvv,email,address1,city,state,postal_code,country',
  current_user: false,
  default_payment_method: 'card',
  description: 'Payment Detail',
  formatted_ach_limit: '$1,000.00',
  formatted_cc_limit: '$200.00',
  hosted_fields_config: {
    gatewayUrl: null,
    paymentTokenizationUrl: null,
    surchargeUrl: null,
    tokenizationUrl: null
  },
  logo: {
    large: 'foo.jpg',
    small: 'bar.jpg'
  },
  merchant: {
    additional_address: 'Suite 300',
    address: '123 Main Street',
    auth_user: 'secret',
    city: 'Austin',
    country: 'United States',
    country_code: 'US',
    name: 'AffiniPay',
    path: 'affinipay123456789012345678901234567890123456789012345678901234567890',
    phone: '978-987-9878',
    postal_code: '02360',
    site: {
      hostname: 'local.affinipay.test',
      identifier: 'affinipay',
      name: 'AffiniPay Site',
      support_email: 'support@affinipay.com',
      title: 'AffiniPay',
      www_host: 'https://www.AffiniPay.com'
    },
    state: 'TX',
    support_email: 'owner@affinipay.com',
    support_phone: '(978) 987-9878',
    website: 'http://www.foobar.com'
  },
  merchant_account: {
    accepted_card_type_image_names: [],
    accepted_card_types: '',
    id: 'secret',
    name: 'Operating',
    supported_cards: 0
  },
  notes: undefined,
  payment_page_url: 'http://local.affinipay.test:3000/pages/affinipay/simple-page',
  privacy_policy_url: 'https://affinipay.com/terms/privacy',
  recurring_visible: 'FULL',
  redirect_url: '',
  reference_label: 'Reference',
  reference_required: 1,
  signature: 0,
  signature_text: null,
  title: 'Invoice Payment',
  v2_captcha_key: 'secret',
  v3_captcha_key: 'secret',
  loan_support_number: '(855) 656-4685'
};

export const cardAndEcheckNoCustomFieldsNoSurcharge = {
  ...defaultProps,
  ach_required_fields: 'name',
  ach_surcharge_amount: 2.99,
  ach_surcharge_enabled: false,
  ach_surcharge_label: 'Some Fee',
  ach_surcharge_percent: '1.5',
  ach_surcharge_type: 'flat',
  amount_fields: [],
  cc_surcharge_amount: 0.01,
  cc_surcharge_enabled: false,
  cc_surcharge_label: 'Percent Fee',
  cc_surcharge_percent: '5.0',
  cc_surcharge_type: 'percent',
  custom_fields: []
};

export const cardAndEcheckNoCustomFieldsWithSurcharge = {
  ...defaultProps,
  ach_required_fields: 'name',
  ach_surcharge_amount: 2.99,
  ach_surcharge_enabled: true,
  ach_surcharge_label: 'Flat dollar fee',
  ach_surcharge_percent: '1.5',
  ach_surcharge_type: 'flat',
  amount_fields: [],
  cc_surcharge_amount: 0.01,
  cc_surcharge_enabled: true,
  cc_surcharge_label: 'Percent Fee',
  cc_surcharge_percent: '1.5',
  cc_surcharge_type: 'percent',
  custom_fields: []
};

export const cardAndEcheckCustomFieldsNoSurcharge = {
  ...defaultProps,
  ach_required_fields: 'name,city,state,postal_code',
  ach_surcharge_amount: 2.99,
  ach_surcharge_enabled: false,
  ach_surcharge_label: 'Some Fee',
  ach_surcharge_percent: '1.5',
  ach_surcharge_type: 'flat',
  amount_fields: [{
    amount: '5',
    custom_field: 'Checkbox Static text $5',
    custom_value: '',
    default_value: '',
    help_text: 'help',
    incompatible_payment_types: ['bank'],
    max_quantity: null,
    name: 'Checkbox Static text $5',
    options: '',
    placeholder: '',
    position: 13,
    required: 1,
    type: 'amount_checkbox'
  },
  {
    amount: '4',
    custom_field: 'Radio $4 Max Quantity 20',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: ['card'],
    max_quantity: 20,
    name: 'Radio $4 Max Quantity 20',
    options: '',
    placeholder: '',
    position: 16,
    required: 1,
    type: 'amount_radio'
  },
  {
    amount: '',
    custom_field: 'Radio Input',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: ['card'],
    max_quantity: null,
    name: 'Radio Input',
    options: '',
    placeholder: '',
    position: 17,
    required: 1,
    type: 'amount_radio'
  },
  {
    amount: '5',
    custom_field: 'Radio Static text $5',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: ['bank'],
    max_quantity: null,
    name: 'Radio Static text $5',
    options: '',
    placeholder: '',
    position: 19,
    required: 1,
    type: 'amount_radio'
  },
  {
    amount: '',
    custom_field: 'Subtotal of section A',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: ['card'],
    max_quantity: null,
    name: 'Subtotal of section A',
    options: '',
    placeholder: '',
    position: 20,
    required: 1,
    type: 'amount_subtotal'
  },
  {
    amount: '',
    custom_field: 'Amount Section',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: ['bank'],
    max_quantity: null,
    name: 'Amount Section',
    options: '',
    placeholder: '',
    position: 22,
    required: 1,
    type: 'amount_section'
  },
  {
    amount: '6',
    custom_field: 'Subtotal Zero',
    custom_value: '',
    default_value: '',
    help_text: 'help',
    incompatible_payment_types: [],
    max_quantity: 60,
    name: 'Subtotal Zero',
    options: '',
    placeholder: '',
    position: 23,
    required: 1,
    type: 'amount_subtotal'
  },
  {
    amount: '100',
    custom_field: 'Only Amount',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: [],
    max_quantity: null,
    name: 'Only Amount',
    options: '',
    placeholder: '',
    position: 24,
    required: 1,
    type: 'amount'
  },
  {
    amount: '',
    custom_field: 'Blank',
    custom_value: 'custom valuez',
    default_value: '',
    help_text: 'help',
    incompatible_payment_types: [],
    max_quantity: null,
    name: 'Blank',
    options: '',
    placeholder: '',
    position: 25,
    required: 1,
    type: 'amount'
  },
  {
    amount: '2',
    custom_field: 'Amount and qty (2 and 3)',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: [],
    max_quantity: 3,
    name: 'Amount and qty (2 and 3)',
    options: '',
    placeholder: '',
    position: 26,
    required: 1,
    type: 'amount'
  },
  {
    amount: '.20',
    custom_field: 'Amount% 20',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: [],
    max_quantity: null,
    name: 'Amount% 20',
    options: '',
    placeholder: '',
    position: 27,
    required: 1,
    type: 'amount_percent'
  },
  {
    amount: '',
    custom_field: 'Subtotal of section B',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: [],
    max_quantity: null,
    name: 'Subtotal of section B',
    options: '',
    placeholder: '',
    position: 28,
    required: 1,
    type: 'amount_subtotal'
  },
  {
    amount: '-.10',
    custom_field: 'Amount% 10 off',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: ['bank'],
    max_quantity: null,
    name: 'Amount% 10 off',
    options: '',
    placeholder: '',
    position: 29,
    required: 1,
    type: 'amount_percent'
  },
  {
    amount: '',
    custom_field: 'Custom Total Amount',
    custom_value: '',
    default_value: '',
    help_text: '',
    incompatible_payment_types: [],
    max_quantity: null,
    name: 'Custom Total Amount',
    options: '',
    placeholder: '',
    position: 30,
    required: 1,
    type: 'amount_total'
  }],
  cc_surcharge_amount: 0.01,
  cc_surcharge_enabled: false,
  cc_surcharge_label: 'Percent Fee',
  cc_surcharge_percent: '5.0',
  cc_surcharge_type: 'percent',
  custom_fields: [{
    custom_field: 'reference',
    default_value: '',
    help_text: '',
    name: 'LABEL',
    options: '',
    placeholder: 'LABEL',
    position: 0,
    required: 1,
    type: 'text_field'
  },
  {
    custom_field: 'tf not require',
    default_value: 'val',
    help_text: 'help',
    name: 'tf not require',
    options: '',
    placeholder: 'placeholder',
    position: 1,
    required: 1,
    type: 'text_field'
  },
  {
    custom_field: 'tf hidden',
    default_value: 'default value',
    help_text: 'help',
    name: 'tf hidden',
    options: '',
    placeholder: 'placeholder',
    position: 2,
    required: 0,
    type: 'text_field'
  },
  {
    custom_field: 'tf require',
    default_value: '',
    help_text: '',
    name: 'tf require',
    options: '',
    placeholder: '',
    position: 3,
    required: 2,
    type: 'text_field'
  },
  {
    custom_field: 'ta not require',
    default_value: 'default value',
    help_text: 'help',
    name: 'ta not require',
    options: '',
    placeholder: 'placeholder',
    position: 4,
    required: 1,
    type: 'text_area'
  },
  {
    custom_field: 'ta req',
    default_value: '',
    help_text: '',
    name: 'ta req',
    options: '',
    placeholder: '',
    position: 5,
    required: 2,
    type: 'text_area'
  },
  {
    custom_field: 'ta hidden',
    default_value: '',
    help_text: 'help',
    name: 'ta hidden',
    options: '',
    placeholder: 'palceholder',
    position: 6,
    required: 0,
    type: 'text_area'
  },
  {
    custom_field: 'ch hidden',
    default_value: 'dv',
    help_text: 'help',
    name: 'ch hidden',
    options: '',
    placeholder: 'placeholder',
    position: 7,
    required: 0,
    type: 'check_box'
  },
  {
    custom_field: 'ch not require',
    default_value: '',
    help_text: 'help',
    name: 'ch not require',
    options: '',
    placeholder: 'placeholder',
    position: 8,
    required: 1,
    type: 'check_box'
  },
  {
    custom_field: 'ch req',
    default_value: '',
    help_text: '',
    name: 'ch req',
    options: '',
    placeholder: '',
    position: 9,
    required: 2,
    type: 'check_box'
  },
  {
    custom_field: 's not req',
    default_value: 'defval',
    help_text: 'help',
    name: 's not req',
    options: 'one, two, three',
    placeholder: 'placeholder',
    position: 10,
    required: 1,
    type: 'select_field'
  },
  {
    custom_field: 's req',
    default_value: '',
    help_text: '',
    name: 's req',
    options: 'oe,eo',
    placeholder: 'placeholder',
    position: 11,
    required: 2,
    type: 'select_field'
  },
  {
    custom_field: 's hidden',
    default_value: '',
    help_text: 'help',
    name: 's hidden',
    options: '',
    placeholder: '',
    position: 12,
    required: 0,
    type: 'select_field'
  }]
};

export const swiperWithCustomFields = {
  ...cardAndEcheckCustomFieldsNoSurcharge,
  card_reader_visible: true,
  swipe_cc_required_fields: 'state,postal_code,country'
};

export const swiperWithSurcharge = {
  ...cardAndEcheckNoCustomFieldsWithSurcharge,
  card_reader_visible: true,
  swipe_cc_required_fields: 'state,postal_code,country'
};

export const mockChargeResponse = {
  id:2559,
  gateway_id:'secret',
  merchant_id:3,
  account_id:4,
  user_id:0,
  charge_id:null,
  status:'AUTHORIZED',
  signature_token:'secret',
  signature_id:null,
  auto_capture:true,
  archived:false,
  nonoriginating_refund_enabled:false,
  currency:'USD',
  authorized_amount: {
    fractional:'0.0',
    currency: {},
    bank: {}
  },
  amount: {
    fractional:'0.0',
    currency: {},
    bank: {}
  },
  authorization_code:'ZTYR3G',
  avs_result:'ADDRESS_AND_POSTAL_CODE',
  cvv_result:'MATCHED',
  payment_data_source:'MANUAL_ENTRY_CARD_PRESENT',
  reference:'ref',
  void_reference:null,
  capture_reference:null,
  ip_address:'66.90.249.23',
  name:null,
  address1:'adr',
  address2:null,
  city:null,
  state:null,
  postal_code:'12345',
  country:'US',
  method:'card',
  fingerprint:'GunPelYVthifNV63LEw1',
  card_type:'VISA',
  card_number:'************4242',
  card_expiration_month:'2',
  card_expiration_year:'2022',
  bank_name:null,
  bank_account_type:null,
  bank_account_number:null,
  bank_routing_number:null,
  email:null,
  phone:null,
  payment_page_id:2,
  data: {},
  delta:true,
  created_at:'2021-02-03T22:32:52.000Z',
  completed_at:null,
  voided_at:null,
  failure_code:null,
  failure_details:null,
  batched_at:null,
  recurring_charge_id:null,
  recurring_charge_occurrence_id:null,
  application_id:null,
  geoip_location:'United States',
  emv_pref_name:null,
  emv_aid:null,
  emv_tvr:null,
  emv_iad:null,
  emv_tsi:null,
  emv_arc:null,
  emv_cvm:null,
  account_holder_type:null,
  surcharge_amount:20,
  subtotal_amount:1000,
  surcharge_type:'percent',
  surcharge_label:'Flat Fee',
  surcharge_applied:'1.95'
};

export const loanPaymentPage = {
  ...cardAndEcheckCustomFieldsNoSurcharge,
  loan_account_id: '123456',
  current_user: false,
  default_payment_method: 'loan'
};