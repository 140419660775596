import { 
  transformOwnerResponseToFormState, 
  transformBeneficialOwnerFormStateForUpdate, 
  transformLegalEntityFormStateForUpdate,
  buildIdentificationTypeFormState
} from '../helpers/utils';
import { 
  beneficialOwnerResponseFromAggregate, 
  beneficialOwner,
  legalEntity
} from '../helpers/fixtures';

describe('transformOwnerResponseToFormState', () => {

  it('returns an empty object if attributes doesnt exist in response', () => {
    expect(transformOwnerResponseToFormState({})).toEqual({});
  });

  it('transforms date of birth date', () => {
    expect(beneficialOwnerResponseFromAggregate.data.attributes.date_of_birth).toEqual('1990-12-12');
    expect(transformOwnerResponseToFormState(beneficialOwnerResponseFromAggregate).date_of_birth).toEqual(new Date('12/12/1990'));
  });

  it('transforms identification expiration_date if it exists', () => {
    const newBeneficialOwner = {
      data: {
        attributes: {
          ...beneficialOwnerResponseFromAggregate.data.attributes,
          identification: {
            type: 'us_driver_license',
            expiration_date: '2024-02-05'
          }
        }
      }
    };
    expect(transformOwnerResponseToFormState(newBeneficialOwner).identification.expiration_date).toEqual(new Date('02/05/2024'));
  });

  it('returns empty string if date is not valid for date_of_birth', () => {
    const newBeneficialOwner = {
      data: {
        attributes: {
          ...beneficialOwnerResponseFromAggregate.data.attributes,
          date_of_birth: 'invalid date'
        }
      }
    };
    expect(transformOwnerResponseToFormState(newBeneficialOwner).date_of_birth).toEqual('');
  });

  it('returns empty string if date is not valid for expiration_date', () => {
    const newBeneficialOwner = {
      data: {
        attributes: {
          ...beneficialOwnerResponseFromAggregate.data.attributes,
          identification: {
            type: 'us_driver_license',
            expiration_date: 'invalid ate'
          }
        }
      }
    };
    expect(transformOwnerResponseToFormState(newBeneficialOwner).identification.expiration_date).toEqual('');
  });

  it('does not include expiration_date key in transformed state if key doesnt exist in initial state', () => {
    expect('expiration_date' in transformOwnerResponseToFormState(beneficialOwnerResponseFromAggregate).identification).toEqual(false);
  });
});

describe('transformBeneficialOwnerFormStateForUpdate', () => {

  it('doesnt include identification fields if all values in initial state are identical to final state', () => {
    expect(transformBeneficialOwnerFormStateForUpdate(beneficialOwner, beneficialOwner).identification).toEqual(undefined);
  });

  it('does include identification fields if any fields change values', () => {
    const newIdentification = {type: 'us_social_security', number: '111-11-1111'};
    const newBeneficialOwner = {...beneficialOwner, identification: newIdentification};
    const transformedState = transformBeneficialOwnerFormStateForUpdate(beneficialOwner, newBeneficialOwner);
    expect(transformedState.identification.type).toEqual('us_social_security');
    expect(transformedState.identification.number).toEqual('111-11-1111');
  });
});

describe('transformLegalEntityFormStateForUpdate', () => {

  it('doesnt include identification fields if all values in initial state are identical to final state', () => {
    expect(transformLegalEntityFormStateForUpdate(legalEntity, legalEntity).tax_id).toEqual(undefined);
  });

  it('does include tax_id if tax_id has changed change values', () => {
    const newLegalEntity = {...beneficialOwner, tax_id: '1234'};
    const transformedState = transformBeneficialOwnerFormStateForUpdate(legalEntity, newLegalEntity);
    expect(transformedState.tax_id).toEqual('1234');
  });
});

describe('buildIdentificationTypeFormState', () => {
  let spy;
  beforeAll(() => {
    spy = jest.spyOn(global, 'Date');
  });

  it('returns social security state for type: us_social_security', () => {
    const expected = {
      type: 'us_social_security',
      number: ''
    };
    expect(buildIdentificationTypeFormState('us_social_security')).toEqual(expected);
  });
  
  it('returns drivers license state for type: us_driver_license', () => {
    const newIdentificationState = buildIdentificationTypeFormState('us_driver_license');
    const date = spy.mock.instances[0];
    const expected = {
      type: 'us_driver_license',
      number: '',
      state: '',
      expiration_date: date
    };
    expect(newIdentificationState).toEqual(expected);
  });

  it('returns visa state for type: visa', () => {
    const newIdentificationState = buildIdentificationTypeFormState('visa');
    const date = spy.mock.instances[0];
    const expected = {
      type: 'visa',
      number: '',
      country: '',
      expiration_date: date
    };
    expect(newIdentificationState).toEqual(expected);
  });

  it('returns passport state for type: passport', () => {
    const newIdentificationState = buildIdentificationTypeFormState('passport');
    const date = spy.mock.instances[0];
    const expected = {
      type: 'passport',
      number: '',
      country: '',
      expiration_date: date
    };
    expect(newIdentificationState).toEqual(expected);
  });

});