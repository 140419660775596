export const mockVanillaResponse = {
  data: [
    {
      id: '16n4dPTwMU9XxdaiOk8K2C',
      merchantVTID: 1,
      merchantUUID: '6QfxqNoSOw6WNYG4TwLJFm',
      name: 'AffiniPay',
      brand: 'affinipay',
      nextDueDate:'06/18/2023',
      status: 'due_soon',
      previousTestDate: '06/20/2023',
      contactEmail: 'affinipay@example.com',
      referralPartner: 'AffiniPay Website',
      integrationPartner: 'Integration Inc.',
      error: null
    },
    {
      id: '6GovmOKiVvG5ZOudl8Nxs2',
      merchantVTID: 2,
      merchantUUID: '3otVAj4E4mqkLWTCdJQUjP',
      name: 'Partner Inc.',
      brand: 'affinipay',
      nextDueDate:'12/22/2023',
      status: 'unknown',
      previousTestDate: null,
      contactEmail: 'owner@partner.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '6JVTeALLWBHvCvlBY1h2rf',
      merchantVTID: 3,
      merchantUUID: '6VXVzMNdMfFueaq6qmLb8a',
      name: 'Acme Inc.',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'owner@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '0dv05jgdTHXL9XpvE2yArz',
      merchantVTID: 4,
      merchantUUID: '5fEeUbIk0SIWkA4rwJu3Qm',
      name: 'Invoicing Inc.',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'mister-invoicing@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '10oUsxzy7VmkobtUrOkHlq',
      merchantVTID: 5,
      merchantUUID: '0BQXtzOZWkwW7gqoUQ1OKJ',
      name: 'Adyen Inc',
      brand: 'affinipay',
      nextDueDate:'12/20/2023',
      status: 'grace',
      previousTestDate: null,
      contactEmail: 'joeadyen@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '1L9fkDLLv4eMOEA5Ux90Kh',
      merchantVTID: 6,
      merchantUUID: '1jnu1wBRXQ85cr1sw37phS',
      name: 'Permission Tests 1',
      brand: 'affinipay',
      nextDueDate:'05/20/2023',
      status: 'expired',
      previousTestDate: '06/20/2023',
      contactEmail: 'main-permissions@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '4ru3qPRggg3pxxFrN2v8pJ',
      merchantVTID: 7,
      merchantUUID: '5KV5iOzyAk4BUqJHs70vBP',
      name: 'Permission Tests 2',
      brand: 'affinipay',
      nextDueDate:'08/20/2023',
      status: 'due_soon',
      previousTestDate: '06/20/2023',
      contactEmail: 'owner-permissions@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '2mhjCxa5oSyNfY4L4KXDS5',
      merchantVTID: 8,
      merchantUUID: '4A1gF5zLvMHZWlrFid9mqR',
      name: 'Voorhees LLP(LawPay)',
      brand: 'lawpay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'jasonvorhees@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '5btQigk6z4OJRX8N7biklY',
      merchantVTID: 9,
      merchantUUID: '3jOUlMvPUGjrb1GCkmO10X',
      name: 'Scoopdog LLP(CPACharge)',
      brand: 'cpacharge',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'scoopdog@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '4QEopdsnqMG8DWnurzQuhs',
      merchantVTID: 10,
      merchantUUID: '0K3E0Pq8YzFN2IbJEcpjZY',
      name: 'Greek LLP(MedPay)',
      brand: 'medpay',
      nextDueDate:'08/20/2023',
      status: 'due_soon',
      previousTestDate: '06/20/2023',
      contactEmail: 'greek@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '1lIm3123jijbEJq6HThxbQ',
      merchantVTID: 11,
      merchantUUID: '66VOKyvfnf8HAxTQE6uvL8',
      name: 'Mirror LLP(ClientPay)',
      brand: 'clientpay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'mirror@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '10KsBo5pkH7qsdm4Hc89Rc',
      merchantVTID: 12,
      merchantUUID: '7d0L1fdbKTHdsFGWftY30A',
      name: 'Bob\'s Burgers',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: '',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '7XBvs4ISKC4osuJHLKByEg',
      merchantVTID: 13,
      merchantUUID: '4m8Fijzy3K6EvYfy6fAEHQ',
      name: 'Frank\'s Frankfurter',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: '',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '0agrN66KUGeNv4KexAMkw7',
      merchantVTID: 14,
      merchantUUID: '08Jpxp1GeF7Tngu7cD34LM',
      name: 'BillGO',
      brand: 'affinipay',
      nextDueDate:'12/20/2023',
      status: 'grace',
      previousTestDate: null,
      contactEmail: '',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '16n4dPTwMU9asdfiOk8K2C',
      merchantVTID: 15,
      merchantUUID: '6QfxqNoSOasdfYG4TwLJFm',
      name: 'AffiniPay',
      brand: 'affinipay',
      nextDueDate:'06/28/2023',
      status: 'due_soon',
      previousTestDate: '06/20/2023',
      contactEmail: 'affinipay@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '6GovmOKiVvGasdfdl8Nxs2',
      merchantVTID: 16,
      merchantUUID: '3otVAj4E4asdfTCdJQUjP',
      name: 'Partner Inc.',
      brand: 'affinipay',
      nextDueDate:'12/22/2023',
      status: 'unknown',
      previousTestDate: null,
      contactEmail: 'owner@partner.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '6JVTeALLWBHasdfBY1h2rf',
      merchantVTID: 17,
      merchantUUID: '6VXVzMNdMfFasdf6qmLb8a',
      name: 'Acme Inc.',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'owner@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '0dv05jgdTHXasdfvE2yArz',
      merchantVTID: 18,
      merchantUUID: '5fEeUbIk0asdf4rwJu3Qm',
      name: 'Invoicing Inc.',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'mister-invoicing@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '10oUsxzy7VmasdfUrOkHlq',
      merchantVTID: 19,
      merchantUUID: '0BQXtzOZWkwasdfUQ1OKJ',
      name: 'Adyen Inc',
      brand: 'affinipay',
      nextDueDate:'12/20/2023',
      status: 'grace',
      previousTestDate: null,
      contactEmail: 'joeadyen@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '1L9fkDLLv4easdf5Ux90Kh',
      merchantVTID: 20,
      merchantUUID: '1jnu1wBRXQ85casfd7phS',
      name: 'Permission Tests 1',
      brand: 'affinipay',
      nextDueDate:'05/20/2023',
      status: 'expired',
      previousTestDate: '06/20/2023',
      contactEmail: 'main-permissions@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '4ru3qPRggg3asdfrN2v8pJ',
      merchantVTID: 21,
      merchantUUID: '5KV5iOzasfdUqJHs70vBP',
      name: 'Permission Tests 2',
      brand: 'affinipay',
      nextDueDate:'08/20/2023',
      status: 'due_soon',
      previousTestDate: '06/20/2023',
      contactEmail: 'owner-permissions@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '2mhjCxa5oSyasdfL4KXDS5',
      merchantVTID: 22,
      merchantUUID: '4A1gF5zLasdfFid9mqR',
      name: 'Voorhees LLP(LawPay)',
      brand: 'lawpay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'jasonvorhees@example.com',
      referralPartner: 'LawPay Website',
      error: null
    },
    {
      id: '5btQigk6z4OasdfN7biklY',
      merchantVTID: 23,
      merchantUUID: '3jOUlMvPUasfd1GCkmO10X',
      name: 'Scoopdog LLP(CPACharge)',
      brand: 'cpacharge',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'scoopdog@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '4QEopdsnqMGasdfurzQuhs',
      merchantVTID: 24,
      merchantUUID: '0K3E0Pq8asdfbJEcpjZY',
      name: 'Greek LLP(MedPay)',
      brand: 'medpay',
      nextDueDate:'08/20/2023',
      status: 'due_soon',
      previousTestDate: '06/20/2023',
      contactEmail: 'greek@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '1lIm3123jijasdf6HThxbQ',
      merchantVTID: 25,
      merchantUUID: '66VOKyvfasdfxTQE6uvL8',
      name: 'Mirror LLP(ClientPay)',
      brand: 'clientpay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: 'mirror@example.com',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '10KsBo5pkH7asdf4Hc89Rc',
      merchantVTID: 26,
      merchantUUID: '7d0L1fdbasdfFGWftY30A',
      name: 'Bob\'s Burgers',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: '',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '7XBvs4ISKC4asdfHLKByEg',
      merchantVTID: 27,
      merchantUUID: '4m8Fijzy3asdfy6fAEHQ',
      name: 'Frank\'s Frankfurter',
      brand: 'affinipay',
      nextDueDate:'06/20/2024',
      status: 'compliant',
      previousTestDate: '06/20/2023',
      contactEmail: '',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    },
    {
      id: '0agrN66KUGeasdfexAMkw7',
      merchantVTID: 28,
      merchantUUID: '08Jpxp1GeF7asdfcD34LM',
      name: 'BillGO',
      brand: 'affinipay',
      nextDueDate:'12/20/2023',
      status: 'grace',
      previousTestDate: null,
      contactEmail: '',
      referralPartner: 'LawPay Website',
      integrationPartner: 'Test integration',
      error: null
    }
  ],
  totalCount: 28
};

export const mockReferralPartnersResponse = {
  data: [
    {
      id: '5SvGR9N4ZviEznDyEwT4yH',
      name: 'AffiniPay Website',
      error: null
    },
    {
      id: '5NlpX7F5JCseqUsA1XSD0M',
      name: 'LawPay Website',
      error: null
    },
    {
      id: '03Tu6WzM4HMWnSlu7fc3g4',
      name: 'CLIO',
      error: null
    },
    {
      id: '7Bq2Pepy0IlxStBSVgHSej',
      name: 'ESQSites',
      error: null
    },
    {
      id: '5Gw3iH5kEQxmOLEgVEgdj2',
      name: 'Solo Practice University',
      error: null
    },
    {
      id: '5AYxamecib2pb1Gkd7S55D',
      name: 'State Bar of California',
      error: null
    },
    {
      id: '3HuLRJgLz4NanltWhVp1ts',
      name: 'ALA Legal Management',
      error: null
    },
    {
      id: '1zioTUBUmHqe4KiIIfGNGB',
      name: 'New York City Bar',
      error: null
    }
  ]
};

export const mockIntegrationPartnersResponse = {
  data: [
    {
      id: '5SvGR9N4ZviEznDyEwT4aS',
      name: 'Integration Inc.',
      error: null
    },
    {
      id: '6alTX7F5XmCseqUsA1XSD0M',
      name: 'New Partner',
      error: null
    },
    {
      id: '12Lu6WzM4HqSnSlu7fc5g0',
      name: 'Test integration',
      error: null
    }
  ]
};
