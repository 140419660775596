import React from 'react';
import {render, screen, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import {mockAccounts} from './mocks';
import DepositAccountsTable from '../DepositAccountsTable';
import * as AccountsService from '../services';

describe('DepositAccountsTable', () => {
  beforeEach(() => {
    jest.spyOn(AccountsService, 'updateProcessorAccountName').mockImplementation(() =>
      Promise.resolve({
        ok: true,
        json: jest.fn(() => Promise.resolve({status: 204}))
      })
    );
  });

  it('renders with No Accounts passed as non quickbill policy', () => {
    render(<DepositAccountsTable />);

    expect(screen.getByText('Deposit Accounts')).toBeVisible();
    expect(screen.getByText('Name / Type')).toBeVisible();
    expect(screen.getByText('No Accounts')).toBeVisible();
  });

  it('renders with No Accounts passed as quickbill policy', () => {
    render(<DepositAccountsTable isPolicyQuickBills={true} />);

    expect(screen.getByText('Deposit Accounts')).toBeVisible();
    expect(screen.getByText('Deposit')).toBeVisible();
    expect(screen.getByText('Processing Account')).toBeVisible();
    expect(screen.getByText('No Accounts')).toBeVisible();
  });

  it('renders with accounts passed', () => {
    render(
      <DepositAccountsTable
        isPolicyQuickBills={true}
        bankAccounts={mockAccounts}
      />
    );

    expect(screen.getByText('Deposit Accounts')).toBeVisible();
    expect(screen.getByText('Deposit')).toBeVisible();
    expect(screen.getByText('Processing Account')).toBeVisible();
    expect(screen.getAllByText('Edit').length).toEqual(8);
    expect(screen.getAllByText('INACTIVE').length).toEqual(1);
  });

  it('clicking the Edit button opens the edit modal', async () => {
    render(
      <DepositAccountsTable
        isPolicyQuickBills={true}
        bankAccounts={mockAccounts}
      />
    );
    expect(screen.queryByTestId('ap-input-text')).not.toBeInTheDocument();
    const firstAccountEdit = screen.getAllByText('Edit')[0];
    userEvent.click(firstAccountEdit);

    await waitFor(() => expect(screen.queryByTestId('ap-input-text')).toBeInTheDocument());
    expect(screen.getByText('Edit Card Account')).toBeInTheDocument();
    expect(screen.getByText('If you would like to make changes to your Bank Account information, please email')).toBeInTheDocument();
    expect(screen.getByText('support@affinipay.com')).toBeInTheDocument();
    expect(screen.getByText('Account Name:')).toBeInTheDocument();
  });

  it('clicking the Close button closes the edit modal', async () => {
    render(
      <DepositAccountsTable
        isPolicyQuickBills={true}
        bankAccounts={mockAccounts}
      />
    );

    const firstAccountEdit = screen.getAllByText('Edit')[0];
    userEvent.click(firstAccountEdit);

    await waitFor(() => expect(screen.getByText('Edit Card Account')).toBeInTheDocument());

    userEvent.click(screen.getByTitle('Close Modal Icon'));

    await waitFor(() => expect(screen.queryByText('Edit Card Account')).not.toBeInTheDocument());
  });

  it('editing the account name and pressing send updates the account names', async () => {
    render(
      <DepositAccountsTable
        isPolicyQuickBills={true}
        bankAccounts={mockAccounts}
      />
    );

    const firstAccountEdit = screen.getAllByText('Edit')[0];
    userEvent.click(firstAccountEdit);

    await waitFor(() => expect(screen.getByText('Edit Card Account')).toBeInTheDocument());

    userEvent.type(screen.getByLabelText('Account Name:'), 'foo');
    userEvent.click(screen.getByText('Save'));

    await waitFor(() => expect(screen.getByText('Operatingfoo')).toBeInTheDocument());
  });

  it('success in editing the account name shows success toast', async () => {
    render(
      <DepositAccountsTable
        isPolicyQuickBills={true}
        bankAccounts={mockAccounts}
      />
    );

    const firstAccountEdit = screen.getAllByText('Edit')[0];
    userEvent.click(firstAccountEdit);

    await waitFor(() => expect(screen.getByText('Edit Card Account')).toBeInTheDocument());

    userEvent.type(screen.getByLabelText('Account Name:'), 'foo');
    userEvent.click(screen.getByText('Save'));

    await waitFor(() => expect(screen.getByText('Operatingfoo')).toBeInTheDocument());
    await waitFor(() => expect(screen.getByTestId('form-message-box')).toBeInTheDocument());
    expect(screen.getByText('Account name updated.')).toBeInTheDocument();
  });

  it('clicking on the close tooltip icon closes the tooltip', async () => {
    const {container} = render(
      <DepositAccountsTable
        isPolicyQuickBills={true}
        bankAccounts={mockAccounts}
      />
    );

    const firstAccountEdit = screen.getAllByText('Edit')[0];
    userEvent.click(firstAccountEdit);

    await waitFor(() => expect(screen.getByText('Edit Card Account')).toBeInTheDocument());

    userEvent.type(screen.getByLabelText('Account Name:'), 'foo');
    userEvent.click(screen.getByText('Save'));

    await waitFor(() => expect(screen.getByText('Operatingfoo')).toBeInTheDocument());
    await waitFor(() => expect(screen.getByTestId('form-message-box')).toBeInTheDocument());

    userEvent.click(container.querySelector('.close-icon'));
    await waitFor(() => expect(screen.queryByTestId('form-message-box')).not.toBeInTheDocument());
  });

  it('failure in editing the account name shows errors', async () => {
    jest.spyOn(AccountsService, 'updateProcessorAccountName').mockImplementation(() =>
      Promise.resolve({
        ok: false,
        json: jest.fn(() => Promise.resolve({
          status: 422,
          errors: [
            "Name can't be blank",
            'Name is too short (minimum is 3 characters)'
          ]
        }))
      })
    );

    render(
      <DepositAccountsTable
        isPolicyQuickBills={true}
        bankAccounts={mockAccounts}
      />
    );

    const firstAccountEdit = screen.getAllByText('Edit')[0];
    userEvent.click(firstAccountEdit);

    await waitFor(() => expect(screen.getByText('Edit Card Account')).toBeInTheDocument());
    userEvent.clear(screen.getByLabelText('Account Name:'));
    userEvent.click(screen.getByText('Save'));

    await waitFor(() => expect(screen.getByLabelText("Account Name: Name can't be blank, Name is too short (minimum is 3 characters)")).toBeInTheDocument());
  });
});
