import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import FeatureToggles from '../index';
import { features, featureGates } from '../helpers/fixtures';
import * as service from '../helpers/services';

describe('Feature toggles table tests', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
  });

  it('Renders no table without any inputs', () => {
    render(<FeatureToggles />);
    expect(screen.getByText('No Records Found')).toBeInTheDocument();
  });

  it("Section header is 'Feature Toggles' in features table view", () => {
    render(<FeatureToggles flipper_features={features} />);
    expect(screen.getByText('Feature Toggles')).toBeInTheDocument();
  });

  it('Button in header in features table view is create feature button not back button', () => {
    render(<FeatureToggles flipper_features={features} />);
    expect(screen.getByText('Create Feature')).toBeInTheDocument();
    expect(screen.queryByText('Back to All Features')).not.toBeInTheDocument();
  });

  it('Renders a table of features', () => {
    render(<FeatureToggles flipper_features={features} />);
    expect(screen.getByRole('table')).toBeInTheDocument();
    // check number of rows in table (heading row + row for each feature)
    expect(screen.getAllByRole('row')).toHaveLength(features.data.length + 1);
    // table headers
    expect(screen.getByText('ID')).toBeInTheDocument();
    expect(screen.getByText('Feature Name')).toBeInTheDocument();
    expect(screen.getByText('Created At')).toBeInTheDocument();
    // checking first feature row
    expect(screen.getByText(features.data[0].attributes.id)).toBeInTheDocument();
    expect(screen.getByText(features.data[0].attributes.key)).toBeInTheDocument();
    expect(screen.getByText(features.data[0].attributes.created_at)).toBeInTheDocument();
  });

});

describe ('Feature Gate table tests', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
    jest.spyOn(service, 'getFeatureGates').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve(featureGates);
        })
      })
    );
  });

  it('clicking on a feature rows show feature button shows a table with the gates for that feature', async () => {
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getAllByText('Show Feature')[0]);
    expect(screen.queryByText('Feature Toggles')).not.toBeInTheDocument();
    expect(screen.getByText('test_feature_1')).toBeInTheDocument();
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    // check number of rows in table (heading row + row for each feature gate)
    expect(screen.getAllByRole('row')).toHaveLength(featureGates.data.length + 1);
    // table headers
    expect(screen.getByText('ID')).toBeInTheDocument();
    expect(screen.getByText('Name')).toBeInTheDocument();
    expect(screen.getByText('Created At')).toBeInTheDocument();
    // checking first feature row
    expect(screen.getByText(featureGates.data[0].attributes.id)).toBeInTheDocument();
    expect(screen.getByText(featureGates.data[0].attributes.value)).toBeInTheDocument();
    expect(screen.getByText(featureGates.data[0].attributes.created_at)).toBeInTheDocument();
  });

  it('Add feature button is not visible in feature gates table', () => {
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getAllByText('Show Feature')[0]);
    expect(screen.queryByText('Create Feature')).not.toBeInTheDocument();
  });

  it('back to features button is visible in feature gates table', () => {
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getAllByText('Show Feature')[0]);
    expect(screen.getByText('Back to All Features')).toBeInTheDocument();
  });

  it('clicking back button takes user back to features table', async () => {
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getAllByText('Show Feature')[0]);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));
    userEvent.click(screen.getByRole('button', {name: 'Back to All Features'}));
    expect(screen.getByRole('heading', {name: 'Feature Toggles'})).toBeInTheDocument();
    expect(screen.queryByRole('heading', {name: 'test_feature_1'})).not.toBeInTheDocument();
  });

  it('Deleting feature shows features table without deleted feature', async () => {
    jest.spyOn(service, 'deleteFeature').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        response: true,
        ok: true,
        json: jest.fn(() => {
          return Promise.resolve({data: features.data[1]});
        })
      })
    );
    render(<FeatureToggles flipper_features={features} />);
    userEvent.click(screen.getAllByText('Show Feature')[1]);
    await waitFor(() => expect(service.getFeatureGates).toHaveBeenCalledTimes(1));

    userEvent.click(screen.getByText('Delete Feature'));
    userEvent.click(screen.getByText('Remove'));
    await waitFor(() => expect(service.deleteFeature).toHaveBeenCalledTimes(1));

    expect(screen.getByText('Feature Toggles')).toBeInTheDocument();
    expect(screen.queryByText(features.data[1].attributes.key)).not.toBeInTheDocument();
    expect(screen.getByText(features.data[0].attributes.key)).toBeInTheDocument();
    expect(screen.queryByText(features.data[1].attributes.key)).not.toBeInTheDocument();
  });
});
