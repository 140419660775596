import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import SendQuickBillButton from '../SendQuickBillButton';

const vanillaProps = {
  handleSendQuickBill: jest.fn(),
  isDisabled: false,
  isSendingAllowed: true
};

describe('SendQuickBillButton', () => {
  it('renders', () => {
    render(<SendQuickBillButton {...vanillaProps} />);
    expect(screen.getByTitle('IconEQB')).toBeInTheDocument();
    expect(screen.getByText('Resend')).toBeVisible();
    expect(screen.getByRole('button')).not.toBeDisabled();
  });

  it('button is disabled if isDisabled prop is true', () => {
    render(<SendQuickBillButton
      handleSendQuickBill={vanillaProps.handleSendQuickBill}
      isDisabled={true}
      isSendingAllowed={true}
    />);
    expect(screen.getByRole('button')).toBeDisabled();
    userEvent.click(screen.getByText('Resend'));
    expect(vanillaProps.handleSendQuickBill).not.toHaveBeenCalled();
  });

  it('invokes passed prop handleSendQuickBill when clicked', () => {
    render(<SendQuickBillButton {...vanillaProps} />);
    userEvent.click(screen.getByText('Resend'));
    expect(vanillaProps.handleSendQuickBill).toHaveBeenCalled();
  });

  it('isDisabled prop defaults to false', () => {
    render(<SendQuickBillButton handleSendQuickBill = {jest.fn()} isSendingAllowed={true} />);
    expect(screen.getByRole('button')).not.toBeDisabled();
  });
});
