import React from 'react';
import { render, screen } from '@testing-library/react';
import CardTabComponent from '../CardTab';

const minimunCardTabProps = {
  formState: {
    cardName: undefined,
    cardNumber: undefined,
    cardCVV: undefined,
    cardExpiration: undefined,
    cardBillingAddress: undefined,
    cardBillingAddress2: undefined,
    cardBillingCity: undefined,
    cardBillingState: undefined,
    cardBillingZip: undefined,
    cardBillingCountry: 'US'
  },
  disabledFieldsState: {
    amountField: false,
    reference: false,
    email: false,
    cardName: false,
    cardNumber: false,
    cardCVV: false,
    cardExpiration: false,
    cardBillingAddress: false,
    cardBillingAddress2: false,
    cardBillingCity: false,
    cardBillingState: false,
    cardBillingZip: false,
    cardBillingCountry: false,
    cc_number: false,
    cvv: false,
    echeckAccountOwnerType: false,
    echeckAccountType: false,
    echeckFirstName: false,
    echeckLastName: false,
    echeckHolderName: false,
    echeckAccountNumber: false,
    echeckRoutingNumber: false,
    echeckAccountConfirm: false,
    echeckRoutingConfirm: false,
    echeckBillingAddress: false,
    echeckBillingAddress2: false,
    echeckBillingCity: false,
    echeckBillingState: false,
    echeckBillingZip: false,
    echeckBillingCountry: false,
    Reference: false,
    'Text area 1': false,
    'Checkbox 1': false
  },
  clientInfoState: {
    email: undefined
  }
};

describe('PaymentPage CardTab component', () => {
  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
  });

  it('CardTab renders correctly when minimal required policies fields are selected', () => {
    render(<CardTabComponent {...minimunCardTabProps} minimalRequiredPaymentFieldsSelected={true} />);

    expect(screen.getAllByLabelText('Zip / Postal Code')[0]).toBeInTheDocument();
    expect(screen.getAllByLabelText('Receipt Email')[0]).toBeInTheDocument();
    expect(screen.queryByLabelText('Address')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('City')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('State')).not.toBeInTheDocument();
    expect(screen.queryByLabelText('Country')).not.toBeInTheDocument();
  });

  it('CardTab renders correctly when the minimal required policies fields are not selected', () => {
    render(<CardTabComponent {...minimunCardTabProps} minimalRequiredPaymentFieldsSelected={false} />);

    expect(screen.getAllByLabelText('Address')[0]).toBeInTheDocument();
    expect(screen.getAllByLabelText('Address 2')[0]).toBeInTheDocument();
    expect(screen.getAllByLabelText('City')[0]).toBeInTheDocument();
    expect(screen.getAllByLabelText('State')[0]).toBeInTheDocument();
    expect(screen.getAllByLabelText('Zip / Postal Code')[0]).toBeInTheDocument();
    expect(screen.getAllByLabelText('Country')[0]).toBeInTheDocument();
    expect(screen.getAllByLabelText('Receipt Email')[0]).toBeInTheDocument();
  });
});
