export const failedInvoicePayment = {
  invoicePayments: [{ payment: { status: 'failed'}}]
};

export const authorizedInvoice = {
  currency: 'USD',
  invoicePayments: [
    {
      payment: {
        amount: 10300,
        bankAccount: '*******6789',
        bankAccountType: 'iolta',
        bankName: 'Wells Fargo',
        status: 'authorized',
        transactionId: 'transactionId'
      },
      amount: 10000,
      surchargeAmount: 300
    }
  ],
  totalAmount: 10000
};

export const completedInvoice = {
  currency: 'USD',
  invoicePayments: [
    {
      payment: { ...authorizedInvoice.invoicePayments[0].payment, status: 'completed' },
      surchargeAmount: 300
    }
  ],
  totalAmount: 10000
};

export const invoiceWithoutPayment = {
  id: 'i_4C8UmxngLgXLAY30ESSwA',
  created: {
    timestamp: '2024-07-26T20:19:50.917Z'
  },
  status: 'paid',
  invoiceDate: '2024-07-26T20:19:50.917Z',
  dueDate: '2024-07-26T20:19:50.917Z',
  totalAmount: 23900,
  totalAmountDue: 0,
  amountPaid: 23900,
  contact: {
    displayName: 'Foo Bar'
  },
  reference: 'foobar1',
  currency: 'CAD',
  invoicePayments: []
};

const invoicePayment = {
  id: 'ipmt_69hfS4DKWiK87ceJeMzPs',
  amount: 23900,
  created: { timestamp: '2024-08-01T20:53:31.828Z' },
  surchargeAmount: 717,
  payment: {
    amount: 24617,
    bankName: 'Bank of Canada',
    bankAccount: '*******6789',
    bankAccountType: 'operating',
    status: 'completed',
    reference: 'foo9',
    invoices: [
      {
        id: 'i_4C8UmxngLgXLAY30ESSwA',
        dueDate: '2024-07-26T20:19:50.917Z',
        invoiceDate: '2024-07-26T20:19:50.917Z',
        status: 'paid',
        invoiceNumber: '000008',
        currency: 'CAD',
        presentmentStatus: 'paid',
        type: 'invoice'
      }
    ]
  }
};

export const invoiceWithPayment = {
  ...invoiceWithoutPayment,
  invoicePayments: [invoicePayment]
};
