
import { waitFor } from '@testing-library/dom';
import { render, screen } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import React from 'react';
import server from '../../../../lib/server';
import AFFINIPAY_COMMUNICATIONS from '../../LoanPayment/helpers/affinipayCommunications';
import AFFIRM_COMMUNICATIONS from '../../LoanPayment/helpers/affirmCommunications';
import serverMock from '../../LoanPayment/mocks/serverMock';
import LoanForm from '../../LoanPayment/LoanForm';
import { affirmMock } from '../testHelpers';

describe('LoanJS Affinipay server non-happy path tests', () => {
  beforeAll(() => {
    affirmMock();
  });

  beforeEach(() => {
    jest.spyOn(console, 'error').mockImplementation(() => {});
    jest.spyOn(AFFINIPAY_COMMUNICATIONS, 'createAffinipayCharge');
    jest.spyOn(AFFIRM_COMMUNICATIONS, 'initiateAffirmCheckout').mockImplementation(() => {});
  });

  it('Token endpoint error handling', async () => {
    jest.spyOn(server, 'post').mockImplementation(() =>
      Promise.reject({
        status: 403,
        response: true,
        ok: false,
        json: jest.fn(() => {
          return Promise.resolve({
            error: 'foobar'
          });
        })
      })
    );
    render(<LoanForm
      externalFirstName='John'
      externalLastName='Doe'
      externalEmail='jd1234@gmail.com'
      pubKey='1234abcd'
      accountId='1234'
      amount='35100'
      minTransactionLimit={35000}
      maxTransactionLimit={2500000}
      chargeURL={'httpz://foobar.com'}
      tokenURL={'httpz://foobar.com'}
    ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    const tokenError = 'There was an error when creating this charge. Please refresh the page and try again.';
    userEvent.click(submitButton);

    expect(await screen.findByText(tokenError)).toBeVisible();
    expect(submitButton).not.toBeDisabled();
    await waitFor(() => expect(AFFINIPAY_COMMUNICATIONS.createAffinipayCharge).not.toHaveBeenCalled());
    await waitFor(() => expect(AFFIRM_COMMUNICATIONS.initiateAffirmCheckout).not.toHaveBeenCalled());
  });

  it('Charge endpoint error handling', async () => {
    jest.spyOn(server, 'post').mockResolvedValueOnce({
      status: 200,
      response: true,
      ok: true,
      json: jest.fn(() => {
        return Promise.resolve(serverMock.paymentTokenResponse);
      })
    }).mockRejectedValueOnce({
      status: 422,
      response: true,
      ok: false,
      json: jest.fn(() => {
        return Promise.resolve({
          'charge':{
            'attributes':{
              'messages':[
                {'code':'incorrect_payment_type','level':'error','message':'Payment method type not supported by the target account','facility':'gateway'}
              ]
            },
            'errors':{
              'base':['Payment method type not supported by the target account']
            },
            'messages':[
              {'attributes':{
                'code':'incorrect_payment_type',
                'level':'error',
                'message':'Payment method type not supported by the target account',
                'facility':'gateway'
              },
              'level':'error',
              'code':'incorrect_payment_type',
              'sub_code':null,
              'context':null,
              'message':'Payment method type not supported by the target account',
              'facility':'gateway'
              }]
          }
        });
      })
    });
    render(<LoanForm
      externalFirstName='John'
      externalLastName='Doe'
      externalEmail='jd1234@gmail.com'
      pubKey='1234abcd'
      accountId='1234'
      amount='35100'
      minTransactionLimit={35000}
      maxTransactionLimit={2500000}
      chargeURL={'httpz://foobar.com'}
      tokenURL={'httpz://foobar.com'}
    ></LoanForm>
    );
    const submitButton = screen.getByText('Begin Process');
    const tokenError = 'There was an error when creating this charge. Please refresh the page and try again.';
    userEvent.click(submitButton);

    expect(await screen.findByText(tokenError)).toBeVisible();
    expect(submitButton).not.toBeDisabled();
    await waitFor(() => expect(AFFIRM_COMMUNICATIONS.initiateAffirmCheckout).not.toHaveBeenCalled());
  });
});
