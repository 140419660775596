import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QuickBills from '../';
import {mockInvoiceJSON} from './mocks';
import client from '../../../../lib/ajax';
import * as Utils from '../../utils.js';

jest.mock('../../ShowQuickBill/QuickBillActionsForPaid', () => ({QuickBillActionsForPaid: () => 'quick bill actions for paid'}));
const defaultProps = {
  canCollectPayments: true,
  permissionBannerHidden: true
};

const mockClientGet = (_, searchParams) => {
  if (searchParams.status === 'sent') return {
    status: 200,
    response: true,
    json: jest.fn(() => Promise.resolve({...mockInvoiceJSON, results: [mockInvoiceJSON.results[1]]}))
  };
  if (searchParams.status === 'paid') return {
    status: 200,
    response: true,
    json: jest.fn(() => Promise.resolve({...mockInvoiceJSON, results: [mockInvoiceJSON.results[0]]}))
  };
  if (searchParams.status === 'viewed') return {
    status: 200,
    response: true,
    json: jest.fn(() => Promise.resolve({...mockInvoiceJSON, results: [mockInvoiceJSON.results[2]]}))
  };

  return {
    status: 200,
    response: true,
    json: jest.fn(() => Promise.resolve(mockInvoiceJSON))
  };
};

describe('QuickBills', () => {
  beforeEach(() => {
    jest.spyOn(client, 'get').mockImplementation(mockClientGet);

    jest.spyOn(Utils, 'formatDaysOut').mockImplementation(timestamp => timestamp.split('T').reverse().join('/'));
  });

  it('renders correctly', async () => {
    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));

    expect(screen.getByText('Status')).toBeVisible();
    expect(screen.getByText('Any')).toBeVisible();
    expect(screen.getByText('There are no matching invoices.')).toBeVisible();
    expect(screen.getByTestId('ap-input-text')).toBeVisible();
    await waitFor(() => expect(screen.getAllByText('Wells Fargo')).toHaveLength(2));
    await waitFor(() => expect(screen.getAllByText('BBVA Compass')).toHaveLength(1));
    () => expect(screen.getAllByText('Sent')).toHaveLength(2);
    () => expect(screen.getByText('Elmer Fudd')).toBeVisible();
    () => expect(screen.getByText('$1,000.00')).toBeVisible();
    () => expect(screen.getByText('shotgun')).toBeVisible();
    () => expect(screen.getByText('Bugs Bunny')).toBeVisible();
    () => expect(screen.getByText('$100.00')).toBeVisible();
    () => expect(screen.getByText('carrots')).toBeVisible();
  });

  it('defaults to error toast when response status is not accounted for', async () => {
    jest.spyOn(client, 'get').mockImplementation(() => ({
      status: 500,
      response: false,
      json: jest.fn(() => Promise.resolve({ error: 'something blew up'}))
    }));
    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));
    await waitFor(() => expect(screen.getByTestId('message-header')).toBeInTheDocument());
    () => expect(screen.getByText('Error')).toBeInTheDocument();
    () => expect(screen.getByText('Unable to fetch QuickBills.')).toBeInTheDocument();
  });

  it('shows a toast when the fetch fails', async () => {
    jest.spyOn(client, 'get').mockImplementation(() => ({
      status: 422,
      response: false,
      json: jest.fn(() => Promise.resolve({ error: 'something blew up'}))
    }));

    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));
    await waitFor(() => expect(screen.getByTestId('message-header')).toBeInTheDocument());

    () => expect(screen.getByText('Error')).toBeInTheDocument();
    () => expect(screen.getByText('Unable to fetch QuickBills.')).toBeInTheDocument();
  });

  it('shows a toast when the merchant is not logged in', async () => {
    jest.spyOn(client, 'get').mockImplementation(() => ({
      status: 401,
      response: false,
      json: jest.fn(() => Promise.resolve({ error: 'Not Authorized'}))
    }));
    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));
    await waitFor(() => expect(screen.getByTestId('message-header')).toBeInTheDocument());
    () => expect(screen.getByText('Not Authorized')).toBeInTheDocument();
    () => expect(screen.getByText('You do not have permission to do this operation.')).toBeInTheDocument();
  });

  it('shows only sent pending invoices when "Status" value is changed to "Sent"', async () => {
    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));
    userEvent.click(screen.getByTestId('combobox'));
    await waitFor(() => expect(screen.getAllByRole('option')[1]).toBeVisible());
    userEvent.click(screen.getAllByRole('option')[1]);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(2));
    expect(client.get).toHaveBeenCalledWith('/quick_bills?page=1&sort=created&direction=desc&status=sent', expect.objectContaining({
      direction: 'desc',
      sort: 'created',
      page: 1,
      status: 'sent'
    }));
    await waitFor(() => expect(screen.queryByText('Wiley Coyote')).toBeVisible());
    expect(screen.queryByText('Bugs Bunny')).not.toBeInTheDocument();
    expect(screen.queryByText('Elmer Fudd')).not.toBeInTheDocument();
  });

  it('shows only paid invoices when "Status" value is changed to "Paid"', async () => {
    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));
    userEvent.click(screen.getByTestId('combobox'));
    await waitFor(() => expect(screen.getAllByRole('option')[3]).toBeVisible());
    userEvent.click(screen.getAllByRole('option')[3]);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(2));
    expect(client.get).toHaveBeenCalledWith('/quick_bills?page=1&sort=created&direction=desc&status=paid', expect.objectContaining({
      direction: 'desc',
      sort: 'created',
      page: 1,
      status: 'paid'
    }));
    await waitFor(() => expect(screen.queryByText('Bugs Bunny')).toBeVisible());
    expect(screen.queryByText('Elmer Fudd')).not.toBeInTheDocument();
    expect(screen.queryByText('Wiley Coyote')).not.toBeInTheDocument();
  });

  it('shows only pending invoices when "Status" value is changed to "Viewed"', async () => {
    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));

    userEvent.click(screen.getByTestId('combobox'));
    await waitFor(() => expect(screen.getAllByRole('option')[2]).toBeVisible());
    userEvent.click(screen.getAllByRole('option')[2]);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(2));
    expect(client.get).toHaveBeenCalledWith('/quick_bills?page=1&sort=created&direction=desc&status=viewed', expect.objectContaining({
      direction: 'desc',
      sort: 'created',
      page: 1,
      status: 'viewed'
    }));
    await waitFor(() => expect(screen.queryByText('Elmer Fudd')).toBeVisible());
    expect(screen.queryByText('Bugs Bunny')).not.toBeInTheDocument();
    expect(screen.queryByText('Wiley Coyote')).not.toBeInTheDocument();
  });

  it('shows all invoices by default and when "Any" value is changed to any', async () => {
    render(<QuickBills {...defaultProps} />);
    expect(client.get).toHaveBeenCalledTimes(1);
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));
    await waitFor(() => expect(screen.queryByText('Bugs Bunny')).toBeVisible());
    expect(screen.queryByText('Elmer Fudd')).toBeVisible();
    expect(screen.queryByText('Wiley Coyote')).toBeVisible();

    userEvent.click(screen.getByTestId('combobox'));
    userEvent.click(screen.getAllByRole('option')[1]);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(2));
    expect(client.get).toHaveBeenCalledWith('/quick_bills?page=1&sort=created&direction=desc&status=sent', expect.objectContaining({
      direction: 'desc',
      sort: 'created',
      page: 1,
      status: 'sent'
    }));
    await waitFor(() => expect(screen.queryByText('Wiley Coyote')).toBeVisible());
    expect(screen.queryByText('Bugs Bunny')).not.toBeInTheDocument();
    expect(screen.queryByText('Elmer Fudd')).not.toBeInTheDocument();

    userEvent.click(screen.getByTestId('combobox'));
    userEvent.click(screen.getAllByRole('option')[0]);
    await waitFor(() => expect(client.get).toHaveBeenCalledTimes(4));
    expect(client.get).toHaveBeenCalledWith('/quick_bills?sort=created&direction=desc', expect.objectContaining({
      direction: 'desc',
      sort: 'created'
    }));
    await waitFor(() => expect(screen.queryByText('Bugs Bunny')).toBeVisible());
    expect(screen.queryByText('Elmer Fudd')).toBeVisible();
    expect(screen.queryByText('Wiley Coyote')).toBeVisible();
  });
});