import React from 'react';
import {screen, render, waitFor} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import QuickBillActionsForPaid from '../QuickBillActionsForPaid';
import client from '../../../../lib/ajax';

describe('QuickBillActionsForPaid', () => {
  beforeEach(() => {
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 200,
        json: jest.fn(() => Promise.resolve({}))
      })
    );
  });

  const invoiceCompletedWithTransactionId = {
    payment: {
      status: 'completed',
      transactionId: '123complete'
    }
  };

  it('renders', () => {
    render(<QuickBillActionsForPaid
      invoice={{
        id: 'abc123',
        transactionId: null,
        invoicePayments: []
      }}
    />);
    expect(screen.getByText('Mark As Unpaid')).toBeVisible();
  });

  it('invokes "markAsPaid" method when Mark as Unpaid button is clicked', async () => {
    render(<QuickBillActionsForPaid
      invoice={{
        id: 'abc123',
        transactionId: null,
        invoicePayments: []
      }}
    />);
    userEvent.click(screen.getByText('Mark As Unpaid'));
    await waitFor(() => expect(client.destroy).toHaveBeenCalledTimes(1));
    expect(client.destroy).toHaveBeenCalledWith('/quick_bills/abc123/mark_as_paid');
  });

  it('renders the processed message if payment is successful', async () => {
    render(<QuickBillActionsForPaid
      invoice={{
        id: 'abc123',
        transactionId: '123complete',
        invoicePayments: [invoiceCompletedWithTransactionId]
      }}
    />);
    expect(screen.getByText('This Quick Bill has been processed and cannot be edited or resent.')).toBeVisible();
    expect(screen.getByText('View Transaction')).toBeVisible();
  });

  it('renders the unauthorized message if response status is 401', async () => {
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 401,
        json: jest.fn(() => Promise.resolve({}))
      })
    );

    render(<QuickBillActionsForPaid
      invoice={{
        id: 'abc123',
        transactionId: null,
        invoicePayments: []
      }}
    />);
    userEvent.click(screen.getByText('Mark As Unpaid'));

    await waitFor(() => expect(screen.getByText('You do not have permission to do this operation')).toBeVisible());
    expect(screen.queryByText('View Transaction')).not.toBeInTheDocument();
  });

  it('renders the error message if response status is 422', async () => {
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 422,
        json: jest.fn(() => Promise.resolve({}))
      })
    );

    render(<QuickBillActionsForPaid
      invoice={{
        id: 'abc123',
        transactionId: null,
        invoicePayments: []
      }}
    />);
    userEvent.click(screen.getByText('Mark As Unpaid'));

    await waitFor(() => expect(screen.getByText('Unable to mark quick bill as unpaid')).toBeVisible());
    expect(screen.queryByText('View Transaction')).not.toBeInTheDocument();
  });

  it('renders the error message if response status is not recognized', async () => {
    jest.spyOn(client, 'destroy').mockImplementation(() =>
      Promise.resolve({
        status: 500,
        json: jest.fn(() => Promise.resolve({}))
      })
    );

    render(<QuickBillActionsForPaid
      invoice={{
        id: 'abc123',
        transactionId: null,
        invoicePayments: []
      }}
    />);
    userEvent.click(screen.getByText('Mark As Unpaid'));

    await waitFor(() => expect(screen.getByText('Unable to mark quick bill as unpaid')).toBeVisible());
    expect(screen.queryByText('View Transaction')).not.toBeInTheDocument();
  });
});
