import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import MarkQuickBillAsPaidButton from '../MarkQuickBillAsPaidButton';

describe('MarkQuickBillAsPaidButton', () => {
  const vanillaProps = {
    invoiceID: 'abc123',
    isDisabled: undefined,
    title: 'Testing'
  };

  it('renders', () => {
    render(<MarkQuickBillAsPaidButton {...vanillaProps} />);
    expect(screen.getByText('Mark As Paid')).toBeVisible();
  });

  it('opens the modal when Mark As Paid button is clicked', () => {
    render(<MarkQuickBillAsPaidButton {...vanillaProps} />);
    const textToFind = ['Close Modal Icon', 'Testing', 'Cash', 'Check', 'Credit Card', 'Other', 'Close', 'Save Changes'];
    userEvent.click(screen.getByText('Mark As Paid'));
    textToFind.forEach(text => {
      expect(screen.getByText(text)).toBeInTheDocument();
    });
  });

  it('is disabled if isDisabled prop is true', () => {
    render(<MarkQuickBillAsPaidButton {...vanillaProps} isDisabled={true} />);
    expect(screen.getByText('Mark As Paid')).toBeDisabled();
  });
});
