import AccessToken from '../AccessToken';

describe('AccessToken', () => {
  const tokenAttributes = {
    access_token: 'j3oqijasojf',
    created_at: '07/07/2023',
    error: undefined,
    expires_in: '12/31/2023',
    scope: 'affinipay',
    token_type: 'admin'
  };

  const expectedOutcome = {
    accessToken: 'j3oqijasojf',
    createdAt: '07/07/2023',
    error: undefined,
    expiresIn: '12/31/2023',
    scope: 'affinipay',
    tokenType: 'admin'
  };

  it('returns an object with correct camelized attributes', () => {
    expect(AccessToken({...tokenAttributes})).toEqual(expectedOutcome);
  });

  it('sets the error correctly', () => {
    expect(AccessToken({...tokenAttributes, error: ''})).toEqual({
      ...expectedOutcome,
      error: 'Identity service error'
    });

    expect(AccessToken({...tokenAttributes, error: 'Something blew up!'})).toEqual({
      ...expectedOutcome,
      error: 'Something blew up!'
    });
  });
});