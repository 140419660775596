import React from 'react';
import { render, screen, waitFor } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { toISODate } from '../PCIModal';
import BulkUpdateModal from '../BulkUpdateModal';
import PCICompliancesService from '../src/services/PCICompliancesService';

const modalProps = {
  filters: ['filter[status]=expired'],
  count: 5,
  isDisabled: false,
  isModalOpen: true,
  toggleModal: jest.fn(),
  onCloseAndRefresh: jest.fn()
};

describe('BulkUpdateModal', () => {
  beforeAll(() => {
    jest.spyOn(PCICompliancesService, 'bulkUpdate').mockImplementation(() => 
      Promise.resolve({ 'atomic:results': []})
    );
  });

  it('renders', () => {
    render(<BulkUpdateModal {...modalProps} />);
    const textOnRender = [
      'Change Due Date',
      'Change Status',
      'Please select a new compliance status from the dropdown options below.',
      'Save changes'
    ];
    textOnRender.forEach(text => {
      expect(screen.getByText(text)).toBeInTheDocument();
    });
  });

  it('invokes passed onCloseAndRefresh function when Save changes button is clicked', async () => {
    render(<BulkUpdateModal {...modalProps} />);
    const saveButton = screen.getByText('Save changes');

    userEvent.click(screen.getByText('Select Status'));
    userEvent.click(screen.getByText('Exempted'));
    expect(saveButton).toBeDisabled();
    userEvent.click(screen.getByTestId('bulk-update-confirm-checkbox'));
    expect(saveButton).not.toBeDisabled();
    userEvent.click(saveButton);
    await waitFor(() => expect(modalProps.onCloseAndRefresh).toHaveBeenCalled());
  });

  it('invokes passed onClose function when modal is closed', async () => {
    render(<BulkUpdateModal {...modalProps} />);
    userEvent.click(screen.getByText('Close Modal Icon'));
    await waitFor(() => expect(modalProps.toggleModal).toHaveBeenCalled());
  });

  it('calles bulk update endpoint when Save changes clicked', async () => {
    render(<BulkUpdateModal {...modalProps} />);
    const saveButton = screen.getByText('Save changes');
    
    userEvent.click(screen.getByText('Select Status'));
    userEvent.click(screen.getByText('Exempted'));
    expect(saveButton).toBeDisabled();
    userEvent.click(screen.getByTestId('bulk-update-confirm-checkbox'));
    expect(saveButton).not.toBeDisabled();
    userEvent.click(saveButton);
    await waitFor(() => expect(PCICompliancesService.bulkUpdate).toHaveBeenCalledWith(['filter[status]=expired'], 'exempted', toISODate(new Date(Date.now()))));
  });
});