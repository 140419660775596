import React from 'react';
import {screen, render} from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import SaveAndResendButton from '../SaveAndResendButton';

const defaultProps = {
  handleSaveAndResend: jest.fn(),
  submitting: false,
  contactChanged: false,
  isInvoicePending: false
};

describe('SaveAndResendButton', () => {
  it('renders', () => {
    render(<SaveAndResendButton {...defaultProps} />);
    expect(screen.getByTestId('save-resend-button')).toBeVisible();
  });

  it('is disabled if submitting', () => {
    render(<SaveAndResendButton {...defaultProps} submitting={true} />);
    expect(screen.getByTestId('save-resend-button')).toBeDisabled();
  });

  it('changes button text if in draft status', () => {
    render(<SaveAndResendButton {...defaultProps} isInvoicePending={true} />);
    expect(screen.queryByText('Save & Resend')).not.toBeInTheDocument();
    expect(screen.getByText('Save & Send')).toBeVisible();
  });

  it('clicking the button will invoke prop handleSaveAndResend', () => {
    render(<SaveAndResendButton {...defaultProps} />);
    userEvent.click(screen.getByTestId('save-resend-button'));
    expect(defaultProps.handleSaveAndResend).toHaveBeenCalledTimes(1);
  });
});